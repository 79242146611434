import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import styles from './sliding_menu.module.css'

export default function SimpleSlidingMenu({
  menuItems,
  setMarker,
  setMarkerItem = () => { },
  wrapperStyles = {},
  menuPadding = false
}) {
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  useEffect(() => {
    if (document.querySelector(`#${setMarker.idToScrollTo}`)){
      document.querySelector('#marker-ssm').style.left = document.querySelector(`#${setMarker.idToScrollTo}`).offsetLeft + 'px';
      document.querySelector('#marker-ssm').style.width = document.querySelector(`#${setMarker.idToScrollTo}`).offsetWidth + 'px';
    }
  }, [setMarker]);

  const indicator = (e) => {
    document.querySelector('#marker-ssm').style.left = e.target.offsetLeft + 'px';
    document.querySelector('#marker-ssm').style.width = e.target.offsetWidth + 'px';
  }

  const onClick = (item) => (e) => {
    setMarkerItem(item);
    indicator(e);
  }

  const onclickRight = () => {
    document.querySelector('#slider-menu-container').scrollLeft = document.querySelector('#slider-menu-container').scrollLeft + 100;
    const timer = setTimeout(() => {
      showLeft()
      showRight()
    }, 200);
    return () => clearTimeout(timer);
  }

  const onclickLeft = () => {
    document.querySelector('#slider-menu-container').scrollLeft = document.querySelector('#slider-menu-container').scrollLeft - 100
    const timer = setTimeout(() => {
      showLeft()
      showRight()
    }, 200);
    return () => clearTimeout(timer);
  }

  const showLeft = () => {
    let menu_cont = document.querySelector('#slider-menu-container')
    if(menu_cont && menu_cont.scrollLeft !== 0){
      setShowLeftArrow(true)
    } else {
      setShowLeftArrow(false)
    }
  }

  const showRight = () => {
    let menu_cont = document.querySelector('#slider-menu-container')
    let innr_menu = document.querySelector('#slider-menu-get-first')
    if(menu_cont && innr_menu && innr_menu.offsetWidth > menu_cont.offsetWidth){
      setShowRightArrow(true)
    }
    if(menu_cont.offsetWidth + menu_cont.scrollLeft === menu_cont.scrollWidth){
      setShowRightArrow(false)
    }
  }

  useEffect(() => {
    showLeft()
    showRight()
  }, []);


  return (
    <>
      <div style={wrapperStyles} id='slider-menu-container' className={`${styles.slider_menu_container} container de-pad`}>
        <div id='slider-menu-get-first' className={`${styles.slider_menu} row de-pad`}>
          {menuItems.map((item, i) =>
            <Link data-pendo-tag={item.pendoTag} to={item.href} key={`sliding-menu-${i}`} onClick={onClick(item)} id={item.idToScrollTo} className={`col ${styles.link} ${menuPadding ? '' : 'de-pad'} ${setMarker.linkText === item.linkText && styles.selected}`} value={item.idToScrollTo}>{item.linkText}</Link>
          )}
        </div>
        <div className={`${styles.marker_row}`}>
          <div id="marker-ssm" className={`${styles.marker}`}></div>
        </div>
      </div>
      {showLeftArrow &&
        <div className={styles.left_arrow_wrap}>
          <img onClick={() => onclickLeft()} className={styles.left_arrow} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/arrow-right.svg'/>
          <div className={styles.left_arrow_shadow}></div>
        </div>
      }
      {showRightArrow &&
        <div className={styles.right_arrow_wrap}>
          <img onClick={() => onclickRight()} className={styles.right_arrow} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/arrow-right.svg'/>
          <div className={styles.right_arrow_shadow}></div>
        </div>
      }
    </>
  )
}
