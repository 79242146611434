import React from "react";
import { Link } from "react-router-dom"
import VideoType2 from '../../video_sections/video_type_2';
import styles from '../style.module.css'

export default function Video({
  bg_image,
  video_link,
  headline,
  cta,
  cta_url,
}) {
  return (
    <div data-pendo-tag="Custom post - Find my mentor" className={styles.custom_post_video_container}>
      <VideoType2 margButton={false} bg={bg_image} videoLink={video_link} bgCover margButton />
      <Link to={cta_url}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-6">
              <p className={styles.custom_post_video_headline}>{headline}</p>
            </div>
            <div className="col-md-12 col-lg-6">
              <button className={styles.custom_post_video_cta}>{cta}</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
