export const createBrowserCall = (
    conversation_id,
    successCallback
) => {
  fetch(`/api/v1/browser_calls`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      conversation_id: conversation_id
    })
  })
  .then(response => response.json())
  .then(data => {
    successCallback(data);
  })
}

export const getBrowserCall = (
  browserCallId,
  successCallback
) => {
  fetch(`/api/v1/browser_calls/${browserCallId}`, {
    headers: {
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(data => {
    successCallback(data);
  })
}

export const updateCall = (
  browserCallId,
  params,
  successCallback
) => {
  fetch(`/api/v1/browser_calls/${browserCallId}`, {
    method: 'PATCH',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: browserCallId,
      status: params.status,
      feedback_rating: params.feedback_rating,
      feedback_comments: params.feedback_comments,
    })
  })
    .then(response => response.json())
    .then(data => {
      successCallback(data);
    })
}
