import React, { useState, useEffect, useContext } from 'react'
import Context from '../../../utils/context';
import PatientPartnerQuestionnaire from '../../questionnaire/patient_partner'

export default function MentorMatch({}) {
  const context = useContext(Context);
  let defaultAnswers = {};
  let defaultProgress = {
    view: 'contact-info',
    questionNumber: null,
  };
  let defaultContactId = null;
  if (context.auth.isLoggedIn()) {
    const currentUser = context.auth.getCurrentUser();
    defaultAnswers.name = currentUser.name;
    defaultAnswers.email = currentUser.email;
    defaultAnswers.phone = currentUser.phone;
    defaultAnswers.zipcode = currentUser.current_profile.zipcode;
    defaultAnswers.gender = currentUser.gender;
    defaultAnswers.selectedProcedures = currentUser.current_profile.procedures;
    defaultAnswers.selectedSurgeries = currentUser.current_profile.surgeries_or_treatments;
    if (currentUser.height) {
      defaultAnswers.feet = Math.floor(currentUser.height / 12);
      defaultAnswers.inches = currentUser.height - (Math.floor(currentUser.height / 12)) * 12;
    }
    defaultAnswers.currentWeight = currentUser.weight;
    if (currentUser.date_of_birth) {
      defaultAnswers.date_of_birth = new Date(currentUser.date_of_birth);
    }
    defaultAnswers.preConditions = currentUser.current_profile.health_conditions && currentUser.current_profile.health_conditions.split(',');
    defaultProgress = {
      view: 'select-procedures',
      questionNumber: null,
    };
    defaultContactId = currentUser.current_profile.other_data.contact_id;
  }
  const [answers, setAnswers] = useState(defaultAnswers);
  const [progress, setProgress] = useState(defaultProgress);
  const [contactId, setContactId] = useState(defaultContactId);
  const [patientPartners, setPatientPartners] = useState([]);

  return (
    <>
      <div style={{ "minHeight" : '200px', "marginTop": '0px' }}>
        <div id="questionnaire" className="marg-bottom-20">
          <div className="row justify-content-center h-100">
            <div className="col my-auto">
              <PatientPartnerQuestionnaire
                globalAnswers={answers}
                setGlobalAnswers={setAnswers}
                progress={progress}
                setProgress={setProgress}
                contactId={contactId}
                setContactIdAction={setContactId}
                patientPartners={patientPartners}
                setPatientPartners={setPatientPartners}
                directConnection={true}
                gtm={`Member Match`}
                origin={`Member Match`}
                doNotShowContactInfo={context.auth.isLoggedIn()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
