import React, { useContext, useEffect, useState } from "react";
import Account from "./account";
import Notifications from "./notifications";
import styles from "./style.module.css";
import Context from "../../utils/context";
import DeactivateAccount from "./deactivate_account";

export default function Settings() {
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();

  return (
    <div className={`${styles.settings} container`}>
      <div className={`row justify-content-center`}>
        <div className={`col-md-8 col`}>
          <div className={styles.mobile_account}>
            <Account isMobile={isMobile} />
          </div>
          <div>
            <Notifications/>
          </div>
          <div>
            <DeactivateAccount/>
          </div>
        </div>
      </div>
    </div>
  
  );
}
