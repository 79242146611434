import React, { useState } from 'react'

export default function OtherTextfield({
    onBack,
    onSubmit,
    title,
    para,
    keyForAnswer,
    yesState,
    required = true,
    cta = 'Submit',
    answers,
    gtm,
    placeholder = 'Type other...'
}) {

    const [other, setOther] = useState(answers[keyForAnswer] || '');

    const submit = (e) => {
        e.preventDefault();
        onSubmit({ [keyForAnswer]: other }, yesState);
    }

    return (
        <div>
            <form className="other-text-field">
                <h5 className="question">
                    {title}
                </h5>
                <p className="question">
                    {para}
                </p>
                <div className="marg-top-20 marg-bottom-20 preexisting-conditions material-input light marg-center">
                    <textarea className="material-input light" placeholder={placeholder} type="text" name="other" value={other} onChange={(event) => setOther(event.target.value)} />
                    <span className="bar"></span>
                </div>
                <div className="container">
                    <div className="row button-holder">
                        <button className="back col-lg-6" type="button" onClick={onBack} data-gtm-click-label={`${gtm} - Back`}>Back</button>
                        <button disabled={`${!required ? '' : other === '' ? 'disabled' : ''}`} className={`gtm-preexisting-conditions submit col-lg-6`} type="submit" onClick={(e) => submit(e)} data-gtm-click-label={`${gtm} - ${cta}`}>{cta}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
