import React, { useState, useEffect } from 'react'
import SimpleSlidingMenu from '../../../simple_sliding_menu';
import StatusColumn from '../../../providers/status_column';
import { toSentDay, toSentTime } from '../../../../helpers/index'
import TextareaAutosize from 'react-textarea-autosize';
import styles from './surgeon_crm_convo.module.css'
import './surgeon_admin_patient_panel.css'

export default function SurgeonAdminPatientPanel({
    patientAssistantInfo,
    sendNote,
    onBackClick,
    update
}) {
    const [view, setView] = useState(patientAssistantInfo.status === 'Not Contacted' ? 'PatientDetails' : 'PatientActivity');
    const [note, setNote] = useState('');
    const [heldKey, setHeldKey] = useState(null);

    useEffect(() => {
        update()
    }, [view]);

    // Listens to know if the device is a mobile device START
    const [isMobile, setDesktop] = useState(window.innerWidth < 768);

    const updateMedia = () => {
        setDesktop(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });
    // Listens to know if the device is a mobile device END

    const renderSurgery = () => {
        if (patientAssistantInfo.surgeries_or_treatments) {
            if (patientAssistantInfo.surgeries_or_treatments.length === 0) {
                if (patientAssistantInfo.selected_procedures) {
                    return patientAssistantInfo.selected_procedures.join(', ');
                }
            }
            else if (patientAssistantInfo.surgeries_or_treatments[0] === "I'm not sure") {
                if (patientAssistantInfo.selected_procedures) {
                    return patientAssistantInfo.selected_procedures.join(', ');
                }
            }
            else {
                return patientAssistantInfo.surgeries_or_treatments.join(', ');
            }
        }

        return '';
    }

    
    return (
        <div className={`surgeon-admin ${styles.profile_view}`}>
            {isMobile &&
                <div style={{ cursor: 'pointer' }} onClick={() => onBackClick()} className="col-2 my-auto marg-top-20">
                    <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.183 2.56066C12.7688 1.97487 12.7688 1.02513 12.183 0.43934C11.5972 -0.146447 10.6475 -0.146447 10.0617 0.43934L0.43934 10.0617C-0.146447 10.6475 -0.146447 11.5972 0.43934 12.183L10.0617 21.8054C10.6475 22.3912 11.5972 22.3912 12.183 21.8054C12.7688 21.2196 12.7688 20.2699 12.183 19.6841L3.62132 11.1224L12.183 2.56066Z" fill="#59B6BE" /></svg>
                </div>
            }
            <div className={`${patientAssistantInfo.patient.type === 'SuperUser' ? 'chat-room-super-user-avatar-lg' : 'chat-room-pp-avatar-lg'} marg-bottom-10 marg-top-10`} style={{ backgroundImage: `url(${patientAssistantInfo.patient.photo})` }} />
            <p className="bold text-center ou-name">
                {patientAssistantInfo.patient.full_name}
            </p>
            <p className="bold text-center ou-name">
                <a className="bold" data-pendo-tag="surgeon CRM - Referral's phone number" href={`tel:+1${patientAssistantInfo.patient.phone}`}>{patientAssistantInfo.patient.phone}</a>
            </p>
            <p style={{ lineBreak: 'anywhere' }} className="bold text-center ou-name">
                <a data-pendo-tag="surgeon CRM - Referral's email" href={`mailto:${patientAssistantInfo.patient.email}`}>{patientAssistantInfo.patient.email}</a>
            </p>
            <div style={{ position: 'relative', zIndex: '11' }}>
                <StatusColumn
                    paiStatus={patientAssistantInfo.status}
                    uuid={patientAssistantInfo.uuid}
                    pendoTag="surgeon CRM chat window - status dropdown"
                />
            </div>
            <SimpleSlidingMenu
                menuItems={[
                    {
                        "idToScrollTo": "PatientDetails",
                        "linkText": "Patient Details",
                        pendoTag: 'surgeon crm chat window - patient details'
                    },
                    {
                        "idToScrollTo": "PatientActivity",
                        "linkText": "Patient Activity",
                        pendoTag: 'surgeon crm chat window - patient activity'
                    },
                ]}
                setView={(view) => setView(view)}
                defaultState={view}
            />
            {view === 'PatientDetails' &&
                <div className={`other-p-bio-sec p-info-para ${isMobile ? 'marg-bottom-40' : ''}`}>
                    {patientAssistantInfo.surgeries_or_treatments &&
                        <>
                            <p className={styles.detail_head}>Surgery:</p>
                            <p className={styles.detail_para}>{renderSurgery()}</p>
                        </>
                    }
                    {patientAssistantInfo.patient.bmi &&
                        <>
                            <p className={styles.detail_head}>BMI:</p>
                            <p className={styles.detail_para}>{patientAssistantInfo.patient.bmi}</p>
                        </>
                    }
                    {patientAssistantInfo.patient.health_conditions && patientAssistantInfo.patient.health_conditions.length > 0 &&
                        <>
                            <p className={styles.detail_head}>Pre-conditions:</p>
                            <p className={styles.detail_para}>{patientAssistantInfo.patient.health_conditions}</p>
                        </>
                    }
                    {patientAssistantInfo.patient.symptoms &&
                        <>
                            <p className={styles.detail_head}>Symptoms:</p>
                            <p className={styles.detail_para}>{patientAssistantInfo.patient.symptoms}</p>
                        </>
                    }
                    {patientAssistantInfo.patient.spine_questionnaire_answer && (
                        <>
                            {patientAssistantInfo.patient.spine_questionnaire_answer.where_back_pain &&
                                <>
                                    <p className={styles.detail_head}>Pre-conditions:</p>
                                    <p className={styles.detail_para}>{patientAssistantInfo.patient.spine_questionnaire_answer.where_back_pain.join(', ')} pain</p>
                                </>
                            }
                            {patientAssistantInfo.patient.spine_questionnaire_answer.previous_treatments &&
                                <>
                                    <p className={styles.detail_head}>Previous treatments:</p>
                                    <p className={styles.detail_para}>{patientAssistantInfo.patient.spine_questionnaire_answer.previous_treatments.join(', ')}</p>
                                </>
                            }
                        </>
                    )
                    }
                    <>
                        <p className={styles.detail_head}>Seen provider before:</p>
                        <p className={styles.detail_para}>{patientAssistantInfo.patient.seen_surgeon ? 'Yes' : 'No'}</p>
                    </>
                    {patientAssistantInfo.patient.insurance_carriers && patientAssistantInfo.patient.insurance_carriers.length > 0 &&
                        <>
                            <p className={styles.detail_head}>Insurance carrier:</p>
                            <p className={styles.detail_para}>{patientAssistantInfo.patient.insurance_carriers.join(', ')}</p>
                        </>
                    }
                    {patientAssistantInfo.patient.date_of_birth &&
                        <>
                            <p className={styles.detail_head}>Date of birth:</p>
                            <p className={styles.detail_para}>{patientAssistantInfo.patient.date_of_birth}</p>
                        </>
                    }
                    {patientAssistantInfo.surgeon &&
                        <>
                            <p className={styles.detail_head}>Requested surgeon:</p>
                            <p className={styles.detail_para}>{patientAssistantInfo.surgeon.name}</p>
                        </>
                    }
                </div>
            }
            {view === 'PatientActivity' &&
                <>
                    <div className={`other-p-bio-sec ${isMobile ? 'marg-bottom-40' : ''}`}>
                        {patientAssistantInfo.patient &&
                            <div className="patient-notes-area">
                                <p>Patient Notes</p>
                                <div className="patient-notes-area-position-parent">
                                    <TextareaAutosize
                                        className={`material-input message-box`}
                                        value={note}
                                        onChange={(event) => setNote(event.target.value)}
                                        onKeyDown={(e) => { e.key === 'Enter' && heldKey !== 'Shift' ? send(e) : e.key === 'Shift' ? setHeldKey(e.key) : null }}
                                        onKeyUp={(e) => { e.key === heldKey ? setHeldKey(null) : null }}
                                        onFocus={() => console.log('is typing')}
                                        placeholder={`Aa`}
                                        maxRows={8}
                                    >
                                    </TextareaAutosize>
                                    <button data-pendo-tag="surgeon CRM - add note button" disabled={note !== '' ? '' : 'disabled'} onClick={() => sendNote(patientAssistantInfo.uuid, 'PatientAssistantInfoLogs::AddNote', { note: note, conversation_id: patientAssistantInfo.conversation.uuid }, () => setNote(''))} className="marg-top-10">Add note</button>
                                </div>
                            </div>
                        }
                        <p className="marg-top-20 marg-bottom-5" style={{ 'color': '#59B6BE' }}>Activity Log</p>
                        <hr className="marg-top-0" style={{ 'borderColor': '#59B6BE' }} />
                        <div className={`${styles.message_container_otr}`}>
                            <div className={`${styles.message_container}`}>
                                {patientAssistantInfo.patient_assistant_info_logs.map((note) => <>
                                    <div className={`marg-top-10 row`}>
                                        <div className='col-8'>
                                            <p style={{ textAlign: 'left' }}>{note.html}</p>
                                        </div>
                                        <div className='col-4'>
                                            <p style={{ textAlign: 'right' }}>{toSentDay(note.created_at)} {toSentTime(note.created_at)}</p>
                                        </div>
                                    </div>
                                    {note.type.includes('AddNote') &&
                                        <div className='row'>
                                            <div className='col'>
                                                <p style={{ padding: '10px', backgroundColor: '#F2F2F2', borderRadius: '5px', lineBreak: 'anywhere' }}>{note.description}</p>
                                            </div>
                                        </div>
                                    }
                                </>)}
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )}
