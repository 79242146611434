import React, { useState } from "react";
import { Link } from "react-router-dom"
import styles from '../style.module.css'
import { Modal, ModalGateway } from "react-images";
import SurgeonsColumn from "../../surgeons_column";

export default function Image({
  image_link,
  headline,
  cta,
  cta_url,
  surgeon,
  post
}) {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = () => {
      setViewerIsOpen(true);
    }
    const closeLightbox = () => {
      setViewerIsOpen(false);
    };

  return (
    <div data-pendo-tag="Custom post - Search doctors" className={styles.custom_post_video_container}>
      {post.custom_post_identifier && post.custom_post_identifier.includes('find_doctor') && 
        <SurgeonsColumn showSeeMore={false} showRequestAnAppointment={false} surgeon={surgeon} clickable={false}/>
      }
      {image_link != "" &&
      <img onClick={openLightbox} alt = 'find-surgeon' style={{width: '646px'}} src={image_link} />
      }
        <a href={cta_url}>
          <div className="container">
            <div className="row justify-content-center" style={{display: 'flex'}}>
              <div className="col-md-12 col-lg-6">
                <p className={styles.custom_post_video_headline}>{headline}</p>
              </div>
              <div className="col-md-12 col-lg-6">
                <button className={styles.custom_post_video_cta}>{cta}</button>
              </div>
            </div>
          </div>
        </a>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <img onClick={openLightbox} alt = 'find-surgeon' style={{width: '646px'}} src={image_link} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}
