import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createPaymentIntent } from '../../../api/payments';
import styles from './index.module.css'
import StripeCardInput from "./stripe_card_input.jsx";
import LoadingScreen from '../../loading_screen'
import ErrorMessage from '../../ui_kit/utilities/error_message'

export default function CheckoutForm({
  checkoutItems = [],
  checkoutTxt = '',
  showTotal = false,
  ctaText = 'Pay now',
  beforeSend = () => {}
}) {
  const stripePromise = loadStripe(gon.stripe_pk);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (checkoutItems.length > 0) createPaymentIntent({ items: checkoutItems }, (data) => setPaymentIntent(data), (data) => setError(data.error))
  }, [checkoutItems]);

  return (
    <div className='container'>
      <div className={`row ${styles.checkout_row}`}>
        <div className={`${showTotal ? 'col-md-7' : 'col'}`}>
          <div style={{ width: '90%', margin: '0px auto' }}>
            {paymentIntent && paymentIntent.payment_intent !== '' ?
              <Elements options={{ clientSecret: paymentIntent.payment_intent, appearance: { theme: 'flat' } }} stripe={stripePromise}>
                <StripeCardInput beforeSend={beforeSend} ctaText={ctaText} clientSecret={paymentIntent.payment_intent} amount={paymentIntent.amount} />
              </Elements>
              :
              error === null ? <LoadingScreen /> : <ErrorMessage error={error} />
            }
            {checkoutTxt !== '' && <>
              <hr style={{ marginBottom: '40px', marginTop: '0px' }} />
              <p className={styles.checkout_txt}>{checkoutTxt}</p>
            </>}
          </div>
        </div>
        {showTotal &&
          <div className='col-md-5'>
            <div className={styles.checkout_items_cont}>
              {checkoutItems.map((item, i) =>
                <div style={{ marginTop: i === 0 ? '' : '15px' }} key={i}>
                  <div className={styles.txt_cont}>
                    <p>{item.name}</p>
                    <p>&#36;{item.month_price}</p>
                  </div>
                  <p className={styles.summary}>{item.description}</p>
                </div>
              )}
              <hr />
              <div className={styles.txt_cont}>
                <p>Today's total</p>
                <p>&#36;{checkoutItems.reduce((a, b) => +a + +b.month_price, 0)}</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}