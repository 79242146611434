import React, { Component } from 'react'
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function MfDiagnosisTime({
  value,
  onSubmit,
  onBack
}) {
  return (
    <OptionsAndOther
      answers={{ mfDiagnosisTime: value}}
      keyForAnswer={'mfDiagnosisTime'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={[
        '0 - 1 years',
        '1 - 5 years',
        '5 - 10 years',
        '10 years or more',
        'Have not been diagnosed with MF',
      ]}
      title={`How long have you or the patient been diagnosed with Primary or Secondary Myelofibrosis (MF)? (Provide best guess)`}
      para={``}
      showOther={false}
      ctaTxt='Next'
      selectOne
    />
  )
}
