export const getProfileStatuses = (profiles, onSuccess, onError) => {
  fetch(`/api/v1/profile_statuses`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ profiles })
  })
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      onSuccess(data.profile_statuses);
    }
    else {
      onError(data.errors);
    }
  });
}
