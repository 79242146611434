export const contactSync = (
  name,
  email,
  answer,
  finishedQuestionnaire,
  bookedMeeting,
  origin,
  patientPartner,
  surgery,
  contactId,
  questionnaireProgress,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/patient_partner_scheduled_contacts',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        ...window.utmInfo,
        name: name,
        email: email,
        questionnaire_state: answer,
        finished_questionnaire: finishedQuestionnaire,
        origin: origin,
        booked_meeting: bookedMeeting,
        patient_partner: patientPartner,
        surgery: surgery,
        contact_id: contactId,
        questionnaire_progress: questionnaireProgress,
      })
    }
  )
    .then(response => response.json())
    .then(data => {
      if (successFunction) {
        successFunction(data);
      }
    })
}

export const contactSyncSurgeonMatch = (
  name,
  email,
  phone,
  zipcode,
  answer,
  finishedQuestionnaire,
  requestedAppointment,
  origin,
  surgeon,
  surgery,
  contactId,
  questionnaireProgress,
  virtualConsultation,
  successFunction,
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/weight_loss_surgeon_match_contacts',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        ...window.utmInfo,
        name: name,
        email: email,
        phone: phone,
        zipcode: zipcode,
        questionnaire_state: answer,
        finished_questionnaire: finishedQuestionnaire,
        origin: origin,
        requested_appointment: requestedAppointment,
        surgeon: surgeon,
        surgery: surgery,
        contact_id: contactId,
        questionnaire_progress: questionnaireProgress,
        virtual_consultation: virtualConsultation
      })
    }
  )
    .then(response => response.json())
    .then(data => {
      if (successFunction) {
        successFunction(data);
      }
    })
}

export const createEmailContact = (
  email,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/email_contacts',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        ...window.utmInfo,
        email: email,
      })
    }
  )
  .then(successFunction)
}

export const findPreviousContact = (email, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/patient_partner_scheduled_contacts/find_previous_contact',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        email: email,
      })
    }
  )
  .then(response => response.json())
  .then(data => {
    if (successFunction) {
      successFunction(data.previous_contact);
    }
  });
}

export const findPreviousWeightLossSurgeonMatchContact = (email, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/weight_loss_surgeon_match_contacts/find_previous_contact',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        email: email,
      })
    }
  )
  .then(response => response.json())
  .then(data => {
    if (successFunction) {
      successFunction(data.previous_contact);
    }
  });
}
