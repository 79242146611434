import React, { useContext } from "react";
import styles from "../index.module.css";
import styles1 from "../../posts/createpost.module.css";
import { Link } from "react-router-dom";
import Context from "../../../utils/context";

export default function FindPeoplePopup({ removeFindPeopleWidgetOnClick }) {
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();

  return (
    <div
      className="content_container marg-bottom-20 marg-top-20"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div className={styles.container_edge}></div>
      <div className="container">
        <div
          className={`${styles1.create_new_banner}`}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <h4 className={styles.title}>Find people like you</h4>
          <span
            onClick={removeFindPeopleWidgetOnClick}
            className={`${styles1.close}`}
          >
            <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg" />
          </span>
        </div>
        <div className={`${styles.body} marg-top-20`}>
          You might notice some members are suggested for you. This means their
          profiles are similar to yours. For the most relevant recommendations,
          update your profile and find people just like you!
        </div>
        <div
          className={`row ${styles1.cta_wrap}`}
          style={{ display: "flex", marginBottom: "15px" }}
        >
           <div className="col-md-12">
          <button
            onClick={removeFindPeopleWidgetOnClick}
            className={`${styles1.create_btn} liner`}
            style={{
              color: "black",
              border: "none",
            }}
          >
            Not now
          </button>
          <Link data-pendo-tag="welcome experience - update my profile" to={`/member/profile/${user.slug}/about-me`}>
            <button
              className={`${styles1.create_btn}`}
              onClick={removeFindPeopleWidgetOnClick}
              style={{float: "right"}}
            >
              Update my profile
            </button>
          </Link>
        </div>
        </div>
      </div>
    </div>
  );
}
