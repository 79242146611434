import React, { useState, useContext } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom';
import Context from '../../../../../utils/context';
import Age from '../../../../questionnaire/age';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function UterineFibroidsPreSurvey({
  path,
  rootPath,
  findMentor,
  surgeonReferredMentorMatch,
  update,
  gtm = ''
}) {
  let history = useHistory();
  const context = useContext(Context);
  const [currentBirthday, setCurrentBirthday] = useState(context.auth.isLoggedIn() ? context.auth.getCurrentUser().date_of_birth : null)
  const [familiarWithFibrosis, setFamiliarWithFibrosis] = useState(surgeonReferredMentorMatch.familiar_with_fibrosis || null)
  const [topics, setTopics] = useState(surgeonReferredMentorMatch.topics || null)

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Age
          title='What is your birthday?'
          para='Receive recommendations and resources relatable to your age group.'
          answers={{ date_of_birth: currentBirthday }}
          onSubmit={(answers) => {
            setCurrentBirthday(answers.date_of_birth);
            context.auth.userUpdate({ date_of_birth: answers.date_of_birth }, () => history.push(`${path}/familiar`));
          }}
          gtm={gtm}
          cta="Next"
        />
      </Route>
      <Route exact path={`${path}/familiar`}>
      <div className={`row`}>
        <div className="col-sm-2">
        </div>
        <div className="col-sm-8">
          <OptionsAndOther
            answers={{ treatment_awareness: familiarWithFibrosis}}
            keyForAnswer={'treatment_awareness'}
            onSubmit={(answers) => {
              update({ treatment_awareness: answers['treatment_awareness'] });
              setFamiliarWithFibrosis(answers['treatment_awareness']);
              history.push(`${path}/topics`);
            }}
            onBack={() => {
              history.push(`${path}`)
            }}
            choices={[
              'Very familiar',
              'Somewhat familiar',
              'Not familiar at all',
            ]}
            title={`How familiar are you with uterine fibroid treatments?`}
            ctaTxt='Next'
            showOther={false}
          />
        </div>
        <div className="col-sm-2">
        </div>
      </div>
      </Route>
      <Route exact path={`${path}/topics`}>
        <div className={`row`}>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-8">
            <OptionsAndOther
                answers={{ topics: topics}}
                keyForAnswer={'topics'}
                onSubmit={(answers) => {
                  update({ conversation_topics: answers['topics'] });
                  setTopics(answers['topics']);
                  history.push(`${rootPath}/loading`);
                  const timer = setTimeout(() => {
                    findMentor();
                  }, 3000);
                  return () => clearTimeout(timer);
                }}
                onBack={() => {
                  history.push(`${path}/familiar`)
                }}
                choices={[
                  'Coping with a fibroids diagnosis',
                  'Tips for managing symptoms',
                  'Fibroid treatment options',
                  'Tips for finding a doctor'
                ]}
                title="What do you want to talk to your mentor about? (Select all that apply)"
                para="We'll let your mentor know so you can get the most out of your conversations with them."
                otherTxt="Enter other topics here..."
                ctaTxt='Submit'
              />
            </div>
            <div className="col-sm-2">
            </div>
          </div>
        </Route>
    </Switch>
  )
}
