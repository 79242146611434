import React, { useState, useEffect } from 'react'
import DatePickerWrapper from '../date_picker_wrapper';
import ErrorMessage from '../ui_kit/utilities/error_message';

export default function DateOfBirth({
  answers,
  onSubmit,
  onBack,
  gtm,
  description = 'This information will help the surgeon understand how they can support you.'
}) {
  const [dateOfBirth, setDateOfBirth] = useState(answers.dateOfBirth);
  let disabled = 'disabled';
  if (dateOfBirth) {
    disabled = '';
  }

  const validate = () => {
  }

  return (
    <div className="row ic-question">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <h5 className="question">
          What is your date of birth?
        </h5>
        <p className="question-sub-headline marg-bottom-30 text-left">
          {description}
        </p>
        <DatePickerWrapper
            selected={dateOfBirth}
            onChange={date => setDateOfBirth(date)}
            maxDate={new Date()}
            wrapperClassName={'react-datepicker-wrapper material-input marg-bottom-30'}
            placeholderText={'MM/DD/YYYY'}
            onBlur={validate}
            light
        />

        <div className="row button-holder">
          {
            onBack && <button data-gtm-click-label={`${gtm} Date of Birth Back`} className="back col-6" type="button" onClick={() => onBack()}>Back</button>
          }

          <button data-gtm-click-label={`${gtm} Date of Birth Submit`} disabled={disabled} className={`submit col-6`} type="submit" onClick={(e) => {e.preventDefault();onSubmit({ ...answers, dateOfBirth: dateOfBirth });}}>Next</button>
        </div>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
