export const updateAnswersAction = (item) => {
  return {
    type: 'UPDATE_ANSWERS',
    item
  }
}

export const setQuestionnaireStateAction = (item) => {
  return {
    type: 'SET_QUESTIONNAIRE_STATE',
    item
  }
}
