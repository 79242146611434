import React, { } from 'react'
import './floating_box.css'

export default function FloatingBox() {

  return (
      <div className="container floating-box">
        <div className="row">
          <div className="col-lg-5 leftside">
            <img alt="bariatric-patient-partner" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/steptwo.png"/>
          </div>
          <div className="col-lg-7 my-auto rightside">
            <h2 className="marg-bottom-30">
              What is the PatientPartner program?
            </h2>
            <p className="marg-bottom-10">
              With PatientPartner&trade;, you can talk to someone who understand what it's like to be where you are and recover from surgery. PatientPartners&trade; are recovered patients volunteering their time to answer your questions based on their experiences
            </p>
          </div>
        </div>
      </div>
  )
}
