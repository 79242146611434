import React, { useState, useEffect, useContext } from 'react'
import { getMatchedSurgeon, requestAppointment } from '../../../api/surgeon_referred_mentor_matches'
import PlatformModal from '../../ui_kit/modals/platform_modal'
import LoadingScreen from '../../loading_screen'
import SuccessPanel from '../../success_panel'
import SurgeonsColumn from '../../surgeons_column';
import styles from '../request_appointment_modal/index.module.css'

export default function DisplayMatchModal({
  setModal,
  patientName,
  surgeonReferredMentorMatchId,
}) {
  const [view, setView] = useState('loading');
  const [surgeon, setSurgeon] = useState({});

  useEffect(() => {
    getMatchedSurgeon(surgeonReferredMentorMatchId, (data) => {
      console.log('data', data);
      setSurgeon(data);
      setView('intro')
    })
  }, []);

  const onSubmit = () => {
    requestAppointment(surgeonReferredMentorMatchId, () => {
      setView('success');
    });
  }


  if (view === 'loading') {
    return (
      <PlatformModal
        title={`Finding the best provider for you!`}
        setModal={setModal}
        content={(<LoadingScreen />)}
      />
    )
  }

  if (view === 'success') {
    return (
      <PlatformModal
        title={`You have been matched with ${surgeon.name}`}
        setModal={setModal}
        width={8}
        content={(<SuccessPanel
          title="Thank you!"
          content={`${surgeon.name}'s office will call you in 24-48 hours to confirm your insurance plan coverage and appointment.`}
        />)}
        submitCta={'Close'}
        onSubmit={() => setModal(false)}
        onCancel={null}
      />
    )
  }

  return (
    <PlatformModal
      title={`You have been matched with ${surgeon.name}`}
      setModal={setModal}
      width={8}
      content={(
        <div className="container">
          <div className="row">
            <div className={`col-12 marg-bottom-20`}>
              <p style={{fontWeight: 700}} className={`${styles.txt}`}>Chat with a specialist to learn more about your fibroid management options and schedule a consultation.</p>
            </div>
            <div className="col-12">
              <SurgeonsColumn
                surgeon={surgeon}
                showRequestAnAppointment={false}
                seeMoreLink={`/surgeon/${surgeon.slug}`}
                onQuestionnaire
              />
            </div>
          </div>
        </div>
      )}
      submitCta={'Request appointment'}
      submitGtmClickLabel={`Surgeon Referral Request Appointment`}
      onSubmit={onSubmit}
      onCancel={null}
    />
  )
}
