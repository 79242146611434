import React, { Component, useState, useEffect } from 'react'
import './index.css'
import HowItWorks from '../how_it_works'

export default function SuccessPanel({
  title,
  content,
  videoLink,
  infoHIW,
  cta,
  centerTitle = false
}) {
  return (
    <div className="success-panel text-left">
      <div className="marg-bottom-20" style={{ textAlign: centerTitle ? 'center' : '' }}>
        <svg style={{marginRight: '15px'}} className="inline marg-bottom-0" viewBox="0 0 512 512"><g><g><path fill="#173959" d="M497.36,69.995c-7.532-7.545-19.753-7.558-27.285-0.032L238.582,300.845l-83.522-90.713c-7.217-7.834-19.419-8.342-27.266-1.126c-7.841,7.217-8.343,19.425-1.126,27.266l97.126,105.481c3.557,3.866,8.535,6.111,13.784,6.22c0.141,0.006,0.277,0.006,0.412,0.006c5.101,0,10.008-2.026,13.623-5.628L497.322,97.286C504.873,89.761,504.886,77.54,497.36,69.995z"/></g></g><g><g><path fill="#173959" d="M492.703,236.703c-10.658,0-19.296,8.638-19.296,19.297c0,119.883-97.524,217.407-217.407,217.407c-119.876,0-217.407-97.524-217.407-217.407c0-119.876,97.531-217.407,217.407-217.407c10.658,0,19.297-8.638,19.297-19.296C275.297,8.638,266.658,0,256,0C114.84,0,0,114.84,0,256c0,141.154,114.84,256,256,256c141.154,0,256-114.846,256-256C512,245.342,503.362,236.703,492.703,236.703z"/></g></g></svg>
        <h2 className="text-left question-headline marg-bottom-20 inline">{title}</h2>
      </div>
        <p className="text-left marg-bottom-30">
          {content}
        </p>
        {cta}

        {infoHIW &&
          <HowItWorks
            showTitle = {false}
            bublets={infoHIW}
          />
        }
        {
          false && (
            <div class="success-panel-video-container">
              <iframe class="success-panel-responsive-iframe" src={videoLink} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"></iframe>
            </div>
          )
        }
    </div>
  );
}
