import React, { useState } from 'react'

export default function BedsideManner({
  answers,
  onSubmit,
  onBack,
  headline = 'Do you prefer your surgeon to have a warm and friendly bedside manner or to the point?',
  para = 'This information will help the surgeon understand how they can support you.',
  choices = [
    { value: 'Warm and friendly'},
    { value: 'Somewhere in the middle'},
    { value: 'To the point'}
  ],
  gtm,
}) {
  const [bedsideManner, setBedsideManner] = useState(answers.bedsideManner || '');

  const onClick = (value) => () => {
    setBedsideManner(value);
  }

  const submit = (e) => {
    e.preventDefault();
    onSubmit({...answers, bedsideManner: bedsideManner});
  }

  return (
    <div className={`row bedsideMannerQuestion`}>
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <div>
          <form id="plans">
            <h5 className="question">
            {headline}
            </h5>
            <p className="question">
            {para}
            </p>
            {choices.map((choice) => (
              <div key={choice.value} className="tile two-column">
                <label key={choice.value} className={`preexisting ${bedsideManner === choice.value ? 'activeCheckbox' : ''}`}><input onClick={onClick(choice.value)} type="checkbox" name={choice.value} value="value" />
                  {choice.icon &&
                    <span style={{width: '20px'}} className="marg-right-20 bedsideManner-icon-holder">{choice.icon}</span>
                  }
                  {choice.value}
                </label>
              </div>
            ))}
            <div className="row button-holder">
              <button data-gtm-click-label={`${gtm} bedside manner - Back`} className="back col-6" type="button" onClick={onBack} >Back</button>
              <button data-gtm-click-label={`${gtm} bedside manner - Submit`} disabled={`${bedsideManner === '' ? 'disabled' : ''}`} className={` submit col-6`} type="submit" onClick={(e) => submit(e)}>Next</button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
