import React, { useState, useContext, useEffect } from "react";
import Divider from "../ui_kit/utilities/divider";
import SimpleAvatar from "../ui_kit/utilities/simple_avatar";
import VerticalAvatar from "../ui_kit/utilities/simple_avatar/vertical_display";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { getRecommendedMembers } from "../../api/profiles";
import LoadingScreen from "../loading_screen";
import Context from "../../utils/context";

export default function SuggestedMembers({ isInline = true }) {
  const [isLoading, setIsLoading] = useState(true);
  const [suggestedMembers, setSuggestedMembers] = useState([]);
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile()

  useEffect(() => {
    getRecommendedProfiles();
  }, []);

  const getRecommendedProfiles = (shuffle = false) => {
    setIsLoading(true);
    getRecommendedMembers(shuffle, (data) => {
      setSuggestedMembers(data);
      setIsLoading(false);
    });
  };

  let renderMembers = suggestedMembers
  if (isMobile){
    renderMembers = renderMembers.slice(0,2)
  }

  return (
    <div className="container">
      <div className="row" style={{alignItems: 'center'}}>
        <div className={`col-8 ${styles.title}`}>Suggested members</div>
        <div className="col" style={{display: 'flex', justifyContent: 'flex-end'}}>
          <button data-pendo-tag="suggested members - shuffle" onClick={() => getRecommendedProfiles(true)} style={{ background: "none", display: 'flex', padding: '0px', alignItems: 'center', width: 'auto'}}>
            <img alt="shuffle" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/Icon+(1).svg" />
            <p className={styles.shuffle}>Shuffle</p>
          </button>
        </div>
      </div>
      <div className={` row ${styles.subtext}`}>
        <div className="col-8 marg-bottom-20">
          These mentors have similar experiences to you based on your profile.
        </div>
      </div>
      {isLoading ?
        <div className="marg-top-20 marg-bottom-20">
          <LoadingScreen />
        </div>
        :
        <div className={`row ${styles.small_screen_cards}`}>
          {renderMembers.map((s, i) => (
            <>
              <div data-pendo-tag={`suggested profile card - ${s.display_name}`} className="content_container col" style={{ paddingTop: "0px", paddingBottom: "10px", display: "inlineBlock" }}>
                <div className="row marg-top-0 justify-content-center">
                  <p style={{padding: '4px 8px'}} className={`${styles.similar_to_you} text-center marg-bottom-10`}>
                    Similar to you
                  </p>
                </div>
                <VerticalAvatar
                  profile={s}
                  className={styles.avatar}
                  showTypeIcon
                  showInfo
                  showProcedure={true}
                  showIcon={false}
                  changeNameColor={true}
                  userSlug={s.slug}
                  clickAvatar={true}
                  centerInfo={true}
                  clickName={true}
                />
                <Divider />
                <div className="row justify-content-center">
                  <Link data-pendo-tag={`suggested members - see profile - ${s.display_name}`} to={`/member/profile/${s.slug}/about-me`}>
                    <p className={`${styles.see_profile} text-link col-8`}>
                      See profile
                    </p>
                  </Link>
                </div>
              </div>
              {isMobile && i === 0 && <div style={{padding: '0px', maxWidth: '3.333333%'}} className="col-1"></div>}
              {!isMobile && i !== 2 && <div style={{padding: '0px', maxWidth: '3.333333%'}} className="col-1"></div>}
            </>
          ))}
        </div>
      }
    </div>
  )
}
