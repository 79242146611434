import React, { useState} from 'react'
import ErrorMessage from '../../ui_kit/utilities/error_message';

export default function HowLong({
  answers,
  keyForYears,
  keyForMonths,
  onSubmit,
  onBack,
  gtm,
  title,
  para,
}) {
  const [years, setYears] = useState(answers[keyForYears] || '');
  const [months, setMonths] = useState(answers[keyForMonths] || '');

  let disabled = 'disabled';
  let yearsError = '';
  const isNumber = (n) => /^-?[\d.]+(?:e-?\d+)?$/.test(n)

  if (years != 0 && isNumber(years)) {
    disabled = '';
  }

  if (months != 0 && isNumber(months)) {
    disabled = '';
  }

  if (months == 0 && years == 0) {
    disabled = 'disabled';
  }

  if (!isNumber(years) && years != '') {
    yearsError = 'Please enter only numbers';
    disabled = 'disabled';
  }

  if (!isNumber(months) && months != '') {
    yearsError = 'Please enter only numbers';
    disabled = 'disabled';
  }

  return (
    <div className="row height">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <form>
          <h5 className="question">
            {title}
          </h5>
          <p className="marg-bottom-20">
            {para}
          </p>
          <div className="row">
            <div className="col text-left">
              <div className="material-input light">
                <input className="autofocus" type="tel" name="years" value={years} onChange={(e) => setYears(e.target.value)} required />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>years{`(s)`}</label>
              </div>
            </div>
          </div>
          <div className="row marg-top-10">
            <div className="col text-left">
              <div className="material-input light">
                <input type="tel" name="months" value={months} onChange={(e) => setMonths(e.target.value)} required />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>month{`(s)`}</label>
              </div>
            </div>
          </div>
          {yearsError &&
            <ErrorMessage
              error={yearsError}
            />
          }
          <div className="row button-holder">
            <button data-gtm-click-label={`${gtm} Back`} className="back col-6" type="button" onClick={() => onBack()}>Back</button>
            <button data-gtm-click-label={`${gtm} Submit`} disabled={disabled} className={`submit col-6`} type="submit" onClick={(e) => {e.preventDefault();onSubmit({ ...answers, [keyForYears]: years, [keyForMonths]: months });}}>Next</button>
          </div>
        </form>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
