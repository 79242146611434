export const updateAnswersAction = (item) => {
  return {
    type: 'UPDATE_SURGEON_MATCH_ANSWERS',
    item
  }
}

export const setQuestionnaireStateAction = (item) => {
  return {
    type: 'SET_SURGEON_MATCH_QUESTIONNAIRE_STATE',
    item
  }
}

export const setContactIdAction = (item) => {
  return {
    type: 'SET_SURGEON_MATCH_CONTACT_ID',
    item
  }
}

export const setSurgeonsAction= (item) => {
  return {
    type: 'SET_SURGEONS',
    item
  }
}
