import React from 'react'
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function MfTreatmentGoals({
  value,
  onSubmit,
  onBack,
  title = `What are you (or the patient) hoping to achieve with your treatment? (Select all the apply)`,
  choices = [
    'Regain the ability to perform daily activities that I\'m currently unable to do',
    'Increase involvement with family and friends',
    'Allocate more time to the important aspects of my life',
    'Attain a greater sense of comfort and optimism regarding my condition',
  ],
  showOther = true
}) {
  return (
    <OptionsAndOther
      answers={{ mfTreatmentGoals: value}}
      keyForAnswer={'mfTreatmentGoals'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={choices}
      title={title}
      para={``}
      showOther={showOther}
      ctaTxt='Next'
    />
  )
}
