import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import DesktopNavItem from '../header/desktop_nav_item'
import MobileNavItem from '../header/mobile_nav_item'
import PortalNav from '../header/portal_nav'
import { getProviderPatients } from '../../api/patient_assistant_infos'
import styles from  './provider_header.module.css'
import {
  useTransition,
  useChain,
  animated,
  useSpringRef,
} from '@react-spring/web'
import Context from '../../utils/context';

export default function ProviderHeader({ }) {
  const context = useContext(Context);
  const [navOpen, setNavOpen] = useState(false);

  const closeNav = () => {
    setNavOpen(false);
    /*When Side bar Close Body Scroll Continue*/
    /*var element = document.getElementById("myDIV");*/
    var headerr = document.getElementById('hdr-content');
    headerr.classList.remove("no_scroll_css");
    var body = document.getElementById('hdr-content').parentElement;
    body.classList.toggle("no_scroll_css");
  }

  // Listens to know if the device is a mobile device START
  const [isMobile, setDesktop] = useState(window.innerWidth < 1191);

  const updateMedia = () => {
    setDesktop(window.innerWidth < 1191);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  // Listens to know if the device is a mobile device END

  // Listens to know if the device is a mobile device START
  const [isSmallMobile, setSmallMobile] = useState(window.innerWidth < 534);

  const updateSmallMedia = () => {
    setSmallMobile(window.innerWidth < 534);
  };

  useEffect(() => {
    window.addEventListener('resize', updateSmallMedia);
    return () => window.removeEventListener('resize', updateSmallMedia);
  });
  // Listens to know if the device is a mobile device END

  let location = useLocation()

  const [headerSticky, setHeaderSticky] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("surgeon/dr-")) {
      setHeaderSticky(true)
    }
    else {
      setHeaderSticky(false)
    }
  })

  const menuItems = [
    {
        title: 'Give feedback',
        smallTitle: 'Help us improve and make your life easier!',
        props:{
          to: '/contact-us'
        },
        pendoTag: 'Surgeons CRM nav - give feedback'
    },
    {
        title: 'Account details',
        hr_top: true,
        props:{
          to: '/provider/account-details'
        },
        pendoTag: 'Surgeons CRM nav - account details'
    },
    {
        link: 'mailto:support@patientpartner.com',
        title: 'Help & support',
        hr_top: true,
        pendoTag: 'Surgeons CRM nav - help and support'
    },
    {
        onclick: 'logout',
        title: 'Log out',
        pendoTag: 'Surgeons CRM nav - log out'
    }
]

  const mobileMenuItems = (context.auth.isLoggedIn() ? [
      {
        'mainLink': {
          link: '/provider/patients',
          title: 'Patients',
          pendoTag: 'Surgeons CRM nav - patients'
        }
      },
      {
        'mainLink': {
          link: '/contact-us',
          title: 'Give feedback',
          smallTitle: 'Help us improve and make your life easier!',
          pendoTag: 'Surgeons CRM nav - contact us'
        }
      },
      {
        'mainLink': {
          link: '/provider/account-details',
          title: 'Account details',
          hr_top: true,
          pendoTag: 'Surgeons CRM nav - account details'
        }
      },
      {
        'mainLink': {
          link: 'mailto:support@patientpartner.com',
          title: 'Help & support',
          hr_top: true,
          pendoTag: 'Surgeons CRM nav - help'
        }
      },
      {
        'mainLink': {
          onclick: 'logout',
          title: 'Log out',
          pendoTag: 'Surgeons CRM nav - log out'
        }
      }
    ] : [
      {
        'mainLink': {
          'title': 'Join for free',
          'link': '/member/join'
        }
      },
      {
        'mainLink': {
          'title': 'Log in',
          'link': '/member/login'
        }
      },
      {
        'mainLink': {
          'title': 'Become a mentor',
          'link': '/for-mentors'
        }
      }
    ]
  )

  const navItemsAnimation = useSpringRef();
  const mobileNavItems = useTransition(navOpen ? [...mobileMenuItems] : [], {
    ref: navItemsAnimation,
    trail: navOpen ? 100 / mobileMenuItems.length : 0,
    config: { mass: .5, tension: 200, friction: 20 },
    from: { opacity: 0, transform: 'translateX(50px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
  });

  useChain(navOpen ? [navItemsAnimation] : [navItemsAnimation], [
    .2
  ]);

  const [amountUnread, setAmountUnread] = useState(0);

  useEffect(() => {
    getProviderPatientsWrapper()
  })

  const getProviderPatientsWrapper = () => {
    getProviderPatients((data) => {
      setAmountUnread(data.filter(r => r.conversation && r.conversation.unread_messages > 0).length);
    })
  }

  return (
    <header className={`${headerSticky === true ? 'nosticky' : ''} platform-header`} id="hdr-content">
      <div className="container-fluid">
        <div className="header-outr row">
          <div className={`${isMobile ? 'col-4' : 'col-2'}`}>
            <div className="hdr-logo">
              <Link to="/">
                <img alt="logo" id="logo" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/svg/pp-logo.svg" data-gtm-click-label="Nav Logo" />
              </Link>
            </div>
          </div>
          <div className={`text-center ${isMobile ? '' : 'col'}`}>
            <div className="header-innr">
              <div className="navbar-header">
                <nav className="navbar navbar-expand-md navbar-light sidenav" id="mySidenav" style={{ right: navOpen ? '0px' : '-100%' }}>
                  {isMobile === true &&
                    <Link onClick={() => setNavOpen(false)} to="/"><img alt="logo" className={`mobile-nav-logo ${navOpen ? 'active' : ''}`} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/svg/pp-logo.svg" data-gtm-click-label="Nav Logo" /></Link>
                  }
                  <div>
                    <ul className="navbar-nav">
                      {/* {!isMobile && <>
                        {menuItems.map((menuItem) =>
                          <DesktopNavItem
                            navItem={menuItem}
                          />
                        )}
                      </>} */}
                      {isMobile && <>
                        {mobileNavItems((style, menuItem) =>
                          <animated.div key={menuItem.mainLink.title} style={{ ...style }} className="mobile-nav-item-animated-wrap">
                            <MobileNavItem
                              navItem={menuItem}
                              setNavOpen={setNavOpen}
                              pendoTag={menuItem.mainLink.pendoTag}
                            />
                          </animated.div>
                        )}
                      </>}
                    </ul>
                  </div>
                  {/* {!isMobile &&
                    <Link onClick={() => getProviderPatientsWrapper()} to={`/provider/patients`} className={`${styles.header_patients} h-100`}>
                      <svg className={`my-auto`} width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M2.4 4.8C3.2825 4.8 4 4.0825 4 3.2C4 2.3175 3.2825 1.6 2.4 1.6C1.5175 1.6 0.8 2.3175 0.8 3.2C0.8 4.0825 1.5175 4.8 2.4 4.8ZM13.6 4.8C14.4825 4.8 15.2 4.0825 15.2 3.2C15.2 2.3175 14.4825 1.6 13.6 1.6C12.7175 1.6 12 2.3175 12 3.2C12 4.0825 12.7175 4.8 13.6 4.8ZM14.4 5.6H12.8C12.36 5.6 11.9625 5.7775 11.6725 6.065C12.68 6.6175 13.395 7.615 13.55 8.8H15.2C15.6425 8.8 16 8.4425 16 8V7.2C16 6.3175 15.2825 5.6 14.4 5.6ZM8 5.6C9.5475 5.6 10.8 4.3475 10.8 2.8C10.8 1.2525 9.5475 0 8 0C6.4525 0 5.2 1.2525 5.2 2.8C5.2 4.3475 6.4525 5.6 8 5.6ZM9.92 6.4H9.7125C9.1925 6.65 8.615 6.8 8 6.8C7.385 6.8 6.81 6.65 6.2875 6.4H6.08C4.49 6.4 3.2 7.69 3.2 9.28V10C3.2 10.6625 3.7375 11.2 4.4 11.2H11.6C12.2625 11.2 12.8 10.6625 12.8 10V9.28C12.8 7.69 11.51 6.4 9.92 6.4ZM4.3275 6.065C4.0375 5.7775 3.64 5.6 3.2 5.6H1.6C0.7175 5.6 0 6.3175 0 7.2V8C0 8.4425 0.3575 8.8 0.8 8.8H2.4475C2.605 7.615 3.32 6.6175 4.3275 6.065Z" fill="#314D69"/></svg>
                      <p className={`my-auto`}>Patients</p>
                      {amountUnread > 0 &&
                        <span className={`my-auto`}>{amountUnread}</span>
                      }
                    </Link>
                  } */}
                </nav>
              </div>
            </div>
          </div>
          <div className={`hdr-btn-wrap ${isMobile ? (isSmallMobile ? 'col-6' : 'col-7') : 'col-3'}`}>
            <div style={{ width: '100%' }} className="hdr-btn-innr in-platform">
              {!isMobile && <>
                <PortalNav
                  navigationItems = {menuItems}
                />
              </>}
            </div>
          </div>
          <div style={isSmallMobile ? {} : { width: 'max-content', maxWidth: 'max-content' }} className={`${isMobile ? 'col-1' : ''}`} className="col-1">
            <svg className={`tgl-innr ${navOpen ? 'active' : ''}`} onClick={() => { setNavOpen(!navOpen) }} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 0.482178C6.71371 0.482178 0 7.17191 0 15.4286C0 23.6853 6.71371 30.375 15 30.375C23.2863 30.375 30 23.6853 30 15.4286C30 7.17191 23.2863 0.482178 15 0.482178ZM22.7419 25.8911C20.5706 27.4882 17.8972 28.4465 15 28.4465C12.1028 28.4465 9.42944 27.4882 7.25806 25.8911V25.0715C7.25806 22.944 8.99395 21.2143 11.129 21.2143C11.8004 21.2143 12.7923 21.9014 15 21.9014C17.2137 21.9014 18.1935 21.2143 18.871 21.2143C21.006 21.2143 22.7419 22.944 22.7419 25.0715V25.8911ZM24.5927 24.2337C24.1815 21.4373 21.7923 19.2857 18.871 19.2857C17.631 19.2857 17.0323 19.9728 15 19.9728C12.9677 19.9728 12.375 19.2857 11.129 19.2857C8.20766 19.2857 5.81855 21.4373 5.40726 24.2337C3.26008 21.9134 1.93548 18.8277 1.93548 15.4286C1.93548 8.2507 7.79637 2.41075 15 2.41075C22.2036 2.41075 28.0645 8.2507 28.0645 15.4286C28.0645 18.8277 26.7399 21.9134 24.5927 24.2337ZM15 7.23218C12.0605 7.23218 9.67742 9.60673 9.67742 12.5357C9.67742 15.4648 12.0605 17.8393 15 17.8393C17.9395 17.8393 20.3226 15.4648 20.3226 12.5357C20.3226 9.60673 17.9395 7.23218 15 7.23218ZM15 15.9107C13.131 15.9107 11.6129 14.398 11.6129 12.5357C11.6129 10.6735 13.131 9.16075 15 9.16075C16.869 9.16075 18.3871 10.6735 18.3871 12.5357C18.3871 14.398 16.869 15.9107 15 15.9107Z" fill="#314D69"></path></svg>
          </div>
        </div>
      </div>
    </header>
  )
}
