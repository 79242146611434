export const setLastRead = (
    uuid,
    successCallback
) => {
    fetch(`/api/v1/user_conversations/${uuid}`, {
        method: 'PATCH',
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(response => response.json())
        .then(data => {
            successCallback(data);
        })
}

export const getLastRead = (
    uuid,
    successCallback
) => {
    fetch(`/api/v1/user_conversations/${uuid}`, {
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(response => response.json())
        .then(data => {
            successCallback(data);
        })
}