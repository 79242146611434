import React, { useState } from 'react'
import InputWithOptions from '../ui_kit/utilities/input_with_options';
import ErrorMessage from '../ui_kit/utilities/error_message';

export default function InsuranceCarrier({
  head = 'Who is your insurance provider?',
  description = 'We will recommend a surgeon that accepts your insurance provider.',
  answers,
  onSubmit,
  onBack,
  gtm = 'wl',
  insurances = ["I don't have insurance", ...gon.all_insurances]
}) {
  const [insuranceCarrier, setInsuranceCarrier] = useState(answers.insuranceCarrier || '');
  let disabled = 'disabled';
  const noMatchTxt = "I don't see my insurance provider";
  if (insurances.includes(insuranceCarrier) || insuranceCarrier === noMatchTxt) {
    disabled = '';
  }

  let error = '';
  if (insuranceCarrier !== '' && disabled === 'disabled') {
    error = 'Please select an insurance provider from the dropdown list';
  }

  return (
    <div className="row ic-question">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <h5 className="question">
          {head}
        </h5>
        <p className="question-sub-headline marg-bottom-30 text-left">
          {description}
        </p>
        <InputWithOptions
          value={insuranceCarrier}
          setValue={setInsuranceCarrier}
          options={insurances}
          label={'Insurance Provider'}
          noMatchTxt={"I don't see my insurance provider"}
          fullWidth={true}
        />
        {error &&
          <ErrorMessage
            error={error}
          />
        }
        <div className="marg-top-20 container">
          <div className="row">
            {
              onBack && <button data-gtm-click-label={`${gtm} Insurance Carrier Back`} className="back col-6" type="button" onClick={() => onBack()}>Back</button>
            }
            <button data-gtm-click-label={`${gtm} Insurance Carrier Submit`} disabled={disabled} onClick={(e) => {e.preventDefault();onSubmit({ ...answers, insuranceCarrier })}} className={` submit col-6`} type="submit" >Next</button>
          </div>
        </div>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
