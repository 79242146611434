import React, { useContext } from "react";
import {
  useHistory,
} from "react-router-dom";
import Context from '../../utils/context';
import styles from './style.module.css';
import SurgeonSection from "./surgeon_section";
import MentorSection from "./mentor_section";
import PostSection from "./post_section";
export default function HomeDashboard({
  profileId
}){
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();

  const history = useHistory();
  history.push("/member/conversations")

  return (
    <div className={`${styles.dash_otr} row justify-content-center`}>
      <div className={`${styles.dash_wrap} col-md-6 col-11`}>
        <div className='container'>
          <div className='row'>
            <p className={styles.header}>{user.sign_in_count === 1 ? `Welcome, ${user.first_name}!` : `Welcome back, ${user.first_name}!`}</p>
          </div>
          <div className='row'>
            <p className={styles.subheader}>Get started on your PatientPartner journey.</p>
          </div>
          <div className='row'>
            <div className={`container ${styles.blue_container}`}>
              <ul className='row marg-top-10'>
                <img alt='mentor-icon' className={styles.icon} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/relatability.svg'/>
                <p className={`col ${styles.about_text}`}>{`Check in with your mentor`} </p>
              </ul>
              <ul className='row marg-top-10 marg-bottom-10'>
                <div className={styles.vertical_line}></div>
              </ul>
              <ul className='row'>
                <img alt='community-icon' className={styles.icon} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/friends.svg'/>
                <p className={`col ${styles.about_text}`}>Connect with the community</p>
              </ul>
              <ul className='row marg-top-10 marg-bottom-10'>
                <div className={styles.vertical_line}></div>
              </ul>
              <ul className='row marg-bottom-10'>
                <img alt='doctor-icon' className={styles.icon} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/icon+-+health.svg'/>
                <p className={`col ${styles.about_text}`}>Find a doctor</p>
              </ul>
            </div>
          </div>
        </div>
        <MentorSection/>
        <PostSection/>
        <SurgeonSection />
      </div>
    </div>
  );
}
