import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getSurgeons } from '../../api/surgeons'
import SurgeonsColumn from '../surgeons_column'
import './surgeons.css'
import CustomCtaFooter from '../custom_cta_footer'
import ScrollToTopButton from '../ui_kit/utilities/scroll_to_top_button'
import LoadingScreen from '../loading_screen'
import TxtLeftImgRightWbg from '../hero_sections/txt_left_img_right_wbg'

export default function Surgeons({
  home,
  surgeons
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [Surgeons, setSurgeons] = useState([]);
  const [surgeryTypeFilter, setSurgeryTypeFilter] = useState('All');

  useEffect(() => {
    getSurgeons((data) => {
      setSurgeons((data).reverse());
      setIsLoading(false);
    });
  }, [])

  const filterPatientPartners = (type) => {
    setIsLoading(true);
    setSurgeryTypeFilter(type);
    getPatientPartners(type, null, null, null, null, (data) => {
      setPatientPartners(data);
      setIsLoading(false);
    });
  }

  const scrollToTop = () => {
    document.querySelector('#topOfPage').scrollIntoView({
      behavior: 'smooth'
    });
  }

  // Listens to know if the user has scrolled from top START
  const [scrolledFromTop, setScrolledFromTop] = useState(window.pageYOffset);

  const updateScroll = () => {
     setScrolledFromTop(window.pageYOffset > 100)
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return () => window.removeEventListener('scroll', updateScroll);
  },[]);
  // Listens to know if the user has scrolled from top END

  return (
    <div className="surgeons-gallery">
      <div>
        <TxtLeftImgRightWbg
          headline = {'Simplify your search'}
          para = {"Find the top surgeon in your area covered by your insurance."}
          heightOfImage={'400px'}
          bg = {'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/bill-oxford-8u_2imJaVQs-unsplash.jpg'}
          actionContent={<Link data-pendo-link="doctor discovery - find a surgeon for me " to="/member/join/surgeon-match"><button className="marg-bottom-40" data-gtm-click-label="Surgeons Gallery Find a Surgeon For Me">Find a physician for me</button></Link>}
        />
      </div>
      <div className="container gallery-sec">
        <div className="row" style={{ display: 'none' }}>
          <div className="col">
            <div className="quick-filter-section">
              <p className="filter-label">Quick Filter:</p>
              <button data-gtm-click-label="Surgeons Gallery View All" className="filter-but" disabled={`${surgeryTypeFilter == 'All' ? 'disabled' : ''}`} onClick={() => filterPatientPartners('All')}>View all</button>
              <button data-gtm-click-label="Surgeons Gallery View Weight Loss" className="filter-but" disabled={`${surgeryTypeFilter == 'Bariatric' ? 'disabled' : ''}`} onClick={() => filterPatientPartners('Weight Loss')}>View Weight Loss</button>
              <button data-gtm-click-label="Surgeons Gallery View Knee Replacement" className="filter-but" disabled={`${surgeryTypeFilter == 'Knee Replacement' ? 'disabled' : ''}`} onClick={() => filterPatientPartners('Knee Replacement')}>View Knee Replacement</button>
            </div>
          </div>
        </div>
        <div className="container">
            {
              isLoading && (
                <LoadingScreen/>
              )
            }
            {
              !isLoading && (
                <div className="row h-100">
                  <div className="col-lg-9">
                    {
                      Surgeons.map((surgeon, index) => {
                        return (
                            <div key={surgeon.name} className="col-12 ppcol">
                              <SurgeonsColumn
                                home={home}
                                surgeon={surgeon}
                                index={(index + 1)}
                              />
                            </div>
                        )
                      })
                    }
                  </div>
                  <div className="col-lg-3">
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <CustomCtaFooter
          headline = "Can't find a surgeon that accepts your insurance?"
          para = "Fill out a short questionnaire and we’ll help you find a great surgeon that accepts your insurance in your area."
          buttontxt = "Get Started"
          actionContent = {<Link to="/member/join/surgeon-match"><button data-gtm-click-label="Surgeons Gallery Get Started">Get Started</button></Link>}
          gtm={"Surgeon Gallery"}
        />
        <ScrollToTopButton gtmClickLabelPage={'Surgeons'} />
    </div>
  )
}
