import React, { useState } from 'react'
import './modal.css'
import Modal from 'react-bootstrap-modal';

export default function ModalWrapper({
  id,
  className,
  content,
  onClose,
  hideClose = false,
  backdrop = true
}) {
  const [showModal, setShowModal] = useState(true);
  const closeModal = () => {
    setShowModal(false);
    onClose()
  }

  return (
    <Modal className={className} id={id} show={showModal} onHide={closeModal} backdrop={backdrop}>
      {
        !hideClose &&
        <div onClick={closeModal} className="closeButton">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.414" height="19.414" viewBox="0 0 19.414 19.414"><path id="Union_1" data-name="Union 1" d="M4590,4961l-9,9,9-9-9-9,9,9,9-9-9,9,9,9Z" transform="translate(-4580.293 -4951.293)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/></svg>
        </div>
      }
      {content}
    </Modal>
  );
}
