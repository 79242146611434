import { connect } from 'react-redux'
import {
  updateAnswersAction,
} from '../../actions/surgeon_match_journey_feedback'
import SurgeonMatchJourneyFeedback from '../../components/weight_loss/journey_feedback'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SurgeonMatchJourneyFeedback)
