import React, { useState, useEffect, useContext } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Divider from '../../ui_kit/utilities/divider';
import { VictoryPie } from "victory";
import USAMap from "react-usa-map";
import UsStatesMap from '../../ui_kit/maps/us_states_map';
import IconButtonSvg from "../../ui_kit/utilities/icon_button_svg";
import Context from '../../../utils/context';
import styles from './index.module.css'
import { getPatientDemographics } from '../../../api/surgeon_administrative_assistants'
import { getExport } from "../../../api/patient_demographics_exports";
import IsScrolling from 'react-is-scrolling';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function PatientDemographics({isScrolling}) {
  const [ageResults, setAgeResults] = useState();
  const [symptomResults, setSymptomResults] = useState();
  const [previouslyDiagnosedResults, setPreviouslyDiagnosedResults] = useState();
  const [ethnicityResults, setEthnicityResults] = useState();
  const [insuranceResults, setInsuranceResults] = useState();
  const [conversationTopicResults, setConversationTopicResults] = useState();
  const [statesResults, setStatesResults] = useState();
  const [genderResults, setGenderResults] = useState();
  const [mfPatientOrCaregiver, setMfPatientOrCaregiver] = useState();
  const [mfDiagnosis, setMfDiagnosis] = useState();
  const [mfUnderstanding, setMfUnderstanding] = useState();
  const [mfDiagnosisTime, setMfDiagnosisTime] = useState();
  const [mfInformation, setMfInformation] = useState();
  const [mfPreviousSymptomsManagement, setMfPreviousSymptomsManagement] = useState();
  const [mfCurrentSymptomsManagement, setMfCurrentSymptomsManagement] = useState();
  const [mfClinicalTrial, setMfClinicalTrial] = useState();
  const [mfTreatmentGoals, setMfTreatmentGoals] = useState();
  const [mfQolAffect, setMfQolAffect] = useState();
  const [xcopriPatientOrCaregiver, setXcopriPatientOrCaregiver] = useState();
  const [xcopriDiagnosis, setXcopriDiagnosis] = useState();
  const [xcopriLastSeisure, setXcopriLastSeisure] = useState();
  const [xcopriSeisureFrequency, setXcopriSeisureFrequency] = useState();
  const [xcopriPrescription, setXcopriPrescription] = useState();
  const [xcopriSymptomManagement, setXcopriSymptomManagement] = useState();
  const [xcopriAsmSideEffect, setXcopriAsmSideEffect] = useState();
  const [xcopriConsideringASwitch, setXcopriConsideringASwitch] = useState();
  const [xcopriReasonForSwitch, setXcopriReasonForSwitch] = useState();
  const [xcopriComfortLevel, setXcopriComfortLevel] = useState();
  const [xcopriAnythingElse, setXcopriAnythingElse] = useState();
  const colors = ['#74CCD3', '#314D69', '#DDF7F9', '#188F8B', '#80BBB8', '#5A7284', '#E0F7F8', '#AAD2CF', '#FFE5CA', '#909090', '#BCBCBC'];
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();

  useEffect(() => {
    getPatientDemographics({}, (results) => {
      if (results.age) {
        setAgeResults(results.age)
      }
      if (results.symptoms) {
        setSymptomResults(results.symptoms)
      }
      setPreviouslyDiagnosedResults(results.previously_diagnosed);
      setEthnicityResults(results.ethnicity);
      setInsuranceResults(results.insurance);
      setConversationTopicResults(results.conversation_topics);
      setStatesResults(results.states);
      if (results.gender) {
        setGenderResults(results.gender);
      }
      if (results.mf_patient_or_caregiver) {
        setMfPatientOrCaregiver(results.mf_patient_or_caregiver)
      }
      if (results.mf_diagnosis) {
        setMfDiagnosis(results.mf_diagnosis)
      }
      if (results.mf_understanding) {
        setMfUnderstanding(results.mf_understanding)
      }
      if (results.mf_diagnosis_time) {
        setMfDiagnosisTime(results.mf_diagnosis_time)
      }
      if (results.mf_information) {
        setMfInformation(results.mf_information)
      }
      if (results.mf_previous_symptoms_management) {
        setMfPreviousSymptomsManagement(results.mf_previous_symptoms_management);
      }
      if (results.mf_current_symptoms_management) {
        setMfCurrentSymptomsManagement(results.mf_current_symptoms_management)
      }
      if (results.mf_clinical_trial) {
        setMfClinicalTrial(results.mf_clinical_trial)
      }
      if (results.mf_treatment_goals) {
        setMfTreatmentGoals(results.mf_treatment_goals)
      }
      if (results.mf_qol_affect) {
        setMfQolAffect(results.mf_qol_affect);
      }
      if (results.xcopri_patient_or_caregiver) {
        setXcopriPatientOrCaregiver(results.xcopri_patient_or_caregiver);
      }
      if (results.xcopri_diagnosis) {
        setXcopriDiagnosis(results.xcopri_diagnosis)
      }
      if (results.xcopri_last_seisure) {
        setXcopriLastSeisure(results.xcopri_last_seisure)
      }
      if (results.xcopri_seisure_frequency) {
        setXcopriSeisureFrequency(results.xcopri_seisure_frequency)
      }
      if (results.xcopri_prescription) {
        setXcopriPrescription(results.xcopri_prescription);
      }
      if (results.xcopri_symptom_management) {
        setXcopriSymptomManagement(results.xcopri_symptom_management);
      }
      if (results.xcopri_asm_side_effect) {
        setXcopriAsmSideEffect(results.xcopri_asm_side_effect)
      }
      if (results.xcopri_considering_a_switch) {
        setXcopriConsideringASwitch(results.xcopri_considering_a_switch)
      }
      if (results.xcopri_reason_for_switch) {
        setXcopriReasonForSwitch(results.xcopri_reason_for_switch)
      }
      if (results.xcopri_comfort_level) {
        setXcopriComfortLevel(results.xcopri_comfort_level);
      }
      if (results.xcopri_anything_else) {
        setXcopriAnythingElse(results.xcopri_anything_else);
      }
    });
  }, []);

  const renderAge = () => {
    return renderBar("Number of patients per age group", "Age group", "Number of patients", ageResults);
  }

  const renderBar = (title, yTitle, xTitle, dta) => {
    if (!dta) {
      return <></>;
    }

    const options = {
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2
        }
      },
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: true,
          text: title
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return nonTrunctedLabels[context[0].dataIndex];
            },
            label: (context) => {
              " " + context.formattedValue;
            },
          },
        },
      },
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            color: '#000000',
            text: yTitle,
            padding: 10,
          },
          ticks: {
            font: {
              size: 10,
            }
          }
        },
        x: {
          display: true,
          title: {
            display: true,
            color: '#000000',
            text: xTitle
          },
          ticks: {
            precision: 0
          }
        }
      }
    };

    let labels = [];
    let values = [];
    let nonTrunctedLabels = [];

    Object.keys(dta).forEach(label => {
      let truncatedLabel = label.length > 35 ? label.substring(0, 35) + '...' : label;
      labels.push(truncatedLabel);
      nonTrunctedLabels.push(label); // for tooltip
      values.push(dta[label]);
    });

    const data = {
      labels,
      nonTrunctedLabels,
      datasets: [
        {
          data: values,
          borderColor: colors,
          backgroundColor: colors
        }
      ]
    };

    return (
      <Bar options={options} data={data} />
    )
  }

  const renderSymptoms = () => {
    return renderBar("Number of patients with symptom", "Symptom", "Number of patients", symptomResults);
  }

  const renderMfInformation = () => {
    return renderBar("Where patients get their information", "Source", "Number of patients", mfInformation);
  }

  const renderPie = (title, data) => {
    if (!data) {
      return <></>
    }

    const renderKey = (color, text) => {
      return (
        <div className='row'>
          <div className="col-2">
            <div style={{background: color}}className={styles.key}></div>
          </div>
          <div className="col-10" style={{ textAlign: 'left'}} >
            <p className={styles.bold_black_text}>{text}</p>
          </div>
        </div>
      )
    }

    let total = 0;
    let keys = Object.keys(data);
    keys.forEach(key => {
      total = total + data[key];
    })

    const graphicData = [];
    keys.forEach(key => {
      graphicData.push({
        x: (Math.ceil((data[key] / total) * 100)) || 0,
        y: (data[key]) || 0
      });
    })

    let graphicColor = [];
    graphicColor = colors.slice(0, keys.length);

    return (
      <>
        <div className={`${styles.gray_header} marg-bottom-20`}>{title}</div>
        <div className='row'>
          <div className='col-lg-6'>
            <div style={{ padding: '50px 20px' }}>
            {
              keys.map((key, i) => {
                return (
                  <>
                    {renderKey(graphicColor[i], key)}
                    { i !== (keys.length - 1) && <Divider/> }
                  </>

                )
              })
            }
            </div>
          </div>
          <div className='col-lg-6'>
            <VictoryPie
              animate={{ easing: "bounce" }}
              data={graphicData}
              colorScale={graphicColor}
              style={{ labels: { fontSize: 16, fill: "white", fontFamily: 'HK Grotesk', fontWeight: '600'}}}
              labelRadius={50}
            />
          </div>
        </div>
      </>
    )
  }

  const renderHasDiagnosed = () => {
    return renderPie("Number of patients that were previously diagnosed", previouslyDiagnosedResults);
  }

  const renderGender = () => {
    return renderPie("Patient Gender", genderResults);
  }

  const renderMfPatientOrCaregiver = () => {
    return renderPie("Patient or Caregiver", mfPatientOrCaregiver);
  }

  const renderMfDiagnosis = () => {
    return renderPie("MF Diagnosis", mfDiagnosis);
  }

  const renderMfUnderstanding = () => {
    return renderPie("MF Familiarity", mfUnderstanding);
  }

  const renderMfDiagnosisTime = () => {
    return renderPie("MF Time Since Diagnosed", mfDiagnosisTime);
  }

  const renderMfClinicalTrial = () => {
    return renderPie("Patient Part of Clinical Trial", mfClinicalTrial);
  }

  const renderEthnicity = () => {
    return renderBar("Number of patients per ethnicity", "Ethnicity", "Number of patients", ethnicityResults);
  }

  const renderInsurance = () => {
    const renderKey = (color, text) => {
      return (
        <div className='row'>
          <div style={{background: color}}className={styles.key}></div>
          <p className={styles.bold_black_text}>{text}</p>
        </div>
      )
    }

    let total = 0;
    if (insuranceResults) {
      total = insuranceResults['Medicare/Medicaid'] + insuranceResults['Uninsured'] + insuranceResults['Private'];
    }

    const graphicData = [
      {
        x: (insuranceResults && Math.ceil((insuranceResults['Medicare/Medicaid'] / total) * 100)) || 0,
        y: (insuranceResults && insuranceResults['Medicare/Medicaid']) || 0
      },
      {
        x: (insuranceResults && Math.ceil((insuranceResults['Uninsured'] / total) * 100)) || 0,
        y: (insuranceResults && insuranceResults['Uninsured']) || 0
      },
      {
        x: (insuranceResults && Math.floor((insuranceResults['Private'] / total) * 100)) || 0,
        y: (insuranceResults && insuranceResults['Private']) || 0
      },
    ];

    const graphicColor = ['#57CC99', '#FF6361', '#0085FF'];

    return (
      <>
        <div className={`${styles.gray_header} marg-bottom-20`}>What insurance patients have</div>
        <div className='row'>
          <div className='col-lg-6'>
            <div style={{ padding: '50px 20px' }}>
              { renderKey('#57CC99', `Medicare/Medcaid`) }
              <Divider/>
              { renderKey('#FF6361', `Uninsured`) }
              <Divider/>
              { renderKey('#0085FF', `Private`) }
            </div>
          </div>
          <div className='col-lg-6'>
            <VictoryPie
              animate={{ easing: "bounce" }}
              data={graphicData}
              colorScale={graphicColor}
              style={{ labels: { fontSize: 16, fill: "white", fontFamily: 'HK Grotesk', fontWeight: '600'}}}
              labelRadius={50}
            />
          </div>
        </div>
      </>
    )
  }

  const renderConversationTopics = () => {
    return renderBar("Patient and mentor conversation topics", "Conversation Topic", "Number of patients", conversationTopicResults);
  }

  const renderMfQolAffect = () => {
    return renderBar("How MF affected quality of life", "Quality of life effect", "Number of patients", mfQolAffect);
  }

  const renderMfTreatmentGoals = () => {
    return renderPie("Treatment Goals", mfTreatmentGoals);
  }

  const renderXcopriPatientOrCaregiver = () => {
    return renderPie("Patient or Caregiver", xcopriPatientOrCaregiver);
  }

  const renderXcopriDiagnosis = () => {
    return renderPie("How long have the patient been diagnosed with epilepsy?", xcopriDiagnosis);
  }

  const renderXcopriLastSeisure = () => {
    return renderPie("When the most recent seizure occurred for the patient", xcopriLastSeisure);
  }

  const renderXcopriSeisureFrequency = () => {
    return renderPie("How often is the patient having seizures?", xcopriSeisureFrequency);
  }

  const renderXcopriPrescription = () => {
    return renderPie("Is the patient currently taking XCOPRI®?", xcopriPrescription);
  }

  const renderXcopriSymptomManagement = () => {
    return renderPie("How long the patient have been using XCOPRI®?", xcopriSymptomManagement);
  }

  const renderXcopriAsmSideEffect = () => {
    return renderBar("Side effects from current ASM (excluding XCOPRI)", "Side effects", "Number of patients", xcopriAsmSideEffect);
  }

  const renderXcopriConsideringASwitch = () => {
    return renderPie("Patient considering a switch?", xcopriConsideringASwitch);
  }

  const renderXcopriReasonForSwitch = () => {
    return renderBar("Reasons for switching", "Reason", "Number of patients", xcopriReasonForSwitch);
  }

  const renderXcopriComfortLevel = () => {
    return renderPie("Did the mentor make the patient feel more comfortable", xcopriComfortLevel);
  }

  const renderXcopriAnythingElse = () => {
    return renderBar("Anthing else the patient needs assistance with", "Topic", "Number of patients", xcopriAnythingElse);
  }

  const renderStates = () => {
    let data = {};
    if (statesResults) {
      let max = 0;
      Object.keys(statesResults).forEach(state => {
        if (statesResults[state] > max) {
          max = statesResults[state];
        }
      });

      Object.keys(statesResults).forEach(state => {
        if (statesResults[state] <= (max / 4)) {
          data[state] = { fill: '#B7F0D1' };
        }
        else if (statesResults[state] <= (max / 2)) {
          data[state] = { fill: '#63E2C4' };
        }
        else if (statesResults[state] <= (3 * max / 4)) {
          data[state] = { fill: '#329785' };
        }
        else {
          data[state] = { fill: '#003F5C' }
        }
      });
    }

    let tooltipFunc = null;
    if (statesResults) {
      tooltipFunc = (state) => {
        return `${state}: ${statesResults[state] || 0}`;
      };
    }

    return (
      <UsStatesMap customize={data} tooltipFunc={tooltipFunc} />
    )
  }

  const renderStackedBar = (title, labels, dta) => {
    if (!dta) {
      return <></>;
    }
    const options = {
      plugins: {
        title: {
          display: true,
          text: title,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const keys = Object.keys(dta);
    const datasets = keys.map((key, i) => {
      return {
        label: key,
        data: dta[key],
        backgroundColor: colors[i]
      }
    });

    const data = {
      labels,
      datasets: datasets,
    };

    return <Bar options={options} data={data} />;
  }

  const renderMfPreviousSymptomsManagement = () => {
    return renderStackedBar("How patients previously managed symptoms", [
      'Jakafi® (ruxolitinib)',
      'VONJO® (pacritinib)',
      'INREBIC® (fedratinib)',
      'Peginterferon',
      'Hydroxyurea',
      'Other'
    ], mfPreviousSymptomsManagement)
  }

  const renderMfCurrentSymptomsManagement = () => {
    return renderStackedBar("How patients previously managed symptoms", [
      'Jakafi® (ruxolitinib)',
      'VONJO® (pacritinib)',
      'INREBIC® (fedratinib)',
      'Peginterferon',
      'Hydroxyurea',
      'Other'
    ], mfCurrentSymptomsManagement)
  }

  return (
    <div className='container marg-bottom-50'>
      <div className={styles.mentor_connect_heading} style={{ marginTop: '20px', marginBottom: '35px' }}>
        <h5 className={`${styles.roster_heading}`}>Patient Demographics</h5>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <IconButtonSvg
            icon={<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2245_12790)">
              <path d="M13.125 10.8125V14.5625H1.875V10.8125M7.5 2.375V11.75M3.75 6.125L7.5 2.375L11.25 6.125" stroke="#F5F7F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_2245_12790">
              <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
              </defs>
              </svg>
            }
            text="Export"
            styles={{ padding: '12px 22px' }}
            onClick={() => {
              getExport(() => {
                console.log("here")
              })
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className={styles.card}>
            { renderAge() }
          </div>
        </div>
        <div className="col-6">
          <div className={styles.card}>
            { user.is_hologic && renderHasDiagnosed() }
            { (user.is_vonjo || user.is_xcopri) && renderGender() }
          </div>
        </div>
      </div>
      {
        user.is_hologic &&
        <>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderSymptoms() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderEthnicity() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderInsurance() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderConversationTopics() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                <div className={`${styles.gray_header} marg-bottom-20`}>Patient population based on location</div>
                { renderStates() }
              </div>
            </div>
          </div>
        </>
      }
      {
        user.is_vonjo &&
        <>
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderMfPatientOrCaregiver() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderMfDiagnosis() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderMfUnderstanding() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderMfDiagnosisTime() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderMfInformation()}
              </div>
            </div>
          </div>
          {
            /*
            <div className="row">
              <div className="col-12">
                <div className={styles.card}>
                  { renderMfPreviousSymptomsManagement()}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className={styles.card}>
                  { renderMfCurrentSymptomsManagement()}
                </div>
              </div>
            </div>
            */
          }
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderMfClinicalTrial() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderMfTreatmentGoals() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderMfQolAffect()}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderConversationTopics()}
              </div>
            </div>
          </div>
        </>
      }
      {
        user.is_xcopri &&
        <>
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriPatientOrCaregiver() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriDiagnosis() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriLastSeisure() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriSeisureFrequency() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriPrescription() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriSymptomManagement() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderXcopriAsmSideEffect()}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriConsideringASwitch() }
              </div>
            </div>
            <div className="col-6">
              <div className={styles.card}>
                { renderXcopriReasonForSwitch() }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderConversationTopics()}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderXcopriComfortLevel()}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.card}>
                { renderXcopriAnythingElse()}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default IsScrolling(PatientDemographics);
