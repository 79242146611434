import React from "react";
import "./process.css";
import CustomCtaFooter from "../custom_cta_footer";
import ProcessHeader from "../process_header";

export default function Process({}) {
  return (
    <div className="process">
      <ProcessHeader
        para1={"How it Works"}
        headline={"Take your first step to a healthier you"}
        para2={"No bias. No hidden agenda. Only support."}
      />
      <div className="container steps">
        <div className="row h-100 marg-top-100 marg-bottom-100">
          <div className="col-md-5 my-auto ">
            <img
              className="step-image one no-mobile"
              alt="stepone"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/stepone.png"
            />
          </div>
          <div className="col-md-2 my-auto">
            <div className="step-number one">01</div>
          </div>
          <div className="col-md-5 my-auto step-text one">
            <img
              className="step-image yes-mobile"
              alt="stepone"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/stepone.png"
            />
            <h2 className="marg-bottom-20">Tell us about yourself</h2>
            <p>
              Take a short questionnaire so we can learn about you and your
              healthcare needs. All of your information is private and secure.
            </p>
          </div>
        </div>
        <div className="row h-100 marg-top-100 marg-bottom-100">
          <div className="col-md-5 my-auto step-text two third-mob">
            <img
              className="step-image yes-mobile"
              alt="stepone"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/process2.jpg"
            />
            <h2 className="marg-bottom-20">Talk to a mentor</h2>
            <p>
              We'll provide recommendations for a relatable PatientPartner
              Mentor who can tell you firsthand about the treatment they went
              through and that you’re considering. Receive unbiased support from
              someone who understands while getting honest answers to the
              questions you’ve wanted to ask.
            </p>
          </div>
          <div className="col-md-2 my-auto second-mob">
            <div className="step-number two">02</div>
          </div>
          <div className="col-md-5 my-auto first-mob">
            <img
              className="step-image two no-mobile"
              alt="stepone"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/process2.jpg"
            />
          </div>
        </div>
        <div className="row h-100 marg-top-100 marg-bottom-100">
          <div className="col-md-5 my-auto">
            <img
              className="step-image three no-mobile"
              alt="stepone"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/steptwo.png"
            />
          </div>
          <div className="col-md-2 my-auto">
            <div className="step-number three">03</div>
          </div>
          <div className="col-md-5 my-auto step-text three">
            <img
              className="step-image yes-mobile"
              alt="stepone"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/steptwo.png"
            />

            <h2 className="marg-bottom-20">Find a doctor</h2>
            <p>
              Use our platform to connect with a doctor near you that’s covered
              by your insurance. We have a network of top-tier physicians with
              PatientPartner Mentors who are ready to guide you through your
              procedure, all for free! Request a consultation and take a step
              towards a healthier life!
            </p>
          </div>
        </div>
      </div>
      <CustomCtaFooter
        headline="Ready to connect with a PatientPartner Mentor?"
        para="Take a short questionnaire and get matched to a PatientPartner Mentor with a relatable story."
        buttontxt="Begin questionnaire"
        link="/member/join/support"
        mergeWithFooter="false"
        gtm="Process"
      />
    </div>
  );
}
