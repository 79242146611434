import React, { useState } from 'react'
import './bedside_manner.css'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

export default function BedsideManner({
  bedsideManner,
  setBedsideManner = () => { },
  slide = true,
  wrapperClassnames,
  wrapperStyles,
  pendoTag = ''
}) {
  const [value, setValue] = useState(bedsideManner);

  return (
    <div className={`bedside-manner-container container ${wrapperClassnames}`} style={wrapperStyles}>
      <div className="row">
        <div className="col">
          <div data-pendo-tag={pendoTag} style={{marginBottom: '-20px', pointerEvents: slide ? 'auto' : 'none'}} className={`slider ${slide ? 'slide-active' : ''}`}>
            <Slider
              min={1}
              max={10}
              value={value}
              tooltip={false}
              onChange={(v) => {setValue(v);setBedsideManner(v)}}
            />
          </div>
        </div>
      </div>
      <div style={{width:'100%', display:'flex'}}>
        <p className="waf">
          Warm and friendly
        </p>
        <p className="ttp">
          To the point
        </p>
      </div>
    </div>
  )
}
