export const createRatings = (
    browserCallId,
    feedbackRating,
    feedbackComments,
    successCallback
) => {
  fetch(`/api/v1/ratings`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      browser_call_id: browserCallId,
      feedback_rating: feedbackRating,
      feedback_comments: feedbackComments,
    })
  })
  .then(response => response.json())
  .then(data => {
    successCallback(data);
  })
}
