import React, { useState, useContext, useEffect } from 'react'
import Context from '../../../../../utils/context';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import HowAreYouFeeling from '../../../../questionnaire/cosmetic/how_are_you_feeling';
import ChooseAnOption from '../../../../questionnaire/choose_an_option';
import YesNoLocation from '../../../../questionnaire/yes_no_location';
import styles from '../../index.module.css'
import { getSurgeonReferredMentorMatch } from '../../../../../api/surgeon_referred_mentor_matches'
import { updateProfile } from '../../../../../api/profiles';

export default function UterineFibroidsPostSurvey({
  path,
  id,
  surgeonReferredMentorMatch,
  update,
  answeredFinalQuestion,
  gtm = ''
}) {
  let history = useHistory();
  const context = useContext(Context);
  const [currentSurgeonReferredMentorMatch, setCurrentSurgeonReferredMentorMatch] = useState(surgeonReferredMentorMatch)
  const [conversationRating, setConversationRating] = useState('')
  const [comfortLevel, setComfortLevel] = useState('')
  const [confidenceLevel, setConfidenceLevel] = useState('')
  const [feelingNow, setFeelingNow] = useState('')
  const [findDoctor, setFindDoctor] = useState()
  const [profileLocation, setProfileLocation] = useState({})

  useEffect(() => {
    getSurgeonReferredMentorMatch(id, (data) => {
      setCurrentSurgeonReferredMentorMatch(data);
      setConversationRating(data.conversation_rating);
      setComfortLevel(data.comfort_level)
      setConfidenceLevel(data.confidence_level)
      setFeelingNow(data.feeling_now)
      setFindDoctor(data.find_a_doctor)
    })
  }, [])

  const updateP = (params) => {
    updateProfile(currentSurgeonReferredMentorMatch.profile_id, params)
  }
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <HowAreYouFeeling
          title={`Please rate your conversation with your mentor${currentSurgeonReferredMentorMatch.patient_partner_name}.`}
          para='Your input helps us ensure you and other patients are getting the support you need. Your mentor will not see your rating.'
          answers={{ conversationRating: conversationRating }}
          numberRating={true}
          onSubmit={(answers) => {
            setConversationRating(answers.conversationRating);
            update({ conversation_rating: answers.conversationRating });
            history.push(`${path}/comfort-level`);
          }}
          star={true}
          icons={[{ text: 'Very disappointing' }, { text: 'Disappointing' }, { text: 'Somewhat helpful' }, { text: 'Helpful' }, { text: 'Very helpful' }]}
          textLabels={['Very disappointing', 'Disappointing', 'Somewhat helpful', 'Helpful', 'Very helpful']}
          keyForAnswer={'conversationRating'}
        />
      </Route>
      <Route exact path={`${path}/comfort-level`}>
        <ChooseAnOption
          onSubmit={(answers) => {
            setComfortLevel(answers.comfortLevel);
            update({ comfort_level: answers.comfortLevel });
            history.push(`${path}/confidence-level`);
          }}
          onBack={() => {
            history.push(`${path}`);
          }}
          answers={{ comfortLevel: comfortLevel }}
          title={`My mentor made me feel more comfortable about seeking treatment for my condition.`}
          para={'Please rate your level of agreement with the statement above.'}
          keyForAnswer={'comfortLevel'}
          choices={[
            'Strongly agree',
            'Agree',
            'Neither agree or disagree',
            'Disagree',
            'Strongly disagree',
          ]}
          showOther={false}
          selectOne
        />
      </Route>
      <Route exact path={`${path}/confidence-level`}>
        <ChooseAnOption
          onSubmit={(answers) => {
            setConfidenceLevel(answers.confidenceLevel);
            update({ confidence_level: answers.confidenceLevel });
            history.push(`${path}/feeling-now`);
          }}
          onBack={() => {
            history.push(`${path}/comfort-level`);
          }}
          answers={{ confidenceLevel: confidenceLevel }}
          title={`My mentor made me feel more confident working with ${currentSurgeonReferredMentorMatch.surgeon ? `${currentSurgeonReferredMentorMatch.surgeon.name}` : 'the surgeon'}.`}
          para={'We want to make sure your receiving the support you’re looking for.'}
          keyForAnswer={'confidenceLevel'}
          choices={[
            'Strongly agree',
            'Agree',
            'Neither agree or disagree',
            'Disagree',
            'Strongly disagree',
          ]}
          showOther={false}
          keyMultiplier={3}
          selectOne
        />
      </Route>
      <Route exact path={`${path}/feeling-now`}>
        <HowAreYouFeeling
          title='How are you feeling about getting treatment?'
          para='Please select the face that best matches your feelings about getting treatment.'
          answers={{ feeling_now: feelingNow }}
          numberRating={true}
          onSubmit={(answers) => {
            setFeelingNow(answers.feeling_now);
            update({ feeling_now: answers.feeling_now });
            history.push(`${path}/find-a-doctor`);
          }}
          onBack={() => {
            history.push(`${path}/confidence-level`);
          }}
          icons={[{ text: 'Very nervous' }, { text: 'Nervous' }, { text: 'Unsure' }, { text: 'Excited' }, { text: 'Very excited' }]}
          keyForAnswer={'feeling_now'}
        />
      </Route>
      <Route exact path={`${path}/find-a-doctor`}>
        <div className={`row activity-question`}>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-8">
            <YesNoLocation
              onSubmit={(answers, location) => {
                setProfileLocation(location.profileLocation)
                setFindDoctor(answers.findDoctor);
                update({ find_a_doctor: answers.findDoctor.includes('Yes') ? true : false });
                updateP({ address: location.profileLocation.address })
                answeredFinalQuestion({ find_a_doctor: answers.findDoctor.includes('Yes') ? true : false });
                answers.findDoctor.includes('Yes') ? history.push(`/member/join/surgeon-match`) : history.push(`${path}/thank-you`);
              }}
              onBack={() => {
                history.push(`${path}/feeling-now`);
              }}
              answers={{ find_a_doctor: findDoctor }}
              title={`Would you like an OBGYN to reach out and discuss your treatment options?`}
              para={"If so, we'll help you find an OBGYN based on your location and health insurance."}
              keyForAnswer={'findDoctor'}
              ctaText="Submit"
              otherPlaceholder="Enter your location"
              keyForLocation={'profileLocation'}
              location={{address: profileLocation}}
            />
          </div>
          <div className="col-sm-2">
          </div>
        </div>
      </Route>
      <Route exact path={`${path}/thank-you`}>
        <p className={styles.thank_you}>Thank you!</p>
        <p className={styles.thank_you_p}>Your feedback is invaluable to us. With it, we can continue to improve the platform and experience for patients everywhere.</p>
        <Link to={context.auth.isLoggedIn() ? '/member/discussions' : `/member/login?return_to=${encodeURIComponent('/member/discussions')}`}><button className={`${styles.thank_you_button}`}>Explore the community</button></Link>
      </Route>
    </Switch>
  )
}
