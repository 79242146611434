import React, { Suspense, useContext } from 'react'
import Context from '../../../utils/context';
const UterineFibroidsQuestionnaire = React.lazy(() => import('../../questionnaire/uterine_fibroids/index.jsx'));

export default function UterineFibroidsSurgeonMatch({
}) {
  return (
    <div id="questionnaire" className="container-fluid" style={{alignContent: 'center', padding: '0px', borderRadius: '8px', width:'96%', marginTop: '100px'}}>
      <div className="row justify-content-center h-100">
        <div className="col my-auto">
          <Suspense fallback={<p>loading</p>}>
            <UterineFibroidsQuestionnaire
            />
          </Suspense>
        </div>
      </div>
    </div>
  )
}
