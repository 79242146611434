import React, { useState, useContext, useEffect } from "react";
import Context from '../../../utils/context';
import styles from '../style.module.css';
import { Link } from "react-router-dom";
import SurgeonsColumn from "../../surgeons_column";
import LoadingScreen from '../../loading_screen';

export default function SurgeonSection({
}) {
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();
  const [isLoading, setIsLoading] = useState(true);
  const [recommendedSurgeon, setRecommendedSurgeon] = useState();

  useEffect(() => {
    context.auth.getRecommendedSurgeon((surgeon) => {
      setRecommendedSurgeon(surgeon);
      setIsLoading(false);
    })
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row' style={{display: 'flex', flexWrap:'wrap'}}>
            <img alt='doctor-icon' src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/icon+-+health.svg'/>
            <p className={`marg-bottom-10 col ${styles.subheader}`}>Find a doctor</p>
            {!isMobile &&
              <Link to='/all-surgeons'>
                <p className={`${styles.see_more_btn} text-link no-arrow`}>See all doctors</p>
              </Link>
            }
        </div>
        <div className='row marg-top-10'>
          <p className={styles.light_text}>Finding a doctor you can trust and afford shouldn't be difficult. Talk to past patients to find out what their experience was like and check your insurance coverage!</p>
        </div>
      </div>
      <div className={`container ${styles.blue_container} marg-bottom-30`}>
        <ul className='row'>
            <img alt='money-icon' src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/icon+-+money.svg'/>
            <p className={`col ${styles.blue_text}`}>Find a doctor, enter your insurance information to find out the cost.</p>
        </ul>
      </div>
      {
        isLoading && <LoadingScreen/>
      }
      {
        !isLoading && recommendedSurgeon && <SurgeonsColumn surgeon={recommendedSurgeon}/>
      }
      {isMobile &&
        <Link to='/all-surgeons'>
          <button className='col marg-top-30 tertiary_hov'>See all doctors</button>
        </Link>
      }
    </>
  )
}
