export const getPatientPartners = (
  type,
  answers,
  contactId,
  start_date,
  end_date,
  limit_5,
  successCallback,
  include_inactive,
  otherParams = {}
) => {
  let query = { ...otherParams, type: type };

  if (answers !== null) {
    query = { ...query, answers }
  }
  if (contactId !== null) {
    query = { ...query, contact_id: contactId }
  }

  if (start_date !== '') {
    query = { ...query, start_date }
  }

  if (end_date !== '') {
    query = { ...query, end_date }
  }

  if(limit_5) {
    query = { ...query, limit_5 }
  }
  if (include_inactive) {
    query = { ...query, include_inactive }
  }
  fetch(`/patient_partners?${objToQueryString(query)}`, {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
      },
  })
    .then(response => response.json())
    .then(data => {
      successCallback(data);
    })
}

export const getPatientPartnersPerDistance = (location, distance, successCallback) => {
  if(location === ''){
    successCallback([])
    return
  }
  let query = {
    location: location,
  };
  if (distance !== 'undefined'){
    query = {
      location: location,
      distance: distance
    };
  }

  fetch(`/patient_partners?${objToQueryString(query)}`, {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
      },
  })
    .then(response => response.json())
    .then(data => {
      successCallback(data);
    })

}

export const getPatientPartner = (
    id,
    successCallback
) => {
    fetch(`/patient_partners/${id}`, {
        headers: {
            "Content-Type": "application/json",
        }
    })
    .then(response => response.json())
    .then(data => {
        successCallback(data);
    })
}
