import React, { useState, useEffect } from 'react'
import DropdownInput from '../../ui_kit/utilities/dropdown_input';
import { updatePatientAssistantInfo, getPatientAssistantInfo } from '../../../api/patient_assistant_infos'
import { sendNote } from '../../../api/patient_assistant_infos'
import styles from './index.module.css'

export default function StatusColumn({
  paiStatus,
  uuid,
  multipleSetOpen = () => {},
  openStatus,
  pendoTag
}) {
  const [status, setStatus] = useState(paiStatus);
  const statuses = [
    { value: 'Not Contacted', color: 'rgb(236, 78, 62)' },
    { value: 'Contacted', color: 'rgb(65, 157, 165)' },
    { value: 'Consult scheduled', color: 'rgb(217,138,19)' },
    { value: 'Surgery scheduled', color: 'rgb(112,86,216)' },
    { value: 'Cancelled appt', color: 'rgb(130,130,130)' },
    { value: 'No longer interested', color: 'rgb(130,130,130)' }
  ]
  const [disabled, setDisabled] = useState('');

  useEffect(() => {
    setStatus(paiStatus)
  }, [paiStatus]);

  useEffect(() => {
    if (status !== paiStatus) {
      setDisabled('disabled')
      updatePatientAssistantInfos({ old_status: paiStatus, new_status: status })
    }
  }, [status]);

  const updatePatientAssistantInfos = (params) => {
    updatePatientAssistantInfo(uuid, params.new_status, () => {getPatientAssistantInfo(uuid, (data) => {setStatus(data.status)});setDisabled('')});
    sendNote(uuid, 'PatientAssistantInfoLogs::NewStatus', params, (data) => console.log(data))
  }

  return (<>
    {paiStatus &&
      <DropdownInput
        values={status}
        setValues={setStatus}
        options={statuses}
        gtm={`Messenger Profile Set Status`}
        type="single"
        elemClassname={styles.input}
        wrapperClassname={`marg-center sap_demarg`}
        disabled={disabled}
        multipleSetOpen={(status) => multipleSetOpen(status)}
        uuid={uuid}
        openStatus={openStatus}
        pendoTag={pendoTag}
      />
    }
  </>)
}
