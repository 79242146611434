import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import { createConversation } from '../../../../api/conversations'
import { createActivity } from '../../../../api/activities'
import Context from '../../../../utils/context';
import RequestButton from '../request_button'
import styles from './index.module.css'

export default function SendMessageButton({
  otherProfile,
  style,
  secondary = true,
  tertiary = false,
  origin,
  pendoTag = 'profile tooltip hover newsfeed',
  initialMessage = null
}) {
  const context = useContext(Context);
  let history = useHistory();


  const goToSignup = (otherProfile) => () => {
    const returnTo = window.location.pathname;
    const pathname = `/member/login?return_to=${encodeURIComponent(returnTo)}`;
    history.push(pathname);
    
    // The following redirect puts you into the sign up flow for discussions but doesn't correctly set the login redirect on the sign up page
    // history.push(`/member/join/support?profile_id=${otherProfile.id}&profile_type=${otherProfile.type}`)
  }

  const sendMessageOnClick = () => {
    createConversation({ profile_id: otherProfile.id, profile_type: otherProfile.type, initial_message: initialMessage }, (conversation) => {
      history.push(`/member/conversations/${conversation.uuid}`);
    });
    if (origin) {
      createActivity('Activity::CreateConversation', { origin: origin, other_profile_id: otherProfile.id, other_profile_type: otherProfile.type })
    }
  };

  if (tertiary){
    secondary = false
  }

  if (otherProfile === undefined){
    return <></>
  }
  return (
    <div>
      {(context.auth.getCurrentUser().current_profile_type !== otherProfile.type || context.auth.getCurrentUser().current_profile_id !== otherProfile.id) &&
        <RequestButton pendoTag={`${pendoTag} - send message`} style={style} classnames={`${styles.ava_button} ${secondary && styles.liner} ${tertiary && 'tertiary_hov'}`} request={context.auth.isLoggedIn() ? sendMessageOnClick : goToSignup(otherProfile)} cta={'Send Message'} />
      }
    </div>
  )
}
