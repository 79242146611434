import React, { useContext } from 'react'
import {
  Link,
} from "react-router-dom";
import Context from '../../../../utils/context';
import ConversationsListItem from '../conversations_list_item';

export default function ConversationList({
  newConversationButtonClick,
  conversations,
  pathToMatch = '',
  selectedConversation
}) {
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();
  const isMobile = context.viewport.getIsMobile();

  return (<>
    {conversations && <>
      <div className="convo-list-header">
        <h5 >My Conversations</h5>
        {
          user.is_super_user &&
          <div className="new-conversation" onClick={newConversationButtonClick}>
            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.4373 17.6248L27.656 3.40606C27.9216 3.13101 28.0686 2.76263 28.0653 2.38026C28.062 1.99789 27.9086 1.63212 27.6382 1.36174C27.3678 1.09135 27.0021 0.937979 26.6197 0.934656C26.2373 0.931333 25.869 1.07832 25.5939 1.34397L11.3752 15.5627L10.9479 18.0521L13.4373 17.6248Z" fill="#59B6BE" />
              <path d="M5.47917 2.375C4.12546 2.375 2.82719 2.91276 1.86998 3.86998C0.912759 4.82719 0.375 6.12546 0.375 7.47917V23.5208C0.375 24.8745 0.912759 26.1728 1.86998 27.13C2.82719 28.0872 4.12546 28.625 5.47917 28.625H21.5208C22.8745 28.625 24.1728 28.0872 25.13 27.13C26.0872 26.1728 26.625 24.8745 26.625 23.5208V12.5833C26.625 12.1966 26.4714 11.8256 26.1979 11.5521C25.9244 11.2786 25.5534 11.125 25.1667 11.125C24.7799 11.125 24.409 11.2786 24.1355 11.5521C23.862 11.8256 23.7083 12.1966 23.7083 12.5833V23.5208C23.7083 24.101 23.4779 24.6574 23.0676 25.0676C22.6574 25.4779 22.101 25.7083 21.5208 25.7083H5.47917C4.89901 25.7083 4.34261 25.4779 3.93237 25.0676C3.52213 24.6574 3.29167 24.101 3.29167 23.5208V7.47917C3.29167 6.89901 3.52213 6.34261 3.93237 5.93237C4.34261 5.52213 4.89901 5.29167 5.47917 5.29167H16.4167C16.8034 5.29167 17.1744 5.13802 17.4479 4.86453C17.7214 4.59104 17.875 4.22011 17.875 3.83333C17.875 3.44656 17.7214 3.07563 17.4479 2.80214C17.1744 2.52865 16.8034 2.375 16.4167 2.375H5.47917Z" fill="#59B6BE" />
            </svg>
          </div>
        }
      </div>
      <div className="container convo-list">
        <div className="row">
          <div className="col de-pad">
            {conversations && conversations.length === 0 &&
              <p style={{ padding: '15px' }}>This is where you'll find a list of conversations you're having!</p>
            }
            {conversations && <>
              {conversations.map((conversation, i) =>
                <div key={i}>
                  <Link  to={`${pathToMatch}${conversation.uuid}`}>
                    <ConversationsListItem
                      user={user}
                      conversation={conversation}
                      selectedConversation={selectedConversation}
                    />
                  </Link>
                </div>
              )}
            </>}
          </div>
        </div>
      </div>
    </>}
  </>)
}