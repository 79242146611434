import React, { Component } from 'react'
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function MfInformation({
  value,
  onSubmit,
  onBack
}) {
  return (
    <OptionsAndOther
      answers={{ mfInformation: value}}
      keyForAnswer={'mfInformation'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={[
        'Internet (Google, WebMD, Healthline, etc.)',
        'Doctor/Healthcare Providers',
        'Social media (Facebook, IG, YouTube, etc.)',
        'Non-profit communities (Voices of NPN, etc.)',
        'Friends and Family'
      ]}
      title={`What sources have you (or the patient) utilized to gather information about Myelofibrosis (MF)?`}
      para={``}
      showOther
      ctaTxt='Next'
    />
  )
}
