import React, { Suspense, useContext } from 'react'
import Context from '../../../utils/context';
import styles from './index.module.css'
const UterineFibroidsQuestionnaire = React.lazy(() => import('../../questionnaire/uterine_fibroids/index.jsx'));

export default function BetterIsPossible({
}) {
  return (
    <div style={{
      backgroundImage: 'url(https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/betterispossiblebg.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
    }}>
      <div id="questionnaire" className="container" style={{
        alignContent: 'center',
        paddingBottom: '50px',
      }}>
        <div>
          <img style={{ width: '250px', marginTop: '25px', marginLeft: '25px' }} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/betterispossiblelogo.png" />
        </div>
        <div className="row justify-content-center h-100">
          <div className={`col my-auto better_is_possible_container ${styles.better_is_possible_container}`}>
            <Suspense fallback={<p>loading</p>}>
              <UterineFibroidsQuestionnaire
                backgroundColor={null}
                headerContent={
                <div className="container">
                  <h1 style={{ textAlign: 'center', marginBottom: '10px', fontSize: '60px', color: '#384a7c' }}>Better starts today<sup style={{ fontSize: '20px', color: '#384a7c'}}>TM</sup></h1>
                  <p style={{ fontSize: '20px' }}>Painful periods and heavy bleeding aren't always just symptoms of your cycle, they could be caused by something else -- fibroids. Let's break down your symptoms so we can help you find relief! Connect with a local physician who accepts your insurance, or connect with a woman who has found relief from similar symptoms. Hologic, in partnership with Patient Partner, believes Better is possible - and it starts today!</p>
                </div>}
                signupHeadline={`Take our quiz to understand your symptoms and the next steps to finding relief. It's free and easy to sign-up!`}
                useFormWrapper
                standAlone
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}
