import React, { useState, useRef, useEffect, useContext } from "react";
import RequestButton from "../../ui_kit/utilities/request_button";
import { createPost, updatePost } from "../../../api/posts";
import { createActivity } from "../../../api/activities";
import { useClickAway } from "react-use";
import styles from "./index.module.css";
import ImageGridDisplay from "../../ui_kit/utilities/image_grid_display";
import S3UploadFile from "../../ui_kit/utilities/s3_upload_file";
import { successToast } from '../../ui_kit/utilities/success_toast';
import Textarea from 'react-expanding-textarea'
import { autofocusAll } from '../../../helpers'
import ScrollTo from "../../scroll_to";
import { useMeasure } from "react-use";
import Context from './../../../utils/context';
import { useHistory } from "react-router-dom"
import UrlPreview from "../url_preview"
import LoadingScreen from '../../loading_screen'
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";
import { pageInspect } from "../../../api/metainspector";
import { isObjEmpty } from '../../../helpers';

export default function Comment({
  post,
  refresh,
  showAllComments = true,
  setShowAllComments,
  setShowComments = () => { },
  commentToEdit = {},
  focus = false,
  defaultExpandComment = false,
  placeholder = 'Leave a comment...',
  cta = 'Send comment'
}) {
  const isEdit = Object.keys(commentToEdit).length !== 0;
  const [clickComment, expandComment] = useState(defaultExpandComment || false);
  const [comment, setComment] = useState(commentToEdit.body || '');
  let defaultFiles = [];
  if (commentToEdit.post_images) {
    defaultFiles = commentToEdit.post_images.map(i => i.image);
  }
  const [files, setFiles] = useState(defaultFiles);
  const [commentToFind, setCommentToFind] = useState('');
  let history = useHistory();
  const context = useContext(Context);
  const [urlPreview, setUrlPreview] = useState();
  const [urlMetadata, setUrlMetadata] = useState(commentToEdit.url_preview_metadata);
  const [isUrlPreviewLoading, setIsUrlPreviewLoading] = useState(false);
  var linkify = require('linkifyjs');

  useEffect(() => {
    if (commentToFind !== ''){
      let elem = document.querySelector(commentToFind)
      if (elem !== null){
        ScrollTo(commentToFind, 100)
        setCommentToFind('')
      }
    }
  }, [post]);

  const ref = useRef(null);
  useClickAway(ref, () => {
    expandComment(false);
  });

  const submitComment = (afterAction) => {
    if (isEdit) {
      updatePost(commentToEdit.uuid, {
        body: comment,
        files: files,
        url_preview_metadata: urlMetadata
      }, (data) => {
        afterAction();
        refresh();
        setComment('')
        setFiles([])
        successToast('Comment updated.')
      });
    } else {
      createActivity("Activity::DiscussionsComment", { post_id: post.uuid });
      createPost(
        {
          body: comment,
          files: files,
          parent: post.uuid,
          url_preview_metadata: urlMetadata
        },
        (data) => {
          setShowComments(true)
          setCommentToFind(`#${data.uuid.replace(/[0-9]/g, '')}`)
          afterAction();
          refresh();
          setComment('')
          setFiles([])
        }
      );
    }
    expandComment(false);
  };

  const removeUrlPreview = () => {
    setUrlMetadata(null);
    setUrlPreview(null);
  };

  useEffect(() => {
    if(focus){
      autofocusAll();
    }
  }, []);

  const debouncedShowUrlPreview = useDebouncedCallback((value) => {
    showUrlPreview(value);
  }, 900);

  const showUrlPreview = (newBody) => {
    const link = linkify.find(newBody).find(l => l.type === 'url');
    if (link && (link.href !== urlPreview)) {
      setUrlPreview(link.href);
      pageInspect(link.href, (data) => {
        if (!isObjEmpty(data) && data.title && data.description && data.image) {
          setUrlMetadata(data);
        }
        setIsUrlPreviewLoading(false);
      })
      setIsUrlPreviewLoading(true);
    }
  };

  const [size_ref, { x, y, width }] = useMeasure();

  return (
    <div className="container">
      <div
        data-pendo-tag="Leave a comment..."
        className="marg-top-10 row justify-content-center"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="col comment-col de-pad">
          <div ref={ref} style={{ position: "relative" }}>
            <Textarea
              ref={size_ref}
              onClick={() => {!context.auth.isLoggedIn() ?
                history.push(`/member/login?return_to=/${encodeURIComponent(post.share_path)}`)
              : expandComment(true)}}
              onChange={(e) => {setComment(e.target.value); debouncedShowUrlPreview(e.target.value)}
              }
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                zIndex: "0",
                minHeight: clickComment ? "200px" : "60px",
                marginTop: "0px",
                paddingBottom: clickComment ? "60px" : "0px"
              }}
              className={`${focus ? 'autofocus' : ''} material-input marg-bottom-10`}
              placeholder={placeholder}
              value={comment}
            />

            {clickComment && (
              <>
                <RequestButton
                  data-pendo-tag='Send comment'
                  classnames={`${styles.comment_btn} ${width < 194 && styles.small_send}`}
                  disabled={comment.length > 0 || files.length > 0 ? "" : "disabled"}
                  cta={width > 194 ? cta : <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/small_send.svg"/>}
                  request={submitComment}
                />
                <S3UploadFile
                  onChange={(newFiles) => {
                    setFiles([...files, ...newFiles])
                  }}
                  type="icon"
                />
              </>
            )}
          </div>
          { /* START website preview */}
          <div style={{ marginTop: '10px' }} classnames={`${styles.comment_btn} ${width < 194 && styles.small_send}`}>
            {
              isUrlPreviewLoading && <LoadingScreen size={'20'} />
            }
            {
              !isUrlPreviewLoading && urlMetadata && <UrlPreview metadata={urlMetadata} onDelete={removeUrlPreview} />
            }
          </div>
          { /* END website preview */}
          <ImageGridDisplay
            photos={files}
            mode={'upload'}
            type={'inline'}
          />
        </div>
      </div>
    </div>
  );
}
