import React from 'react'
import ChooseManyOptions from '../choose_many_options';

export default function PpPreventedFromSurgery({
  answers,
  onSubmit,
  onBack,
  gtm
}) {
  return (
    <ChooseManyOptions
      answers={answers}
      keyForAnswer={'preventedFromSurgery'}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} What has prevented you from getting surgery or treatment?`}
      options={[
        'I am researching my options',
        'I have insurance questions',
        'I can’t find a qualified doctor',
        'I’m nervous about recovery',
      ]}
      title={`What has prevented you from getting surgery or treatment?`}
      para={`This will help your PatientPartner know how they can help you. Select any that apply.`}
      otherItemLabel={`reasons`}
    />
  );
}
