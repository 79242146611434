import React from 'react'
import { useIntersection } from 'react-use';

export default function ScrollTrigger({ callback = () => console.log('Scrolled into view'), delay = 0}) {
  const [hasCalledback, setHasCalledback] = React.useState(false);

  const intersectionRef = React.useRef(null);
  let intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  });

  if (intersection && intersection.intersectionRatio === 1 && hasCalledback === false) {
    const timer = setTimeout(() => {
      callback()
    }, delay);
    setHasCalledback(true)
    return () => clearTimeout(timer);
  }

  return (
    <div style={{ visibility: 'hidden' }} ref={intersectionRef}></div>
  )
}