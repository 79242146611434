import React from 'react'
import ProceduresPerformed from './panels/procedures_performed'
import AssociatedMentors from './panels/associated_mentors'
import FeaturedVideo from './panels/featured_video'
import CareCost from '../../../../care_cost'

export default function Overview({
  surgeon
}) {

  return (
    <div style={{ marginTop: '20px' }}>
      { /* <CareCost surgeon={surgeon} /> */ }
      <ProceduresPerformed surgeon={surgeon} />
      {surgeon.patient_partners && surgeon.patient_partners !== null && surgeon.patient_partners.length !== 0 &&
        <AssociatedMentors mentors={surgeon.patient_partners} slice/>
      }
      {surgeon.extra && surgeon.extra.patient_partner_video_url !== null && surgeon.extra.patient_partner_video_url !== undefined &&
        <FeaturedVideo video={surgeon.extra.patient_partner_video_url} title={surgeon.extra.patient_partner_video_title} />
      }
    </div>
  )
}
