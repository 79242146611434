import React, { useState } from 'react'

export default function HoursAndMinutes({
    onBack,
    onSubmit,
    title,
    para,
    keyForAnswer,
    yesState,
    gtm
}) {

    const [selectedMinutes, setSelectedMinutes] = useState(0);
    const [selectedHours, setSelectedHours] = useState(0);

    var hours = []
    for (let i = 0; i <= 6; i += 1) {
        hours.push(<option key={i} value={i}>{i}</option>)
    }

    var minutes = []
    for (let i = 0; i <= 60; i += 5) {
        minutes.push(<option key={i} value={i}>{i}</option>)
    }

    var time = parseInt(selectedMinutes) + (parseInt(selectedHours) * 60)
    const submit = (e) => {
        e.preventDefault();
        console.log({ [keyForAnswer]: time })
        onSubmit({ [keyForAnswer]: time }, yesState);
    }

    return (
        <div>
            <form id="plans">
                <h5 className="question">
                    {title}
                </h5>
                <p className="question">
                    {para}
                </p>
                <div className="marg-top-20 marg-bottom-20 preexisting-conditions material-input light marg-center">
                    <p>Hours</p>
                    <select onChange={(e) => setSelectedHours(e.target.value)}>
                        {hours}
                    </select>
                </div>
                <div className="marg-top-20 marg-bottom-20 preexisting-conditions material-input light marg-center">
                    <p>Minutes</p>
                    <select onChange={(e) => setSelectedMinutes(e.target.value)}>
                        {minutes}
                    </select>
                </div>
                <div className="container">
                    <div className="row button-holder">
                        <button className="back col-lg-6" type="button" onClick={onBack} data-gtm-click-label={`${gtm} - Back`}>Back</button>
                        <button disabled={`${time > 0 ? '' : 'disabled'}`} className={`gtm-preexisting-conditions submit col-lg-6`} type="submit" onClick={(e) => submit(e)} data-gtm-click-label={`${gtm} - Next`}>Next</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
