import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid'
import Context from '../../utils/context';
import { createConversation } from '../../api/conversations';
import styles from './index.module.css'
import './index.css'

export default function PatientPartnerCard({
  partner,
  onDisplay = false,
  showSendMessage = true,
}) {
  const [showBio, setShowBio] = useState(onDisplay);
  const hideBio = () => {
    window.scrollTo({
      top: (document.querySelector('#' + cardId).getBoundingClientRect().y + window.scrollY) + -100,
      left: 0,
      behavior: 'smooth'
    });
    setShowBio(false);
  }
  const showBioClick = () => {
    setShowBio(true);
  }
  let history = useHistory();
  const context = useContext(Context);

  const cardId = `card_${uuid()}`

  // Listens to know if the device is a mobile device START
  const isMobile = context.viewport.getIsMobile();

  useEffect(() => {
    if (onDisplay) {
      window.scrollWithOffset(cardId, -125);
    }
  }, [])

  const goToSignup = () => () => {
    history.push(`/member/join/support?patient_partner_id=${partner.id}`)
  };

  const generateActionContent = () => {
    let actionContent = null;
    if (!showSendMessage) {
      return <></>;
    }
    
    if (context.auth.isLoggedIn()) {
      actionContent = (
        <button data-pendo-tag="Surgeon Page - Mentor - send a message" data-gtm-click-label="Patient Partner Card Message Patient Partner"  className={`marg-bottom-0 ${styles.send_message_cta}`} onClick={() => {
          createConversation({ patient_partner_id: partner.id }, (conversation) => {
            history.push(`/member/conversations/${conversation.uuid}`);
          })
        }}>Send a message</button>
      );
    }
    else {
      actionContent = (
        <button data-pendo-tag="Surgeon Page - Mentor - send a message" data-gtm-click-label="Patient Partner Card Message Patient Partner" className={`marg-bottom-0 ${styles.send_message_cta}`} onClick={goToSignup(partner)}>Send a message</button>
      );
    }

    return actionContent;
  }

  return (
    <div key={cardId} className={`row patient-partner-card ${!showBio && !isMobile ? 'fixed-height-card' : ''}`} id={cardId}>
      <div className="col">
        <div className="row">
          <div className="col-4 patient-partner-card-image-column">
            <div style={{backgroundImage: `url('${partner.image}')`}} className="patient-partner-card-image-thumbnail"></div>
          </div>
          <div className="col-8 patient-partner-card-detail-column">
            <h5 className="marg-bottom-10">{partner.name}</h5>
            <p className="patient-partner-card-detail-label">Age: <b>{partner.display_age}</b></p>
            <p className="patient-partner-card-detail-label">Location: <b>{partner.display_location}</b></p>
            {
              partner.weight_loss && <p className="patient-partner-card-detail-label">Weight-Loss: <b>{partner.weight_loss} lbs</b></p>
            }
            <p className="patient-partner-card-detail-label">Health conditions: <b>{partner.health_conditions && partner.health_conditions.length > 0 ? partner.health_conditions.join(', ') : 'None'}</b></p>
            {
              partner.surgeries_or_treatments &&
              <p className="patient-partner-card-detail-label">Surgery: <b>{partner.surgeries_or_treatments.join(', ')}</b></p>
            }
            <>
              <p className="patient-partner-card-detail-label"><img className="phone-call-icon" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/phone.svg"></img> Had <b>{partner.num_meetings}</b> PatientPartner™ calls</p>
            </>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {
              showBio && (
                <>
                  <p className="patient-partner-card-bio">{partner.bio}</p>
                  <div>
                    <p data-gtm-click-label="Patient Partner Card Show Less" className="text-link no-arrow marg-top-10" onClick={hideBio}>Show less</p>
                  </div>
                </>
              )
            }
            {
              !showBio && (
                <div>
                  <p data-gtm-click-label="Patient Partner Card Read Full Bio" className="text-link no-arrow marg-bottom-10" onClick={showBioClick}>Read full bio</p>
                </div>
              )
            }
          </div>
        </div>
        <div className={`row ${!showBio && !isMobile ? 'position-bottom' : ''}`}>
          <div className="col">
            {generateActionContent()}
          </div>
        </div>
      </div>
    </div>
  )
}
