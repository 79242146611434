import React, { useState } from 'react'
import { validateText } from '../../../helpers';
import ErrorMessage from '../../ui_kit/utilities/error_message';
import styles from './patient_gender.module.css'

export default function HealthcareJourney({
  answers,
  onSubmit,
  onBack,
  gtm,
  allowOther = true
}) {
  const ALL_CONDITIONS = [
    'Considering a procedure',
    'Looking for a doctor',
    'Preparing for a procedure',
    'Surgery scheduled',
    'Recovering from a procedure'
  ];

  const [currentJourney, setCurrentJourney] = useState(answers.currentJourney ? answers.currentJourney : '');

  const onClick = (condition) => () => {
    if (currentJourney.includes(condition)) {
      setCurrentJourney('')
    }
    else {
      setCurrentJourney(condition)
    }
  };

  const renderParen = (condition) => {
    if (condition === 'Considering a procedure') {
      return <span className={styles.italicized}> (gathering information)</span>
    }
    if (condition === 'Preparing for a procedure') {
      return <span className={styles.italicized}> (insurance approval, etc.)</span>
    }

    return <></>;
  }

  return (
    <div className="row">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <h5 className="text-left mobile-marg-20 marg-bottom-10">
          Where are you in your healthcare journey? (Select the best match.)
        </h5>
        <p className="marg-bottom-20">
          We'll let your mentor know where you are in your journey.
        </p>
        <form id="plans">
          {ALL_CONDITIONS.map((condition) => (
            <div key={condition} className="tile">
              <label key={condition} className={`weight-loss-journey-tile preexisting ${currentJourney.includes(condition) ? 'activeCheckbox' : ''}`} ><input onClick={onClick(condition)} type="checkbox" name={condition} value="value"/>{condition}{renderParen(condition)}</label>
            </div>
          ))}
          {allowOther &&
            <div className="marg-top-20 marg-bottom-20 preexisting-conditions material-input light marg-center">
              <h5 className="question other">
                  Other:
              </h5>
              <input placeholder="Other status type here" type="text" name="other" onChange={(event) => setCurrentJourney(event.target.value)}/>
              <span className="bar"></span>
            </div>
          }
          {(currentJourney !== '' && !validateText(currentJourney)) &&
            <ErrorMessage
              error = {'Please enter other reasons without special characters'}
            />
          }
          <div className="row button-holder">
            <button data-gtm-click-label={`${gtm} Where are you in your healthcare journey Back`} className="back col-6" type="button" onClick={() => onBack()}>Back</button>
            <button data-gtm-click-label={`${gtm} Where are you in your healthcare journey Submit`} disabled={`${(currentJourney !== '' && validateText(currentJourney)) ? '' : 'disabled'}`} className={` submit col-6`} type="submit" onClick={(e) => {e.preventDefault();onSubmit({ ...answers, currentJourney: [currentJourney] })}}>Next</button>
          </div>
        </form>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
