import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ClickToSee from "../../ui_kit/utilities/click_to_see";
import SimpleAvatar from "../../ui_kit/utilities/simple_avatar";
import Context from "../../../utils/context";
import { isObjEmpty } from "../../../helpers";
import {
  getNotifications,
  markEverythingAsRead,
  updateNotification,
} from "../../../api/notifications";
import styles from "./index.module.css";
import NoNotifications from "../no_notifications";
export default function Notifications({
  unreadNotificationCount,
  embedded = false
}) {
  const context = useContext(Context);
  const history = useHistory();
  if (!context.auth.isLoggedIn()) {
    return <></>;
  }

  const currentUser = context.auth.getCurrentUser();
  if (isObjEmpty(currentUser.current_profile)) {
    return <></>;
  }

  if (!currentUser.profile_id) {
    return <></>;
  }

  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const loadNotifications = () => {
    setLoadingNotifications(true);
    getNotifications((data) => {
      setNotifications(data);
      setLoadingNotifications(false);
    });
  };

  const markEverythingAsReadClick = () => {
    markEverythingAsRead();
    const newNotifications = notifications.map((notification) => {
      return {
        ...notification,
        read: true,
      };
    });

    setNotifications(newNotifications);
  };

  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const notificationOnClick = (notification) => () => {
    if (notification.read) {
      setNotificationsOpen(false);
      return;
    }

    const newNotifications = notifications.map((n) => {
      if (n.id === notification.id) {
        return {
          ...n,
          read: true,
        };
      }
      return n;
    });
    updateNotification(notification.id, { read: true });
    setNotifications(newNotifications);
    setNotificationsOpen(false);
  };

  const renderCustomTextNotification = (notification) => {
    return (
      <p>{notification.custom_text}</p>
    )
  };

  const renderNormalNotification = (notification) => {
    if (notification.num_other_distinct_users >= 1) {
      return (
        <p>
          <b>{notification.action_profile.display_name} </b>
          and {" "} {notification.num_other_distinct_users} {" "}
          {notification.num_other_distinct_users == 1 ?
            <>
              other{" "} </> : <> others{" "}</>}
          {notification.action_phrase} <b>"{notification.gist}"</b>
        </p>
      )
    }

    return (
      <p>
        <b>{notification.action_profile.display_name} </b>
        {notification.action_phrase}{" "}
        <b>"{notification.gist}"</b>
      </p>
    )
  };

  const renderNotification = (notification) => {
    let morePeople = ""
    if (notification.num_other_distinct_users > 0) {
      morePeople = ` and ${notification.num_other_distinct_users} more people`;
    }

    return (
      <div
        key={`notification-${notification.id}`}
        className={`${styles.notification_container} ${notification.read ? "" : styles.notification_unread
          } container`}
        data-pendo-tag={`notifications - individual notification alerts - ${notification.action_phrase}`}
      >
        <div className={`row`}>
          <div className={`${styles.notification_avatar_container} col-sm-2`}>
            <SimpleAvatar profile={notification.action_profile} />
          </div>
          <div
            onClick={notificationOnClick(notification)}
            className={`${styles.notification_text_container} col-sm-10`}
          >
            <Link to={notification.action_link}>
              { notification.type === 'Notifications::CustomText' ? renderCustomTextNotification(notification) : renderNormalNotification(notification)}
              <div className={styles.notification_timestamp}>
                {notification.last_notified_at}
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (<>
    {!embedded ?
      <div style={{ position: 'relative' }}>
        <ClickToSee
          open={notificationsOpen}
          setOpen={setNotificationsOpen}
          contentToClick={
            <>
              <img
                alt="notifications"
                id="notifications"
                src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+15/Icon.svg"
              />
              {unreadNotificationCount > 0 && (
                <div className={styles.unread_notification_count_container}>
                  <p className={styles.unread_notification_count}>
                    {unreadNotificationCount}
                  </p>
                </div>
              )}
            </>
          }
          onClick={loadNotifications}
          headerTitle={"Notifications"}
          headerRightContent={
            <div data-pendo-tag="notifications - mark everything as read" onClick={markEverythingAsReadClick} className={styles.mark_everything_as_read}>
              Mark everything as read
            </div>
          }
          contentToSeeClassNames={styles.notif_container}
        >
          <div>
            {loadingNotifications && <div>Loading</div>}
            {!loadingNotifications && (
              <>
                {notifications.length == 0 ? (
                  <NoNotifications headerTitle={"Notifications"} />
                ) : (
                  notifications.map(renderNotification)
                )}
              </>
            )}
          </div>
        </ClickToSee>
      </div>
      :
      <div style={{display: 'relative'}}>
        <img
          alt="notifications"
          id="notifications"
          src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+15/Icon.svg"
        />
        {unreadNotificationCount > 0 && (
          <div className={styles.unread_notification_count_container}>
            <p className={styles.unread_notification_count}>
              {unreadNotificationCount}
            </p>
          </div>
        )}
      </div>
    }
  </>);
}
