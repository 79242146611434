import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ModalWrapper from '../modal';
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms';
import InputWithOptions from '../ui_kit/utilities/input_with_options';
import ReCAPTCHA from "react-google-recaptcha";
import SuccessPanel from '../success_panel'
import { createDirectSurgeonBookingContacts } from '../../api/direct_surgeon_booking_contacts'
import { validatePhone, validateEmail, validateText, validateZipcode, isEmpty } from '../../helpers';
import './index.css';
import Todpicker from '../todpicker';
import DatePickerWrapper from '../date_picker_wrapper';

export default function SurgeonBookingModal({
  home = [],
  surgeon,
  insurances = [...gon.all_insurances],
  onClose,
  origin,
  insurance
}) {
  const [view, setView] = useState('form')
  const [name, setName] = useState('');
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [zipcode, setZipCode] = useState('')
  const [insuranceCarrier, setInsuranceCarrier] = useState('');
  const [surgery, setSurgery] = useState('');
  const [error, setError] = useState('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const recaptchaRef = React.createRef();

  const runRecaptcha = async (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  }

  const getNextWork = (d) => {
      d.setDate(d.getDate() + 1); // tomorrow
      if (d.getDay() == 0) d.setDate(d.getDate() + 1);
      else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
      return d;
  }

  const minDate = getNextWork(getNextWork(new Date()));
  const [apptDate, setApptDate] = useState('');
  const [apptTimeOfDay, setApptTimeOfDay] = useState('Preferred appt. time');

  const isWeekday = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  let disabled = 'disabled';
  var checkedEmail = validateEmail(email);
  var checkedName = validateText(name);
  var checkedZipcode = validateZipcode(zipcode);
  var checkedPhone = validatePhone(phone);
  var checkedInsuranceCarrier = insuranceCarrier !== '';
  var checkedApptDate = apptDate !== '' && apptDate !== null;
  var checkedApptTimeOfDay = apptTimeOfDay !== 'Preferred appt. time';
  var checkedSurgery = surgery !== '';

  if (checkedEmail &&
      checkedName &&
      checkedZipcode &&
      checkedPhone &&
      checkedInsuranceCarrier &&
      checkedApptDate &&
      checkedApptTimeOfDay &&
      checkedSurgery &&
      acceptedTerms) {
      disabled = '';
  }

  const onClick = (event) => {
    if(hasBeenSubmitted == false){
      setHasBeenSubmitted(true);
      if (checkedEmail === false) {
        setError('Please enter a valid email');
        setHasBeenSubmitted(false);
      }
      else if (checkedName == false) {
        setError('Please enter a valid name');
        setHasBeenSubmitted(false);
      }
      else if (checkedPhone == false) {
        setError('Please enter a valid phone number');
        setHasBeenSubmitted(false);
      }
      else if (checkedZipcode == false) {
        setError('Please enter a valid Zip Code');
        setHasBeenSubmitted(false);
      }
      else if (checkedSurgery === false) {
        setError('Please select a surgery');
        setHasBeenSubmitted(false);
      }
      else {
        setView('success');
        createDirectSurgeonBookingContacts(
          surgeon,
          name,
          email,
          phone,
          zipcode,
          insuranceCarrier,
          apptDate,
          apptTimeOfDay,
          '',
          origin,
          surgery,
          (contact) => {
            console.log('success');
          }
        )
      }
    }
  }

  return (
    <ModalWrapper
      className={'surgeon-booking-modal'}
      content={(
        <>
          {
            view == 'success' && (
              <div className="pad-30">
                <SuccessPanel
                  title="Thank you!"
                  content={`${surgeon.name}'s office will call you in 24-48 hours to confirm your appointment and insurance plan coverage.  In the meantime, talk to a recovered patient about their experience with your procedure.`}
                  cta={<Link to={`/get-started/patient-partner-match`}><button data-gtm-click-label="Surgeon Booking Modal Success">Talk to a recovered patient</button></Link>}
                />
              </div>
            )
          }
          {
            view == 'form' && (
              <div className="container">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <form className="surgeon-appt-signup-form">
                      <h5 className="bold marg-bottom-10">Request an appointment with {surgeon.name}</h5>
                      <p>{surgeon.name} office will reach out within 24-48 hours to confirm your appointment.</p>
                      <div className="material-input">
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                          required
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Name</label>
                      </div>
                      <div className="material-input">
                        <input
                          type="tel"
                          required
                          name="phone"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label>Phone Number</label>
                      </div>
                      <div className="material-input">
                        <input
                          type="name"
                          name="name"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          required
                          className="icon-email"
                        />
                        <span className="bar"></span>
                        <label>Email</label>
                      </div>
                      <div className="material-input">
                        <input
                          type="text"
                          name="zipcode"
                          value={zipcode}
                          onChange={(event) => setZipCode(event.target.value)}
                          required
                        />
                        <span className="bar"></span>
                        <label>Zip Code</label>
                      </div>
                      <InputWithOptions
                        value={surgery}
                        setValue={setSurgery}
                        options={["I don't know", ...surgeon.surgeries_or_treatments]}
                        name={'insuranceCarrier'}
                        label={'Surgery'}
                        light={false}
                        noMatchTxt={''}
                      />
                      <InputWithOptions
                        value={insuranceCarrier}
                        setValue={setInsuranceCarrier}
                        options={insurances}
                        name={'insuranceCarrier'}
                        label={'Insurance Provider'}
                        light={false}
                        noMatchTxt={''}
                      />
                      <DatePickerWrapper
                        selected={apptDate}
                        onChange={date => setApptDate(date)}
                        minDate={minDate}
                        filterDate={isWeekday}
                        wrapperClassName={'react-datepicker-wrapper material-input'}
                        placeholderText={'Preferred appt. date'}
                      />
                      <Todpicker
                        value={apptTimeOfDay}
                        onChange={(event) => {setApptTimeOfDay(event.target.value);}}
                      />
                      <AgreeToTerms acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
                      {error &&
                        <ErrorMessage
                          error={error}
                        />
                      }
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={gon.recaptcha_sitekey}
                        onChange={onClick}
                      />
                      <button disabled={disabled} onClick={runRecaptcha} data-gtm-click-label="Surgeon Booking Modal Submit" className={`marg-center marg-top-10 submit`} type="submit">Submit</button>
                    </form>
                  </div>
                  <div className="col-sm-2"></div>
                </div>
              </div>
            )
          }
        </>
      )}
    onClose={onClose}
    />
  );
}
