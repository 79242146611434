import React, { useState, useRef } from 'react'
import styles from './index.module.css'
import { useClickAway } from 'react-use';
import parentstyles from '../../index.module.css'
import { deleteProcedure, updateProcedure } from '../../../../api/profiles';
import { successToast } from '../../../ui_kit/utilities/success_toast';
import { createActivity } from '../../../../api/activities';

export default function ProcedureRow({
  user,
  procedure,
  i,
  setProcedures,
  procedures,
  profileIsCurrentUser
}) {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setShowOptions(false)
  });

  const removeProcedure = () => {
    deleteProcedure(user.profile_id, procedure.id, (data) => {
      setProcedures(procedures.filter(procedure => procedure.id !== data.success.id));
      setShowOptions(false)
      successToast('Procedure successfully removed.');
      createActivity("Activity::EditProfile", { fields: 'remove_procedure' });
      window.location.reload();
    });
  }

  const editVisibility = () => {
    updateProcedure(user.profile_id, procedure.id, {private: !procedure.private}, (data) => {
      setProcedures(data);
      // successToast('Procedure visibility successfully edited.')
    });
  }

  const renderSurgeryOrProcedure = (cond) => {
    if (cond.surgery && cond.surgery !== "I'm not sure") {
      return cond.surgery;
    }

    return cond.procedure;
  }

  return (
    <div data-pendo-tag="profile page - health profile - procedure row" className={i !== 0 ? 'marg-top-15' : null} style={{ display: 'flex' }}>
      <p className={`${parentstyles.bio}`}>{renderSurgeryOrProcedure(procedure)}</p>
      {profileIsCurrentUser &&
        <div data-pendo-tag="profile page - health profile - edit procedure row" style={{ marginLeft: 'auto', cursor: 'pointer', position: 'relative', display:'flex', alignItems: 'center' }}>
          <img onClick={() => editVisibility(procedure.id)} src={procedure.private ? "https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye-off.svg" : "https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye.svg"}/>
          <div style={{position: 'relative'}}>
            <img style={{marginLeft:'10px'}} className={`${styles.options_icon} `} onClick={() => setShowOptions(!showOptions)} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+3/Vector.svg" />
            {showOptions &&
              <div ref={ref} className={`${styles.share_list}`}>
                <div className="container">
                  <div className={`${styles.icon_row} row`}>
                    <div onClick={() => removeProcedure(procedure.id)} className={`${styles.icon_col} col`}>
                      <img className={`${styles.image}`} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/trash.svg" />
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}
