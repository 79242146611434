import { getProfileStatuses } from '../api/profile_statuses';

export default class ProfileStatus {
  constructor() {
    this.profiles = [];
    this.pollStatus();
  }

  addProfile = (profile) => {
    if (this.profiles.filter((p) => p.type === profile.type && p.id === profile.id).length === 0) {
      this.profiles.push(profile);
    }
  }

  pollStatus = () => {
    setTimeout(() => {
      if (this.profiles && this.profiles.length > 0) {
        getProfileStatuses(this.profiles.map(p => {
          return { id: p.id, type: p.type };
        }), (profile_statuses) => {
          profile_statuses.forEach(ps => this.updateProfile(ps));
        }, (errors) => {
          console.log("errors");
          console.log(errors);
        })
      }
      this.pollStatus();
    }, 60000);
  }

  updateProfile = (profile) => {
    if (profile.last_active_display === 'now') {
      document.querySelectorAll(`[data-avatar-profile-id='${profile.id}'][data-avatar-profile-type='${profile.type}']`).forEach(e => e.classList.add('active'));
      document.querySelectorAll(`[data-last-active-profile-id='${profile.id}'][data-last-active-profile-type='${profile.type}']`).forEach((e) => {
        e.innerText = 'Last active now';
      });
    }
    else {
      document.querySelectorAll(`[data-avatar-profile-id='${profile.id}'][data-avatar-profile-type='${profile.type}']`).forEach((e) => {
        e.classList.remove('active');
      });
      document.querySelectorAll(`[data-last-active-profile-id='${profile.id}'][data-last-active-profile-type='${profile.type}']`).forEach((e) => {
        e.innerText = `Last active ${profile.last_active_display}`;
      });
    }
  }
}
