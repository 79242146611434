import React, { useState, useEffect, useContext } from 'react'
import { useHistory, Link } from "react-router-dom";
import NameEmailPhoneZip from './../name_email_phone_zip'
import Age from '../age'
import PreexistingConditions from '../preexisting_conditions'
import ThinkingAboutSurgery from '../thinking_about_surgery'
import ConfirmationForm from '../confirmation_form'
import PatientGender from '../patient_partner/patient_gender'
import ConversationTopics from '../conversation_topics'
import DirectConnection from '../direct_connection';
import { contactSync, findPreviousContact } from '../../../api/contacts'
import { createConversation } from '../../../api/conversations'
import { getPatientPartners } from '../../../api/patient_partners'
import ModalWrapper from '../../modal';
import PatientPartnerLoadingScreen from '.././patient_partner_loading_screen';
import ErrorMessage from '../../ui_kit/utilities/error_message/index';
import LoadingScreen from '../../loading_screen';
import DocPatientPartners from '../../doc-patient-partners'
import { autofocusAll } from '../../../helpers'
import '../questionnaire.css'
import './patient_partner_questionnaire.css'
import HeightAndWeight from '../height_and_weight';
import PreviousSurgeries from '../joint_replacement/previous_surgeries';
import ActivityLevel from '../activity_level';
import AgreeToCallModal from '../../agree_to_call_modal';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import HealthcareJourney from './healthcare_journey';
import PpPreventedFromSurgery from './pp_prevented_from_surgery';
import WhatSurgery from './what_surgery';
import WhatProcedure from './what_procedure';
import DateTime from '../weight_loss_surgeon_match/date_time';
import SignUp from '../../sign_up';
import PrimaryCaregiver from '../../questionnaire/cosmetic/primary_caregiver';
import TalkTopics from '../../questionnaire/cosmetic/talk_topics';
import HowAreYouFeeling from '../../questionnaire/cosmetic/how_are_you_feeling';
import MentorCard from "../../ui_kit/utilities/mentor_card";
import Context from '../../../utils/context';

// const PATIENT_PARTNER_VIEW = {
//   contactInfo: 'contact-info',
//   selectSurgeries: 'select-surgeries',
//   answerQuestions: 'answer-questions',
//   loadingPatientPartners: 'loading-patient-partners',
//   patientPartners: 'patient-partners',
// };
//
// const PATIENT_PARTNER_QUESTIONNAIRE_SLIDE_STATE = {
//   none: 'none',
//   left: 'left',
//   right: 'right',
// };

export default function PatientPartnerQuestionnaire({
  globalAnswers = {},
  setGlobalAnswers,
  progress,
  setProgress,
  contactId = null,
  setContactIdAction,
  predefinedSurgeries,
  predefinedProcedures,
  patientPartners,
  setPatientPartners,
  directConnection,
  gtm,
  origin,
  doNotShowContactInfo = false,
  skipPassword = false,
  useFacebookConversionApi = false,
  includeBackButton = false,
}) {
  const currentGtm = `${gtm} Patient Partner Questionnaire`;
  const [slideState, setSlideState] = useState('none');
  const [previousContact, setPreviousContact] = useState(null);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [currentPatientPartner, setCurrentPatientPartner] = useState();
  const [showAgreeToCallModal, setShowAgreeToCallModal] = useState(false);
  let history = useHistory();
  const context = useContext(Context);
  const facebookConversionApi = context.facebookConversionApi;
  let para = 'We’ll find the most relatable PatientPartner™ based on your current health goals.';
  let head = 'Find a relatable recovered patient'
  // START figure out what questions to show the user
  let applicableQuestions = [
    { component: PatientGender },
    { component: Age },
  ];
  if (globalAnswers.selectedProcedures && globalAnswers.selectedProcedures.includes('Weight Loss')) {
    applicableQuestions.push({ component: HeightAndWeight });
    applicableQuestions.push({ component: PreexistingConditions });
    applicableQuestions.push({ component: HealthcareJourney });
    applicableQuestions.push({ component: ThinkingAboutSurgery });
    applicableQuestions.push({ component: PpPreventedFromSurgery });
  }
  if (globalAnswers.selectedSurgeries && (globalAnswers.selectedSurgeries.includes("Tummy Tuck") || globalAnswers.selectedSurgeries.includes("Liposuction"))) {
    head = 'Talk to a mentor and receive honest answers'
    para = "Let's find the mentor similar to you! Answer a few simple questions and start messaging."
    applicableQuestions.push({ component: ActivityLevel});
    applicableQuestions.push({ component: PrimaryCaregiver });
    applicableQuestions.push({ component: HealthcareJourney, props: {allowOther: false} });
    applicableQuestions.push({ component: HowAreYouFeeling });
    applicableQuestions.push({ component: TalkTopics });
  }
  if (globalAnswers.selectedProcedures && globalAnswers.selectedProcedures.includes('Joint Replacement')) {
    applicableQuestions.push({ component: PreviousSurgeries });
    applicableQuestions.push({ component: ActivityLevel });
    applicableQuestions.push({ component: ThinkingAboutSurgery });
    applicableQuestions.push({ component: PpPreventedFromSurgery });
  }
  if (predefinedProcedures && predefinedProcedures.includes('Women\'s Health')) {
    para = 'We’ll find a recovered patient mentor based on your location, age, and gender.'
  }
  if (predefinedSurgeries && (predefinedSurgeries.includes("Tummy Tuck") || predefinedSurgeries.includes("Liposuction"))){
    para = 'Let’s find the mentor similar to you! Answer a few simple questions and start messaging.';
    head = 'Talk to a mentor and receive honest answers';
  }
  let CurrentQuestion = null;
  let currentPara = null;
  let currentProps = null;
  if (progress.view === 'answer-questions') {
    CurrentQuestion = applicableQuestions[progress.questionNumber].component;
    currentPara = applicableQuestions[progress.questionNumber].para
    currentProps = applicableQuestions[progress.questionNumber].props
  }
  // END figure out what questions to show the user

  let procedures = []
  for (const [key, value] of Object.entries(gon.procedure_surgery_mappings)) {
    procedures.push({
      procedure: key,
      surgeries: value,
    });
  };

  let availableProcedures = Object.keys(gon.procedure_surgery_mappings);

  var availableSurgeries = []
  if (globalAnswers.selectedProcedures) {
    procedures.filter((procedureCategory) => {
      globalAnswers.selectedProcedures.filter((procedure) => {
        if (procedure.toLowerCase() === procedureCategory.procedure.toLowerCase()){
          availableSurgeries = procedureCategory.surgeries;
        }
      });
    });
  }

  const showSurgeriesAndProcedures = predefinedSurgeries === undefined;

  const startFadeOut = () => {
    setSlideState('left');
  }

  const startFadeIn = (nextProgress) => {
    setSlideState('right');
    setTimeout(() => {
      setSlideState('none');
      setProgress(nextProgress);
      autofocusAll();
      scrollToQuestionnaire();
    }, 100);
  }

  const contactSyncWrapper = (answer, finishedQuestionnaire, patientPartner, bookedMeeting, nextProgress, callback) => {
    let finalAnswers = answer;
    if (predefinedSurgeries && predefinedProcedures) {
      finalAnswers = {
        ...finalAnswers,
        selectedSurgeries: predefinedSurgeries,
        selectedProcedures: predefinedProcedures,
      };
    }

    contactSync(
      finalAnswers.name,
      finalAnswers.email,
      finalAnswers,
      finishedQuestionnaire,
      bookedMeeting || false,
      origin,
      patientPartner ? patientPartner.name : null,
      finalAnswers.selectedProcedures && finalAnswers.selectedProcedures[0],
      contactId,
      nextProgress,
      (data) => {
        setContactIdAction(data.contact.uuid);
        if (callback) {
          callback();
        }
      }
    );
  };

  const setGlobalAnswersWrapper = (answers) => {
    let finalAnswers = answers;
    if (predefinedSurgeries && predefinedProcedures) {
      finalAnswers = {
        ...finalAnswers,
        selectedSurgeries: predefinedSurgeries,
        selectedProcedures: predefinedProcedures,
      };
    }

    setGlobalAnswers(finalAnswers);
  };

  const backButtonFromResults = () => {
    startFadeOut();
    if (directConnection) {
      startFadeIn({
        view: 'answer-questions',
        questionNumber: applicableQuestions.length - 1,
      });
    }
    else {
      startFadeIn({
        view: 'conversation-topics',
        questionNumber: applicableQuestions.length - 1,
      });
    }
  }

  const nameAndEmailOnSubmit = (newAnswer) => {
    if (useFacebookConversionApi) {
      facebookConversionApi.leadEvent({
        email: newAnswer.email
      });
    }
    setGlobalAnswersWrapper(newAnswer);
    startFadeOut();
    findPreviousContact(newAnswer.email, (previousContact) => {
      if (previousContact && previousContact.patient_partner_questionnaire_progress) {
        setPreviousContact(previousContact);
        setShowRestartModal(true);
      } else {
        if (showSurgeriesAndProcedures) {
          contactSyncWrapper(newAnswer, false, null, false,{view: 'select-procedures',questionNumber: null}, null);
          startFadeIn({
            view: 'select-procedures',
            questionNumber: null,
          });
        } else {
          setGlobalAnswersWrapper(newAnswer);
          contactSyncWrapper(newAnswer, false, null, false,{view: 'answer-questions',questionNumber: 0}, null)
          startFadeIn({
            view: 'answer-questions',
            questionNumber: 0,
          });
        }
      }
    })
  }

  const loadPatientPartners = (currentContactId) => {
    getPatientPartners(null, globalAnswers, currentContactId || contactId, null, null, (data) => {
      if (data && data.length > 0) {
        setPatientPartners(data);
        setTimeout(() => {
          startFadeIn({
            ...progress,
            view: 'patient-partners',
          });
        }, 2000);
      }
      else {
        startFadeIn({
          ...progress,
          view: 'no-patient-partners',
        });
      }
    });
  };

  const onAnswerQuestionsSubmit = (newAnswer) => {
    // if it is the last step before loading patient partners
    const finishedLastQuestion = progress.questionNumber === (applicableQuestions.length - 1);
    if (directConnection) {
      if (finishedLastQuestion) {
        const newProgress = {
          ...progress,
          view: 'loading-patient-partners',
        };
        setGlobalAnswersWrapper(newAnswer);
        contactSyncWrapper(newAnswer, true, null, false, newProgress, () => {
          startFadeIn(newProgress);
          loadPatientPartners();
        });
      }
      else {
        const newProgress = {
          view: 'answer-questions',
          questionNumber: progress.questionNumber + 1,
        };
        setGlobalAnswersWrapper(newAnswer);
        startFadeOut();
        contactSyncWrapper(newAnswer, false, null, false, newProgress, null);
        startFadeIn(newProgress);
      }
    }
    else {
      const newProgress = {
        view: finishedLastQuestion ? 'conversation-topics' : 'answer-questions',
        questionNumber: progress.questionNumber + (finishedLastQuestion ? 0 : 1),
      };

      setGlobalAnswersWrapper(newAnswer);
      startFadeOut();
      contactSyncWrapper(newAnswer, false, null, false, newProgress, null);
      startFadeIn(newProgress);
    }
  }

  const onAnswerQuestionsBack = () => {
    setSlideState('right');
    setTimeout(() => {
      if (progress.questionNumber === 0) {
        if (showSurgeriesAndProcedures) {
          startFadeIn({
            view: 'select-surgeries',
            questionNumber: null,
          });
        }
        else {
          setProgress({
            view: 'contact-info',
            questionNumber: null,
          });
        }
      }
      else {
        setProgress({
          ...progress,
          questionNumber: progress.questionNumber - 1,
        });
      }
      setSlideState('none');
    }, 200);
  };

  const scrollToQuestionnaire = (useDelay) => {
    const scrollFunc = () => {
      const element = document.getElementById('questionnaire-scroll-to');
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset - 50;
        window.scrollTo({
          top: y,
          behavior: 'smooth'
        });
      }
    }

    if (useDelay) {
      setTimeout(scrollFunc, 1000);
    }
    else {
      scrollFunc();
    }
  }

  const onContinue = () => {
    setGlobalAnswersWrapper(previousContact.global_answers);
    setContactIdAction(previousContact.uuid);
    startFadeIn(previousContact.patient_partner_questionnaire_progress);
    if (previousContact.patient_partner_questionnaire_progress.view === 'loading-patient-partners') {
      scrollToQuestionnaire(true);
      loadPatientPartners(previousContact.uuid);
    }
    setShowRestartModal(false);
  }

  const restart = () => {
    setShowRestartModal(false);
    if (showSurgeriesAndProcedures) {
      startFadeIn({
        view: 'select-procedures',
        questionNumber: null,
      });
    } else {
      startFadeIn({
        view: 'answer-questions',
        questionNumber: 0,
      });
    }
  };

  const dateTimeSubmit = (partner, answers) => {
    const newAnswers = {
      ...globalAnswers,
      ...answers
    };
    setGlobalAnswersWrapper(newAnswers);
    setCurrentPatientPartner(partner);
    startFadeOut();
    contactSyncWrapper(newAnswers, false, partner, false, {...progress,view: 'confirmation'}, null);
    startFadeIn({
      ...progress,
      view: 'confirmation',
    });
  };

  const seeAllPatientPartners = () => {
    history.push("/partners");
  }

  useEffect(() => {
    if (progress.view === 'loading-patient-partners') {
      loadPatientPartners();
    }
  }, [])

  // Listens to know if the device is a mobile device START
  const [isMobile, setDesktop] = useState(window.innerWidth < 768);

  const updateMedia = () => {
     setDesktop(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  // Listens to know if the device is a mobile device END

  return (
    <div id={`questionnaire-scroll-to`} className={`patient-partner-questionnaire container questionnaire-wrapper text-left ${progress.view === 'loading-patient-partners' ? 'fixedHeight' : ''}`}>
      {progress.view !== 'patient-partners' &&
      <div className="row h-100 questionnaire-content">
        <div className="col-md-2"></div>
        <div className={`col-md-8 fadeOp ${slideState !== 'none' ? 'fadeOut' : ''} ${slideState === 'left' ? 'slideLeft' : ''} ${slideState === 'right' ? 'slideRight' : ''}`}>
          {
            slideState === 'left' && <LoadingScreen />
          }
          {progress.view === 'contact-info' &&
            <NameEmailPhoneZip
              answers={globalAnswers}
              onSubmit={nameAndEmailOnSubmit}
              includeBackButton={includeBackButton}
              gtm={currentGtm}
              head={head}
              para={para}
            />
          }
          {
            progress.view === 'select-procedures' &&
            <WhatProcedure
              head={'What kind of procedure are you considering?'}
              para={'We’ll match you with somone who has had the same procedure.'}
              selected={globalAnswers.selectedProcedures}
              choices={[...availableProcedures]}
              label = {'Type procedure here...'}
              gtm={currentGtm}
              onSubmit={(values) => {
                const newAnswers = {
                  ...globalAnswers,
                  selectedProcedures: values,
                };
                // remove surgery if the procedure has changed
                if (values[0] !== (globalAnswers && globalAnswers.selectedProcedures && globalAnswers.selectedProcedures[0])) {
                  newAnswers.selectedSurgeries = [];
                }
                setGlobalAnswersWrapper(newAnswers);
                startFadeOut();
                if (arrayIntersect(values.map(v => v.toLowerCase()) , availableProcedures.map(v => v.toLowerCase())).length > 0) {
                  contactSyncWrapper(newAnswers, false, null, false, {view: 'select-surgeries',questionNumber: null}, null);
                  startFadeIn({
                    view: 'select-surgeries',
                    questionNumber: null,
                  });
                }
                else {
                  contactSyncWrapper(newAnswers, false, null, false, {view: 'no-procedure-results',questionNumber: null}, null);
                  startFadeIn({
                    view: 'no-procedure-results',
                    questionNumber: null,
                  });
                }
              }}
              onBack={doNotShowContactInfo ? null : () => {
                startFadeIn({
                  ...progress,
                  view: 'contact-info',
                });
              }}
            />
          }
          {
            progress.view === 'select-surgeries' &&
            <WhatSurgery
              head = {'What surgery are you considering?'}
              selected={globalAnswers.selectedSurgeries}
              choices={[...availableSurgeries]}
              noMatchTxt={"I'm not sure"}
              label = {'Type surgery here...'}
              gtm={currentGtm}
              onSubmit={(values) => {
                const newAnswers = {
                  ...globalAnswers,
                  selectedSurgeries: values,
                };
                setGlobalAnswersWrapper(newAnswers);
                if (arrayIntersect(values.map(v => v.toLowerCase()), availableSurgeries.map(v => v.toLowerCase())).length > 0 || values[0].toLowerCase() === "i'm not sure") {
                  contactSyncWrapper(newAnswers, false, null, false, {view: 'answer-questions',questionNumber: 0}, null);
                  startFadeIn({
                    view: 'answer-questions',
                    questionNumber: 0
                  });
                }
                else {
                  contactSyncWrapper(newAnswers, false, null, false, {view: 'no-surgery-results',questionNumber: null}, null);
                  startFadeIn({
                    view: 'no-surgery-results',
                    questionNumber: null,
                  });
                }
              }}
              onBack={() => {
                startFadeIn({
                  ...progress,
                  view: 'select-procedures',
                });
              }}
            />
          }
          {
            progress.view === 'answer-questions' &&
              <CurrentQuestion
                answers={globalAnswers}
                onSubmit={onAnswerQuestionsSubmit}
                onBack={onAnswerQuestionsBack}
                para={currentPara}
                gtm={currentGtm}
                {...currentProps}
              />
          }
          {
            progress.view === 'conversation-topics' &&
              <ConversationTopics
                answers={globalAnswers}
                onBack={() => {
                  startFadeIn({
                    ...progress,
                    view: 'answer-questions',
                  })}
                }
                onSubmit={(newAnswers) => {
                  setGlobalAnswersWrapper(newAnswers);
                  contactSyncWrapper(newAnswers, true, null, false, {...progress,view: 'loading-patient-partners'}, () => {
                    startFadeIn({
                      ...progress,
                      view: 'loading-patient-partners',
                    });
                    loadPatientPartners();
                  });
                }}
                gtm={currentGtm}
              />
          }
          {
            progress.view === 'loading-patient-partners' &&
              <PatientPartnerLoadingScreen />
          }
          {
            progress.view === 'confirmation' &&
            <ConfirmationForm
              head={'Confirm your contact information'}
              para={'We’ll send you a text to confirm and coordinate your PatientPartner call time and day.'}
              surgeons={patientPartners[0]}
              answers={globalAnswers}
              onSubmit={(newAnswers) => {
                setGlobalAnswersWrapper(newAnswers);
                setShowAgreeToCallModal(true);
              }}
              onBack={() => {
                startFadeIn({
                  ...progress,
                  view: 'patient-partners',
                });
              }}
              datePlaceholder={'Preferred call date'}
              timePlaceholder={'Preferred call time'}
              gtm={currentGtm}
              cta={'Submit request'}
            />
          }
          {
            progress.view === 'success' &&
              (
                <>
                  <div className="row">
                    <div className="col-sm-2">
                    </div>
                    <div className="col-sm-8">
                      <h2 className="marg-bottom-20">
                        Your request has been received!
                      </h2>
                      <h5 className="marg-bottom-20">
                        Here’s what’s next:
                      </h5>
                      <div className="marg-bottom-30 row">
                        <div className="col-2 my-auto">
                          <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.54 11.9256L16.5401 11.9256L16.5365 11.915C15.7197 9.50291 15.2778 6.91746 15.2778 4.22222C15.2778 2.56844 13.8994 1.25 12.25 1.25H4.27778C2.62835 1.25 1.25 2.56844 1.25 4.22222C1.25 25.5205 18.9372 42.75 40.7222 42.75C42.3717 42.75 43.75 41.4316 43.75 39.7778V32.0222C43.75 30.3684 42.3717 29.05 40.7222 29.05C37.9772 29.05 35.2999 28.6182 32.8241 27.8206C31.7639 27.4678 30.5603 27.7329 29.7435 28.5298L25.1174 33.0431C19.191 29.9645 14.3095 25.2235 11.172 19.4551L15.7804 14.9591C16.6283 14.1318 16.8751 12.9658 16.54 11.9256Z" stroke="#314D69" strokeWidth="1.5"/></svg>
                        </div>
                        <div className="col-10 my-auto">
                          <p>
                            We’ll coordinate with your  PatientPartner and confirm their availability and contact you via text.
                          </p>
                        </div>
                      </div>
                      <h6 style={{color: '#59B6BE'}} className="marg-bottom-10 bold">
                        Looking for a surgeon?
                      </h6>
                      <p className="marg-bottom-20">
                        Answer a few questions and we’ll help you find a surgeon in your area that accepts your insurance!
                      </p>
                      <Link to="/get-started/surgeon-match"><button data-gtm-click-label={`${currentGtm} Find a Surgeon`} className="liner">Find a surgeon</button></Link>
                    </div>
                    <div className="col-sm-2">
                    </div>
                  </div>
                  {
                    gon.facebook_page_id && gon.facebook_app_id && (
                      <MessengerCustomerChat
                        pageId={gon.facebook_page_id}
                        appId={gon.facebook_app_id}
                        minimized={false}
                        greetingDialogDisplay={'show'}
                        shouldShowDialog={true}
                        loggedInGreeting={'Have questions? Chat with our team!'}
                        loggedOutGreeting={'Have questions? Chat with our team!'}
                      />
                    )
                  }
                </>
              )
          }
          {
            progress.view === 'direct-connection-success' &&
              (
                <>
                  <div className="row">
                    <div className="col-sm-2">
                    </div>
                    <div className="col-sm-8">
                      <h2 className="marg-bottom-20">
                        Your request has been received!
                      </h2>
                      <h5 className="marg-bottom-20">
                        Here’s what’s next:
                      </h5>
                      <div className="marg-bottom-30 row">
                        <div className="col-3 col-md-2 my-auto">
                          <img style={{width: '100%'}} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/whatsnext-phone.png"/>
                        </div>
                        <div className="col-9 col-md-10 my-auto">
                          <p>
                            Your PatientPartner will be in touch within the next 48 hours
                          </p>
                        </div>
                      </div>
                      <h6 style={{color: '#59B6BE'}} className="marg-bottom-10 bold">
                        Looking for a surgeon?
                      </h6>
                      <p className="marg-bottom-20">
                        Answer a few questions and we’ll help you find a surgeon in your area that accepts your insurance!
                      </p>
                      <Link to="/get-started/surgeon-match"><button data-gtm-click-label={`${currentGtm} Find a Surgeon`} className="liner">Find a surgeon</button></Link>
                    </div>
                    <div className="col-sm-2">
                    </div>
                  </div>
                </>
              )
          }
          {
            progress.view === 'no-procedure-results' &&
              <div className="container text-left">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <div className='container '>
                      <div className='row'>
                        <div className="col">
                          <p className="text-link all-surgeons-button text-left" onClick={() => setProgress({ ...progress, view: 'select-procedures' })}><a data-gtm-click-label={`${currentGtm } No Procedure Results Back`}>Back to questionnaire</a></p>
                          <h2 className="marg-bottom-20">
                            We're adding PatientPartners to our community every day!
                          </h2>
                          <p className="bold marg-bottom-20">
                            It looks like we don't currently have a PatientPartner who can share their experience with {globalAnswers.selectedProcedures[0]}.
                          </p>
                          <h6 style={{color: '#59B6BE'}} className="marg-bottom-10 bold">
                            What's next?
                          </h6>
                          <p className="marg-bottom-20">
                            We will send you an email when a PatientPartner joins the community!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
          {
            progress.view === 'no-surgery-results' &&
              <div className="container text-left">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <div className='container '>
                      <div className='row'>
                        <div className="col">
                          <p className="text-link all-surgeons-button text-left" onClick={() => setProgress({ ...progress, view: 'select-surgeries' })}><a data-gtm-click-label={`${currentGtm} No Procedure Results Back`}>Back to questionnaire</a></p>
                          <h2 className="marg-bottom-20">
                            We're adding PatientPartners to our community every day!
                          </h2>
                          <p className="bold marg-bottom-20">
                            It looks like we don't currently have a PatientPartner who can share their experience with {globalAnswers.selectedSurgeries[0].toLowerCase() === "i'm not sure" ? globalAnswers.selectedProcedures[0] : globalAnswers.selectedSurgeries[0]}.
                          </p>
                          <h6 style={{color: '#59B6BE'}} className="marg-bottom-10 bold">
                            What's next?
                          </h6>
                          <p className="marg-bottom-20">
                            We will send you an email when a PatientPartner joins the community!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
          {
            progress.view === 'no-patient-partners' &&
              <div className="container text-left">
                <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <div className='container '>
                      <div className='row'>
                        <div className="col">
                          <p className="text-link all-surgeons-button text-left" onClick={() => setProgress({ ...progress, view: 'answer-questions' })}><a data-gtm-click-label={`${currentGtm} No Surgery Results Back`}>Back to questionnaire</a></p>
                          <h2 className="marg-bottom-20">
                            We're adding PatientPartners to our community every day!
                          </h2>
                          <p className="bold marg-bottom-20">
                            It looks like we don't currently have a PatientPartner who can share their experience with {globalAnswers.selectedSurgeries[0].toLowerCase() === "i'm not sure" ? globalAnswers.selectedProcedures[0] : globalAnswers.selectedSurgeries[0]}.
                          </p>
                          <h6 style={{color: '#59B6BE'}} className="marg-bottom-10 bold">
                            What's next?
                          </h6>
                          <p className="marg-bottom-20">
                            We will send you an email when a PatientPartner joins the community!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          }
        </div>
        <div className="col-md-2"></div>
      </div>
      }
      {progress.view === 'patient-partners' && (
        <div className="container text-left">
          <p className="text-link all-surgeons-button text-left marg-bottom-0 marg-top-20" onClick={() => backButtonFromResults()}><a data-gtm-click-label={`${currentGtm} Results Back to Questionnaire`}>Back to questionnaire</a></p>
          <div className="row">
            <div className="col-lg-7 carousel-questionnaire">
              <div className="questionnaire-results-header">
                <h3 className="text-left marg-top-20">
                  You have been matched with {patientPartners[0].name}.
                </h3>
                <hr/>
                <p className="text-left">
                  {globalAnswers.name}, based on your survey responses, we recommend you talk to {patientPartners[0].name} about their healthcare experience.
                </p>
                <h6 style={{color: '#59B6BE'}}  className="text-left marg-top-20 marg-bottom-10 bold">
                  What's Next
                </h6>
                <p className="marg-bottom-20">
                {
                  patientPartners[0].sign_up_beta_test ?
                    !context.auth.isLoggedIn() ? `Create your free account and get support in a private and safe space.` : `Send ${patientPartners[0].name} a message and learn about their experience!`
                    : 'Request a connection by selecting your communication preference and introducing yourself!'
                }
                </p>
                {isMobile &&
                  <div className="marg-bottom-20">
                  {
                    directConnection && patientPartners[0].sign_up_beta_test && !context.auth.isLoggedIn() &&
                    <div className="embedded-signup-wrapper">
                      <SignUp
                        defaultName={globalAnswers.name}
                        defaultEmail={globalAnswers.email}
                        defaultPhone={globalAnswers.phone}
                        onSuccess={(user) => {
                          createConversation({ patient_partner_id: patientPartners[0].id }, (conversation) => {
                            if (conversation) {
                              history.push(`/member/conversations/${conversation.uuid}?view=conversation`);
                            }
                            else {
                              history.push('/member/conversations?view=conversation');
                            }
                          });
                        }}
                        gtm={currentGtm}
                        showAcceptTerms={false}
                        params={{ origin: origin, patient_partner_scheduled_contact_id: contactId, patient_partner_id: patientPartners[0].id, zipcode: globalAnswers.zipcode }}
                        headline={'Join the community'}
                        para={'Your mentor is here to help. Create your account to start messaging them for free.'}
                        skipPassword={skipPassword}
                      />
                    </div>
                  }
                  {
                    directConnection && !patientPartners[0].sign_up_beta_test &&
                      <DirectConnection
                          answers={globalAnswers}
                          title={`Request connection with ${patientPartners[0].name}`}
                          subtitle={`Your PatientPartner will respond within 2 days of your request.`}
                          onSubmit={(newAnswers) => {
                            setGlobalAnswersWrapper(newAnswers);
                            startFadeOut();
                            const newProgress = {
                              ...progress,
                              view: 'direct-connection-success'
                            };
                            contactSyncWrapper(newAnswers, true, patientPartners[0], true, newProgress, null);
                            startFadeIn(newProgress);
                          }}
                          gtm={currentGtm}
                      />
                  }
                  {
                    !directConnection &&
                      <DateTime
                        onSubmit={(answers) => {dateTimeSubmit(patientPartners[0], answers)}}
                        answers={globalAnswers}
                        surgeons={patientPartners[0]}
                        title={`Request a phone call with ${patientPartners[0].name}`}
                        subtitle={'We’ll coordinate with your PatientPartner based on your preference! Select your preferred day and time.'}
                        datePlaceholder={'Preferred call date'}
                        timePlaceholder={'Preferred call time'}
                        gtm={currentGtm}
                      />
                  }
                  </div>
                }
                {!isMobile &&
                  <MentorCard mentor={patientPartners[0]}/>
                }
              </div>
            </div>
            <div className="col-lg-5">
              {!isMobile &&
                <div className="marg-bottom-20">
                {
                  directConnection && patientPartners[0].sign_up_beta_test && !context.auth.isLoggedIn() &&
                  <div className="embedded-signup-wrapper">
                    <SignUp
                      defaultName={globalAnswers.name}
                      defaultEmail={globalAnswers.email}
                      defaultPhone={globalAnswers.phone}
                      onSuccess={(user) => {
                        createConversation({ patient_partner_id: patientPartners[0].id }, (conversation) => {
                          if (conversation) {
                            history.push(`/member/conversations/${conversation.uuid}?view=conversation`);
                          }
                          else {
                            history.push('/member/conversations?view=conversation');
                          }
                        })
                      }}
                      gtm={currentGtm}
                      showAcceptTerms={false}
                      params={{ origin: origin, patient_partner_scheduled_contact_id: contactId, patient_partner_id: patientPartners[0].id }}
                      headline={'Join the community'}
                      para={'Your mentor is here to help. Create your account to start messaging them for free.'}
                      skipPassword={skipPassword}
                    />
                  </div>
                }
                {
                  directConnection && !patientPartners[0].sign_up_beta_test &&
                    <DirectConnection
                        answers={globalAnswers}
                        title={`Request connection with ${patientPartners[0].name}`}
                        subtitle={`Your PatientPartner will respond within 2 days of your request.`}
                        onSubmit={(newAnswers) => {
                          setGlobalAnswersWrapper(newAnswers);
                          startFadeOut();
                          const newProgress = {
                            ...progress,
                            view: 'direct-connection-success'
                          };
                          contactSyncWrapper(newAnswers, true, patientPartners[0], true, newProgress, null);
                          startFadeIn(newProgress);
                        }}
                        gtm={currentGtm}
                    />
                  }
                  {
                    !directConnection &&
                      <DateTime
                        onSubmit={(answers) => {dateTimeSubmit(patientPartners[0], answers)}}
                        answers={globalAnswers}
                        surgeons={patientPartners[0]}
                        subtitle={'We’ll coordinate with your PatientPartner based on your preference! Select your preferred day and time.'}
                        title={`Request a phone call with ${patientPartners[0].name}`}
                        datePlaceholder={'Preferred call date'}
                        timePlaceholder={'Preferred call time'}
                        gtm={currentGtm}
                      />
                  }
                </div>
              }
              {isMobile &&
                <MentorCard mentor={patientPartners[0]}/>
              }
            </div>
            {origin && origin !== 'Realself Cosmetic Patient Mentor' &&
              <div className="container questionnaire-results-footer marg-bottom-30">
                <div className="row">
                  <div className="col">
                    <h5 className="text-left">
                      Want to see other PatientPartners?
                    </h5>
                    <hr/>
                    <p>
                      We chose your PatientPartner based on your survey answers, but there are more available you can talk to!
                    </p>
                    <button data-gtm-click-label={`${currentGtm} See all PatientPartners`} onClick={seeAllPatientPartners} className={`marg-top-20 marg-bottom-30 submit liner`} type="submit">See all PatientPartners™</button>
                  </div>
                  <div className="col">
                    <h5 className="marg-bottom-5 marg-top-5">
                      Need help finding a surgeon?
                    </h5>
                    <hr/>
                    <p className="marg-bottom-20">
                      Answer a couple questions and find a surgeon that accepts your insurance!
                    </p>
                    <Link to="/get-started/surgeon-match"><button data-gtm-click-label={`${currentGtm} Find a Surgeon`} className="liner marg-bottom-20">Find a surgeon</button></Link>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      )}
      {
        showRestartModal &&
          <ModalWrapper
            className="patient-partner-match-modal already-took-this"
            content={(
              <div>
                <h3 className="marg-bottom-20">It looks like you've already taken this questionnaire!</h3>
                <ErrorMessage
                  error={'Restarting this questionnaire might change your recommended patient partners'}
                />
                <div className="marg-top-20 row max-wid">
                  <div className="col-sm-6">
                    <button data-gtm-click-label={`${currentGtm} Prefill Questionnaire`} onClick={onContinue} className={`submit`} type="submit" >Continue where I left off</button>
                  </div>
                  <div className="col-sm-6">
                    <button data-gtm-click-label={`${currentGtm} Retake Questionnaire`} onClick={restart} className={`submit destructive`} type="submit" >Retake questionnaire</button>
                  </div>
                </div>
              </div>
            )}
            onClose={() => {
              setShowRestartModal(false)
            }}
          />
      }
      {
        showAgreeToCallModal &&
          <AgreeToCallModal
            onSubmit={() => {
              setShowAgreeToCallModal(false);
              contactSyncWrapper(globalAnswers, false, patientPartners[0], true,{...progress,view: 'success'}, null);
              startFadeIn({
                ...progress,
                view: 'success',
              });
            }}
            onClose={() => {
              setShowAgreeToCallModal(false);
            }}
            gtm={currentGtm}
          />
      }
    </div>
  );
}
