import React, { useState, useRef, useEffect } from "react";
import styles from "../../ui_kit/utilities/share_links/style.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReportModal from "../report_modal";
import ConfirmationModal from '../../ui_kit/utilities/confirmation_modal'
import { deletePost } from "../../../api/posts";
import { useClickAway } from "react-use";

export default function MoreLinks({
  post,
  removePost,
  onEdit,
  parentPendoLink = ''
}) {
  const [showShareLinks, setShowShareLinks] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const ref = useRef(null);

  const toggleShowShareLinks = () => {
    setShowShareLinks(!showShareLinks);
  };

  useClickAway(ref, () => {
    setShowShareLinks(false);
  });

  useEffect(() => {
    if (reportModal){
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('body').style.height = '100%'
      document.querySelector('html').style.overflow = 'hidden'
      document.querySelector('html').style.height = '100%'
    } else {
      document.querySelector('body').style.overflowY = 'scroll'
    }
  }, [reportModal]);

  if (!post.can_edit && !post.can_report && !post.can_delete) {
    return <></>;
  }

  return (<>
    <button
      ref={ref} style={ {  padding: '17px 10px', marginRight: '3px'  } }
      className={`${styles.share} ${showShareLinks ? styles.more_links_active : ''}`}
      onClick={toggleShowShareLinks}
      data-pendo-tag={parentPendoLink}
    >
      <img  src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+3/Vector.svg" />
      {showShareLinks && (
        <div className={`${styles.share_list}`}>
          <div className="container">
          {
            /*
            <div className={`${styles.icon_row} row`}>
              <div className={`${styles.icon_col} col`}>
                <img
                  className={`${styles.image}`}
                  src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+20/Vector.svg"
                />
                Save
              </div>
            </div>

            <div className={`${styles.icon_row} row`}>
              <div className={`${styles.icon_col} col`}>
                <img
                  className={`${styles.image}`}
                  src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+21/Vector.svg"
                />
                Hide
              </div>
            </div>
            */
          }
            {post.can_report && (
            <div
              data-pendo-tag="individual discussions post - report post"
              onClick={() => setReportModal(!reportModal)}
              className={`${styles.icon_row} row`}
            >
              <div className={`${styles.icon_col} col`}>
                <img
                  className={`${styles.image}`}
                  src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/report_flag.svg"
                />
                Report
              </div>
            </div>
              )}
            {post.can_edit && (
              <div
                onClick={onEdit}
                className={`${styles.icon_row} row`}
                data-pendo-tag="discussions post - edit post"
              >

                  <div className={`${styles.icon_col} col`}>
                    <img
                      className={`${styles.image}`}
                      src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/edit.svg"
                    />
                    Edit
                  </div>
                </div>
              )}
              {post.can_delete && (
                <div
                  onClick={() => setShowDeleteModal(true)}
                  className={`${styles.icon_row} row`}
                  data-pendo-tag="discussions post - delete post"
                >
                  <div className={`${styles.icon_col} col`}>
                    <img
                      className={`${styles.image}`}
                      src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/trash.svg"
                    />
                    Delete
                  </div>
                </div>
              )}
            </div>
          </div>
      )}
    </button>
    {reportModal && (
        <ReportModal setReportModal={setReportModal} post={post} />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          title={`Delete post`}
          para={`Are you sure you want to delete this post? The content and comments will be deleted and cannot be restored.`}
          confirmationText={`Delete`}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() => {
            deletePost(post.uuid, () => {
              removePost(post);
              setShowDeleteModal(false);
            });
          }}
        />
      )}
    </>
  );
}
