const initialState = gon.weight_loss_questionnaire_state || {
  answers: {
    // name: 'Zhichao Test',
    // email: 'zhichao+test98398hafa@rightdevice.com',
    // zipcode: '12345',
    // gender: 'male',
    // age: '12',
    // feet: '1',
  },
  questionnaireState: 1,
  contactId: null,
  patientPartners: []
};

const weightLoss = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ANSWERS':
      return {
        ...state,
        answers: action.item
      }
    case 'SET_QUESTIONNAIRE_STATE':
      return {
        ...state,
        questionnaireState: action.item,
      }
    case 'SET_CONTACT_ID':
      return {
        ...state,
        contactId: action.item,
      }
    case 'SET_PATIENT_PARTNERS':
      return {
        ...state,
        patientPartners: action.item,
      }
    default:
      return state
  }
}

export default weightLoss
