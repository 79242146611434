export const getNotifications = (successCallback) => {
  fetch(`/api/v1/notifications`, {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
      },
  })
  .then(response => response.json())
  .then(data => {
    successCallback(data);
  })
}

export const markEverythingAsRead = (id, type, params, successCallback) => {
  fetch(`/api/v1/notifications/mark_everything_as_read`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then(response => response.json())
  .then(data => {
    if (successCallback) {
      successCallback(data);
    }
  })
}

export const updateNotification = (id, params, successCallback) => {
  fetch(`/api/v1/notifications/${id}`, {
    method: 'PATCH',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params)
  })
  .then(response => response.json())
  .then(data => {
    if (successCallback) {
      successCallback(data);
    }
  })
}
