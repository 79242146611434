import React, { Component } from 'react'
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function MfUnderstanding({
  value,
  onSubmit,
  onBack
}) {
  return (
    <OptionsAndOther
      answers={{ mfUnderstanding: value}}
      keyForAnswer={'mfUnderstanding'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={[
        'I have no knowledge of MF',
        'I have a basic understanding of MF',
        'I consider myself knowledgeable about MF',
        'I feel like an expert in MF',
      ]}
      title={`How familiar are you with Myelofibrosis (MF)? Please select one of the following options:`}
      para={``}
      showOther={false}
      ctaTxt='Next'
      selectOne
    />
  )
}
