import { connect } from 'react-redux'
import {
  templateAction
} from '../../actions/partners'
import Partners from '../../components/partners'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Partners)
