import React from "react";
import { useHistory } from "react-router-dom";
import "./index.css";

export default function NotFoundPage() {
  let history = useHistory();

  const onClick = () => {
    history.push("/");
  };

  return (
    <div
      style={{ backgroundColor: "#F1FEFF" }}
      className={"not-found-page container-fluid"}
    >
      <div style={{transform: "translateY(20%)", minHeight:'650px'}} className="container marg-bottom-0">
        <div className="semibg"></div>
        <div className="row justify-content-center h-100">
          <div className="col">
            <h5 className="text-center sm-header">ERROR 404</h5>
          </div>
        </div>
        <div
          className="row justify-content-center text-center h-100 "
          style={{ marginBottom: "100px" }}
        >
          <div className="col">
            <h2 className="title">
              {" "}
              Oops! We can’t find the page you’re looking for.
            </h2>
            <button
              data-gtm-click-label="Not Found Page Go Home"
              onClick={onClick}
              className=""
            >
              Go back home
            </button>
          </div>
        </div>
        <div className="row justify-content-center text-center ">
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
}
