export const createSurgeonMatchFeedbackContact = (
  contactId,
  answers,
  completedFeedbackSurvey,
  successFunction,
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/surgeon_match_feedbacks',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        contact_id: contactId,
        answers,
        completed_feedback_survey: completedFeedbackSurvey,
      })
    }
  )
    .then(response => response.json())
    .then(data => {
      successFunction(data);
    }).catch(function (error){
      console.log(error)
    });
}
