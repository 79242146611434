const initialState = {};

const SurgeonMatchForm = (state = initialState, action) => {
  switch (action.type) {
    case 'TEMPLATE':
      return {
        ...state,
      }
    default:
      return state
  }
}

export default SurgeonMatchForm
