import React, { useState } from 'react';
import styles from './index.module.css';
import Autocomplete from "react-google-autocomplete";
import ErrorMessage from '../../../ui_kit/utilities/error_message'
import { isObjEmpty } from '../../../../helpers';

export default function LocationInput({
  value,
  setValue,
  gtm,
  style,
  light,
  placeholder,
  classNames,
  inputStyles = {},
  pendoTag = '',
  showSmallLabel,
  fullWidth = false,
  countryRestriction = 'us',
}) {
  const [currentValue, setCurrentValue] = useState((value && value.address) || '');
  const [choseValueFromSuggested, setChoseValueFromSuggested] = useState(false);
  const [showLocationLabel, setShowLocationLabel] = useState(false);

  const findAddressComponent = (components, type) => {
    let component = components.filter(c => c.types.includes(type))[0];

    if (component) {
      return component;
    }

    return null;
  }

  const processPlace = (place) => {
    if (isObjEmpty(place)) {
      return {};
    }

    let ret = {};
    ret['address'] = place.formatted_address;
    if (place.geometry && place.geometry.location && place.geometry.location.lat()) {
      ret['latitude'] = place.geometry.location.lat();
    }
    if (place.geometry && place.geometry.location && place.geometry.location.lng()) {
      ret['longitude'] = place.geometry.location.lng();
    }

    ret['google_place_id'] = place.place_id;
    const zipcodeComponent = findAddressComponent(place.address_components, 'postal_code');
    if (zipcodeComponent) {
      ret['zipcode'] = zipcodeComponent.long_name;
    }
    const stateComponent = findAddressComponent(place.address_components, "administrative_area_level_1");
    if (stateComponent) {
      ret['state'] = stateComponent.short_name;
    }
    const cityComponent = findAddressComponent(place.address_components, "locality");
    if (cityComponent) {
      ret['city'] = cityComponent.short_name;
    }

    return ret;
  }

  let error = '';
  if (currentValue !== '' && currentValue !== (value && value.address) && !choseValueFromSuggested) {
    error = 'Select a location from the list';
  }

  return (
    <>
      <div data-pendo-tag={pendoTag} style={style} className={`locator material-input ${light ? 'light' : ''} ${fullWidth && 'w-100'}`}>
        <Autocomplete
          data-gtm-click-label={`${gtm} location`}
          apiKey={gon.google_maps_key}
          onPlaceSelected={(place) => {
            setCurrentValue(place.formatted_address);
            setValue(processPlace(place));
            setChoseValueFromSuggested(true)
          }}
          onChange={(e) => {
            setCurrentValue(e.target.value);
            setChoseValueFromSuggested(false);
          }}
          value={currentValue}
          options={{
            types: ["(regions)"],
            componentRestrictions: { country: countryRestriction },
          }}
          name={`location`}
          style={{paddingLeft:'20px', ...inputStyles}}
          className={`${styles.autocomplete_input} ${classNames}`}
          placeholder={showLocationLabel ? null : placeholder}
          onFocus={()=> setShowLocationLabel(true)}
          onBlur={()=> setShowLocationLabel(false)}
        />
        {showSmallLabel &&
          <label className={`${styles.location_label} ${showLocationLabel || currentValue !== '' ? styles.in_final_position : ''}`}>Location</label>
        }
      </div>
      {error &&
        <ErrorMessage
          error={error}
        />
      }
    </>
  )
}
