import React from 'react'
import './press_room.css'
import TxtLeftImgRightWbg from '../hero_sections/txt_left_img_right_wbg'
import PatientPartnerColumn from '../patient_partner_column'
import SmallArticle from '../small_article'

export default function ({
  featuredArticles = gon.featured_articles.slice(0, 3),
  articles = gon.press_articles.slice(3, 100000).sort((a, b) => parseFloat(new Date(b.date).getTime()) - parseFloat(new Date(a.date).getTime()))
}) {

  return (
    <div className="press-room">
      <TxtLeftImgRightWbg
        headline="PatientPartner™ Press room"
        para="Learn more about PatientPartner™ and how we're improving the patient experience and bringing empath back to healthcare"
        bg="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/press.jpg"
        iconContent={<svg viewBox="0 0 448 448" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M336 383.86V40C335.982 33.6405 333.447 27.5467 328.95 23.0498C324.453 18.553 318.36 16.0185 312 16H40C33.6405 16.0185 27.5467 18.553 23.0498 23.0498C18.553 27.5467 16.0185 33.6405 16 40V392C16.0317 402.599 20.2561 412.755 27.7507 420.249C35.2453 427.744 45.4011 431.968 56 432H384" stroke="#59B6BE" strokeWidth="32" strokeLinejoin="round" /><path d="M384 432C371.27 432 359.061 426.943 350.059 417.941C341.057 408.939 336 396.73 336 384V96H408C414.365 96 420.47 98.5286 424.971 103.029C429.471 107.53 432 113.635 432 120V384C432 396.73 426.943 408.939 417.941 417.941C408.939 426.943 396.73 432 384 432Z" stroke="#59B6BE" strokeWidth="32" strokeLinejoin="round" /><path d="M208 96H272" stroke="#59B6BE" strokeWidth="32" strokeLinecap="round" strokeLinejoin="round" /><path d="M208 160H272" stroke="#59B6BE" strokeWidth="32" strokeLinecap="round" strokeLinejoin="round" /><path d="M80 224H272" stroke="#59B6BE" strokeWidth="32" strokeLinecap="round" strokeLinejoin="round" /><path d="M80 288H272" stroke="#59B6BE" strokeWidth="32" strokeLinecap="round" strokeLinejoin="round" /><path d="M80 352H272" stroke="#59B6BE" strokeWidth="32" strokeLinecap="round" strokeLinejoin="round" /><path d="M144 176H80C75.7565 176 71.6869 174.314 68.6863 171.314C65.6857 168.313 64 164.243 64 160V96C64 91.7565 65.6857 87.6869 68.6863 84.6863C71.6869 81.6857 75.7565 80 80 80H144C148.243 80 152.313 81.6857 155.314 84.6863C158.314 87.6869 160 91.7565 160 96V160C160 164.243 158.314 168.313 155.314 171.314C152.313 174.314 148.243 176 144 176Z" fill="#59B6BE" /></svg>}
        topOfIcon="85%"
        heightOfImage = "320px"
      />
      <div className="container marg-top-70 marg-bottom-50 marg-me-mob-press-room">
        <h2 className="marg-top-50 marg-bottom-50 de-pad-bottom">Featured articles</h2>
        <div className="row">
          {featuredArticles.map((article, index) =>
            <div className="col-lg-4 mobile-pad">
              <PatientPartnerColumn
                partner={article}
                actionContent={<a href={article.link} target="_blank"><button style={{ width: '83%' }}>Read more</button></a>}
                index={(index + 1)}
                showShowMore={false}
                article={true}
              />
            </div>
          )}
        </div>
        <div className="container small-art-cont">
          <h2 className="marg-top-80">Articles in the archive</h2>
          <h6 className="marg-bottom-30">What the media is saying about us</h6>
          <div className="row">
            {articles.map((article, index) =>
              <div className="col-lg-6">
                <SmallArticle
                  article={article}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
