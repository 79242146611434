import React, { useContext, useState } from 'react'
import styles from './index.module.css'
import Context from '../../../../utils/context';
import GhostButton from '../../../ui_kit/utilities/ghost_button';
import RequestButton from '../../../ui_kit/utilities/request_button';
import { successToast } from '../../../ui_kit/utilities/success_toast';
import VideoType2 from '../../../video_sections/video_type_2'
import { createActivity } from '../../../../api/activities';

export default function About({
  user
}) {
  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  const [editing, setEditing] = useState(false);
  const [newBio, setNewBio] = useState(user.current_profile.bio || '');
  const [profile, setProfile] = useState(user.current_profile);

  if (!user.current_profile) {
    return <></>
  }

  const updateBio = (afterAction) => {
    context.auth.updateProfile(
      { bio: newBio },
      (result) => {
        successToast("Your changes have been saved.");
        setProfile(result)
        setNewBio(result.bio)
        setEditing(false);
        createActivity('Activity::EditProfile', { fields: 'bio' });
        afterAction();
      }
    )
  }

  let profileIsCurrentUser = false
  if (context.auth.isLoggedIn() && currentUser.current_profile && profile && currentUser.current_profile.id === profile.id){
    profileIsCurrentUser = true
  }

  return (
    <>
      <div id="bio" style={{ display: 'flex' }}>
        <h2 className={styles.profile_section_headline}>About Me</h2>
        {currentUser && currentUser.current_profile && profile && <>
          {profileIsCurrentUser && !editing && (profile.bio !== '' || profile.bio === null) &&
            <GhostButton data-pendo-tag={profile.bio === null ? 'Edit Button - Create Bio' : 'Edit Button - Edit Bio'} onClick={() => setEditing(true)} style={{ marginLeft: 'auto' }}>
              <img alt="edit bio" style={{ marginRight: '8px', height: '16px', marginTop: '-4px' }} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/edit.svg" />
              <span>Edit</span>
            </GhostButton>
          }
          {profileIsCurrentUser && editing && <>
            <GhostButton style={{ marginLeft: 'auto', padding: '5px 23px', marginBottom: '10px' }} onClick={() => setEditing(false)}>
              <p>Cancel</p>
            </GhostButton>
            <RequestButton
              style={{ padding: '5px 23px', marginBottom: '10px' }}
              cta={'Save'}
              request={(afterAction) => updateBio(afterAction)}
              disabled={newBio === user.current_profile.bio || ((user.current_profile.bio === null || user.current_profile.bio === '') && newBio === '')}
            />
          </>}
        </>}
      </div>
      <div className={`content_container`}>
        {editing ?
          <textarea
            className={`${styles.bio} material-input marg-top-0`}
            style={{ width: '100%', maxWidth: '100%' }}
            value={newBio}
            onChange={(e) => setNewBio(e.target.value)}
          />
          :
          <div className={styles.bio}>{profile.bio !== null && profile.bio !== '' ?
            profile.bio
            :
            profileIsCurrentUser ?
              <p data-pendo-tag='+ Add Button - Create Bio' onClick={() => setEditing(true)} className={styles.add_bio}>
                + Add a few words about yourself
              </p>
            :
              'No bio yet'
          }</div>

        }
        <div style={{ marginTop: '10px' }}>
          {profile.video_url && profile.video_url.includes("youtube") &&
            <VideoType2
              videoLink={profile.video_url}
              height='300px'
              iconSize='70px'
              autoplay
            />
          }
          {
            profile.video_url && profile.video_url.includes("vimeo") &&
            <div style={{ paddingBottom: "56.25%", position: "relative" }}>
              <iframe
                width="100%"
                height="100%"
                src={profile.video_url}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%" }}>
              </iframe>
            </div>
          }
        </div>
      </div>
    </>)
}
