import React, { useState } from "react";
import LikeButton from '../ui_kit/utilities/like_button';
import ShareLinks from '../ui_kit/utilities/share_links';
import MoreLinks from "./more_links";
import styles from "./style.module.css";
import CreatePost from './create_post';

export default function ButtonRow({
  post,
  showComments,
  refreshPost,
  removePost,
}) {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <div className={`${styles.button_row_cont} container`}>
      <div className="row">
        <div className="col-2" style={{ display: "flex", padding: '0px' }} >
          <button data-pendo-tag="individual discussions post - see comments button" onClick={showComments} className={`${styles.comment_svg} ${styles.icon}`}>
            <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+2/Vector.svg" />
            {post.descendant_count > 0 &&
              <p data-pendo-tag="individual discussions post - like number" className={`marg-bottom-0 ${styles.comment_like_count}`}>{post.descendant_count}</p>
            }
          </button>
        </div>
        <div className={`col ${styles.icons_right_wrapper} justify-content-end`} style={{ display: "flex"}}>
          <MoreLinks
            post={post}
            refreshPost={refreshPost}
            removePost={removePost}
            onEdit={() => setShowEdit(true)}
          />
          <ShareLinks title={post.title} link={post.share_url} logExtra={{ post_id: post.id }} shareLabelStyle={{ fontSize: '16px', fontWeight: '600', color: '#333333',  paddingTop: '3px', display: 'inlineFlex', marginLeft: '3px'}}/>
          <LikeButton post={post} />
        </div>
      </div>
      {showEdit && (
        <CreatePost
          onClose={() => {
            setShowEdit(false);
            refreshPost();
          }}
          postToEdit={post} />
      )}
    </div>
  );
}
