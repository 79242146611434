import React from 'react'
import styles from './index.module.css'

export default function WaveSeparator({
  color = "#DDF7F9"
}) {

  return (
    <div style={{position:'relative', zIndex:'-1'}}>
      <svg className={styles.wave} viewBox="0 0 1440 166" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M509.488 3.08522C122.424 -19.1164 -1.44734 83.9431 -15 138.248V166H1468V43.6854C1354.33 75.2063 1138.67 111.01 981.629 84.2856C859.476 63.4985 618.417 12.1646 509.488 3.08522Z" fill={color} />
      </svg>
    </div>
  )
}