import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { getArticle } from '../../../api/articles';
import styles from './index.module.css'
import { monthDateYear } from '../../../helpers/index';
import './dangerously_set_content.css'
import DOMPurify from '../../../utils/purify.min.js'

export default function Article({

}) {
  const { id } = useParams();
  const [article, setArticle] = useState();

  useEffect(() => {
    getArticle(id, (data) => {
      setArticle(data);
    });
  }, [id])

  return (<>{article &&
    <div className={`${styles.article}`}>
      <div className={`${styles.hero} container h-100 marg-bottom-50 marg-top-30`}>
        <div className="row h-100">
          <div className="col-md-6 my-auto">
            <h1 className={`${styles.article_heading}`}>{article.article.title}</h1>
            <p className={`${styles.article_tease}`}>{article.article.teaser}</p>
            <div className={`${styles.meta_info_wrap}`}>
              <div className={`${styles.published_at}`}>
                <img alt="date posted" className={`${styles.meta_icon}`} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/calendar.svg" />
                <p>{monthDateYear(article.article.published_at)}</p>
              </div>
              <div className={`${styles.pipe}`}> | </div>
              <div className={`${styles.author_names}`}>
                <img alt="author" className={`${styles.meta_icon}`} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/person.svg" />
                <p>{article.article.author_names}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.min_height_col} col-md-6`}>
            <div className={`${styles.hero_image}`} style={{ backgroundImage: `url(${article.article.image_url})` }}></div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className={`${styles.content}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.partial) }}></div>
          </div>
          <div className='col-md-4 marg-bottom-50'>
            {/* <h2 className={`${styles.sm_header}`}>
              Tags
            </h2>
            {article.article.tag_list.map((tag) =>
              <p className={`${styles.tag}`}>{tag}</p>
            )} */}
          </div>
        </div>
      </div>
    </div>
  }</>)
}
