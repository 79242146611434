import React from 'react'

export default function PatientPartnerLoadingScreen() {
  return (
    <div className="load-wrapp">
      <div className="load-9">
        <div className="spinner">
          <div className="bubble-1"></div>
          <div className="bubble-2"></div>
        </div>
      </div>
      <h2 className="marg-bottom-30 marg-top-30">
        Finding your mentor...
      </h2>
      <div className="content">
        <p>
          Get ready to meet a real patient who will answer your questions and support you through your journey!
        </p>
      </div>
    </div>
  );
}
