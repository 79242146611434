import React, { Component } from 'react'
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function MfClinicalTrial({
  value,
  onSubmit,
  onBack
}) {
  return (
    <OptionsAndOther
      answers={{ mfClinicalTrial: value}}
      keyForAnswer={'mfClinicalTrial'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={[
        'Yes',
        'No',
      ]}
      title={`Are you (or the patient) part of a clinical trial?`}
      para={``}
      showOther={false}
      ctaTxt='Next'
      selectOne
    />
  )
}
