import React, { useContext } from 'react'
import './index.css'
import Context from '../../../../utils/context';

export default function AvatarWActive({
    profile,
    showInfo = false
}) {
    if (!profile) {
      return <></>;
    }

    const context = useContext(Context);
    const profileStatus = context.profileStatus;
    profileStatus.addProfile(profile);

    var active = profile.active;

    return (
        <div style={{display: 'flex', alignItems: 'center'}} data-active-id={profile.id}>
            <div className="avatar-holder">
                <div className="chat-room-pp-avatar" style={{ backgroundImage: `url(${profile.photo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                <div data-avatar-profile-id={profile.id} data-avatar-profile-type={profile.type} className={`active-status-light ${active ? 'active' : ''}`}></div>
            </div>
            {showInfo &&
                <div style={{flexDirection: 'column'}}>
                    <p style={{marginLeft: '10px',lineHeight: 'normal',fontSize: '16px'}} className="bold">{profile.display_name}</p>
                    {profile.last_active &&
                        <p data-last-active-profile-id={profile.id} data-last-active-profile-type={profile.type} style={{marginLeft: '10px',fontSize: '12px'}} >Last active {profile.last_active_display}</p>
                    }
                </div>
            }
        </div>
    )
}
