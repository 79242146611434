import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './review_guidelines.css'

export default function ReviewGuidelines() {
  return (
    <div class="container-fluid nopadding marg-top-for-transparent-header">
      <div class="row">
          <div class="col-sm-1"></div>
          <div class="col-sm-10">
            <section class="terms-section">
              <h1><b>Review Guidelines</b></h1>
              <p><b>Your review matters</b></p>
              <p>Your constructive feedback helps others in your community find the right care. Patients say reviews are one of the key factors they consider when choosing a doctor, and doctors say fair reviews help them improve patient experience.</p>
              <p><b>We take editorial integrity seriously</b></p>
              <p>Attempting to submit false, misleading or inflammatory content is strictly prohibited. Before a review is published to our site, it must pass all verification steps and rules that govern content.</p>
              <p><b>Make them relevant, helpful, and recent</b></p>
              <p>You can leave a review for a practice you or a family member has had contact with. You’re required to answer the first question: “how likely are you to recommend?” Only answer questions relevant to your experience.</p>
              <p><b>Guidelines to writing an effective review</b></p>
              <p>Ask yourself: Would I visit this practice or provider again? Answering this question in your review helps other patients quickly gauge your overall experience.</p>
              <p>Keep it relevant: Your review should include key points of your experience that other patients would find interesting.</p>
              <p><b>4. Your Service Account; Email Consent</b></p>
              <p>Consider length: A short, easy-to-read paragraph works great.</p>
              <p>Be specific: Say how you feel about your experience and why. Consider including details about things like scheduling, parking, payment options, or how the office staff met your needs, and the provider’s communication style.</p>
              <p>Respect others: When adding reviews, consider the following:</p>
              <ul>
                <li>Don’t post content that is libelous, defamatory, harassing, threatening, or inflammatory.</li>
                <li>Don’t use obscenities, profanity, or express intolerance for others on the basis of race, ethnicity, nationality, gender, gender identity, religion, sexual orientation, age, or disability.</li>
                <li>Don’t post content that is obscene or lewd.</li>
                <li>Don’t post misleading, deceptive or fraudulent content.</li>
                <li>Don’t post content that contains personal information including phone numbers, email addresses, mailing addresses, full name, or other personal information.</li>
                <li>Don’t post content with personal website links or mentions.</li>
                <li>Don’t impersonate other people or organizations, or pretend to be someone or something you are not.</li>
                <li>Don’t engage in name-calling or personal attacks.</li>
                <li>Don’t post content that mentions or endorses specific brands including pharmaceuticals.</li>
                <li>Don’t post content mentioning legal matters or proceedings.</li>
                <li>If your review is rejected for violating our business or privacy rules, you can submit a new review.</li>
              </ul>

              <p>Please read the above guidelines before submitting.</p>

              <p><b>Reporting abuse</b></p>
              <p>We encourage anyone who suspects a violation of our guidelines to notify us by flagging the comment. Once a comment is flagged, our team will look at the review again to make sure it fits our standards.</p>
              <p>By accessing or using Rightdevice features, you agree to the terms and conditions and to abide by these guidelines</p>
              <p>Please know that Rightdevice will not publish reviews that violate any of our rules, terms and conditions. We reserve the right to reject or remove any content from the site for any reason. Rightdevice may update this policy at any time in its sole discretion by posting a revised policy to the site. Rightdevice retains sole and absolute discretion with respect to interpreting and enforcing this policy.</p>

              <p><b>More questions about reviews?</b></p>
              <p>Once you submit a review, you will receive an automated email or text message from Rightdevice. Use that message to confirm your review. Your survey won’t be processed unless you confirm.</p>
              <p>Once you confirm your submission, the average star-rating you left for your provider is taken immediately into account on their profile. Written comments may take a little longer to post to the profile.</p>
              <p>Interested in editing your review? At this time, you cannot edit a review. Simply submit a new survey to override your previous review.</p>
            </section>
          </div>
          <div class="col-sm-1"></div>
        </div>
    </div>
  )
}
