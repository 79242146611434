import React, { Component, useState, useEffect, Suspense, lazy, useContext } from 'react'
import TextLeftImgRightWbg from '../../hero_sections/txt_left_img_right_wbg'
import FeaturedInMedia from '../../featured_in_media'
import HorizontalBanner from '../../hero_sections/horizontal_banner';
import PhotoWBlueBg from '../../hero_sections/photo_w_blue_bg';
import Testimonial from '../../hero_sections/testimonial';
import HowItWorks from '../../hero_sections/how_it_works';
import { Link } from 'react-router-dom'
import { autofocusAll } from '../../../helpers'
import Context from '../../../utils/context';
import './index.css'
const SurgeonMatchQuestionnaire = React.lazy(() => import('../../questionnaire/surgeon_match'));

export default function CosmeticLandingPage({
  home,
  updateAnswersAction,
  setContactIdAction,
  setSurgeonMatchQuestionnaireProgress,
  setSurgeon,
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth'
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  }

  React.useEffect(() => {
    if (new URLSearchParams(window.location.search).get('contact_id')){
      const timer = setTimeout(() => {
        document.querySelector('#questionnaire').scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <TextLeftImgRightWbg
        headline={'Find an award-winning plastic surgeon'}
        para={"Considering plastic surgery? Find the best surgeon in your area. We'll help guide you through the whole process."}
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/cosmetic/cosmetichero2.jpg'}
        bgColor={'#F1FEFF'}
        actionContent={<div className="btn-wrap"><button onClick={scrollToQuestionnaire} className="maincta" style={{marginBottom: '30px'}} data-gtm-click-label="Cosmetic Surgeon Match Get Started">Get started</button></div>}
        imgOffset={false}
        topOfIcon={'75%'}
        heightOfImage={isMobile ? '300px' : '500px'}
        iconContent={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.05 163.45"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="#74ccd3" className="cls-1" d="M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z"/></g></g></svg>}
        imgShadow={false}
        smallerHeadline={true}
      />
      <FeaturedInMedia />
      <HorizontalBanner
        headline={'Your tailored surgery experience'}
        bgColor={'#F1FEFF'}
        para={'Cosmetic surgery has given many a chance to feel younger and more confident about themselves. Whether you want to refresh your face, body or both, take the first step to a new you! PatientPartner makes it easy to find the best plastic surgeon in your area. Every doctor in our network is vetted by their prior patients who you’re able to connect directly with for advice.'}
      />
      <div className="container second-sec">
        <div className="row marg-top-100 marg-bottom-100 h-100">
          <div className="col-md-5 leftside my-auto">
            <img alt="patient-partner" src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/cosmetic/left-2.jpg'/>
          </div>
          <div className="col-md-7 my-auto rightside">
            <Testimonial
              para={'“I was scared to go through with surgery for years, but thanks for PatientPartner, I was able to find an amazing doctor in minutes! By connecting to their prior patients that underwent a similar procedure, I was able to get over my fear of scary results, complications and pain. PatientPartner made the process easy and took away any hesitation I had by chatting with patients like me. I feel better than ever about my body!”'}
              name={'Millie'}
              location={'Los Angeles, CA'}
            />
          </div>
        </div>
      </div>
      <div className="container ppyct-wrapper">
        <div className="row marg-top-100 h-100">
          <div className="col-md-7 my-auto rightside">
            <h2 className="marg-bottom-30">
              An easier process.<br/> Find<span className="highlight"> the best care</span>, fast.
            </h2>
            <ol className="marg-bottom-40">
              <li>Connect with board-certified and award-winning surgeons from our network in minutes.</li>
              <li>Schedule consultations directly on our platform to save time and be seen sooner.</li>
              <li>Speak directly with past patients to hear honest feedback and reviews about their experience.</li>
            </ol>
            <Link className="marg-top-30 btn-wrap" onClick={scrollToQuestionnaire}><button data-gtm-click-label="Cosmetic Landing Page Find a Doctor" className="liner marg-bottom-30">Find a doctor</button></Link>
          </div>
          <div className="col-md-5 leftside my-auto">
            <img className="" alt="patient-partner" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/cosmetic/phone-right.jpg" />
          </div>
        </div>
      </div>
      <HowItWorks
        headline={(
          <h2>Getting <span className="how-it-works headline highlight">connected to resources</span> is free and easy:</h2>
        )}
        headlineStyle={{fontSize: '40px', fontWeight: '400', color: '#314D69' }}
        smallHeadlineStyle={{fontFamily: 'HK Grotesk', lineHeight: '23px', fontSize: '18px', color: '#59B6BE', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '0.15em'}}
        steps={[
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/about.svg" />
            ),
            headline: 'Tell us about yourself',
            para: 'Take a short questionnaire about your health history and needs.'
          },
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/finasurgeon.svg" />
            ),
            headline: 'Find a surgeon',
            para: 'Review your recommendation and learn more about the top-tier surgeon in your area.'
          },
          {
            image: (
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/request.svg" />
            ),
            headline: 'Request an appointment',
            para: 'Schedule a consultation and connect to the office to ask questions prior to your appointment.'
          }
        ]}
      />
      <div id="questionnaire" className="container-fluid pad-cont" style={{backgroundColor: 'rgb(245, 247, 249)', alignContent: 'center', padding: '0px', borderRadius: '8px', width:'96%'}}>
        <div className="row justify-content-center h-100">
          <div className="col my-auto">
            <Suspense fallback={<p>loading</p>}>
              <SurgeonMatchQuestionnaire
                globalAnswers={{ ...home.answers, selectedProcedures: ['Cosmetic'] }}
                setGlobalAnswers={updateAnswersAction}
                progress={home.surgeonMatchQuestionnaireProgress}
                setProgress={setSurgeonMatchQuestionnaireProgress}
                contactId={home.contactId}
                surgeon={home.surgeon}
                setSurgeon={setSurgeon}
                setContactIdAction={setContactIdAction}
                predefinedProcedures = {['Cosmetic']}
                gtm={'Cosmetic Surgeon Match'}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  )
}
