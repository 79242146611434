import React, { Component, useState, useEffect } from 'react'
import './ihh_type_1.css'

export default function Ihh_type_1({
    // data
}) {

    // functions

    return (
        <div className="ihh-sec container-fluid ihh-type-1">
            <div className="ihh-sec container ihh-type-1">
                <div className="row h-100">
                    <div className="col-sm-5 my-auto">
                        <img alt="about-us-graphic" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/aboutus.jpg"/>
                    </div>
                    <div className="col-sm-7 my-auto">
                        <h1 className="marg-bottom-20">
                        Dreamers and doers who are transforming healthcare
                        </h1>
                        <p>
                            PatientPartner™ is here to help guide you through your treatment journey with a community of like-minded patients and a network of top doctors to assist you.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
