import React from 'react'
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-maskedinput';
import './date-picker-wrapper.css'

export default function DatePickerWrapper({
    selected,
    onChange,
    minDate,
    maxDate,
    filterDate,
    wrapperClassName,
    placeholderText,
    onBlur,
    light = false,
    isWeekday = false,
    label = true,
    fullWidth = false
}) {
  const isWeekdayFunc = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

    return (
        <div style={{width: fullWidth ? '100%' : '', maxWidth: fullWidth ? '100%' : ''}} className={`material-input marg-center ${light ? 'light' : ''} tod-picker`}>
            {label && <label className={`${selected !== null && selected !== '' ? 'in-final-position' : ''}`}>{placeholderText}</label>}
            <DatePicker
              selected={selected}
              onChange={onChange}
              minDate={minDate}
              maxDate={maxDate}
              filterDate={(date) => {
                let pass = true;

                if (isWeekday) {
                  pass = pass && isWeekdayFunc(date)
                }

                if (filterDate) {
                  pass = pass && filterDate(date)
                }

                return pass;
              }}
              wrapperClassName={wrapperClassName}
              placeholderText={placeholderText}
              onBlur={onBlur}
              customInput={
                <MaskedInput style={{textAlign:'left'}} mask="11/11/1111" placeholder="mm/dd/yyyy" />
              }
            />
        </div>
    )
}
