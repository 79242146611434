import React, { useState } from 'react'
import styles from './faq.module.css'

export default function FAQ({
    headline,
    headlineContent,
    para,
    content,
    openHeight
}) {
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.drawer}>
            <div onClick={() => setOpen(!open)} className={styles.drawer_inr}>
                {headline && <h3 className={styles.headline}>{headline}</h3>}
                {headlineContent && headlineContent}
                <div className={styles.arrow_wrap}>
                    <svg className={`${open ? styles.open : '' } ${styles.arrow}`} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="19" transform="rotate(-90 20 20)" stroke="#314D69" strokeWidth="2" /><path d="M28 17L19.5 25L11 17" stroke="#314D69" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
                </div>
            </div>
            <div className={`${open ? styles.open : '' } ${styles.drawer_content}`} style={{maxHeight: open ? openHeight ? openHeight : '100px' : '' }}>
                {para && <p className={styles.para}>{para}</p>}
                {content && content}
            </div>
        </div>
    )
}