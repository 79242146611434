import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import '../terms.css'

export default function Baa() {
  return (
    <div class="container-fluid nopadding marg-top-for-transparent-header">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <section class="terms-section">
            <h1><b>BUSINESS ASSOCIATE AGREEMENT</b></h1>
            <p>WHEREAS, Business Associate performs Services for or on behalf of Covered Entity, and in performing said Services, Business Associate creates, receives, maintains, or transmits individually identifiable health information;</p>
            <p>WHEREAS, the Parties intend to protect the privacy and provide for the security of the individually identifiable health information Disclosed by Covered Entity to Business Associate, or accessed, received, created, or transmitted by Business Associate, when providing Services.  Such individually identifiable health information or Protected Health Information (“PHI”) will be protected in compliance with HIPAA, the Health Information Technology for Economic and Clinical Health Act (Public Law 111-005) (the “HITECH Act”) and its implementing regulations and guidance issued by the Secretary, and other applicable state and federal laws, all as amended from time to time; and</p>
            <p>WHEREAS, Covered Entity wishes to enter into a Business Associate Agreement that meet certain requirements with respect to the Use and Disclosure of PHI, which are met by this BAA.  Accordingly, to the extent required by HIPAA, Business Associate agrees to comply with this BAA.</p>
            <p>In consideration of the Recitals and for other good and valuable consideration, the receipt and adequacy of which is hereby acknowledged, the Parties agree as follows:</p>
            <ol>
              <li>
                <h1><b>DEFINITIONS</b></h1>
                <p>The following terms shall have the meaning set forth below.  Capitalized terms used in this BAA and not otherwise defined shall have the meanings ascribed to them in the HIPAA Regulations.</p>
                <ol>
                  <li><b>“Breach”</b> shall have the meaning given under and 45 C.F.R. § 164.402.</li>
                  <li><b>“Designated Record Set”</b>shall have the meaning given such term under 45 C.F.R. § 164.501.</li>
                  <li><b>“Disclose”</b> and <b>“Disclosure”</b> mean, with respect to PHI, the release, transfer, provision of access to, or divulging in any other manner of PHI outside of Business Associate or to other than member of its Workforce, as set forth in 45 C.F.R. § 160.103.</li>
                  <li><b>“Electronic PHI”</b> or <b>“e-PHI”</b> means PHI that is transmitted or maintained in electronic media, as set forth in 45 C.F.R. § 160.103.</li>
                  <li><b>“Protected Health Information”</b> and <b>“PHI”</b> mean any information, whether oral or recorded in any form or medium, provided by Covered Entity to Business Associate, that: (a) relates to the past, present or future physical or mental health or condition of an individual; the provision of health care to an individual, or the past, present or future payment for the provision of health care to an individual; (b) identifies the individual (or for which there is a reasonable basis for believing that the information can be used to identify the individual); and (c) shall have the meaning given to such term under 45 C.F.R. § 160.103.  Protected Health Information includes e-PHI.</li>
                  <li><b>“Required by Law”</b> shall have the meaning given to such term under 45 C.F.R. § 160.103.</li>
                  <li><b>“Security Incident”</b> shall have the meaning given to such term under 45 C.F.R. § 164.304.</li>
                  <li><b>“Services”</b> shall mean the services for or functions on behalf of Covered Entity performed by Business Associate pursuant to any service agreement(s) between Covered Entity and Business Associate which may be in effect now or from time to time (<b>“Underlying Agreement”</b>), or, if no such agreement is in effect, the services or functions performed by Business Associate that constitute a Business Associate relationship, as set forth in 45 C.F.R. § 160.103.</li>
                  <li><b>“Unsecured PHI”</b> shall have the meaning given to such term under 42 U.S.C. § 17932(h), 45 C.F.R. § 164.402, and guidance issued pursuant to the HITECH Act including, but not limited to the guidance issued on April 17, 2009 and published in 74 Federal Register 19006 (April 27, 2009) by the Secretary.</li>
                  <li><b>“Use”</b> or <b>“Uses”</b> mean, with respect to PHI, the sharing, employment, application, utilization, examination or analysis of such PHI within Business Associate’s internal operations, as set forth in 45 C.F.R. § 160.103.</li>
                  <li><b>“Workforce”</b> shall have the meaning given to such term under 45 C.F.R. § 160.103.</li>
                </ol>
              </li>
              <li>
                <h1><b>OBLIGATIONS OF BUSINESS ASSOCIATE</b></h1>
                <ol>
                  <li>Permitted Uses and Disclosures of Protected Health Information.  Business Associate shall not Use or Disclose PHI received, accessed, maintained, or created for or on behalf of Covered Entity except to perform the Services required by any Underlying Agreement, or as permitted by this BAA or Required by Law.  Business Associate shall not Use or Disclose PHI in any manner that would constitute a violation of the HIPAA Regulations if so Used or Disclosed by Covered Entity.  Without limiting the generality of the foregoing, Business Associate is permitted to (i) Use PHI for the proper management and administration of Business Associate; (ii) Use and Disclose PHI to carry out the legal responsibilities of Business Associate, provided that with respect to any such Disclosure either: (a) the Disclosure is Required by Law; or (b) Business Associate obtains an agreement from the person to whom the PHI is to be Disclosed that such person will hold the PHI in confidence and will not Use and further Disclose such PHI except as Required by Law and for the purpose(s) for which it was Disclosed by Business Associate to such person, and that such person will notify Business Associate of any instances of which it is aware in which the confidentiality of the PHI has been breached; (iii) Use PHI for Data Aggregation purposes in connection with the Health Care Operations of Covered Entity; and (iv) Use PHI for purposes of de-identification of the PHI.</li>
                  <li>Adequate Safeguards of PHI.  Business Associate shall comply with Subpart C of 45 C.F.R. Part 164 with respect to PHI, to reasonably and appropriately protect the confidentially, integrity, and availability of e-PHI that it creates, receives, maintains or transmits on behalf of Covered Entity.</li>
                  <li>Mitigation.  Business Associate agrees to mitigate, to the extent practicable, any harmful effect that is known to Business Associate of a Use or Disclosure of PHI by Business Associate in violation of the requirements of this BAA.</li>
                  <li>Reporting Security Incidents and Non-Permitted Uses or Disclosures.  Business Associate shall notify Covered Entity in writing of any Security Incident or Use or Disclosure by Business Associate, members of its Workforce, or its Subcontractors that is not specifically permitted by this BAA no later than three (3) calendar days after becoming aware of such Security Incident or non-permitted Use or Disclosure, in accordance with the notice provisions set forth herein.  Notwithstanding the foregoing, Business Associate and Covered Entity acknowledge the ongoing existence and occurrence of attempted but ineffective Security Incidents that are trivial in nature, such as pings and other broadcast service attacks, and Covered Entity acknowledges and agrees that no additional notification to Covered Entity of such ineffective Security Incidents is required, as long as no such incident results in unauthorized access, Use or Disclosure of PHI.  Business Associate shall investigate each unauthorized access, acquisition, Use or Disclosure of PHI that it creates, receives, maintains, or transmits for or on behalf of Covered Entity.  If such Security Incident or non-permitted Use or Disclosure constitutes a reportable Breach of Unsecured PHI, then Business Associate shall comply with the requirements of Section 2.5 below.</li>
                  <li>Breach of Unsecured PHI.  Business Associate shall provide a written report to Covered Entity of such Breach without unreasonable delay but no later than five (5) business days after discovery of the Breach.  Business Associate shall be deemed to have discovered a Breach as of the first day that the Breach is either known to Business Associate or any of its employees, officers or agents, other than the person who committed the Breach, or by exercising reasonable diligence should have been known to Business Associate or any of its employees, officers or agents, other than the person who committed the Breach.  To the extent the information is available to Business Associate, Business Associate’s written notice shall include the information required by 45 C.F.R. §164.410(c).  Business Associate shall promptly supplement the written report with additional information regarding the Breach as it obtains such information.  Business Associate shall cooperate with Covered Entity in meeting Covered Entity’s obligations with respect to such Breach.  Covered Entity shall have sole control over the timing and method of providing notification of such Breach to the affected individual(s), the Secretary and, if applicable, the media.  Business Associate shall reimburse Covered Entity for its reasonable costs and expenses in providing the notification, including, but not limited to, any administrative costs associated with providing notice, printing and mailing costs, and costs of mitigating the harm (which may include the costs of obtaining credit monitoring services and identity theft insurance) for affected individuals whose PHI has or may have been compromised as a result of the Breach.</li>
                  <li>Delegated Responsibilities.  To the extent that Business Associate carries out one or more of Covered Entity’s obligations under Subpart E of 45 C.F.R. Part 164, Business Associate must comply with the requirements of Subpart E that apply to Covered Entities in the performance of such obligations.</li>
                  <li>Availability of Internal Practices, Books, and Records to Government.  Business Associate agrees to make its internal practices, books and records relating to the Use and Disclosure of Covered Entity’s PHI available to the Secretary for purposes of determining Covered Entity’s compliance with HIPAA, the HIPAA Regulations, and the HITECH Act.</li>
                  <li>Access to and Amendment of Protected Health Information.  To the extent that Business Associate maintains a Designated Record Set on behalf of Covered Entity and within ten (10) calendar days of such request by Covered Entity, Business Associate shall (a) make the PHI it maintains (or which is maintained by its Subcontractors) in such Designated Record Set available to Covered Entity for inspection and copying or, if requested by Covered Entity, to an individual, to enable Covered Entity to fulfill its obligations under 45 C.F.R. § 164.524; or (b) amend the PHI it maintains (or which is maintained by its Subcontractors) in such Designated Record Set to enable Covered Entity to fulfill its obligations under 45 C.F.R. § 164.526.  If Business Associate maintains PHI in a Designated Record Set electronically, Business Associate shall provide such information in the electronic form and format requested by Covered Entity if it is readily reproducible in such form and format, and, if not, in such other form and format agreed to by Covered Entity to enable Covered Entity to fulfill its obligations under 45 C.F.R. § 164.524(c)(2).</li>
                  <li>Accounting.  To the extent that Business Associate maintains a Designated Record Set on behalf of Covered Entity, within thirty (30) days of receipt of a request from Covered Entity or an individual for an accounting of disclosures of PHI, Business Associate and its Subcontractors shall make available to Covered Entity the information required to provide an accounting of disclosures to enable Covered Entity to fulfill its obligations under 45 C.F.R. § 164.528.</li>
                  <li>Use of Subcontractors.  Business Associate shall require each of its Subcontractors that creates, receives, maintains, or transmits PHI on behalf of Business Associate, to execute a written agreement that includes substantially the same restrictions and conditions that apply to Business Associate under this BAA with respect to PHI.</li>
                  <li>Minimum Necessary.  Business Associate (and its Subcontractors) shall, to the extent practicable, limit its request, Use, or Disclosure of PHI to the minimum amount of PHI necessary to accomplish the purpose of the request, Use or Disclosure, in accordance with 42 U.S.C. § 17935(b) and 45 C.F.R. § 164.502(b)(1) or any other guidance issued thereunder.</li>
                  <li>Qualified Service Organization.  Business Associate acknowledges that it may be a Qualified Service Organization (“QSO”), as defined in 42 C.F.R. § 2.11, with regard to the services provided to Covered Entity.  To the extent Business Associate qualifies as a QSO with regard to the services provided to Covered Entity, Business Associate acknowledges that certain PHI may not be Disclosed or re-disclosed under the rules addressing the Confidentiality of Alcohol and Drug Abuse Patient Records pursuant to 42 C.F.R. Part 2 (“Confidentiality Regulations”) without the individual’s written consent, even though such Disclosure or re-disclosure might be permitted by HIPAA or other laws.  Further, Business Associate agrees to be fully bound by the Confidentiality Regulations in receiving, storing, processing, transmitting, transporting or otherwise dealing with any PHI that is subject to the Confidentiality Regulations.  Business Associate will also resist in judicial proceedings any efforts to obtain applicable PHI except as permitted by the Confidentiality Regulations.</li>
                </ol>
              </li>
              <li>
                <h1><b>TERM AND TERMINATION</b></h1>
                <ol>
                  <li>Term.  The term of this BAA shall be effective as of the Effective Date and shall terminate as of the date that all of the PHI provided by Covered Entity to Business Associate, or created or received by Business Associate on behalf of Covered Entity, is destroyed or returned to Covered Entity, or, if it is infeasible to return or destroy the PHI, protections are extended to such information.</li>
                  <li>Termination for Cause.  In addition to and notwithstanding the termination provisions set forth in any Underlying Agreement, upon Covered Entity’s knowledge of a material breach or violation of this BAA by Business Associate, Covered Entity shall either: (a) Notify Business Associate of the breach in writing, and provide an opportunity for the Business Associate to cure the breach or end the violation within thirty (30) days of such notification; provided that if Business Associate fails to cure the breach or end the violation within such time period to the satisfaction of Covered Entity, Covered Entity may immediately terminate this BAA upon written notice to Business Associate; or (b) Upon thirty (30) days written notice to Business Associate, immediately terminate this BAA and any Underlying Agreement if Covered Entity determines that such breach cannot be cured.</li>
                  <li>Unfeasibility of Return or Destruction of PHI/Continuing Obligations. The parties acknowledge and agree that because of the nature of the Technology Services, upon the termination of the Underlying Agreement it will not be feasible for Business Associate to return or destroy all PHI received from Covered Entity or created or received by Business Associate on behalf of Covered Entity and which Business Associate still maintains as PHI. Accordingly, the parties acknowledge and agree that, with respect to any such PHI, the terms and provisions of this Business Associate Agreement shall survive termination of the Underlying Agreement and such PHI shall be used or disclosed solely for the purposes which prevented its return or destruction.</li>
                </ol>
              </li>
              <li>
                <h1><b>MISCELLANEOUS</b></h1>
                <ol>
                  <li>Amendment to Comply with Law.  To the extent applicable, amendments or modification to HIPAA or the HITECH Act may require amendments to certain provisions of this BAA.  Amendments shall only be effective if executed in writing and signed by a duly authorized representative of each Party.</li>
                  <li>Relationship to Underlying Agreement Provisions.  In the event that a provision of this BAA is contrary to a provision of an Underlying Agreement, the provision of this BAA shall control.  Otherwise, this BAA shall be construed under, and in accordance with, the terms of such Underlying Agreement, and shall be considered an amendment of and supplement to such Underlying Agreement, subject to Section 4.3 below.</li>
                  <li>Notices.  Any notices or communications hereunder shall be in writing by certified mail, return receipt requested, or delivered by a nationally recognized courier service with delivery confirmation, such as FedEx, or by facsimile (with evidence of receipt) at the addresses that follow the signature blocks at the end of this BAA.</li>
                  <li>Relationship of Parties.  Notwithstanding anything to the contrary in any Underlying Agreement, Business Associate is an independent contractor and not an agent of Covered Entity under this BAA.  Business Associate has the sole right and obligation to supervise, manage, contract, direct, procure, perform or cause to be performed all Business Associate obligations under this BAA.</li>
                  <li>Interpretation.  This BAA shall be interpreted as broadly as necessary to implement and comply with HIPAA, the HIPAA Regulations and the HITECH Act.  The parties agree that any ambiguity in this BAA shall be resolved in favor of a meaning that complies and is consistent with such laws.</li>
                  <li>No Third Party Beneficiaries.  Nothing express or implied in this BAA is intended to confer, nor shall anything herein</li>
                </ol>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </div>
  )
}
