import React from 'react'
import styles from './index.module.css'

export default function ({
  para,
  photo,
  name,
  location,
}) {
  return (
    <div className={``}>
      <svg width="57" height="46" viewBox="0 0 57 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.85 21.51C18.71 21.77 20.8767 22.6366 22.35 24.11C23.8233 25.5833 24.56 28.0966 24.56 31.65V33.6C24.56 35.68 24.4733 37.24 24.3 38.28C24.2133 39.32 23.8233 40.5333 23.13 41.92C21.83 44.4333 18.5367 45.69 13.25 45.69C7.96333 45.69 4.45333 44.8233 2.72 43.09C1.07333 41.3566 0.25 38.0633 0.25 33.21C0.25 31.0433 0.336667 29.44 0.51 28.4C1.11667 20.7733 3.37 14.2733 7.27 8.89996C11.17 3.43996 16.11 0.709961 22.09 0.709961C22.6967 0.709961 23.2167 1.18663 23.65 2.13996C24.17 3.00663 24.43 4.17663 24.43 5.64996C24.43 7.03663 24.2133 8.16329 23.78 9.02996C23.4333 9.89663 23 10.4166 22.48 10.59C22.0467 10.6766 21.7 10.7633 21.44 10.85C21.18 10.85 20.7033 11.0666 20.01 11.5C19.4033 11.8466 18.84 12.3666 18.32 13.06C16.8467 14.7933 16.0233 17.61 15.85 21.51ZM47.57 21.51C50.43 21.77 52.5967 22.68 54.07 24.24C55.63 25.7133 56.41 28.8333 56.41 33.6C56.41 38.7133 55.5 41.9633 53.68 43.35C51.5133 44.91 48.1767 45.69 43.67 45.69C39.25 45.69 36.1733 44.8233 34.44 43.09C32.7933 41.3566 31.97 38.0633 31.97 33.21C31.97 31.0433 32.0567 29.44 32.23 28.4C32.8367 20.7733 35.09 14.2733 38.99 8.89996C42.9767 3.43996 47.96 0.709961 53.94 0.709961C54.5467 0.709961 55.0667 1.18663 55.5 2.13996C56.02 3.00663 56.28 4.17663 56.28 5.64996C56.28 7.03663 56.0633 8.16329 55.63 9.02996C55.2833 9.89663 54.85 10.4166 54.33 10.59C53.8967 10.6766 53.55 10.7633 53.29 10.85C53.03 10.85 52.5533 11.0666 51.86 11.5C51.1667 11.8466 50.56 12.3666 50.04 13.06C48.5667 14.7933 47.7433 17.61 47.57 21.51Z" fill="#59B6BE" /></svg>
      <p className="marg-top-20">{para}</p>
      <div className={`${styles.info_holder} container`}>
        <div className="row h-100">
          {
            photo && (
              <div className="col-3 my-auto de-pad">
                <div className={styles.photo} style={{ backgroundImage: 'url(' + photo + ')' }}></div>
              </div>
            )
          }
          <div className={`col-9 my-auto de-pad`}>
              <h5 className={`${styles.pad_wrap} ${styles.heading5}`}>{name}</h5>
              <h6 className={`${styles.pad_wrap} ${styles.heading6}`}>{location}</h6>
          </div>
        </div>
      </div>
    </div>
  )
}
