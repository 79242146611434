import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../ui_kit/utilities/error_message'
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms'
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmail, validateZipcode, validateText, validatePhone } from '../../helpers';

export default function NameEmailPhoneZip({
  answers,
  onSubmit,
  gtm,
  head,
  para,
  includeBackButton = false
}) {
  const [name, setName] = useState(answers.name || '');
  const [phone, setPhone] = useState(answers.phone || '');
  const [email, setEmail] = useState(answers.email || '');
  const [zipcode, setZipcode] = useState(answers.zipcode || '');
  const [acceptedTerms, setAcceptedTerms] = useState(answers.acceptedTerms || false);

  const [error, setError] = useState('');

  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  let disabled = 'disabled';
  let questionnaireError = '';
  var checkedEmail = validateEmail(email);
  var checkedZipcode = validateZipcode(zipcode);
  var checkedName = validateText(name);
  var checkedPhone = validatePhone(phone);

  if (checkedName == true && checkedEmail === true && acceptedTerms && checkedZipcode && checkedPhone) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  const onClick = (event) => {
    if (hasBeenSubmitted == false) {
      setHasBeenSubmitted(true);
      if (checkedEmail === false) {
        setError('Please enter a valid email');
        setHasBeenSubmitted(false);
      }
      else if (checkedZipcode == false) {
        setError('Please enter a valid zipcode');
        setHasBeenSubmitted(false);
      }
      else if (checkedName == false) {
        setError('Please enter a name without special characters');
        setHasBeenSubmitted(false);
      }
      else if (checkedPhone == false) {
        setError('Please enter a valid phone number');
        setHasBeenSubmitted(false);
      }
      else {
        const newAnswer = { ...answers, name, phone, email, zipcode, acceptedTerms };
        onSubmit(newAnswer);
        setError('');
      }
    }
  }
  const validate = (event) => {
    event.preventDefault();

    if (checkedEmail === false && email !== '') {
      if (email.indexOf('@') == '-1') {
        setEmail('')
      }
      setError('Please enter a valid email');
    }
    else if (checkedPhone == false && phone !== '') {
      setError('Please enter a valid phone number');
    }
    else if (checkedZipcode == false && zipcode !== '') {
      setError('Please enter a valid zipcode');
    }
    else if (checkedName == false && name !== '') {
      setError('Please enter a name without special characters');
    }
    else {
      setError('');
    }
  }
  if (phone !== "") {
    checkedPhone = validatePhone(phone);
  }

  const recaptchaRef = React.createRef();

  const runRecaptcha = async (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  }

  let history = useHistory();

  const onBackClick = () => {
    history.goBack();
  }

  return (
    <div className="row">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8 intro-question">
        {/* <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss-surgeon-match/device_hand.png"/> */}
        <h5 className="text-left mobile-marg-20 marg-bottom-20">{head}</h5>
        <p className="question-sub-headline marg-bottom-0 text-left">
          {para}
        </p>
        <form id="infoCap">
          <div className="material-input light">
            <input className="autofocus" type="text" name="name" value={name} onChange={(event) => setName(event.target.value)} onBlur={validate} required />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>Full Name</label>
          </div>
          <div className="material-input light">
            <input type="tel" name="phone" value={phone} onChange={(event) => setPhone(event.target.value)} onBlur={validate} required />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>Phone Number</label>
          </div>
          <div className="material-input light">
            <input type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} onBlur={validate} required />
            <span className="bar"></span>
            <label>Email</label>
          </div>
          <div className="material-input light">
            <input type="tel" name="zipcode" value={zipcode} onChange={(event) => setZipcode(event.target.value)} onBlur={validate} required />
            <span className="bar"></span>
            <label>Zip Code</label>
          </div>
          <AgreeToTerms parentClass={''} acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
          {error &&
            <ErrorMessage
              error={error}
            />
          }
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={gon.recaptcha_sitekey}
            onChange={onClick}
          />
          <div className="row button-holder">
              {
                <button data-gtm-click-label={`${gtm} Name Email Phone Zipcode Back`} className="back get-started-back" type="button" onClick={onBackClick}>Back</button>
              }
              <button disabled={disabled} className={`wl-tell-us-about-yourself submit col-6`} type="submit" onClick={runRecaptcha} data-gtm-click-label={`${gtm} Name Email Phone Zipcode Submit`}>Start</button>
            </div>
        </form>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
