import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import styles from './index.module.css'
import SignUp from '../../sign_up';
import Context from '../../../utils/context';

export default function JoinDoctorConnect() {
  const history = useHistory();
  const returnTo = new URLSearchParams(window.location.search).get("returnTo")

  return (
    <div className={styles.questionnaire_ottr} id="questionnaire">
      <div className="row justify-content-center h-100">
        <div className="col-md-8 h-100">
          <SignUp
            onSuccess={() => history.push(returnTo)}
            headline={`Join PatientPartner today!`}
            para={`Create your account and explore your personalized support community.`}
            embedded={true}
            alignLeft={true}
            light={false}
            captureFirstLastName
            params={{ origin: 'Paid Beta Soft Launch' }}
            gtm={'Paid Beta Soft Launch'}
          />
        </div>
      </div>
    </div>
  );
}
