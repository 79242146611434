import React, { useState, useEffect, useContext } from 'react'
import IsScrolling from 'react-is-scrolling';
import LoadingScreen from '../../loading_screen';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import DropdownInput from '../../ui_kit/utilities/dropdown_input';
import { getCommunityInsights } from '../../../api/surgeon_administrative_assistants'
import styles from '../patients/mentor_connect/index.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function CommunityInsights() {
  const [view, setView] = useState('loading');
  const [results, setResults] = useState({});
  const [chartView, setChartView] = useState('New Members');

  useEffect(() => {
    getCommunityInsights({ chart_view: chartView }, (results) => {
      setResults(results);
      setView('loaded');
    });
  }, [chartView]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: chartView,
      },
    },
  };

  const labels = (results.chart && results.chart.map((c) => {
    let day = new Date(c.date);
    let nextDay = new Date(day);
    nextDay.setDate(day.getDate() + 1);
    return nextDay.toLocaleDateString();
  }) || []);

  const data = {
    labels,
    datasets: [
      {
        label: chartView,
        data: (results.chart && results.chart.map(c => c.count)) || [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  if (view === 'loading') {
    return <LoadingScreen />
  }

  return (
    <div className={`${styles.roster_container_wrapper}`}>
      <div className={styles.mentor_connect_heading} style={{ marginTop: '20px', marginBottom: '35px' }}>
        <h5 className={`${styles.roster_heading}`}>Community Insights</h5>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className={styles.card}>
              <div className={styles.card_number}>
                {results.total_members}
              </div>
              <div className={styles.card_label}>
                number of members
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className={styles.card}>
              <div className={styles.card_number}>
                {results.total_posts}
              </div>
              <div className={styles.card_label}>
                number of posts
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={styles.card}>
              <div className={styles.card_number}>
                {results.total_comments}
              </div>
              <div className={styles.card_label}>
                number of comments
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className={styles.card}>
              <div className={styles.card_number}>
                {results.total_likes}
              </div>
              <div className={styles.card_label}>
                number of likes
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chart_container}>
        <DropdownInput
          values={[chartView]}
          options={['New Members', 'New Posts', 'New Comments', 'New Likes'].map(v => {
            return { value: v }
          })}
          setValues={(v) => {
            setView('loading');
            setChartView(v);
          }}
          gtm={`Patient partner gallery filters - Procedure`}
          type="single"
        />
        <Line options={options} data={data} />
      </div>
    </div>
  )
}

export default IsScrolling(CommunityInsights);
