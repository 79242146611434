const initialState = gon.surgeon_match_questionnaire_state || {
  surgeonMatchAnswers: {
    // name: 'Zhichao Test',
    // email: 'zhichao+test98398hafa@rightdevice.com',
    // zipcode: '12345',
    // gender: 'male',
    // age: '12',
    // feet: '1',
  },
  surgeonMatchQuestionnaireState: 1,
  surgeonMatchContactId: null,
  surgeons: []
};

const weightLossSurgeonMatch = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SURGEON_MATCH_ANSWERS':
      return {
        ...state,
        surgeonMatchAnswers: action.item
      }
    case 'SET_SURGEON_MATCH_QUESTIONNAIRE_STATE':
      return {
        ...state,
        surgeonMatchQuestionnaireState: action.item,
      }
    case 'SET_SURGEON_MATCH_CONTACT_ID':
      return {
        ...state,
        surgeonMatchContactId: action.item,
      }
    case 'SET_SURGEONS':
      return {
        ...state,
        surgeons: action.item,
      }
    default:
      return state
  }
}

export default weightLossSurgeonMatch
