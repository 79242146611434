import React, { useContext } from "react";
import Context from "../../../../utils/context";
import MentorTag from '../../../profile/profile_panel/profile_panel_sections/mentor_tag';
import LocationDisplay from '../../../profile/profile_panel/profile_panel_sections/location_display';
import styles from './index.module.css'
import { Link } from "react-router-dom";

export default function SimpleAvatar({
  profile = null,
  showInfo = false,
  showTime = false,
  post = null,
  showTypeIcon = false,
  userSlug,
  changeNameColor,
  showProcedure,
  showIcon,
  centerInfo
}) {
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();

  if (profile == null && user && user.current_profile) {
    profile = user.current_profile;
  }

  return (
    <div data-active-id={profile.id}>
      <Link to={`/member/profile/${userSlug}/about-me`}>
        <div className="avatar-holder" style={{ margin: '0px auto' }}>
          <div
            className="chat-room-pp-avatar-lg"
            style={{
              backgroundImage: `url(${profile.photo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        </div>
      </Link>
      {showInfo && (
        <div style={{ position: 'relative' }}>
          {centerInfo ? (
            <>
              <p style={{ lineHeight: "normal", fontSize: "18px", fontWeight: "500", textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', margin: '15px auto 8px auto', justifyContent: 'center' }}>
                  <Link to={`/member/profile/${userSlug}/about-me`}>
                    <p style={{ textAlign: 'center', fontSize: '18px' }} className={`${changeNameColor ? styles.name1 : styles.name}`}>
                      {profile.display_name}
                    </p>
                  </Link>
                  {
                    showTime &&
                    <p className={styles.date}>
                      {post.how_long_posted}
                    </p>
                  }
                </div>
              </p>
              <div className="row justify-content-center">
                {showTypeIcon && profile && profile.type && profile.type === 'PatientPartner' &&
                  <MentorTag centerTag={centerInfo} />
                }
              </div>
              <div className="row justify-content-center">
                {profile.display_location &&
                  <LocationDisplay showLocationIcon={showIcon} location={profile.display_location} />
                }
              </div>
              <div className="row justify-content-center">
                {showProcedure &&
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className={styles.procedure_type}>
                      {profile.procedures.length > 1 ?
                        [...new Set(profile.procedures)].map(p => p).join(', ')
                        :
                        profile.procedures.map(p => p)}
                    </p>
                  </div>
                }
              </div>
            </>
          ) : (
            <>
              <div style={{ lineHeight: "normal", fontSize: "18px", fontWeight: "500", textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', margin: '15px auto 8px auto', justifyContent: 'center' }}>
                  <Link to={`/member/profile/${userSlug}/about-me`}>
                    <p style={{ textAlign: 'center', fontSize: '18px' }} className={`${changeNameColor ? styles.name1 : styles.name}`}>
                      {profile.display_name}
                    </p>
                  </Link>
                  {showTypeIcon && profile && profile.type && profile.type === 'PatientPartner' &&
                    <MentorTag />
                  }
                  {showTime &&
                    <p className={styles.date}>
                      {post.how_long_posted}
                    </p>
                  }
                </div>
                <div className="row justify-content-center">
                  {showProcedure &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p className={styles.procedure_type}>
                        {profile.procedures.length > 1 ?
                          [...new Set(profile.procedures)].map(p => p).join(', ')
                          :
                          profile.procedures.map(p => p)}
                      </p>
                    </div>
                  }
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
