import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { getPatientPartners } from '../../api/patient_partners'
import Modal from '../modal'
import Oncehub from '../oncehub'
import PatientPartnerColumnForGallery from '../patient_partner_column_for_gallery'
import './partners.css'
import CustomCtaFooter from '../custom_cta_footer'
import { createDirectBookingContacts } from '../../api/direct_booking_contacts'
import { getPatientPartnersPerDistance } from '../../api/patient_partners'
import { createConversation } from '../../api/conversations'
import LoadingScreen from '../loading_screen'
import Autocomplete from "react-google-autocomplete";
import IconButtonSvg from '../ui_kit/utilities/icon_button_svg';
import DropdownInput from '../ui_kit/utilities/dropdown_input';
import ScrollTo from '../scroll_to'
import Context from '../../utils/context';
import SignUp from '../sign_up';
import HeaderLeftTextRight from '../hero_sections/header_left_text_right'

export default function Partners() {
  const [isLoading, setIsLoading] = useState(true);
  const [patientPartners, setPatientPartners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [selectedPatientPartner, setSelectedPatientPartner] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [lowIndex, setLowIndex] = useState(0);
  const [highIndex, setHighIndex] = useState(8);
  const [selectedPage, setSelectedPage] = useState(0);
  const [locationPlaceHolder, setLocationPlaceHolder] = useState('');
  let history = useHistory();
  const context = useContext(Context);
  const [mentorSurgeries, setMentorSurgeries] = useState([]);
  const filterableAges = [
    {
      value: '18-24',
      low: 18,
      high: 24,
    },
    {
      value: '25-34',
      low: 25,
      high: 34,
    },
    {
      value: '35-44',
      low: 35,
      high: 44,
    },
    {
      value: '45-54',
      low: 45,
      high: 54,
    },
    {
      value: '55-64',
      low: 55,
      high: 64,
    },
    {
      value: '65-74',
      low: 65,
      high: 74,
    },
    {
      value: '75+',
      low: 75,
      high: 200,
    },
  ];
  const [chosenProcedures, setChosenProcedures] = useState(new URLSearchParams(window.location.search).get("procedures") != null ? new URLSearchParams(window.location.search).get('procedures').split(',').map(procedure => ({value: procedure})) : []);
  const [chosenAges, setChosenAges] = useState(new URLSearchParams(window.location.search).get("age") != null ? new URLSearchParams(window.location.search).get("age").split(',').map(age => filterableAges.filter(a => a.value === age)[0])  : []);
  const [chosenGender, setChosenGender] = useState(new URLSearchParams(window.location.search).get("gender") != null ? new URLSearchParams(window.location.search).get('gender').split(',').map(gender => ({value: gender})):[]);
  const [chosenLocation, setChosenLocation] = useState(new URLSearchParams(window.location.search).get('location') != null ? new URLSearchParams(window.location.search).get('location') : '')
  const [chosenDistance, setChosenDistance] = useState(new URLSearchParams(window.location.search).get('distance') != null ? new URLSearchParams(window.location.search).get('distance') : '')
  const [distancePartners, setDistancePartners] = useState([]);

  const titleCase = (string) => {
    var surgery = string.toLowerCase().split(" ");
    for(var i = 0; i< surgery.length; i++){
      if (surgery[i][0]) {
        surgery[i] = surgery[i][0].toUpperCase() + surgery[i].slice(1);
      }
    }
    return surgery.join(" ")
  }

  useEffect(() => {
    getPatientPartners('All', null, null, null, null, null, (data) => {
      setPatientPartners(data);
      setIsLoading(false);
      resetPagination(data);
      const surgeries = ((data.map(e => e.surgeries_or_treatments).join(',').split(', ').join(', ').split(',')).filter(i=> i!== '')).map(p => titleCase(p))
      setMentorSurgeries(([...new Set(surgeries)]).sort())
    });
  }, [])

  const resetPagination = (data) => {
    var arr = []
    var paginationPageNumbers = Math.ceil(data.length / 9)
    for (var i = 0; i < paginationPageNumbers; i++) {
      arr.push(i);
    }
    // setPages(arr)
  }
  const onScheduled = (data) => {
    createDirectBookingContacts(
      data.bookingInfo.CustomerName,
      data.bookingInfo.CustomerEmail,
      data.bookingInfo.CustomerPhone,
      window.patient_partner.name,
      data.bookingInfo.EventTypeName,
      () => {
        setShowModal(false);
      }
    )
  }

  const goToSignup = (partner) => () => {
    history.push(`/member/join/support?patient_partner_id=${partner.id}`)
  };

  let filterableProcedures = [];
  mentorSurgeries.map(e => filterableProcedures.push({value: e}))

  const setDistance = (value) => {
    if (chosenDistance === (value)) {
      setChosenDistance('')
    } else {
      setChosenDistance(value)
    }
  }

  const clearFilters = () => {
    setDistancePartners([])
    setChosenProcedures([])
    setChosenAges([])
    setChosenGender([])
    setChosenDistance('')
    setChosenLocation('')
    setLocationPlaceHolder('')
    setSelectedPage(0)
    setLowIndex(0)
    setHighIndex(8)
  }

  useEffect(() => {
    setIsLoading(true)
    getPatientPartnersPerDistance(chosenLocation, chosenDistance, (data) => {setDistancePartners(data);setIsLoading(false)})
  }, [chosenLocation,chosenDistance]);

  const checkToClearLocation = (location) => {
    if (location === ''){
      setChosenLocation('')
      setChosenDistance('')
      setDistancePartners([])
    }
    setLocationPlaceHolder(location)
  }

  var paginationPageNumbers;
  var pageArr = [];
  var genderArr = [];
  if (chosenGender.length > 0) {
    for (let i = 0; i <= (chosenGender.length - 1); i++) {
      patientPartners.forEach(a => {
        if (a.extra.gender && a.extra.gender.toLowerCase() === chosenGender[i].value.toLowerCase()) {
          genderArr.push(a)
        }
      })
    }
  }

  var proceduresArr = [];
  if (chosenProcedures.length > 0) {
    for (let i = 0; i <= (chosenProcedures.length - 1); i++) {
      patientPartners.forEach(a => {
        if (a.surgeries_or_treatments && a.surgeries_or_treatments.join(', ').toLowerCase().includes(chosenProcedures[i].value.toLowerCase())){
          proceduresArr.push(a)
        }
      })
    }
  }

  var agesArr = []
  if (chosenAges.length > 0) {
    for (let i = 0; i <= (chosenAges.length - 1); i++) {
      patientPartners.forEach(a => {
        if (a.display_age && a.display_age >= chosenAges[i].low && a.display_age <= chosenAges[i].high) {
          agesArr.push(a)
        }
      })
    }
  }

  var distanceArr = [];
  if (distancePartners.length > 0) {
    for (let i = 0; i <= (distancePartners.length - 1); i++) {
      patientPartners.forEach(a => {
        if (a.name == distancePartners[i].name) {
          distanceArr.push(a)
        }
      })
    }
  }

  var filteredArrays = [genderArr, proceduresArr, agesArr, distanceArr].filter(ele => ele.length !== 0)
  var finals = []

  patientPartners.forEach(
    function(masterPartner){
        var counter = 0;
        filteredArrays.filter(function(arr){
          if(arr.findIndex(ele => ele.name == masterPartner.name) !== -1){
            counter++
          }
        })
        if (counter == filteredArrays.length){
          finals.push(masterPartner)
        }
    }
  )
  if (distancePartners.length === 0 && chosenLocation !== ''){
    finals = []
  }
  if(chosenGender.length > 0 && genderArr.length === 0){
    finals = []
  }

  let partnersForGallery = finals;
  paginationPageNumbers = Math.ceil(finals.length / 9)
  for (var i = 0; i < paginationPageNumbers; i++) {
    pageArr.push(i);
  }
  const pages = pageArr

  useEffect(() => {
    if (chosenProcedures.length === 0 && chosenAges.length === 0 && chosenGender.length === 0 && chosenDistance === '' && chosenLocation === '') {
      var arr = [...patientPartners];
      arr = arr.sort((a, b) => a.name.localeCompare(b.name));
      partnersForGallery = arr
      resetPagination(patientPartners);
    }

    let searchParams = new URLSearchParams()

    if(chosenAges.length >0) searchParams.append('age', chosenAges.map(age=> age.value).join(','))
    if(chosenProcedures.length >0) searchParams.append('procedures', chosenProcedures.map(p=> p.value).join(','))
    if(chosenGender.length >0) searchParams.append('gender', chosenGender.map(g=> g.value).join(','))
    if(chosenLocation !== '') searchParams.append('location', chosenLocation)
    if(chosenDistance !== '') searchParams.append('distance', chosenDistance)
    history.push("?" + searchParams.toString())

  }, [chosenProcedures, chosenAges, chosenGender, chosenDistance, chosenLocation]);

// Listens to know if the device is a mobile device START
  const [isMobile, setDesktop] = useState(window.innerWidth < 992);

  const updateMedia = () => {
    setDesktop(window.innerWidth < 992);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);
  // Listens to know if the device is a mobile device END

  const [makeSticky, setMakeSticky] = useState(false);
  function stickyScroll() {
    if (document.querySelector('.gallery-sec').getBoundingClientRect().y < 500) {
      setMakeSticky(true)
    }
    else {
      setMakeSticky(false)
      setShowFilters(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', stickyScroll);
    return () => window.removeEventListener('scroll', stickyScroll);
  }, []);

  const partnersForGalleryToShow = partnersForGallery.slice(lowIndex, highIndex+1);
  const partnersForGalleryToShowInRows = [];
  for (var i = 0; i < partnersForGalleryToShow.length; i += 3) {
    partnersForGalleryToShowInRows.push(partnersForGalleryToShow.slice(i, i + 3));
  }

  const generateActionContent = (partner) => {
    let actionContent = null;
    if (context.auth.isLoggedIn()) {
      actionContent = (
        <button className='justify-content-center' style={{width: '80%'}} data-pendo-tag="mentor gallery - send a message" data-gtm-click-label="Patient partner gallery Send a Message" onClick={() => {
          createConversation({ patient_partner_id: partner.id }, (conversation) => {
            history.push(`/member/conversations/${conversation.uuid}`);
          })
        }}>Send a message</button>
      );
    }
    else {
      actionContent = (
        <button style={{width: '80%'}} data-pendo-tag="mentor gallery - send a message" data-gtm-click-label="Patient partner gallery Sign Up" onClick={goToSignup(partner)}>Send a message</button>
      );
    }

    return actionContent;
  }

  return (
    <div className="partner-gallery light-nav">
      <div style={isMobile ? {paddingBottom: '150px'} : null}>
      <HeaderLeftTextRight
        headline={'Meet our PatientPartner Mentors'}
        actionContent={<Link data-pendo-link="Partners Find a mentor for me" to="/member/mentor-match"><button className="marg-bottom-40" data-gtm-click-label="Partners Find a mentor for me">Find a mentor for me</button></Link>}
      />
      </div>
      {isMobile && showFilters &&
        <div data-gtm-click-label="Patient partner gallery filters - Mobile Show Filter" onClick={() => setShowFilters(false)} className="mobile-filter-bg"></div>
      }
      <div style={isMobile ? {paddingTop: '100px'} : {paddingBottom: '175px'}} className={`container ${isMobile ? 'mobile-filter-wrapper' : ''}`}>
        <div className={`${isMobile ? 'mobile' : ''} ${showFilters ? 'show' : ''} filter-tools marg-top-40 row`}>
            <div className="col-lg-2">
              <p className={`bold filter-title ${isMobile ? 'marg-top-10' : ''}`}>
                Procedure Type
              </p>
              <DropdownInput
                values={chosenProcedures}
                options={filterableProcedures}
                setValues={setChosenProcedures}
                gtm={`Patient partner gallery filters - Procedure`}
                type="multi"
              />
            </div>

            <div className="col-lg-2">
              <p className="bold filter-title">
                Age Range
              </p>
              <DropdownInput
                values={chosenAges}
                options={filterableAges}
                setValues={setChosenAges}
                gtm={`Patient partner gallery filters - Age`}
              />
            </div>

            <div className="col-lg-2">
              <p className="bold filter-title">
                Gender
              </p>
              <DropdownInput
                values={chosenGender}
                options={['Male', 'Female', 'Non-binary'].map(g => {
                  return { value: g };
                })}
                setValues={setChosenGender}
                gtm={`Patient partner gallery filters - Gender`}
              />
            </div>

            <div className="col-lg-3 range-finder">
              <p className="bold filter-title">
                Location
              </p>
              <div className={`${chosenLocation !== '' ? 'active' : ''} light locator material-input`}>
                <Autocomplete
                  data-gtm-click-label="Patient partner gallery filters - Location"
                  apiKey={gon.google_maps_key}
                  onPlaceSelected={(place) => {
                    setChosenLocation(place.formatted_address);
                    setLocationPlaceHolder(place.formatted_address);
                  }}
                  onChange={(e) => checkToClearLocation(e.target.value)}
                  value={locationPlaceHolder}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "us" },
                  }}
                  placeholder='Enter zip or city'
                />
              </div>
              <div className={`${chosenLocation !== '' ? 'active' : ''} dropdown multiselect light singleselect distance-field`}>
                <button data-gtm-click-label="Patient partner gallery filters - Radius" disabled={`${chosenLocation === '' ? 'disabled' : ''}`} className={`${chosenDistance !== '' ? 'active' : ''} dropdown-toggle material-input`} type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  <p className="dropdown-placeholder">{chosenDistance != '' ? chosenDistance + ' Miles' : 'Radius'}</p>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                  {['10','50','100'].map(a => (
                    <li key={a}>
                      <a className="position-parent" value={a}>
                        <label onClick={() => setDistance(a)} htmlFor={a}>
                          <p data-gtm-click-label="Patient partner gallery filters - Radius" className={`${chosenDistance === a ? 'active' : ''}`}>{a} Miles</p>
                        </label>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 marg-top-30 clear-filters-button">
              <button onClick={() => clearFilters()} data-pendo-tag="Patient partner gallery filters - Reset Filters" data-gtm-click-label="Patient partner gallery filters - Reset Filters" className="liner w-100">Clear all filters</button>
            </div>
          </div>
      </div>
      {isMobile && makeSticky &&
        <div className='container filter-wrapper'>
          <div className='row'>
            <div className='col text-center'>
              <IconButtonSvg
                icon = {showFilters === true ? <></> : <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5V0.5ZM18 3.5C18.8284 3.5 19.5 2.82843 19.5 2C19.5 1.17157 18.8284 0.5 18 0.5V3.5ZM2 6.5C1.17157 6.5 0.5 7.17157 0.5 8C0.5 8.82843 1.17157 9.5 2 9.5V6.5ZM18 9.5C18.8284 9.5 19.5 8.82843 19.5 8C19.5 7.17157 18.8284 6.5 18 6.5V9.5ZM2 12.5C1.17157 12.5 0.5 13.1716 0.5 14C0.5 14.8284 1.17157 15.5 2 15.5V12.5ZM18 15.5C18.8284 15.5 19.5 14.8284 19.5 14C19.5 13.1716 18.8284 12.5 18 12.5V15.5ZM2 3.5H18V0.5H2V3.5ZM2 9.5H18V6.5H2V9.5ZM2 15.5H18V12.5H2V15.5Z" fill="#fff"/></svg>}
                text = {`${showFilters === true ? 'Show Partners' : 'Filters'}`}
                onClick={() => {setShowFilters(!showFilters);ScrollTo('.gallery-sec',100)}}
                classNames={`${showFilters === true ? 'active' : ''} filter-toggle-button marg-top-20`}
                other={(chosenProcedures.length + chosenAges.length + chosenGender.length + (chosenLocation !== '' ? 1 : 0 ) + (chosenDistance !== '' ? 1 : 0 )) > 0 &&
                    (<span className={`filter-count ${showFilters === true ? '' : 'active'}`}><p>{chosenProcedures.length + chosenAges.length + chosenGender.length + (chosenLocation !== '' ? 1 : 0 ) + (chosenDistance !== '' ? 1 : 0 )}</p></span>)
                }
              />
            </div>
          </div>
        </div>
      }
      <div className="container gallery-sec">
          {
            isLoading && (
              <LoadingScreen />
            )
          }
          {
            !isLoading && (
              <>
                {
                  partnersForGalleryToShowInRows.map((row, rowIndex) => {

                    return (
                      <div className="container">
                        <div className="row h-100">
                        {
                          row.map((partner, partnerIndex) => {
                            const actionContent = generateActionContent(partner);
                            return (
                              <div key={partnerIndex} style={{paddingBottom: '150px'}} className="col-sm-4 ppcol align-items-center marg-bottom-10">
                                <PatientPartnerColumnForGallery
                                  index={partnerIndex}
                                  partner={partner}
                                  actionContent={actionContent}
                                  rowIndex={(partnerIndex + 1)}
                                  showSurgerySpecialty
                                  showTitle
                                  showAge
                                  key={partnerIndex}
                                />
                              </div>
                            )
                          })
                        }
                        </div>
                      </div>
                    )
                  })
                }

              <div className="row h-100">
                {partnersForGallery.length == 0 &&
                  <div className="marg-top-40 marg-center ">
                    <h6 className="marg-bottom-20 text-center">No results found for those filters</h6>
                    <Link style={{width: 'max-content'}} to={'/get-started/patient-partner-match'} className="marg-center"><button data-gtm-click-label="Patient partner gallery No Partners Find a Patient Partner" className="liner text-center">Find a mentor for me</button></Link>
                  </div>
                }
              </div>
              {pages.length > 1 &&
                <div className='container'>
                  <div className='row'>
                    <div className='col'>
                      <div className="pagination-holder">
                        <button style={{ opacity: lowIndex === 0 ? '0': '1'}} disabled={`${lowIndex === 0 ? 'disabled' : ''}`} className="pagination-navigation prev" onClick={() => {setLowIndex(lowIndex - 9);setHighIndex(highIndex - 9);setSelectedPage(selectedPage - 1);ScrollTo('.gallery-sec',100)}}>
                          <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 2L2 9.5L9.5 17" stroke="#173959" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </button>
                          <div className='pages'>
                            {selectedPage > 1 &&
                              <button className={`pagination-number`} onClick={() => {setLowIndex(0);setHighIndex(8);setSelectedPage(0);ScrollTo('.gallery-sec',100)}}>1</button>
                            }
                            {selectedPage > 2 &&
                              <p>...</p>
                            }
                            {selectedPage > 0 &&
                              <button className={`pagination-number`} onClick={() => {setLowIndex(lowIndex - 9);setHighIndex(highIndex - 9);setSelectedPage(selectedPage - 1);ScrollTo('.gallery-sec',100)}}>{selectedPage}</button>
                            }
                            <button className={`pagination-number active`} onClick={() => {setLowIndex(selectedPage * 9);setHighIndex(((selectedPage + 1) * 9) - 1);setSelectedPage(selectedPage);ScrollTo('.gallery-sec',100)}}>{selectedPage + 1}</button>
                            {selectedPage < (pages.length - 1) &&
                              <button className={`pagination-number`} onClick={() => {setLowIndex(lowIndex + 9);setHighIndex(highIndex + 9);setSelectedPage(selectedPage + 1);ScrollTo('.gallery-sec',100)}}>{selectedPage + 2}</button>
                            }
                            {selectedPage < (pages.length - 3) &&
                              <p>...</p>
                            }
                            {selectedPage < (pages.length - 2) &&
                              <button className={`pagination-number`} onClick={() => {setLowIndex((pages.length - 1) * 9);setHighIndex(pages.length * 9);setSelectedPage(pages.length - 1);ScrollTo('.gallery-sec',100)}}>{pages.length}</button>
                            }
                          </div>
                        <button data-gtm-click-label="Patient partner gallery Pagination" style={{ opacity: highIndex > partnersForGallery.length ? '0': '1'}} disabled={`${highIndex < partnersForGallery.length ? '' : 'disabled'}`} className="pagination-navigation next" onClick={() => {setLowIndex(lowIndex + 9);setHighIndex(highIndex + 9);setSelectedPage(selectedPage + 1);ScrollTo('.gallery-sec',100)}}>
                          <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-gtm-click-label="Patient partner gallery Pagination" d="M2.22852 2L9.72852 9.5L2.22852 17" stroke="#173959" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/></svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
            )
          }
          {
            showModal && (
              <Modal
                id="oncehub-modal"
                content={(
                  <Oncehub
                    divid={selectedPatientPartner.oncehub_divid}
                    page={selectedPatientPartner.oncehub_page}
                    onScheduled={onScheduled}
                    params={{
                      "Requested PatientPartner": selectedPatientPartner.name
                    }}
                  />
                )}
                onClose={() => {
                  setShowModal(false)
                }}
              />
            )
          }
          {
            showSignupModal && (
              <Modal
                id="signup-modal"
                content={(
                  <SignUp
                    onSuccess={() => {
                      history.push('/member/home');
                    }}
                    params={{ origin: 'Patient Partner Gallery', patient_partner_id: selectedPatientPartner.id }}
                  />
                )}
                onClose={() => {
                  setShowSignupModal(false)
                }}
              />
            )
          }
      </div>
      <CustomCtaFooter
        headline="Looking for the most relatable PatientPartner&trade;?"
        para="Take a short questionnaire and we’ll provide you with the most relatable PatientPartner™ recommendations"
        buttontxt="Get Started"
        link="/member/join/support"
        gtm={'Patient partner gallery'}
      />
      {/* <ScrollToTopButton gtmClickLabelPage={'Partners'} /> */}
    </div>
  )
}
