import React, { useState } from 'react'
import Rating from '../../ui_kit/inputs/rating'

export default function HowAreYouFeeling({
  onSubmit,
  onBack,
  answers,
  gtm,
  numberRating = false,
  title = "Share how you're currently feeling about the procedure.",
  para = "We'll let your mentor know how you're currently feeling.",
  star = false,
  keyForAnswer = 'currentlyFeeling',
  textLabels=['Very disappointing', 'Disappointing', 'Somewhat helpful', 'Helpful', 'Very helpful']
}) {

  const icons = [
    {
      icon: <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z" fill="black" /><path d="M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z" fill="black" /><path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10 12C7.67 12 5.68 13.45 4.88 15.5H6.55C7.24 14.31 8.52 13.5 10 13.5C11.48 13.5 12.75 14.31 13.45 15.5H15.12C14.32 13.45 12.33 12 10 12V12Z" fill="black" /></svg>,
      text: textLabels[0],
      number: 1
    },
    {
      icon: <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z" fill="black" /><path d="M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z" fill="black" /><path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10 14.5C10.73 14.5 11.39 14.69 11.97 15.03C12.09 14.89 12.83 14.05 12.98 13.89C12.13 13.33 11.11 13 10 13C8.89 13 7.87 13.33 7.01 13.88C7.98 14.97 7.02 13.9 8.02 15.02C8.61 14.69 9.27 14.5 10 14.5Z" fill="black" /></svg>,
      text: textLabels[1],
      number: 2

    },
    {
      icon: <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z" fill="black" /><path d="M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z" fill="black" /><path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10 14C9.27 14 8.62 13.82 8.04 13.48C7.92 13.62 7.18 14.46 7.03 14.63C7.89 15.18 8.9 15.5 10 15.5C11.11 15.5 12.12 15.17 12.98 14.62C12.01 13.53 12.97 14.6 11.97 13.47C11.38 13.82 10.73 14 10 14Z" fill="black" /></svg>,
      text: textLabels[2],
      number: 3
    },
    {
      icon: <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z" fill="black" /><path d="M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z" fill="black" /><path d="M10 14C8.52 14 7.25 13.19 6.55 12H4.88C5.68 14.05 7.67 15.5 10 15.5C12.33 15.5 14.32 14.05 15.12 12H13.45C12.76 13.19 11.48 14 10 14ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill="black" /></svg>,
      text: textLabels[3],
      number: 4
    },
    {
      icon: <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z" fill="black" /><path d="M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z" fill="black" /><path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 12C5.78 14.34 7.72 16 10 16C12.28 16 14.22 14.34 15 12H5Z" fill="black" /></svg>,
      text: textLabels[4],
      number: 5
    }
  ];

  const [selected, setSelected] = useState(icons[answers[keyForAnswer] - 1]);
  const submit = () => {
    onSubmit({...answers, [keyForAnswer]: selected.number});
  };

  return (
    <div className={`row activity-question`}>
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <h5 className="question">
          {title}
        </h5>
        <p className="question">
          {para}
        </p>
        <Rating
          selected={selected}
          setSelected={setSelected}
          wrapperStyles={{margin: '30px 0px'}}
          icons={icons}
          star={star}
        />
        <div className="container-fluid de-pad">
          <div className="row button-holder de-pad">
            {onBack &&
              <button className="back col-lg-6 col" type="button" onClick={onBack} data-gtm-click-label={`${gtm} - Back`}>Back</button>
            }
            <button disabled={`${selected == null ? 'disabled' : ''}`} className={`gtm-preexisting-conditions submit ${onBack ? 'col-lg-6' : 'col'} col`} type="submit" onClick={(e) => submit(e)} data-gtm-click-label={`${gtm} - Next`}>Next</button>
          </div>
        </div>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  )
}
