import React, { useState, useContext } from 'react'
import SimpleAvatar from "../../ui_kit/utilities/simple_avatar";
import RequestButton from '../../ui_kit/utilities/request_button';
import Context from '../../../utils/context';
import styles from './index.module.css';
import SendMessageButton from '../../ui_kit/utilities/send_message_button';

const ProfileTooltipWrapper = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const context = useContext(Context);
  const auth = context.auth;

  const onMouseEnter = () => {
    setShowTooltip(true);
  };

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-flex' }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {props.children}
      {
        showTooltip && (
          <div className={`${styles.info_wrap}`}>
            <div style={{ width: '100%' }}>
              <SimpleAvatar showInfo={true} profile={props.profile} showTypeIcon={true} userSlug={props.userSlug} />
            </div>
            <div className={`row ${styles.treatment_row}`}>
              <div className={`col ${styles.treatment_col}`}>
                {props.profile.surgeries_or_treatments && props.profile.surgeries_or_treatments.map((treatment, index) =>
                  <p key={`profile-tooltip-wrapper-${index}`} className={`${styles.treatment}`}>{treatment}</p>
                )}
              </div>
            </div>
            <div className={`row ${styles.treatment_row}`}>
              <div className={`col ${styles.treatment_col}`}>
                <SendMessageButton otherProfile={props.profile} origin={`Profile Tooltip`}/>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ProfileTooltipWrapper;
