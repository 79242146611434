export const updateAnswersAction = (item) => {
  return {
    type: 'UPDATE_ANSWERS',
    item
  }
}

export const setQuestionnaireStateAction = (item) => {
  return {
    type: 'SET_QUESTIONNAIRE_STATE',
    item
  }
}

export const setGlobalContactInfoAction = (item) => {
  return {
    type: 'SET_GLOBAL_CONTACT_INFO',
    item
  }
}

export const clearGlobalContactInfoAction = () => {
  return {
    type: 'CLEAR_GLOBAL_CONTACT_INFO',
  }
}

export const setContactIdAction = (item) => {
  return {
    type: 'SET_CONTACT_ID',
    item
  }
}

export const setPatientPartnerQuestionnaireProgress = (item) => {
  return {
    type: 'SET_PATIENT_PARTNER_QUESTIONNAIRE_PROGRESS',
    item
  }
}

export const setSurgeonMatchQuestionnaireProgress = (item) => {
  return {
    type: 'SET_SURGEON_MATCH_QUESTIONNAIRE_PROGRESS',
    item,
  }
}

export const setPatientPartners = (item) => {
  return {
    type: 'SET_PATIENT_PARTNERS',
    item
  }
}

export const setSurgeon = (item) => {
  return {
    type: 'SET_SURGEON',
    item
  }
}
