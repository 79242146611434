import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import { getSurgeon } from '../../../api/surgeons'
import { createDirectSurgeonBookingContacts } from '../../../api/direct_surgeon_booking_contacts'
import { createPatientAssistantInfo } from '../../../api/patient_assistant_infos'
import InputWithOptions from '../../ui_kit/utilities/input_with_options';
import RequestButton from '../../ui_kit/utilities/request_button'
import { successToast } from '../../ui_kit/utilities/success_toast';
import DatePickerWrapper from '../../date_picker_wrapper';
import SignUp from '../../sign_up';
import SuccessPanel from '../../success_panel'
import Todpicker from '../../todpicker';
import AppointmentConfirmationForm from '../../questionnaire/appointment_confirmation_form';
import UserDropdownSelector from '../../users/dropdown_selector';
import Context from '../../../utils/context';
import './index.css'

export default function SurgeonRequestAppointment({
}) {
  const { id } = useParams();
  const [view, setView] = useState('loading');
  const [surgeon, setSurgeon] = useState();
  const [surgery, setSurgery] = useState('');
  const [insuranceCarrier, setInsuranceCarrier] = useState('');
  const [apptDate, setApptDate] = useState('');
  const [apptTimeOfDay, setApptTimeOfDay] = useState('Preferred appt. time');
  const insurances = [...gon.all_insurances];
  const [userForReferral, setUserForReferral] = useState();
  const [extraInfo, setExtraInfo] = useState();
  const context = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    getSurgeon(id, (data) => {
      setSurgeon(data);
      setView('surgery-info');
      setExtraInfo(`Hello, I'm interested in a consultation with ${data.name}`);
    })
  }, []);

  const getNextWork = (d) => {
    d.setDate(d.getDate() + 1); // tomorrow
    if (d.getDay() == 0) d.setDate(d.getDate() + 1);
    else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
    return d;
  }

  const minDate = getNextWork(getNextWork(new Date()));

  const isWeekday = date => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  let surgeryInfoSubmitDisabled = 'disabled';
  var checkedInsuranceCarrier = insuranceCarrier !== '';
  var checkedApptDate = apptDate !== '' && apptDate !== null;
  var checkedApptTimeOfDay = apptTimeOfDay !== 'Preferred appt. time';
  var checkedSurgery = surgery !== '';
  var checkedExtraInfo = extraInfo !== '';

  var isHologic = surgeon && surgeon.is_hologic;

  if (checkedInsuranceCarrier &&
    (checkedApptDate && checkedApptTimeOfDay || checkedExtraInfo || isHologic) &&
    checkedSurgery) {
    surgeryInfoSubmitDisabled = '';
  }

  const createDirectBookingContactFunc = (ad, at, ei, callback) => {
    createDirectSurgeonBookingContacts(
      surgeon,
      context.auth.getCurrentUser().name,
      context.auth.getCurrentUser().email,
      context.auth.getCurrentUser().phone,
      '',
      insuranceCarrier,
      ad,
      at,
      ei,
      'Request Appointment Page',
      surgery,
      callback,
    )
  };

  return (
    <div className="surgeon-request-appointment questionnaire-wrapper text-left">
      {
        view === 'loading' && <>Loading</>
      }
      {
        view === 'surgery-info' &&
        <div className="container">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <form className="surgeon-appt-signup-form surgeon-request-appt-sa pad-30">
                <h5 className="bold marg-bottom-10">Request an appointment with {surgeon.name}</h5>
                <p>Select your preferred appointment date and time of day</p>
                {
                  context.auth.isLoggedIn() && context.auth.getCurrentUser().current_profile_type === 'SuperUser' &&
                  <UserDropdownSelector
                    user={userForReferral}
                    setUser={setUserForReferral}
                    light={false}
                    placeholder={'Find a user'}
                  />
                }
                <InputWithOptions
                  value={surgery}
                  setValue={setSurgery}
                  options={surgeon.surgeries_or_treatments}
                  name={'insuranceCarrier'}
                  label={'Surgery'}
                  noMatchTxt={''}
                  light={false}
                  style={{zIndex: '4'}}
                />
                <InputWithOptions
                  value={insuranceCarrier}
                  setValue={setInsuranceCarrier}
                  options={insurances}
                  name={'insuranceCarrier'}
                  label={'Insurance Provider'}
                  noMatchTxt={''}
                  light={false}
                  style={{zIndex: '4'}}
                />
                {
                  !isHologic &&
                  <DatePickerWrapper
                    selected={apptDate}
                    onChange={date => setApptDate(date)}
                    minDate={minDate}
                    filterDate={isWeekday}
                    wrapperClassName={'react-datepicker-wrapper material-input'}
                    placeholderText={'Preferred appt. date'}
                  />
                }
                {
                  !isHologic &&
                  <Todpicker
                    value={apptTimeOfDay}
                    onChange={(event) => { setApptTimeOfDay(event.target.value); }}
                    light={false}
                  />
                }
                <p className='marg-top-20'>What else would you like your surgeon to know?</p>
                <textarea className='material-input marg-bottom-20 marg-top-10' placeholder="Tell us here..." value={extraInfo} onChange={(e) => setExtraInfo(e.target.value)}></textarea>
                <RequestButton
                  disabled={surgeryInfoSubmitDisabled}
                  request={(afterAction) => {
                    if (context.auth.isLoggedIn()) {
                      if (userForReferral) {
                        createPatientAssistantInfo({
                            surgeon_administrative_assistant_id: surgeon.surgeon_administrative_assistant_id,
                            requested_appt_date: apptDate,
                            requested_appt_time_of_date: apptTimeOfDay,
                            extra_info: extraInfo,
                            insurance_carrier: insuranceCarrier,
                            user_id: userForReferral.id,
                            create_initial_appointment_request_message: true,
                          },
                          (patientAssistantInfo) => {
                            successToast('The referral has been created');
                            afterAction();
                            history.push(`/surgeon/${surgeon.slug}`);
                          }
                        )
                      }
                      else {
                        afterAction();
                        if (isHologic) {
                          createDirectBookingContactFunc(apptDate, apptTimeOfDay, extraInfo, () => {
                            setView('success');
                          })
                        }
                        else {
                          setView('review-and-submit');
                        }
                      }
                    }
                    else {
                      afterAction();
                      setView('create-your-account');
                    }
                  }}
                  cta={userForReferral ? "Submit" : "Next"}
                  classnames={`marg-center marg-top-10 submit`}
                  gtm={`Surgeon Booking Page Surgery Info Submit`}
                />
              </form>
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
      }
      {
        view === 'create-your-account' &&
        <div className="container marg-bottom-20">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8 marg-bottom-20">
              <div className="surgeon-request-appt-sa pad-10">
                <SignUp
                  onSuccess={() => {
                    setView('review-and-submit');
                  }}
                  params={{ origin: 'Surgeon Request Appointment' }}
                  headline={`Create your account`}
                  para={`Submit your request and communicate with the office in your private and secure account.`}
                  embedded={true}
                  alignLeft={true}
                  light={false}
                  skipPassword
                />
              </div>
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
      }
      {
        view === 'review-and-submit' &&
        <div className="surgeon-appt-signup-form">
          <AppointmentConfirmationForm
            surgeons={surgeon}
            answers={{ apptDate, apptTimeOfDay, extraInfo }}
            onSubmit={(newAnswers) => {
              createDirectBookingContactFunc(newAnswers.apptDate, newAnswers.apptTimeOfDay, newAnswers.extraInfo, (data) => {
                history.push(`/member/conversations/${data.conversation_id}`)
              });
            }}
            datePlaceholder={'Preferred apt. date'}
            timePlaceholder={'Preferred apt. time'}
            gtm={'Surgeon Request Appointment'}
            light={false}
            wrapperClassName={'surgeon-request-appt-sa pad-30'}
          />
        </div>
      }
      {
        view === 'success' &&
        <div className="surgeon-success-form">
          <div className="container">
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className='container '>
                  <div className='row'>
                    <div className="col">
                      <SuccessPanel
                        title="Thank you!"
                        content={`${surgeon.name}'s office will call you in 24-48 hours to confirm your insurance plan coverage and appointment. In the meantime, you can learn more about ${surgeon.name} and about speaking to one of their past patients by follow the link below.`}
                        cta={<Link to={`/member/home`}><button data-gtm-click-label={`Explore the community`}  style={{width: '100%'}}>Explore the community</button></Link>}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
