import React from 'react'
import Divider from '../../../../ui_kit/utilities/divider'
import parent_styles from '../../../index.module.css'
import styles from './index.module.css'
import SurgeonLocation from './panels/location'

export default function Practice({
  surgeon
}) {


  return (
    <div style={{ marginTop: '20px' }}>
      {surgeon.extra && surgeon.extra.practice_general_info && surgeon.extra.practice_general_info !== null &&
        <>
          <p className={parent_styles.profile_section_headline}>General info</p>
          <div style={{ display: 'flex' }} className='content_container'>
            <div className='row'>
              <div className='col'>
                <p className={styles.bio_p}>{surgeon.extra.practice_general_info}</p>
              </div>
            </div>
          </div>
        </>
      }
      <div style={{ display: 'flex' }} className='content_container container'>
        <div style={{ width: '100%' }} className='row'>
          <div className='col-12 col-md-6'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ marginRight: '10px' }} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/surgeons/license.svg' />
              <p className={styles.det_p}>No license suspensions</p>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className={styles.marg_top} style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ marginRight: '10px' }} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/surgeons/flag.svg' />
              <p className={styles.det_p}>No malpractice suits</p>
            </div>
          </div>
        </div>
      </div>
      {surgeon.office_locations && surgeon.office_locations !== null && surgeon.office_locations !== 0 &&
        <SurgeonLocation surgeon={surgeon} />
      }
      {surgeon.extra.hospital_affiliations && surgeon.extra.hospital_affiliations !== null && surgeon.extra.hospital_affiliations.length > 0 &&
        <>
          <p className={parent_styles.profile_section_headline}>Affiliated hospitals</p>
          <div style={{ display: 'flex', paddingTop: '0px' }} className='content_container'>
            <div className='row'>
              <div className='col'>
                {surgeon.extra.hospital_affiliations.map((h, i) =>
                  <>
                    {i !== 0 && <Divider />}
                    <p className={styles.bio_p} style={{ marginTop: '20px' }}>{h}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}