import React from "react";
import "./we_give_back.css";
import CustomCtaFooter from "../custom_cta_footer";
import WeGiveBackHeader from "../we_give_back_header";
import VideoType2 from "../video_sections/video_type_2";
import styles from "../for_providers/for_providers.module.css";

export default function WeGiveBack({}) {
  return (
    <div className="we-give-back">
      <WeGiveBackHeader
        headline={"Giving back is our top priority"}
      />
      <div style={{ 'paddingBottom': '50px','overflow':'hidden' }} className='container we-give-back-video'>

      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <h2 style={{ color: "#314D69" }} className="vidheadline">
            Why giving back is important to us
          </h2>
          <div style={{ position: "relative" }}>
            <svg
              className="svg1"
              width="57"
              height="113"
              viewBox="0 0 57 113"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.5 113C41.5153 113 27.1443 107.047 16.5485 96.4515C5.95266 85.8557 1.98376e-06 71.4847 6.73756e-07 56.5C-6.36251e-07 41.5153 5.95266 27.1443 16.5485 16.5485C27.1443 5.95267 41.5153 3.11508e-06 56.5 6.73757e-07L56.5 56.5L56.5 113Z"
                fill="#314D69"
              />
            </svg>

            <VideoType2
              bg="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/we-give-back.png"
              videoLink="https://www.youtube.com/watch?v=yWA6CBYCs7w"
              gtm="Giving Back Video Watch"
              height = '390px'
            />
            <svg
              className="svg2"
              width="259"
              height="208"
              viewBox="0 0 259 208"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M239.812 0.326238C248.879 16.0304 254.764 33.3667 257.131 51.3452C259.498 69.3237 258.3 87.5923 253.607 105.108C248.914 122.624 240.816 139.044 229.777 153.43C218.738 167.816 204.974 179.888 189.269 188.954C173.565 198.021 156.229 203.906 138.251 206.273C120.272 208.64 102.003 207.442 84.4876 202.749C66.9719 198.056 50.5521 189.958 36.1657 178.919C21.7793 167.88 9.70813 154.116 0.641313 138.412L120.227 69.3689L239.812 0.326238Z"
                fill="#59B6BE"
              />
            </svg>
            <img
              className="svg3"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/svgdots.svg"
            />
          </div>
        </div>
      </div>
      </div>
      <div className="container marg-top-100">
        <div className="row marg-top-100">
          <div className="col">
            <h2 className="marg-bottom-30">Our circle of giving:</h2>
            <p>
              Our PatientPartner Mentors volunteer to help patients like you
              because they remember what it was like to navigate the healthcare
              journey before their treatment—the questions, the uncertainty, the
              stress of making a decision and finding the right doctor.
              <br></br>
              <br></br>
              Now that they are on the other side of their procedure, they are
              passionate about sharing their experience so others can learn what
              it’s really like and feel more prepared.
              <br></br>
              <br></br>
              In turn, we support our PatientPartner Mentors by donating to a
              charity of their choice for every PatientPartner conversation they
              have with patients like you.
              <br></br>
              <br></br>
              This is our Circle of Giving, and you become part of it when you
              join our community and speak to a PatientPartner Mentor.
            </p>
          </div>
        </div>
        <div className="row marg-top-60">
          <div className="col-md-4">
            <div className="step-wrap one">
              <h5 className="step-txt">
                You talk to your PatientPartner&trade; Mentor
              </h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="step-wrap two">
              <h5 className="step-txt">
                A PatientPartner&trade; Mentor happily donates their time to you
              </h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="step-wrap three">
              <h5 className="step-txt">
                We donate to charity - it’s that simple.
              </h5>
            </div>
          </div>
        </div>
        <div className="row marg-top-100">
          <div className="col">
            <h5 className="text-center">
              We’ve been able to donate to these organizations and many more:
            </h5>
          </div>
        </div>
        <div className="row marg-top-100">
          <div className="col-md-4">
            <img
              className="org-photo"
              alt="als"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ALS+Logo.png"
            />
          </div>
          <div className="col-md-4">
            <img
              className="org-photo"
              alt="ASPCA"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ASPCA-logo.png"
            />
          </div>
          <div className="col-md-4">
            <img
              className="org-photo"
              alt="St-Jude-Logo"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/St.-Jude-Logo-500x300.png"
            />
          </div>
        </div>
        <div className="row marg-top-100 stats">
          <div className="col-md-4">
            <p className="number-stat">100+</p>
            <p className="txt-stat">
              PatientPartner&trade; volunteers wanting to talk to you
            </p>
          </div>
          <div className="col-md-4">
            <p className="number-stat">320+</p>
            <p className="txt-stat">Patients we have helped</p>
          </div>
          <div className="col-md-4">
            <p className="number-stat">10+</p>
            <p className="txt-stat">Supported health conditions</p>
          </div>
        </div>
      </div>
      <CustomCtaFooter
        headline="Give back by becoming a PatientPartner Mentor"
        para="Your story can help someone else who is considering the same procedure or treatment plan you experienced."
        buttontxt="Become a mentor"
        link="/for-mentors"
        targetBlank
        gtm={"About"}
      />
    </div>
  );
}
