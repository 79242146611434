import React from 'react'
import './webinar_popup.css'

export default function WebinarPopup({
  headline,
  smallHeadline,
  subheadline,
  time,
  para,
  actionContent,
  image,
  showLogo = true,
  wrapperClassnames = '',
  leftClassnames = '',
  middleContent,
  imageLink,
  showRightSide = true,
  headerClassnames = ''
}) {

  return (
  <div className={`webinar-popup ${wrapperClassnames}`}>
    <div className={`left-side ${leftClassnames}`}>
      {showLogo && <img height="50px" className="popup-logo marg-bottom-20" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/PatientPartner+Logo_Teal.png"/>}
      {headline && <h1 className={`webinar-headline marg-bottom-10 ${headerClassnames}`}>{headline}</h1>}
      {smallHeadline && <h3 className="small-headline marg-bottom-20">{smallHeadline}</h3>}
      {subheadline && <h5 className="subheadline marg-bottom-10">{subheadline}</h5>}
      {middleContent}
      {time && <p className="bold marg-bottom-20">{time}</p>}
      {para && <p className="paragraph marg-bottom-30">{para}</p>}
      {actionContent}
    </div>
    {
      showRightSide &&
      <div className="right-side photo-col">
        {imageLink ?
          <a href={imageLink} target="_blank">
            <div className="webinar-photo" style={{backgroundImage: `url('${image}')`}}></div>
            <svg className="modal-icon-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.05 163.45"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="#74ccd3" class="cls-1" d="M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z"></path></g></g></svg>
          </a>
        :
          <>
            <div className="webinar-photo" style={{backgroundImage: `url('${image}')`}}></div>
            <svg className="modal-icon-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.05 163.45"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path fill="#74ccd3" class="cls-1" d="M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z"></path></g></g></svg>
          </>
        }
      </div>
    }
  </div>
  )
}
