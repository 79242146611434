import React, { useState, useEffect } from 'react'

export default function DirectConnection({
    answers,
    title,
    subtitle,
    onSubmit,
    gtm,
}) {
  const [disabled, setDisabled] = useState('disabled');
  const [error, setError] = useState('');
  const options = ['Communication Preference', 'Phone call', 'Text message', 'Email'];
  const [communicationPreference, setCommunicationPreference] = useState(answers.communicationPreference || options[0]);
  const [conversationTopics, setConversationTopics] = useState(answers.extraInfo || '')

  return (
      <div>
          <div className="date-time-smallform">
              <h5 className="text-left">{title}</h5>
              {subtitle &&
                <p className="text-left marg-top-10">{subtitle}</p>
              }
              <div className={`marg-top-30 material-input tod-picker communication-preference`}>
                <label className={`${communicationPreference !== options[0] ? 'in-final-position' : ''}`}>{options[0]}</label>
                <select data-gtm-click-label={`${gtm} Direct Connection Dropdown`} className={`${communicationPreference === options[0] ? 'greyed-out' : ''}`} value={communicationPreference} onChange={(e) => setCommunicationPreference(e.target.value)}>
                    {options.map((option, index) =>
                        <option data-gtm-click-label={`${gtm} Direct Connection Dropdown Choice: ${option}`} selected={option === options[0] ? 'selected' : ''} disabled={index === 0 ? 'disabled' : ''} key={index}>{option}</option>
                    )}
                </select>
              </div>
              <div className="text-box-wrap marg-bottom-20">
                <textarea className="material-input" placeholder="Introduce yourself and share what you’d like to talk about... (e.g What was the recovery process like?)" value={conversationTopics} onChange={(e) => setConversationTopics(e.target.value)}></textarea>
              </div>
              {error &&
                  <ErrorMessage
                      error={error}
                  />
              }
              <button data-gtm-click-label={`${gtm} Direct Connection Submit`} disabled={communicationPreference !== options[0] ? '' : 'disabled'} onClick={() => onSubmit({ ...answers, communicationPreference, extraInfo: conversationTopics })}>Request a connection</button>
          </div>
      </div>
  );
}
