import React, { useContext } from 'react'
import { Link, Redirect } from 'react-router-dom';
import Context from '../../utils/context';
import './we_give_back_header.css'


export default function WeGiveBackHeader({
    para1 = 'We Give Back',
    headline = "This is a big headline",
    bgColor = "#F1FEFF",
    wrapperPadding,
}) {
    const context = useContext(Context);
    const viewport = context.viewport;
    const isMobile = viewport.getIsMobile();

    return (
        <div style={{backgroundColor: bgColor, paddingTop: `${isMobile ? '' : wrapperPadding }`, paddingBottom: wrapperPadding}} className={`container-fluid marg-bottom-0`}>
            <div className="container">
                <div className="semibg"></div>
                <div className="row justify-content-center h-100">
                  <div className="col">
                    <p className="text-center sm-header">{para1}</p>
                  </div>
                </div>
                <div className="row justify-content-center text-center h-100">
                  <div className="col">
                    <h1 className='title'>{headline}</h1>
                  </div>
                </div>
            </div>
        </div>
    )
}
