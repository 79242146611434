export const createPostUserReport = (
  post_id,
  reason,
  successFunction
) => {
  
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch(`/api/v1/posts/${post_id}/post_user_reports`,
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        post_id: post_id,
        reason: reason,
      })
    }
  )
  .then(response => response.json())
  .then(data => {
    if (successFunction) {
      successFunction(data);
    }
  })
}
