import React, { useState, useEffect, useContext } from 'react'
import Context from '../../../utils/context';
import TextLeftImgRightWbg from '../../hero_sections/txt_left_img_right_wbg'
import ImgLeftTextRight from '../../hero_sections/img_left_text_right'
import HowItWorks from '../../hero_sections/how_it_works';
import styles from './index.module.css'
import LoadingScreen from '../../loading_screen';
import { getSurgeon } from '../../../api/surgeons'
import OssioQuestionnaire from '../../questionnaire/ossio';
import TwoTestimonials from '../../two_testimonials';

export default function MentorMatchOssio({
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();
  const [view, setView] = useState('loading');
  const [surgeon, setSurgeon] = useState();

  const scrollToSignIn = () => {
    document.querySelector('.sign-in').scrollIntoView({
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    getSurgeon('ossio', (data) => {
      setSurgeon(data);
      setView('loaded');
    })
  }, []);

  return (
    <div>
      <TextLeftImgRightWbg
        headline={<p className={styles.headline}>Connect with your OSSIO<em>fiber</em><sup>®</sup> mentor</p>}
        para={<p className={styles.para}>Chat with a patient who had an OSSIO<em>fiber</em><sup>®</sup> Metal-Free Bunion Correction Procedure to ask them about their experience for free!</p>}
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ossio-hero3.png'}
        actionContent={
          <div className="btn-wrap">
            <button onClick={scrollToSignIn} className={styles.button} data-gtm-click-label="Mentor match OSSIO hero section CTA Get Started">
              Find my mentor
            </button>
          </div>
        }
        imgOffset={false}
        topOfIcon={isMobile ? '65%' : '75%'}
        leftOfIcon={isMobile ? '10%' : '35%'}
        heightOfImage={isMobile ? '230px' : '400px'}
        bgColor="#DFF1D74D"
        iconContent=""
        imgShadow={false}
        thoseDots={''}
        mobileBackgroundSize='contain'
        afterActionContent={<p className={styles.after_action_content}><em>*Get matched with a mentor in under a minute.</em></p>}
      />
      <div style={{backgroundColor: '#D7D2C54D', paddingTop: '35px', paddingBottom: '35px', borderRadius: '20px' }} className={styles.container_fluid}>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <p className={styles.so_headline} style={{fontSize: '25px', textAlign: 'center', letterSpacing: '0.01em', color: '#48433D'}}>
                PatientPartner is a complimentary, private, and easy-to-use tool that allows you to ask questions and get more insight about OSSIO<em>fiber</em><sup>®</sup> procedure from your mentor.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ImgLeftTextRight
        img={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ossio-couple.png'}
        headline={"Feel empowered and confident in your decisions!"}
        subheadline={<p className={styles.subheadline}>Things you can ask your mentor privately:</p>}
        list={[]}
        containerClassNames={isMobile ? 'marg-top-100' : "marg-top-100 marg-bottom-100 h-100"}
        littlePara={
          <ul>
            <li>How was your recovery?</li>
            <li>Can you feel the OSSIO<em>fiber</em><sup>®</sup> implant at all? Does it bother you?</li>
            <li>Any tips for preparing for surgery?</li>
            <li>Did you have shoe limitations?</li>
            <li>What were your symptoms before, and what are they now?</li>
          </ul>
        }
        cta={
          <button
            data-gtm-click-label="Mentor Match OSSIO Connecting you to real patients Get connected"
            className={styles.button}
            onClick={scrollToSignIn}
          >
            Find my mentor
          </button>
        }
        blueBg={false}
      />

      <div className={styles.how_it_works}>
        <HowItWorks
          mainHeadline={"How it works"}
          smallHeadlineStyle={{fontFamily: 'Utopia Std', fontSize: '53px', fontWeight: 'bold', color: '#48433D', lineHeight: '1'}}
          steps={[
            {
              image: (
                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ossio-clipboard.png" />
              ),
              headline: 'Tell us about yourself',
              para: (<p className={styles.para}> Take our quiz to find a mentor who had a Metal-Free OSSIO<em>fiber</em><sup>®</sup> Bunion Correction Procedure, ensuring the perfect match for you!</p>),
            },
            {
              image: (
                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ossio-conversation.png" />
              ),
              headline: 'Message your mentor',
              para: "Virtually meet the mentor you've been matched with and send them a message to introduce yourself and ask questions."
            },
            {
              image: (
                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ossio-advice(1).png" />
              ),
              headline: 'Get support and advice',
              para: `Ask questions about personal experiences and advice your doctor might not be able to provide you.`,
            }
          ]}
          paraColor={'#585854'}
        />
      </div>
      <TwoTestimonials
        smallHeadline={<p style={{color: '#FFFFFF', fontWeight: "700"}}>Real patients, real stories.</p>}
        headline={<p style={{color: "#FFFFFF", fontWeight: "700", fontFamily: "Utopia STD", fontSize: "45px"}}>Hear what others are saying.</p>}
        bg="#63BC47"
        doNotAnimate={true}
        testimonials={
          [
            {
                text: `I was scared to go through with my procedure for years.
                       By talking to someone who's been through it already, I was able to
                       get over my fears of the unknown. PatientPartner made the
                       process easy and took away any hesitation I had by chatting with someone like me.`,
                image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ossio-rose.png',
                name: 'Frances',
                city: 'Long Beach, CA'
            },
            {
                text: `The doctors can't answer all of your questions because they haven't been through it.
                       My mentor was exactly what I needed to make educated decisions and move forward
                       through the process with confidence. This is an indispensable resource you should use!`,
                image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/ossio-rana.png',
                name: 'Rana',
                city: 'Las Vegas, NV'
            },
          ]
        }
      />
      {
        view === 'loading' &&
        <LoadingScreen />
      }
      {
        view === 'loaded' &&
        <OssioQuestionnaire/>
      }

    </div>
  )
}
