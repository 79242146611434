import React from 'react'
import styles from './index.module.css'

export default function PhotoWBlueBg({
  img,
  blueBg = true,
  bgCover = false,
}) {
  return (
    <div className={`${styles.photo_front} ${blueBg ? styles.blue_bg : ''} ${bgCover ? styles.bg_cover : styles.bg_contain }`} alt="patient-partner" style={{backgroundImage: `url(${img})`}}></div>
  )
}
