import React from 'react'
import WebinarView from '.'


export default function({
}) {
 
  return (
    <div className="webinar-view">
      <WebinarView
        video={'https://youtube.com/embed/8428TKn5KlY'}
      />
    </div>
  )
}
