import React, { Component, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import SuccessPanel from '../success_panel'
import LoadingScreen from '../loading_screen'
import ErrorMessage from '../ui_kit/utilities/error_message'
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms'
import { createSurgeonMatchContact } from '../../api/surgeon_match_contacts'
import { validatePhone, validateEmail, validateText, validateZipcode, isEmpty } from '../../helpers';
import './index.css'

export default function SurgeonMatchForm({
  SurgeonMatchForm,
  home,
  weightLoss,
  bg = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/surgeon-match.jpg',
  updateAnswersAction,
}) {
  let fromQuestionnaire = (new URLSearchParams(window.location.search)).get("questionnaire");
  if (fromQuestionnaire == null){
    fromQuestionnaire = false;
  }

  const [view, setView] = useState('form')
  const [name, setName] = useState(home.answers.name || '');
  const [email, setEmail] = useState(home.answers.email || '')
  const [phone, setPhone] = useState(home.answers.phone || '')
  const [zipcode, setZipCode] = useState(home.answers.zipcode || '')
  const [surgeryType, setSurgeryType] = useState((fromQuestionnaire ? 'Weight Loss ' : null) || home.answers.surgeryType || '')
  const [insuranceCarrier, setInsuranceCarrier] = useState(home.answers.insuranceCarrier || '')
  const [error, setError] = useState('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  let history = useHistory();

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const recaptchaRef = React.createRef();

  const runRecaptcha = async (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  }

  let disabled = 'disabled';
  let questionnaireError = '';
  var checkedEmail = validateEmail(email);
  var checkedName = validateText(name);
  var checkedZipcode = validateZipcode(zipcode);
  var checkedSurgeryType = validateText(surgeryType);

  let checkedPhone = false;
  if (phone !== "") {
    checkedPhone = validatePhone(phone);
  }

  if (checkedName === true && checkedEmail === true && checkedPhone === true && checkedZipcode === true && checkedSurgeryType === true && acceptedTerms){
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  const validate = (event) => {
    event.preventDefault();
    if (checkedEmail === false && email !== '') {
      if(email.indexOf('@') == '-1'){
        setEmail('')
      }
      setError('Please enter a valid email');
    }
    else if (checkedPhone == false && phone !== '') {
      setError('Please enter a valid phone number');
    }
    else if (checkedZipcode == false && zipcode !== '') {
      setError('Please enter a valid Zip Code');
    }
    else if (checkedSurgeryType == false && surgeryType !== '') {
      setError('Please enter a surgery type without special characters');
    }
    else {
      setError('');
    }
  }

  const onClick = (event) => {
    if(hasBeenSubmitted == false){
      setHasBeenSubmitted(true);
      if (checkedEmail === false) {
        setError('Please enter a valid email');
        setHasBeenSubmitted(false);
      }
      else if (checkedPhone == false) {
        setError('Please enter a valid phone number');
        setHasBeenSubmitted(false);
      }
      else if (checkedZipcode == false) {
        setError('Please enter a valid Zip Code');
        setHasBeenSubmitted(false);
      }
      else if (checkedSurgeryType == false) {
        setError('Please enter a surgery type without special characters');
        setHasBeenSubmitted(false);
      }
      else {
        setView('success')
        createSurgeonMatchContact(
          name,
          email,
          phone,
          zipcode,
          surgeryType,
          insuranceCarrier,
          fromQuestionnaire,
          () => {
            setShowSuccess(true)
          }
        )
        setError('');
        updateAnswersAction({
          ...home.answers,
          name,
          email,
          phone,
          zipcode,
        });
      }
    }
  }

  const backToQuestionnaire = () => {
    history.push('/get-started/patient-partner-match');
  }

  return (
    <div className="surgeon-referral">
      <div className="container-fluid surgeon-hero">
        <div className="img-cover"></div>
        <div className="img-bg" style={{backgroundImage: `url('${bg}')`}}>
        </div>
        <div className="container">
          <div className="row h-100">
            <div className="col-sm-6">
              <p className="little-strong marg-bottom-20">
                Free Surgeon Referral
              </p>
              <h1 className="main marg-bottom-20">
                We'll Help You Find The Best Surgeon
              </h1>
              <h2 className="submain">
              </h2>
            </div>
            <div className="col-sm-6 my-auto">
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div id="sign-up" className="col-md-5 move-me-up first-on-mobile">
            <div className="sign-up-otr">
              {
                !showSuccess && (
                  <form id="infoCap">
                    <h5 className="bold">Fill out the form and we'll find the best surgeon in your area:</h5>
                    <div className="material-input">
                      <input
                        autoFocus={isEmpty(home.answers.name)}
                        type="text"
                        name="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        onBlur={validate}
                        required
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Name</label>
                    </div>
                    <div className="material-input">
                      <input
                        autoFocus={!isEmpty(home.answers.name) && isEmpty(home.answers.email)}
                        type="email"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onBlur={validate}
                        required
                      />
                      <span className="bar"></span>
                      <label>Email</label>
                    </div>
                    <div className="material-input">
                      <input
                        autoFocus={!isEmpty(home.answers.name) && !isEmpty(home.answers.email) && isEmpty(home.answers.phone)}
                        type="tel"
                        required
                        name="phone"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        onBlur={validate}
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Phone Number</label>
                    </div>
                    <div className="material-input">
                      <input
                        autoFocus={!isEmpty(home.answers.name) && !isEmpty(home.answers.email) && !isEmpty(home.answers.phone) && isEmpty(home.answers.zipcode)}
                        type="text"
                        name="zipcode"
                        value={zipcode}
                        onChange={(event) => setZipCode(event.target.value)}
                        onBlur={validate}
                        required
                      />
                      <span className="bar"></span>
                      <label>Zip Code</label>
                    </div>
                    <div className="material-input ">
                      <input type="text" name="surgeryType" value={surgeryType} onChange={(event) => setSurgeryType(event.target.value)} onBlur={validate} required/>
                      <span className="bar"></span>
                      <label>Surgery Type</label>
                    </div>
                    <div className="material-input ">
                      <input type="text" name="insuranceCarrier" value={insuranceCarrier} onChange={(event) => setInsuranceCarrier(event.target.value)} onBlur={validate} required/>
                      <span className="bar"></span>
                      <label>Insurance Carrier</label>
                    </div>
                    <AgreeToTerms acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
                    {error &&
                      <ErrorMessage
                        error = {error}
                      />
                    }
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={gon.recaptcha_sitekey}
                      onChange={onClick}
                    />
                    <button disabled={disabled} onClick={runRecaptcha} data-gtm-click-label="Surgeon Match Form Submit" className={`marg-top-10 submit`} type="submit">Submit</button>
                  </form>
                )
              }
              {
                showSuccess &&
                  <div className="success-panel-wrapper marg-top-30 text-center">
                    <SuccessPanel
                      title="Thank you!"
                      content="We'll give you a call within the next 24-48 hours and share more information about some surgeons in your area."
                    />
                    {
                      fromQuestionnaire && (
                        <button onClick={backToQuestionnaire} data-gtm-click-label="Surgeon Referral Form Back to Questionnaire" className={`marg-top-10 maincta`}>Back to your PatientPartners</button>
                      )
                    }
                  </div>
              }
            </div>
          </div>
          <div className="col-md-7 last-on-mobile">
            <div className="content-body">
              <p className="marg-top-20 marg-bottom-20">We know that finding a credible, reliable, and experienced surgeon can be hard. That's why the PatientPartner™ team has vetted and built relationships with the best surgeons for bariatric surgery so you can feel confident knowing you're in good hands.</p>
              <p className="bold marg-bottom-10">How do we vet the surgeons?</p>
              <ul className="marg-bottom-30">
                <li>Number of procedures completed</li>
                <li>The rate of successful outcomes</li>
                <li>Years of experience and training</li>
                <li>Direct feedback from their past patients through hours of conversations</li>
              </ul>
              <p className="bold marg-bottom-10">How do I get a PatientPartner™ vetted surgeon referral?</p>
              <ol className="marg-bottom-50">
                <li>Fill out the form on this page</li>
                <li>Our team will confirm your insurance coverage and location</li>
                <li>We'll give you a call and share more information about some surgeons in your area</li>
                <li>Upon your request, the surgeon's office can reach out to you directly to share more info</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
