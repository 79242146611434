import React, { useState, useEffect, useContext } from 'react'
import Context from '../../../../utils/context';
import GhostButton from '../../../ui_kit/utilities/ghost_button'
import styles from './index.module.css'
import parentstyles from '../../index.module.css'
import AddProcedure from './add_procedure';
import Divider from '../../../ui_kit/utilities/divider';
import { getProcedures } from '../../../../api/profiles';
import ProcedureRow from './procedure_row';


export default function ProfileProcedures({
  user,
  tab = 'current'
}) {
  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  const [addProcedure, setAddProcedure] = useState(false);
  const [profile, setProfile] = useState(user.current_profile);
  const [procedures, setProcedures] = useState([]);
  const [recovered, setRecovered] = useState(false);

  let profileIsCurrentUser = false
  if (context.auth.isLoggedIn() && currentUser.current_profile && profile && currentUser.current_profile.id === profile.id) {
    profileIsCurrentUser = true
  }

  useEffect(() => {
    getProcedures(user.profile_id, (data) => {
      setProcedures(data);
    });
  }, []);

  useEffect(() => {
    if (tab === 'current'){
      setRecovered(false)
    } else {
      setRecovered(true)
    }
  }, [tab]);


  return (
    <div>
      <div style={{ display: 'flex' }}>
        <h2 className={styles.profile_section_headline}>Procedures/Treatments</h2>
        {profileIsCurrentUser &&
          <GhostButton data-pendo-tag="profile page - health profile - add procedure" onClick={() => setAddProcedure(!addProcedure)} style={{ marginLeft: 'auto' }}>
            <img alt='add procedure' style={{ marginRight: '14px', height: '12px', transform: addProcedure ? 'rotate(45deg)' : 'rotate(0deg)' }} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/plus_.svg" />
            <div style={{ position: 'relative' }}>
              <span className={`${addProcedure ? styles.show : null} ${styles.no_show} ${styles.cancel}`} style={{ fontWeight: '600', color: '#419DA5', position: 'absolute', right: '0px' }}>Cancel</span>
              <span className={`${addProcedure ? null : styles.show} ${styles.no_show} ${styles.add}`} style={{ fontWeight: '600', color: '#419DA5' }}>Add procedure</span>
            </div>
          </GhostButton>
        }
      </div>
      {addProcedure &&
        <div className={`marg-bottom-10`}>
          <AddProcedure
            procedures={procedures}
            setProcedures={setProcedures}
            setAddProcedure={setAddProcedure}
            recovered={recovered}
          />
        </div>
      }
      <div className='content_container'>
        {procedures.filter(procedure => procedure.recovered == recovered).length > 0 ? <>
          {procedures.filter(procedure => procedure.recovered == recovered).map((procedure, i) =>
            <div key={i}>
              <ProcedureRow
                user={user}
                procedure={procedure}
                i={procedures.filter(procedure => procedure.recovered == recovered).findIndex(p => p.id == procedure.id)}
                setProcedures={setProcedures}
                procedures={procedures}
                profileIsCurrentUser={profileIsCurrentUser}
              />
              {procedures.filter(procedure => procedure.recovered == recovered).findIndex(p => p.id == procedure.id) !== procedures.filter(procedure => procedure.recovered == recovered).length - 1 &&
                <Divider />
              }
            </div>
          )}
        </>
          :
          <p className={parentstyles.bio}>No procedures added yet</p>
        }
      </div>
    </div >
  )
}
