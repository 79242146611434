import React, { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { validatePhone, validateEmail, validateText } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message'
import SuccessMessage from '../ui_kit/utilities/success_message'
import './contact_us.css'
import TxtLeftImgRightWbg from '../hero_sections/txt_left_img_right_wbg';
import { createContactUsContacts } from '../../api/contact_us_contacts';

export default function ({
  home,
  updateAnswersAction,
}) {
  const [name, setName] = useState(home.answers.name || '');
  const [email, setEmail] = useState(home.answers.email || '');
  const [phone, setPhone] = useState(home.answers.phone || '');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const recaptchaRef = React.createRef();

  const runRecaptcha = async (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  }

  let disabled = 'disabled';
  var checkedEmail = validateEmail(email);

  let checkedPhone = false;
  if (phone !== "") {
    checkedPhone = validatePhone(phone);
  }
  var checkedName = validateText(name);

  if (checkedName === true && checkedEmail === true && hasBeenSubmitted == false && message !== '') {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  const validate = (event) => {
    event.preventDefault();
    if (checkedEmail === false && email !== '') {
      if (email.indexOf('@') == '-1') {
        setEmail('')
      }
      setError('Please enter a valid email');
    }
    else if (checkedName == false && name !== '') {
      setError('Please enter a name without special characters');
    }
    else {
      setError('');
    }
  }

  const validateMessage = (event) => {
    event.preventDefault();
    if (message == '') {
      setError('Please enter a message');
    }
    else {
      setError('');
    }
  }

  const onClick = (event) => {
    if (hasBeenSubmitted == false) {
      if (checkedEmail === false) {
        setError('Please enter a valid email');
        setHasBeenSubmitted(false);
      }
      else if (checkedName == false) {
        setError('Please enter a name without special characters');
        setHasBeenSubmitted(false);
      }
      else if (message == '') {
        setError('Please enter a message');
        setHasBeenSubmitted(false);
      }
      else {
        setError('');
        setHasBeenSubmitted(true);
        createContactUsContacts(
          name,
          email,
          phone,
          message,
          () => {
            setSuccess('You message has been sent and we will be in touch shortly!')
          }
        )
        updateAnswersAction({
          ...home.answers,
          name,
          email,
          phone
        })
      }
    }
  }

  return (
    <div className="contact-us">
      <TxtLeftImgRightWbg
        headline = "Get in touch"
        para = "Have questions, comments, or suggestions? We’d love to know. Fill out the form below and we’ll get back to you within 24 hours."
      />
      <div className="container content">
        <div className="row">
          <div id="infoCap" className="col-md-9">
            <h3 className="marg-bottom-20 marg-top-30">Send us a message:</h3>
            <div className="material-input marg-bottom-30">
              <input className="autofocus" onBlur={validate} value={name} onChange={(event) => setName(event.target.value)} type="text" name="name" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>Name</label>
            </div>
            <div className="material-input marg-bottom-30">
              <input className="autofocus" onBlur={validate} value={email} onChange={(event) => setEmail(event.target.value)} type="email" name="email" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>Email</label>
            </div>
            <div className="material-input marg-bottom-30">
              <input className="autofocus" onBlur={validate} value={phone} onChange={(event) => setPhone(event.target.value)} type="number" name="phone" required />
              <span className="highlight"></span>
              <span className="bar"></span>
              <label>Phone</label>
            </div>
            <textarea className="material-input message-box" onBlur={validateMessage} value={message} onChange={(event) => setMessage(event.target.value)} placeholder="Tell us here..."></textarea>
            {error && hasBeenSubmitted !== true &&
              <ErrorMessage
                error={error}
              />
            }
            {success &&
              <SuccessMessage
                success={success}
              />
            }
            <button disabled={disabled} onClick={runRecaptcha} className="marg-bottom-40">Submit</button>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={gon.recaptcha_sitekey}
              onChange={onClick}
            />
          </div>
          <div className="col-md-3">
            <h4 className="marg-top-40 marg-bottom-10">
              Connect with us:
            </h4>
            <div class="socials"><a href="https://www.facebook.com/PatientPartner1"><img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/facebook.svg" aria-hidden="true" data-gtm-click-label="Contact us Facebook"></img></a><a href="https://twitter.com/patientpartner1"><img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/twitter.svg" aria-hidden="true" data-gtm-click-label="Contact us Twitter"></img></a><a href="https://www.instagram.com/patient_partner/"><img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/instagram.svg" aria-hidden="true" data-gtm-click-label="Contact us Instagram"></img></a><a href="https://in.linkedin.com/company/rightdevice"><img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/linkedin.svg" aria-hidden="true" data-gtm-click-label="Contact us Linkedin"></img></a><a href="https://www.youtube.com/channel/UC5EthG-VCW6xJ6RnTPaYUIQ"><img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/contact-us/youtube.svg" aria-hidden="true" data-gtm-click-label="Contact us Youtube"></img></a></div>
            <h5 className="marg-top-40 marg-bottom-10">San Diego</h5>
            <p className="marg-bottom-10">1025 Prospect St. Suite 350<br/>La Jolla, 92037</p>
            <h5 className="marg-top-40 marg-bottom-10">Give us a call:</h5>
            <p className="marg-bottom-100">619-268-1112</p>
          </div>
        </div>
      </div>
    </div>
  )
}
