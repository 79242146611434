import React, { Component, useEffect } from 'react'
import queryString from 'query-string'

export default function Oncehub({
  divid,
  page,
  height,
  style,
  params,
  onScheduled,
}) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.oncehub.com/mergedjs/so.js';
    script.async = true;
    document.body.appendChild(script);

    const listener = (event) => {
      onScheduled(event.detail);
    }

    window.addEventListener('oncehub_trigger', listener);

    return () => {
      window.removeEventListener('oncehub_trigger', listener);
    }
  }, []);

  return (
    <div id={divid} data-so-page={page} data-height={height} data-style={style} data-so-qry-prm={queryString.stringify(params)}></div>
  )
}
