import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ErrorMessage from '../ui_kit/utilities/error_message'
import PasswordInput from '../ui_kit/utilities/password_input'
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from 'react-router-dom';
import { autofocusAll } from '../../helpers'
import './index.css'
import Context from '../../utils/context';

export default function UsersPasswordReset({
}) {
  const [password, setPassword] = useState('');
  const context = useContext(Context)
  const [error, setError] = useState('');

  let disabled = 'disabled';
  let validPassword = password !== '';

  if (validPassword) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  let history = useHistory();

  useEffect(() => {
    autofocusAll();
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let returnTo = '';
  if (searchParams.get('return_to')) {
    returnTo = searchParams.get('return_to');
  }

  const onClick = (event) => {
    context.auth.userResetPassword(gon.reset_password_token, password, () => {
      if (returnTo === '') {
        history.push('/member/home');
      }
      else {
        history.push(returnTo);
      }
    }, (errors) => {
      setError(errors.join(','));
    });
    setError('');
  }

  const recaptchaRef = React.createRef();

  const runRecaptcha = async (event) => {
    event.preventDefault();
    recaptchaRef.current.reset();
    recaptchaRef.current.execute();
  }

  return (<>
      <div className="container sign-in">
        <div className="row h-100">
          <div className="col-md-3 no-mobile">
          </div>
          <div className="col-md-6 mobile-margins">
            <div className="registrations-container">
              <h5 className="text-center marg-top-20 marg-bottom-10">Create a new password</h5>
              <p className="text-center login-title marg-bottom-20">Enter a new password for your account.</p>
              <PasswordInput
                password={password}
                setPassword={setPassword}
                label={'New password'}
                requireConfirmation
                signUp={true}
              />
              {error &&
                <ErrorMessage
                  error={error}
                />
              }
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={gon.recaptcha_sitekey}
                onChange={onClick}
              />
              <button style={{ width: '100%' }} disabled={disabled} onClick={runRecaptcha} className={`marg-top-20 submit`} type="submit">Create new password</button>
            </div>
          </div>
        </div>
        <div className="col-md-3 no-mobile">
        </div>
      </div>
  </>);
}
