import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { createSurgeonMatchFeedbackContact } from '../../../api/surgeon_match_feedback_contacts'
import DatePickerWrapper from '../../date_picker_wrapper';
import LoadingScreen from '../../loading_screen';
import OptionsAndOther from '../../questionnaire/feedback_questions/options_and_other';
import OtherTextfield from '../../questionnaire/feedback_questions/other_textfield';
import WhenIsConsultation from '../../questionnaire/feedback_questions/when_is_consultation';
import YesNoSubmit from '../../questionnaire/feedback_questions/yes_no_submit';
import './surgeon_match_journey_feedback.css'

export default function SurgeonMatchJourneyFeedback({
  updateAnswersAction,
  surgeonMatchJourneyFeedback,
}) {
  const [showLoading, setShowLoading] = useState(true);
  const [questionnaireState, setQuestionnaireState] = useState('intro');
  const [fade, setFade] = useState(false);
  const [slideLeft, setSlideLeft] = useState(false);
  const [slideRight, setSlideRight] = useState(false);
  const [answers, setAnswers] = useState({});
  const [id] = useState(new URLSearchParams(window.location.search).get("contact_id"));
  const [name, setName] = useState();
  const [surgeonName, setSurgeonName] = useState();
  const [questionnaireHistory, setQuestionnaireHistory] = useState(['intro']);

  useEffect(() => {
    contactSyncWrapper(id, answers, null, (data) => { setShowLoading(false); setName(data.name); setSurgeonName(data.surgeon_name);});
  }, [id]);

  const startFadeOut = () => {
    setFade(true);
    setSlideLeft(true);
    setShowLoading(true);
  }

  const startFadeIn = () => {
    setShowLoading(false);
    setSlideLeft(false);
    setSlideRight(true);
    setSlideRight(false);
    setFade(false);
  }

  const contactSyncWrapper = (contactId, answer, nextState, callback) => {
      if(nextState !== null){
        setQuestionnaireHistory([...questionnaireHistory, nextState])
      }
      createSurgeonMatchFeedbackContact(
        contactId,
        answer,
        nextState == 'thank_you',
        (data) => {
          if (callback) {
            callback(data);
          }
        }
      );
    }

  const onSubmit = (newAnswer, nextState) => {
    if(questionnaireState === 'intro'){
      nextState = 'did_office_contact_you'
    }
    setAnswers({ ...answers, ...newAnswer })
    startFadeOut();
    contactSyncWrapper(id, { ...answers, ...newAnswer }, nextState, (data) => { setQuestionnaireState(nextState); startFadeIn(); });
  }

  const onBackClick = () => {
    setFade(true);
    setSlideRight(true);
    var arr = questionnaireHistory;
    if (questionnaireHistory.length > 1){
      arr.splice(-1)
    }
    setQuestionnaireHistory(arr);
    setQuestionnaireState(questionnaireHistory[questionnaireHistory.length - 1]);
    setSlideLeft(false);
    setSlideRight(false);
    setFade(false);
  }

  return (
    <div>
      <div id={`questionnaire-scroll-to`} className={`container questionnaire-wrapper surgeon-match-feedback marg-bottom-20`}>
        {showLoading &&
          <div className="load-screen-wrapp-full-size">
            <LoadingScreen />
          </div>
        }
        <div className="row h-100 questionnaire-content">
          <div className="col-lg-4 col-1"></div>
          <div className="col-lg-4 col-10">
            <div className={`fadeOp ${fade ? 'fadeOut' : ''} ${slideLeft ? 'slideLeft' : ''} ${slideRight ? 'slideRight' : ''}`}>
              {questionnaireState === 'intro' &&
                <>
                  <h2 className="marg-bottom-20">Share your feedback</h2>
                  <p className="marg-bottom-40">Hi {name}, thank you for taking the time to share your experience with us. This survey should only take a minute and will help us support you better.</p>
                  <button onClick={() => onSubmit()} data-gtm-click-label={`Surgeon Match Feedback Start Survey`}>Start survey</button>
                </>
              }
              {questionnaireState === 'did_office_contact_you' &&
              <>
                <YesNoSubmit
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`Did ${surgeonName}'s office contact you?`}
                  para={'We want to make sure the doctor’s office reached out.'}
                  keyForAnswer={'did_office_contact_you'}
                  yesState={'were_you_able_to_book'}
                  noState={'are_you_still_interested_in_booking'}
                  gtm={'Surgeon Match Feedback Did Office Contact You'}
                />
              </>
              }
              {questionnaireState === 'are_you_still_interested_in_booking' &&
                <YesNoSubmit
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`Are you still interested in booking a consultation?`}
                  para={`Oh no, we're so sorry to hear that. Do you still want to book a consultation with ${surgeonName}?`}
                  keyForAnswer={'are_you_still_interested_in_booking'}
                  yesState={'anything_we_can_help_you_with'}
                  noState={'anything_we_can_help_you_with'}
                  gtm={'Surgeon Match Feedback Are You Still Interested In Booking'}
                />
              }
              {questionnaireState === 'were_you_able_to_book' &&
                <YesNoSubmit
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`Were you able to book a consultation?`}
                  para={'We want to make sure you got the help you were looking for.'}
                  keyForAnswer={'were_you_able_to_book'}
                  yesState={'what_date_is_the_consultation'}
                  noState={'what_prevented_you_from_booking'}
                  gtm={'Surgeon Match Feedback Were You Able To Book'}
                />
              }
              {questionnaireState === 'what_date_is_the_consultation' &&
                <WhenIsConsultation
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`What date is your consultation scheduled for?`}
                  para={'We want to support you throughout your journey!'}
                  keyForAnswer={'what_date_is_the_consultation'}
                  yesState={'anything_we_can_help_you_with'}
                  gtm={'Surgeon Match Feedback What Date Is The Consultation'}
                />
              }
              {questionnaireState === 'what_prevented_you_from_booking' &&
                <OptionsAndOther
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`What prevented you from booking a consultation?`}
                  para={'We want help you find options and feel supported. Select any that apply.'}
                  keyForAnswer={'what_prevented_you_from_booking'}
                  choices={[
                      'Insurance policy won’t cover it',
                      'I’m not emotionally ready',
                      'I missed their call',
                  ]}
                  yesState={'anything_we_can_help_you_with'}
                  gtm={'Surgeon Match Feedback What Prevented You From Booking'}
                />
              }
              {questionnaireState === 'anything_we_can_help_you_with' &&
                <OptionsAndOther
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`Is there anything else we can help you with?`}
                  para={'We want to make sure you feel supported. Select all that apply.'}
                  keyForAnswer={'anything_we_can_help_you_with'}
                  choices={[
                    'Talk to a recovered patient',
                    'Insurance questions',
                    'No, thank you'
                  ]}
                  yesState={'anything_we_can_improve'}
                  gtm={'Surgeon Match Feedback Anything We Can Help You With'}
                />
              }
              {questionnaireState === 'anything_we_can_improve' &&
                <OtherTextfield
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`Is there anything we can do to improve?`}
                  para={'Your feedback will help us serve you better.'}
                  keyForAnswer={'anything_we_can_improve'}
                  yesState={'thank_you'}
                  required={false}
                  cta={'Submit'}
                  gtm={'Surgeon Match Feedback Anything We Can Improve'}
                />
              }
              {questionnaireState === 'thank_you' &&
                <>
                  <h2 className="marg-bottom-20">Thank you!</h2>
                  <p>We’ve received your feedback. We’ll send you a text to help you further based on your answers.</p>
                </>
              }
            </div>
          </div>
          <div className="col-lg-4 col-1"></div>
        </div>
      </div>
    </div>
  )
}
