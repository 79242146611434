import React, { useState } from 'react'

export default function PaymentMethod({
  answers,
  onSubmit,
  onBack,
  gtmSubmitLabel = 'wl',
  gtm,
  choices = [
    { value: 'Health Insurance',
      icon: <svg width="30" height="30" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-gtm-click-label="Surgeon Questionnaire Payment Method Health Insurance" d="M6.5 11H4.2C4.08954 11 4 10.9105 4 10.8V8.2C4 8.08954 4.08954 8 4.2 8H6.5V5.7C6.5 5.58954 6.58954 5.5 6.7 5.5H9.3C9.41046 5.5 9.5 5.58954 9.5 5.7V8H11.8C11.9105 8 12 8.08954 12 8.2V10.8C12 10.9105 11.9105 11 11.8 11H9.5V13.3C9.5 13.4105 9.41046 13.5 9.3 13.5H6.7C6.58954 13.5 6.5 13.4105 6.5 13.3V11ZM8.35112 0.131671C8.12474 0.0467768 7.87526 0.0467769 7.64888 0.131671L0.648876 2.75667C0.258572 2.90304 0 3.27616 0 3.693V9.09C0 14.0507 3.29049 18.6833 7.75766 19.9357C7.91604 19.9801 8.08396 19.9801 8.24234 19.9357C12.7095 18.6833 16 14.0507 16 9.09V3.693C16 3.27616 15.7414 2.90304 15.3511 2.75667L8.35112 0.131671ZM14 9.09C14 13.09 11.45 16.79 8 17.92C4.55 16.79 2 13.1 2 9.09V4.39L8 2.14L14 4.39V9.09Z" fill="#314D69"/></svg>
    },
    { value: 'Cash Pay',
      icon: <svg width="30" height="30" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-gtm-click-label="Surgeon Questionnaire Payment Method Cash Pay" d="M4 1C4 0.447715 4.44772 0 5 0H21C21.5523 0 22 0.447715 22 1V11C22 11.5523 21.5523 12 21 12H5C4.44772 12 4 11.5523 4 11V1ZM13 3C13.7956 3 14.5587 3.31607 15.1213 3.87868C15.6839 4.44129 16 5.20435 16 6C16 6.79565 15.6839 7.55871 15.1213 8.12132C14.5587 8.68393 13.7956 9 13 9C12.2044 9 11.4413 8.68393 10.8787 8.12132C10.3161 7.55871 10 6.79565 10 6C10 5.20435 10.3161 4.44129 10.8787 3.87868C11.4413 3.31607 12.2044 3 13 3ZM8 2C8 2.53043 7.78929 3.03914 7.41421 3.41421C7.03914 3.78929 6.53043 4 6 4V8C6.53043 8 7.03914 8.21071 7.41421 8.58579C7.78929 8.96086 8 9.46957 8 10H18C18 9.46957 18.2107 8.96086 18.5858 8.58579C18.9609 8.21071 19.4696 8 20 8V4C19.4696 4 18.9609 3.78929 18.5858 3.41421C18.2107 3.03914 18 2.53043 18 2H8ZM0 5C0 4.44772 0.447715 4 1 4V4C1.55228 4 2 4.44772 2 5V13C2 13.5523 2.44772 14 3 14H17C17.5523 14 18 14.4477 18 15V15C18 15.5523 17.5523 16 17 16H1C0.447716 16 0 15.5523 0 15V5Z" fill="#314D69"/></svg>
    },
  ],
}) {
  const [paymentMethod, setPaymentMethod] = useState(answers.paymentMethod || '');
  const onClick = (value) => () => {
    setPaymentMethod(value);
  }

  return (
    <div className="row paymentMethodQuestion">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <div>
          <form id="plans">
            <h5 className="question">
              How do you plan to pay for your medical treatment?
            </h5>
            <p className="question-sub-headline marg-bottom-30 text-left">
              We will recommend a surgeon that accepts your insurance coverage or offers cash pay options.
            </p>
            {choices.map((choice) => (
              <div key={choice.value} className="tile two-column">
                <label key={choice.value} className={`preexisting ${paymentMethod === choice.value ? 'activeCheckbox' : ''}`}><input onClick={onClick(choice.value)} type="checkbox" name={choice.value} value="value" />
                  <span className="inline">
                    {choice.icon &&
                      <span style={{width: '20px'}} className="marg-right-20 gender-icon-holder">{choice.icon}</span>
                    }
                  </span>
                  <span className="inline btn-text">
                    {choice.value &&
                      <p>{choice.value}</p>
                    }
                    {choice.subTitle &&
                      <p>{choice.subTitle}</p>
                    }
                  </span>
                </label>
              </div>
            ))}
            <div className="marg-top-20 container">
              <div className="row">
                { onBack && <button data-gtm-click-label={`${gtm} payment method - back`} className="back col-6" type="button" onClick={() => onBack()}>Back</button> }
                <button data-gtm-click-label={`${gtm} payment method - next`} disabled={`${paymentMethod === '' ? 'disabled' : ''}`} onClick={(e) => {e.preventDefault();onSubmit(paymentMethod)}} className={`submit col-6`} type="submit" >Next</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
