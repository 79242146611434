import React from 'react'
import VideoType1 from '../../video_sections/video_type_1'
import WeightLossWebinarParent from '../index'

export default function April282021({
  home,
  updateAnswersAction,
}) {

  return (
    <div>
      <WeightLossWebinarParent
        home={home}
        updateAnswersAction={updateAnswersAction}
        title={'What You Need to Know about Weight Loss Surgery'}
        webinarDate={'Apr 28, 2021 17:00:00'}
        webinarDateText={['Wednesday, April 28th, 2021', '5:00 PM PT', '45 Minutes']}
        webinarTitleForDb={'April282021'}
        evergreenPage={'/webinar/april-28-2021/view'}
        googleCalendarLink={'https://calendar.google.com/event?action=TEMPLATE&tmeid=MTlzMTViMHE4NmJkMGVlYTFodGlqYW0wcnMgaW5mb0BwYXRpZW50cGFydG5lci5jb20&tmsrc=info%40patientpartner.com'}
        otherCalendarLink={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/weight-loss/dr-russo-and-dina/PatientPartner+Webinar_+What+you+need+to+know+about+weight-loss+surgery.ics'}
        content={
          <>
            <h5 className="marg-top-30">
              Are you interested in learning more about losing weight with bariatric surgery from an expert?
            </h5>
            <p className="marg-top-30">
              Weight-loss surgery is the proven, most effective treatment for obesity. Find out how to begin your journey to a healthier and more fulfilling life!
            </p>
            <p className="marg-top-20">
              Dr. Russo of Smart Dimensions™ weight loss surgery practice in Orange County will discuss the stigma behind obesity, weight loss surgery, and how to overcome it. We will explain why you might be eligible, the effectiveness of surgery, and insurance accessibility. We will also welcome Dr. Russo's recovered patient, Dina, who will share her story on fighting obesity and finally committing to bariatric surgery, which was the best decision she has ever made.
            </p>
            <h2 className="body-subheadline marg-top-40">
              Join our webinar and get facts from one of the best doctors in the industry. In this free weight-loss surgery webinar, you'll learn:
            </h2>
            <ul className="marg-top-10">
              <li>
                What obesity actually is and how to deal with it
              </li>
              <li>
                The safety and effectiveness of bariatric surgery
              </li>
              <li>
                Insurance options and how you can get your surgery covered
              </li>
              <li>
                A personal story from a past patient, telling you directly what she went through
              </li>
            </ul>
            <p className="marg-top-20">
              Weight loss surgery is safe and effective. It is not cheating or the easy way out. Join our webinar to find out if this could be a life-changing option for you.
            </p>
            <h2 className="body-subheadline marg-top-40">
              About Dr. Russo:
            </h2>
            <div className="surgeon">
              <div className="row marg-top-20 photo-wrap rs-bio-vid">
                <VideoType1
                  bg = {''}
                  videoLink = {'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/weight-loss/dr-russo-and-dina/I Want People Excited To Be In My Clinic _ Dr. Michael Russo _ Bariatric Surgeon.mp4'}
                  headline = {`About Dr. Russo`}
                  gtm={'Webinar April 28 2021'}
                />
              </div>
            </div>
            <p className="marg-top-20 marg-bottom-20">
              Dr. Michael Russo, MD, is a Bariatric Surgery Specialist in Orange County, CA, and has over 13 years of experience in the medical field. As a board-certified and fellowship-trained bariatric, gastroesophageal and general surgeon, Dr. Michael Russo specializes in minimally-invasive and robotic techniques to provide his patients with the best possible results. He graduated from Medical College Of Ohio medical school in 2007. He is affiliated with Fountain Valley Regional Hospital and Medical Center and Orange Coast Memorial Medical Center.
            </p>
            <button className="marg-top-20 send-it-final no-mobile">Register now</button>
          </>
        }
      />
    </div>
  )
}
