import React, { Component, useState, useEffect } from 'react'
import styles from './index.module.css'

export default function FeaturedInCount() {
    return (
      <div className={`container-fluid ${styles.container}`}>
        <div className='container'>
          <div className='row h-100'>
            <div className='col-sm-1'></div>
            <div className='col-xs-3 col-sm-3 my-auto'>
              <p className={styles.fi_text}>Customer Count:</p>
            </div>
            <div className='col-xs-4 col-sm-4 my-auto'>
              <div className={styles.number}>
                20+
              </div>
              <div className={styles.bar}>
              </div>
              <div className={styles.companies}>
                Pharmaceutical Companies
              </div>
            </div>
            <div className='col-xs-4 col-sm-4 my-auto'>
              <div className={styles.number}>
                30+
              </div>
              <div className={styles.bar}>
              </div>
              <div className={styles.companies}>
                Medical Device Companies
              </div>
            </div>
            <div className='col-sm-1'></div>
          </div>
        </div>
      </div>
    )
}
