import React, { Component, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import LoadingScreen from '../../loading_screen';
import { getDirectSurgeonBookingContact } from '../../../api/direct_surgeon_booking_contacts';
import styles from './index.module.css'

export default function DirectSurgeonMatchShow() {
  const { id } = useParams();
  const [view, setView] = useState('loading');
  const [directSurgeonBookingContact, setDirectSurgeonBookingContact] = useState();

  useEffect(() => {
    getDirectSurgeonBookingContact(id, (result) => {
      setDirectSurgeonBookingContact(result);
      setView('loaded')
    })
  }, [])

  if (view === 'loading') {
    return <LoadingScreen />
  }

  return (
    <div>
      <div className={styles.hero_container}>
        <h1>Congratulations You Have A New Referral!</h1>
        <p>A patient has requested an appointment with your practice to learn more about Acessa and treatment options for their fibroid symptoms. Please see the patient’s information below and make sure to follow-up with them within 24 hours, if possible.</p>
        <p><b>Made Possible By:</b><img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/hologic.svg" aria-hidden="true"></img></p>
      </div>
      <div className={`container ${styles.body_container}`}>
        <div className="row">
          <div className="col-2">
            <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/patient-information.svg" aria-hidden="true"></img>
          </div>
          <div className="col-10">
            <h1>Patient Information</h1>
            <hr/>
            <div className="container">
              <div className={`row ${styles.body_row}`}>
                <div className="col-4"><b>Full name:</b></div>
                <div className="col-8">{directSurgeonBookingContact.name}</div>
              </div>
              <div className={`row ${styles.body_row}`}>
                <div className="col-4"><b>Email:</b></div>
                <div className="col-8">{directSurgeonBookingContact.email}</div>
              </div>
              <div className={`row ${styles.body_row}`}>
                <div className="col-4"><b>Phone number:</b></div>
                <div className="col-8">{directSurgeonBookingContact.phone}</div>
              </div>
              <div className={`row ${styles.body_row}`}>
                <div className="col-4"><b>Insurance:</b></div>
                <div className="col-8">{directSurgeonBookingContact.insurance}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/questionnaire-results.svg" aria-hidden="true"></img>
          </div>

          <div className="col-10">
            <h1>Questionnaire Results</h1>
            <hr/>
            <div className="container">
              <div className={`row ${styles.body_row}`}>
                <p><b>Questions or additional information listed for the doctor:</b></p>
              </div>
              <div className={`row ${styles.body_row}`}>
                <p>{directSurgeonBookingContact.extra_info}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer_container}>
        <h1>What's Next:</h1>
        <p style={{ paddingBottom: '100px' }}>This patient is waiting for your call, it is now your responsibility to contact this patient and schedule an appointment Please reach out in the next 24 hours, to ensure a positive experience for your practice.</p>
        <h1>Questions?</h1>
        <p>If you have any questions, please do not hesitate to reach out to info@patientpartner.com</p>
      </div>
    </div>
  );
}
