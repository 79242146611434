import React, { useState, useContext, useEffect } from 'react'
import { useHistory, Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import Context from '../../../utils/context';
import SignUp from '../../sign_up';
import Age from '../age';
import PatientGender from '../patient_partner/patient_gender';
import MfUnderstanding from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mf_understanding';
import PatientOrCaregiver from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/patient_or_caregiver';
import MfDiagnosisTime from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mf_diagnosis_time';
import QuestionnaireStopper from '../questionnaire_stopper';
import MfPrescribedMedication from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mf_prescribed_medication';
import MfTreatmentGoals from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mf_treatment_goals';
import MentorConversationTopics from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mentor_conversation_topics';
import LoadingScreen from '../../loading_screen';
import MfMentorMatch from '../../surgeon/referred_mentor_match/pre_conversation_questionnaire/myelofibrosis/mf_mentor_match';
import VonjoThankYouPage from '../../vonjo/thank_you_page';
import { getSurgeonReferredMentorMatch, updateSurgeonReferredMentorMatch, addMatchedMentor, createSurgeonReferredMentorMatch } from '../../../api/surgeon_referred_mentor_matches';
import styles from './index.module.css'

export default function VonjoQuestionnaire({
  headerContent = <></>,
}){
  const context = useContext(Context);
  const { id } = useParams();
  const history = useHistory();
  const [currentBirthday, setCurrentBirthday] = useState(context.auth.isLoggedIn() ? context.auth.getCurrentUser().date_of_birth : null);
  const [currentGender, setCurrentGender] = useState(context.auth.isLoggedIn() ? context.auth.getCurrentUser().gender : null);
  const [mfUnderstanding, setMfUnderstanding] = useState("");
  const [mfPatientOrCaregiver, setMfPatientOrCaregiver] = useState('');
  const [mfDiagnosisTime, setMfDiagnosisTime] = useState("");
  const [mfPrescribedMedication, setMfPrescribedMedication] = useState("");
  const [mfTreatmentGoals, setMfTreatmentGoals] = useState([]);
  const [mentorFound, setMentorFound] = useState(false);
  const [mentorConversationChoices, setMentorConversationChoices] = useState([]);
  const [mentorConversationTopics, setMentorConversationTopics] = useState(() => {
    const saved = sessionStorage.getItem('mentorConversationTopics');
    return saved ? JSON.parse(saved) : [];
  } );
  const [surgeonReferredMentorMatch, setSurgeonReferredMentorMatch] = useState(() => {
    const saved = sessionStorage.getItem('surgeonReferredMentorMatch');
    return saved ? JSON.parse(saved) : '';
  } );
  const [patientPartner, setPatientPartner] = useState(() => {
    const saved = sessionStorage.getItem('patientPartner');
    return saved ? JSON.parse(saved) : '';
  } );
  let { path } = useRouteMatch();
  path = path.replace(`:id`, id);

  useEffect(() => {
    if (mentorFound && patientPartner) {
      history.push(`${path}/patient-partner`);
    }
  }, [mentorFound, patientPartner]);

  useEffect(() => {
    if (mfPrescribedMedication === "Yes") {
      setMentorConversationChoices(
        [
          'Hear how they coped with their MF diagnosis',
          `Gain the mentor's personal insight on emotional impact and how they dealt with it`,
          'Get advice on creating/maintaining a support system',
          'General experience with VONJO® (pacritinib)',
        ]
      );
    } else {
      setMentorConversationChoices(
        [
          'Hear how they coped with their MF diagnosis',
          `Gain the mentor's personal insight on emotional impact and how they dealt with it`,
          'Get advice on creating/maintaining a support system',
       ]
      );
    }
  }, [mfPrescribedMedication]);

  const findMentor = () => {
    addMatchedMentor(surgeonReferredMentorMatch.uuid, (data) => {
      setPatientPartner(data);
      sessionStorage.setItem('patientPartner', JSON.stringify(data));
      setMentorFound(true);
    });
  };

  const scrollToTopOfPage = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const createAndRedirect = () => {
    createSurgeonReferredMentorMatch(
      {
        surgeon_id: gon.vonjo_surgeon_id,
        surgery: 'VONJO',
      }, (match) => {
        history.push(`/member/mentor-matches/${match.uuid}/spv/age`);
        setTimeout(() => {
          scrollToTopOfPage();
        }, 0);
    });
  };

  const update = (params) => {
    getSurgeonReferredMentorMatch(id, (data) => {
      updateSurgeonReferredMentorMatch(data.uuid, params, (data) => {
        setSurgeonReferredMentorMatch(data);
        sessionStorage.setItem('surgeonReferredMentorMatch', JSON.stringify(data));
      })
    })
  }

  let steps = [
    'age',
    'gender',
    'mf-understanding',
    'mf-patient-or-caregiver',
    'mf-length',
    'mf-prescribed-medication',
    'mf-treatment-goals',
    'mentor-conversation-topics',
    'loading-patient-partner',
  ];

  let nextStep = steps[steps.findIndex(r => window.location.pathname.includes(r)) + 1];
  let previousStep = steps[steps.findIndex(r => window.location.pathname.includes(r)) - 1];

  if (!nextStep) {
    nextStep = 'patient-partner';
  }

  if (!previousStep) {
    previousStep = 'age';
  }

  return (
    <div className={styles.questionnaire_ottr} style={{ backgroundColor: "#f5f7f9", maxWidth: "100%" }}>
      <div className={`${styles.questionnaire_content}`}>
        <div className={`${styles.cont_row}`}>
          <div className={`col-md-8`} style={{ paddingLeft: '15px', paddingRight: '15px', flex: '0 0 100%', maxWidth: '100%' }}>
            { headerContent }
              <Switch>
                <Route exact path={`${path}`}>
                  <SignUp
                    onSuccess={createAndRedirect}
                    params={{ keap_force_sync: true, origin: "Vonjo Landing Page", procedure: "Myelofibrosis", surgery: "VONJO" }}
                    headline={`Let's create your account to get started!`}
                    para={`Only patients who have been diagnosed with MF or their caregivers are allowed to participate. Additional criteria may apply.`}
                    embedded
                    alignLeft
                    light={false}
                    captureFirstLastName
                    captureLocation
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/age`}>
                  <Age
                    title='What is your birthday?'
                    para=''
                    answers={{ date_of_birth: currentBirthday }}
                    onSubmit={(answers) => {
                      setCurrentBirthday(answers.date_of_birth);
                      context.auth.userUpdate({ date_of_birth: answers.date_of_birth }, () => history.push(`${path}/${nextStep}`));
                    }}
                    onBack={() => history.push(`${path}/${previousStep}`)}
                    gtm={''}
                    cta="Next"
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/gender`}>
                  <PatientGender
                    para=""
                    answers={{ gender: currentGender }}
                    onSubmit={(answers) => {
                      setCurrentGender(answers.gender);
                      context.auth.userUpdate({ gender: answers.gender }, () => history.push(`${path}/${nextStep}`));
                    }}
                    onBack={() => history.push(`${path}/${previousStep}`)}
                    gtm={''}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/mf-understanding`}>
                  <MfUnderstanding
                    value={mfUnderstanding}
                    onSubmit={(answers) => {
                      update({ mf_understanding: answers['mfUnderstanding'] });
                      setMfUnderstanding(answers['mfUnderstanding']);
                      history.push(`${path}/${nextStep}`);
                    }}
                    onBack={() => history.push(`${path}/${previousStep}`)}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/mf-patient-or-caregiver`}>
                  <PatientOrCaregiver
                    title={`Are you a patient or caregiver?`}
                    choices={[
                      'Patient',
                      'Caregiver',
                    ]}
                    value={mfPatientOrCaregiver}
                    onSubmit={(answers) => {
                      update({ mf_patient_or_caregiver: answers['mfPatientOrCaregiver'] });
                      setMfPatientOrCaregiver(answers['mfPatientOrCaregiver']);
                      history.push(`${path}/${nextStep}`);
                    }}
                    onBack={() => history.push(`${path}/${previousStep}`)}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/mf-length`}>
                  <MfDiagnosisTime
                    value={mfDiagnosisTime}
                    onSubmit={(answers) => {
                      const diagnosisTime = answers['mfDiagnosisTime']
                      if (diagnosisTime === "Have not been diagnosed with MF") {
                        update({ mf_diagnosis_time: answers['mfDiagnosisTime'] });

                        history.push(`${path}/thank-you`);
                      } else {
                        update({ mf_diagnosis_time: answers['mfDiagnosisTime'] });

                        setMfDiagnosisTime(diagnosisTime);
                        history.push(`${path}/${nextStep}`);
                      }
                    }}
                    onBack={() => history.push(`${path}/${previousStep}`)}
                  />
                </Route>

                <Route exact path={`${path}/thank-you`}>
                  <QuestionnaireStopper />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/mf-prescribed-medication`}>
                  <MfPrescribedMedication
                    value={mfPrescribedMedication}
                    onSubmit={(answers) => {
                      update({ mf_prescribed_medication: answers['mfPrescribedMedication'] });
                      setMfPrescribedMedication(answers['mfPrescribedMedication']);
                      history.push(`${path}/${nextStep}`);
                    }}
                    onBack={() => history.push(`${path}/${previousStep}`)}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/mf-treatment-goals`}>
                  <MfTreatmentGoals
                    answers={mfTreatmentGoals}
                    title={`What are your or the patient's aspirations for their healthcare journey? (Select all that apply)`}
                    choices={[
                      'Hear how the mentor has adjusted their daily routines while living with MF',
                      'Increase involvement with family and friends',
                      'Allocate more time to the important aspects of life',
                      'Attain a better sense of what to expect from my diagnosis',
                    ]}
                    onSubmit={(answers) => {
                      update({ mf_treatment_goals: answers['mfTreatmentGoals'] });
                      setMfTreatmentGoals(answers['mfTreatmentGoals']);
                      history.push(`${path}/${nextStep}`);
                    }}
                    onBack={() => history.push(`${path}/${previousStep}`)}
                    showOther={false}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/mentor-conversation-topics`}>
                  <MentorConversationTopics
                    answers={mentorConversationTopics}
                    choices={mentorConversationChoices}
                    onSubmit={(answers) => {
                      update({ mentor_conversation_topics: answers['mentorConversationTopics'] });
                      setMentorConversationTopics(answers['mentorConversationTopics']);
                      sessionStorage.setItem('mentorConversationTopics', JSON.stringify(answers['mentorConversationTopics']));
                      history.push(`${path}/${nextStep}`);

                      const timer = setTimeout(() => {
                        findMentor();
                      }, 3000);
                      return () => clearTimeout(timer);
                      }
                    }
                    onBack={() => history.push(`${path}/${previousStep}`)}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/loading-patient-partner`}>
                  <LoadingScreen
                    title={'Finding your mentor...'}
                    content={'Get ready to meet a real patient who will answer your questions and support you through your journey!'}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/patient-partner`}>
                  <MfMentorMatch
                    patientPartner={patientPartner}
                    id={id}
                    whatsNextMessage={(p) => {
                      return `Request a call, and we will contact you shortly to schedule a time for you to talk to ${p.name} about their experience.`
                    }}
                  />
                </Route>
              </Switch>

              <Switch>
                <Route exact path={`${path}/thank-you-success`}>
                  <VonjoThankYouPage />
                </Route>
              </Switch>
            </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
}
