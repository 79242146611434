export const createDownloadGuideContact = (
  params,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/download_guide_contacts',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        ...window.utmInfo,
        email: params.email,
        type: params.type,
        procedures: params.procedures,
      })
    }
  )
    .then(response => response.json())
    .then(data => {
      successFunction(data);
    })
}
