import { connect } from 'react-redux'
import {
  updateAnswersAction,
} from '../../../actions/home'
import January182022 from '../../../components/webinars/january_18_2022'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(January182022)
