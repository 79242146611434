import React, { Component, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CountdownTimer from '../countdown_timer'
import SuccessPanel from '../success_panel'
import './weight_loss_webinar.css'
import FsLightbox from 'fslightbox-react';
import { createWebinarContacts } from '../../api/webinar_contacts'
import { useCookies } from 'react-cookie';
import { validatePhone, validateEmail, validateText } from '../../helpers';

export default function({
  home,
  updateAnswersAction,
}) {
  const ALL_VALUES = [
    'Learning more about weight-loss surgery',
    'Finding out if insurance will cover the procedure',
    'Learning about surgical revisions',
    'Preparing for my upcoming surgery',
    'Looking for support during recovery'
  ];
  const defaultFullName = home.answers.name || '';
  const defaultFirstName = defaultFullName.split(' ').slice(0, -1).join(' ');
  const defaultLastName = defaultFullName.split(' ').slice(-1).join(' ');

  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);
  const [email, setEmail] = useState(home.answers.email || '');
  const [phone, setPhone] = useState(home.answers.phone || '');
  const [extraInfo, setExtraInfo] = useState('');
  const [currentJourney, setCurrentJourney] = useState(ALL_VALUES[0]);
  const [toggler, setToggler] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(cookies);
  const [evergreen, setEvergreen] = useState(false);

  let disabled = 'disabled';
  let emailError = '';
  let phoneError = '';
  var checkedEmail = validateEmail(email);
  var checkedFirstName = validateText(firstName);
  var checkedLastName = validateText(lastName);
  var checkedPhone = validatePhone(phone);

  if (checkedFirstName === true && checkedLastName === true && checkedEmail === true && checkedPhone === true){
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  if (email !== "" && email !== undefined && checkedEmail === false){
    emailError = 'Please enter a valid email';
  } else if (email !== "" && checkedEmail === true) {
    emailError = '';
  }

  if (phone !== "" && phone !== undefined) {
    if (checkedPhone === false) {
      phoneError = "Please enter a valid phone number";
    }
    else {
      phoneError = '';
    }
  }

  const onSubmit = () => {
    createWebinarContacts(
      email,
      'Weight Loss',
      firstName,
      lastName,
      phone,
      currentJourney,
      null,
      extraInfo,
      (data) => {
        setShowSuccess(true);
        scrollToSignUp();
        setCookie('weightlosswebinar', {"shownPopup": true}, { path: '/' })
      }
    )
    updateAnswersAction({
      ...home.answers,
      name: `${firstName} ${lastName}`,
      email,
      phone,
    })
  }

  const scrollToSignUp = () => {
    document.querySelector('#sign-up').scrollIntoView({
      behavior: 'smooth'
    });
  }

  const scrollToWebinar = () => {
    document.querySelector('#webinar').scrollIntoView({
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    var webinarTime = new Date("Feb 11, 2021 11:00:00").getTime();
    var currentTime = new Date().getTime();
    if((webinarTime - currentTime) <= 0){
      setEvergreen(true)
    }
  }, []);

  return (
    <div className="weight-loss-webinar">
      <div className="container-fluid webinar-hero">
        <div className="video-cover"></div>
        <div className="video-bg">
          <video autoPlay="autoplay" muted loop playsInline>
              <source src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/WLW/video/vbruce_promo.mp4" type="video/mp4"/>
          </video>
        </div>
        <div className="container">
          <div className="row h-100">
            <div className="col-sm-6">
              <p className="little-strong marg-bottom-20">
                Free Weight Loss Webinar
              </p>
              <h1 className="main marg-bottom-20">
                Life through Bariatric surgery: Questions you’re scared to ask
              </h1>
              <h2 className="submain">
              </h2>
            </div>
            <div className="col-sm-6 my-auto">
              <div onClick={() => setToggler(!toggler)} className="click-container">
                <div>
                  <h1 className="main wtfilm marg-bottom-20 text-center">
                    Watch Trailer
                  </h1>
                  <svg className="play-button" xmlns="http://www.w3.org/2000/svg" width="68" height="78" viewBox="0 0 68 78"><g id="Polygon_5" data-name="Polygon 5" transform="translate(68) rotate(90)" fill="#fff"><path d="M 77.13683319091797 67.5 L 0.8631658554077148 67.5 L 39 1.005007147789001 L 77.13683319091797 67.5 Z" stroke="none"/><path d="M 39 2.009994506835938 L 1.726325988769531 67 L 76.273681640625 67 L 39 2.009994506835938 M 39 0 L 78 68 L 0 68 L 39 0 Z" stroke="none" fill="#707070"/></g></svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div id="sign-up" className="col-md-5 move-me-up last-on-mobile">
            <div className="sign-up-otr">
              {!evergreen &&
                <>
                  <h6>Thursday, February 11th, 2021</h6>
                  <h5>11:00 AM PT | 45 Minutes</h5>
                  <div className="counter-wrap">
                    <CountdownTimer/>
                  </div>
                </>
              }
              {
                !showSuccess && (
                  <>
                    {!evergreen &&
                      <h5 className="marg-top-40">Register now for the free webinar:</h5>
                    }
                    {evergreen &&
                      <h5>Register now to view the webinar replay:</h5>
                    }
                    <div class="material-input">
                      <input type="text" name="firstName" value={firstName} onChange={(event) => setFirstName(event.target.value)} required/>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>First Name</label>
                    </div>
                    <div class="material-input">
                      <input type="text" name="lastName" value={lastName} onChange={(event) => setLastName(event.target.value)} required/>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Last Name</label>
                    </div>
                    <div class="material-input">
                      <input type="text" name="email" value={email} onChange={(event) => setEmail(event.target.value)} required/>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Email</label>
                    </div>
                    <div class="material-input">
                      <input type="phone" name="phoneNumber" value={phone} onChange={(event) => setPhone(event.target.value)} required/>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Phone Number</label>
                    </div>
                    <div class="material-input">
                      <p className="marg-bottom-10">Where are you in your weight-loss journey:</p>
                      <select>
                        {
                          ALL_VALUES.map(value => (
                            <option value={value} onClick={() => setCurrentJourney(value)}>{value}</option>
                          ))
                        }
                      </select>
                    </div>
                    {!evergreen &&
                      <div class="material-input">
                        <p className="marg-bottom-10">Ask Victoria anything! What questions would you like answered:</p>
                        <textarea placeholder="Tell us here..." value={extraInfo} onChange={(e) => setExtraInfo(e.target.value)}></textarea>
                      </div>
                    }
                    <button disabled={disabled} className="marg-top-20 send-it" onClick={onSubmit}>Register now</button>
                    <p className="error">{emailError}</p>
                    <p className="error">{phoneError}</p>
                  </>
                )
              }
              {
                showSuccess && !evergreen && (
                  <div className="success-panel-wrapper marg-top-30">
                    <SuccessPanel
                      title="Thank you for registering for webinar."
                      content="You will receive an email shortly with details.  In the meantime, if you have any questions, feel free to send us a message at: info@patientpartner.com"
                    />
                  </div>
                )
              }
              {
                showSuccess && evergreen && (
                  <div className="success-panel-wrapper marg-top-30">
                    <SuccessPanel
                      title="Thank you for registering for the webinar replay."
                      content=""
                    />
                    <Link to="/weight-loss-webinar-view"><button className="marg-center">Watch the webinar</button></Link>
                  </div>
                )
              }
            </div>
          </div>
          <div className="col-md-7 first-on-mobile">
            <div className="content-body">
              <div className="yes-mobile marg-top-20">
                <h6>Thursday, February 11th, 2021</h6>
                <h5>11:00 AM PT | 45 Minutes</h5>
              </div>
              <p className="body marg-top-30">
                Do you feel nervous to take the first step in learning about weight-loss surgery and how it can help you? Are you getting lost in the misinformation you are receiving online? Want to get real advice and get your questions answered from someone who has been through weight-loss surgery?
              </p>
              <p className="body marg-top-20">
                Join our webinar and discover the personal journey of Victoria Bruce as she <b>successfully lost 150 lbs with weight-loss surgery.</b> After the birth of her second child, she reached her highest weight of 335 lbs. She was 30 years old with a BMI of 44.2 and felt trapped in her own body as she couldn’t enjoy the activities with her family. After her sleeve gastrectomy surgery six-month follow-up, she had lost 150lbs and gained a new BMI of 24.4!
              </p>
              <button onClick={scrollToSignUp} className="marg-top-20 send-it-final yes-mobile">Register now</button>
              <h2 className="body-subheadline marg-top-40">
                During the webinar, Victoria will:
              </h2>
              <ul className="body marg-top-10">
                <li>Talk through her personal journey and share her results</li>
                <li>Uncover things she wishes she knew before considering surgery</li>
                <li>Discuss the effects this surgery has on your family, and much more!</li>
                <li>Explain how surgery gave her a new lease on life</li>
                <li>Answering any of your questions.</li>
              </ul>
              <h2 className="body-subheadline marg-top-40">
                About the speaker Victoria Bruce:
              </h2>
              <div className="row marg-top-20 photo-wrap">
                <div className="col-sm-6">
                  <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/WLW/victoria1.jpg"/>
                </div>
                <div className="col-sm-6">
                  <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/WLW/victoria2.jpg"/>
                </div>
              </div>
              <p className="body marg-top-20 marg-bottom-20">
                Victoria Bruce has been involved in the weight-loss community for over eight years after having her VSG in 2012. She has established an international support group following for patients to find support, both in-person and on-line. Victoria has also worked with obesity scientists and specialists at The Hospitals of Providence, The Institute for Healthy Living, and Obesity Week on their weight-loss surgery programs.<br/><br/>Victoria now sits on the Board of Directors for the American Society for Metabolic and Bariatric Surgery Foundation, serving as a resource and support to the charitable arm of the ASMBS.
              </p>
              <button onClick={scrollToSignUp} className="marg-top-20 send-it-final no-mobile">Register now</button>
            </div>
          </div>
        </div>
      </div>
      {!evergreen &&
        <FsLightbox className="high-z-index"
          toggler={toggler}
          sources={[
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/WLW/video/vbruce_promo.mp4',
          ]}
          type="video"
        />
      }
      {evergreen &&
        <FsLightbox className="high-z-index"
          toggler={toggler}
          sources={[
          /* webinar link goes here \/ */
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/WLW/video/vbruce_promo.mp4',
          ]}
          type="video"
        />
      }
    </div>
  )
}
