import React, { useState, useContext, useEffect } from 'react'
import './index.css'
import { requestACall } from '../../../../api/conversations';
import { updateCall } from '../../../../api/browser_calls';
import ModalWrapper from '../../../modal';
import Context from '../../../../utils/context';
import ConnectionFeedback from '../../connection_feedback';

export default function ClickToRequestCall({
    conversation,
    feedbackModal = false,
    browserCall = null,
    callComplete
}) {
    const context = useContext(Context)
    const isMobile = context.viewport.getIsMobile();

    const [showModal, setShowModal] = useState(false);

    let showCallFeedbackModal = feedbackModal;
    let currentBrowserCall = browserCall;

    return (
        <div style={{ display: 'inline-block' }}>
            <svg style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.87815 13.9919L10.0081 14.1218C12.2457 16.2882 14.1184 17.3056 15.6261 17.174L16.0605 16.7687C16.6785 16.2064 17.1444 15.85 17.4584 15.6994C18.112 15.3859 18.5707 15.3922 19.8094 16.0625C21.439 16.9442 22.5642 17.9691 23.1392 18.5441C23.6731 19.078 23.5072 19.9977 23.3197 20.4057L23.1955 20.6333C22.8619 21.2129 21.6521 23.1249 19.3334 23.4521C18.4523 23.5764 17.3399 23.4624 16.0357 23.0696C13.2419 22.1024 10.4598 20.3297 7.68932 17.7514L7.19661 17.2834L7.19197 17.288L6.95419 17.046L6.71199 16.808L6.71664 16.8034L6.24857 16.3107C3.67035 13.5402 1.89763 10.7581 0.930416 7.96428C0.537607 6.66006 0.423627 5.54772 0.547946 4.66655C0.89822 2.18383 3.06566 0.972407 3.46871 0.746715L3.50964 0.723616C3.85849 0.522447 4.88095 0.285854 5.45591 0.860816L5.72814 1.14078C6.32079 1.76756 7.18177 2.79378 7.93755 4.19059C8.6078 5.42934 8.61405 5.88801 8.30058 6.54161C8.11655 6.92534 7.625 7.53612 6.82595 8.37394C6.69439 9.88161 7.71179 11.7543 9.87815 13.9919Z" fill="#59B6BE" /></svg>
            {showModal &&
                <ModalWrapper
                    className={`agree-to-call already-took-this call_modal`}
                    content={<>
                        <div>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col">
                                        <h5 className="marg-bottom-15">Request a phone call</h5>
                                        {
                                          conversation.other_user_conversations[0].profile.type === 'PatientPartner' &&
                                          <p>
                                              We’ll let your PatientPartner mentor know that you’d like to chat over the phone.<br /><br />
                                              Your PatientPartner mentor will coordinate a day and time to chat!
                                          </p>
                                        }
                                        {
                                          conversation.other_user_conversations[0].profile.type === 'SurgeonAdministrativeAssistant' &&
                                          <p>
                                              We'll let the doctor's office know that you'd like to chat over the phone.<br /><br />
                                              The doctor's office will coordinate a day and time to chat!
                                          </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="container marg-top-20">
                                <div className="row justify-content-center">
                                    <div className="col">
                                        <button onClick={() => requestACall(conversation.uuid, () => setShowModal(false))} className="justify-content-center" style={isMobile ? { padding: '10px 5px', fontSize: '12px' } : {}, { display: 'flex', padding: '10px 15px', width: '100%' }} >
                                            <p style={{ display: 'flex', color: 'inherit', fontWeight: 'inherit' }}>Request a call</p>
                                            <svg style={{ width: '15px', display: 'flex', alignSelf: 'center', marginLeft: '10px' }} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.5344 12.0233L11.645 11.9127C13.4886 10.0084 14.3545 8.41472 14.2425 7.13163L13.8975 6.76187C13.419 6.23598 13.1157 5.83944 12.9875 5.57225C12.7208 5.016 12.7261 4.62566 13.2965 3.57144C14.0469 2.18457 14.9192 1.22698 15.4085 0.737666C15.8628 0.283301 16.6456 0.424461 16.9928 0.584019L17.1865 0.689709C17.6797 0.973639 19.307 2.00325 19.5853 3.9765C19.6911 4.72641 19.5941 5.67305 19.2598 6.78299C18.4367 9.16061 16.9281 11.5283 14.7339 13.8861L14.3355 14.3054L14.3395 14.3094L14.1336 14.5117L13.931 14.7179L13.9271 14.7139L13.5078 15.1122C11.15 17.3064 8.78225 18.8151 6.40464 19.6382C5.2947 19.9725 4.34805 20.0695 3.59815 19.9637C1.48525 19.6656 0.454283 17.821 0.26221 17.478L0.242553 17.4432C0.0713497 17.1463 -0.130001 16.2761 0.359315 15.7868L0.597577 15.5551C1.13099 15.0508 2.00434 14.3181 3.19309 13.6749C4.24731 13.1044 4.63765 13.0991 5.19389 13.3659C5.52047 13.5225 6.04026 13.9408 6.75327 14.6209C8.03637 14.7328 9.63007 13.867 11.5344 12.0233Z" fill="white" /></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    onClose={() => setShowModal(false)}
                />
            }
            {showCallFeedbackModal && currentBrowserCall !== null &&
                <ModalWrapper
                    className={`agree-to-call already-took-this call_modal`}
                    content={<ConnectionFeedback browserCall={currentBrowserCall}/>}
                    onClose={() => callComplete()}
                />
            }
        </div>
    )
}
