import React from 'react'
import Divider from '../../../../ui_kit/utilities/divider'
import MentorCard from '../../../../ui_kit/utilities/mentor_card'
import styles from '../../index.module.css'

export default function MentorMatch({
  patientPartner,
  callback,
  whatsNextMessage = (p) => {
    return `Send your first message to ${p.name} and get answers to questions about the procedure, recovery, and their experience with ${p.other_data.previous_surgeon ? (p.other_data.previous_surgeon.name === 'Dr. Shaneeta Johnson' ? 'Dr. Johnson or Dr. Hobson' : p.other_data.previous_surgeon.name) : 'their surgeon'}.`
  }
}){
  return(
    <div className='container'>
      <div className='row'>
        <h3 style={{fontWeight: '700'}}>You’ve been matched with {patientPartner.name}!</h3>
        <Divider color={'#173959'}/>
        <h5 className={`${styles.light_blue_text} marg-top-15`}>
          meet your mentor
        </h5>
        <div className='marg-top-20'>
        {patientPartner &&
          <MentorCard mentor={patientPartner} callback={callback}/>
        }
        </div>
        <div className='container'>
          <div className='row'>
            <h5 className={`${styles.light_blue_text} marg-bottom-10`}>What's next?</h5>
            <p>{whatsNextMessage(patientPartner)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
