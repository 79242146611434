import React, { useState, useContext, useRef } from 'react'
import styles from '../../index.module.css'
import GhostButton from '../../../ui_kit/utilities/ghost_button';
import RequestButton from '../../../ui_kit/utilities/request_button';
import Context from '../../../../utils/context';
import { successToast } from '../../../ui_kit/utilities/success_toast';
import Divider from '../../../ui_kit/utilities/divider';
import DropdownInput from '../../../ui_kit/utilities/dropdown_input';
import ErrorMessage from '../../../ui_kit/utilities/error_message';
import DatePickerWrapper from '../../../date_picker_wrapper';
import { createActivity } from '../../../../api/activities';

export default function PersonalInformation({
  user
}) {

  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  const [editing, setEditing] = useState(false);
  const [profile, setProfile] = useState(user.current_profile);
  const age = useRef(user.age);
  const [height, setHeight] = useState(user.height);
  const feet = useRef(Math.floor(height / 12));
  const inches = useRef(height - (Math.floor(height / 12) * 12));
  const [weight, setWeight] = useState(user.weight);
  const [gender, setGender] = useState(user.gender);
  const [maritalStatus, setMaritalStatus] = useState(user.marital_status);
  const [disabled, setDisabled] = useState(true);
  const [birthday, setBirthday] = useState(user.date_of_birth);
  const [birthdateError, setBirthdateError] = useState('');
  const [feetError, setFeetError] = useState('');
  const [inchesError, setInchesError] = useState('');
  const [weightError, setWeightError] = useState('');
  const params = useRef({})

  if (!user.current_profile) {
    return <></>
  }

  const updateInfo = (afterAction) => {
    validateHeight()
    if (params.current.height) {
      setHeight(params.current.height)
    }
    const finalParams = { ...params.current, date_of_birth: birthday };
    let fields = [];
    if (finalParams.date_of_birth !== user.date_of_birth) {
      fields.push('date_of_birth');
    }
    if (finalParams.height && (finalParams.height || null) !== user.height) {
      fields.push('height');
    }
    if (finalParams.weight && (finalParams.weight || null) !== user.weight) {
      fields.push('weight');
    }
    if (finalParams.gender && finalParams.gender !== user.gender) {
      fields.push('gender');
    }
    if (finalParams.marital_status && finalParams.marital_status !== user.marital_status) {
      fields.push('marital_status');
    }

    context.auth.userUpdate(
      { ...params.current, date_of_birth: birthday },
      (data) => {
        successToast("Your changes have been saved.");
        setEditing(false);
        createActivity('Activity::EditProfile', { fields: fields.join(',') });
        afterAction();
      }
    )
  }

  let profileIsCurrentUser = false
  if (context.auth.isLoggedIn() && currentUser.id === user.id) {
    profileIsCurrentUser = true
  }

  var now = new Date();
  let maxDate = now.toISOString().substring(0,10);
  let minDate = `${maxDate.slice(0,4) - 120}-${maxDate.slice(5,1000)}`

  const checkBirthdayForAge = (birthdayInput) => {
    let formattedAge = birthdayInput.slice('-')
    var birthDate = new Date(formattedAge);
    if (birthDate < new Date(minDate)){
      setDisabled(true)
      setBirthdateError('Birthday is too far in the past')
      return
    }
  }

  const validateBirthday = (date) => {
    if (date !== birthday){
      params.current = { ...params.current, date_of_birth: date }
      let today = new Date()
      let newAge = today.getFullYear() - date.getFullYear();
      let m = today.getMonth() - date.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
        newAge--;
      }
      age.current = newAge
      setBirthday(date);
      setDisabled(false)
      setBirthdateError('')
    } else {
      setDisabled(true)
    }
  }

  const validateFeet = (feetInput) => {
    if (feetInput > 8 || feetInput < 0 || isNaN(feetInput)){
      setFeetError('Please input a valid feet value between 0 - 8.')
      setDisabled(true)
      return
    }
    if (feetInput !== '' && feetInput !== 0) {
      feet.current = feetInput;
      params.current = { ...params.current, height: (parseInt(feetInput) * 12) + parseInt(inches.current) }
      setFeetError('')
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const validateInches = (inchesInput) => {
    if (inchesInput > 11 || inchesInput < 0 || isNaN(inchesInput)){
      setInchesError('Please input a valid inch value between 0 - 12.')
      setDisabled(true)
      return
    }
    if (inchesInput !== '' && inchesInput !== 0) {
      inches.current = inchesInput;
      params.current = { ...params.current, height: (parseInt(feet.current) * 12) + parseInt(inchesInput) }
      setInchesError('')
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const validateHeight = () => {
    if (feet.current === 0 && inches.current !== 0){
      setDisabled(true)
      setFeetError('Please input a feet value.')
      return
    }
  }

  const validateWeight = (weightInput) => {
    if (isNaN(weightInput)) {
      setWeightError('Please enter only numbers.')
      setDisabled(true)
      return
    }
    if (weightInput !== user.current_profile.weight) {
      setWeight(weightInput);
      params.current = { ...params.current, weight: weightInput }
      setWeightError('')
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const validateGender = (genderInput) => {
    if (genderInput !== gender) {
      setGender(genderInput)
      setDisabled(false)
      params.current = { ...params.current, gender: genderInput.toLowerCase() }
      if (genderInput === 'Gender' || genderInput === 'gender'){
        params.current = { ...params.current, gender: null }
      }
    }
  }

  const validateMaritalInput = (maritalInput) => {
    if (maritalInput !== user.current_profile.marital_status) {
      setMaritalStatus(maritalInput)
      setDisabled(false)
      params.current = { ...params.current, marital_status: maritalInput }
      if (maritalInput === 'Marital status' || 'marital status'){
        params.current = { ...params.current, marital_status: null }
      }
    }
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <h2 className={styles.profile_section_headline}>Personal information</h2>
        {currentUser && currentUser.current_profile && profile && <>
          {profileIsCurrentUser && !editing &&
            <GhostButton data-pendo-tag="profile personal information - edit" onClick={() => setEditing(true)} style={{ marginLeft: 'auto' }}>
              <img style={{ marginRight: '8px', height: '16px', marginTop: '-4px' }} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/edit.svg" />
              <span>Edit</span>
            </GhostButton>
          }
          {profileIsCurrentUser && editing && <>
            <GhostButton style={{ marginLeft: 'auto', padding: '5px 23px', marginBottom: '10px' }} onClick={() => setEditing(false)}>
              <p>Cancel</p>
            </GhostButton>
            <RequestButton
              style={{ padding: '5px 23px', marginBottom: '10px' }}
              cta={'Save'}
              request={(afterAction) => updateInfo(afterAction)}
              disabled={disabled}
            />
          </>}
        </>}
      </div>
      <div className={`content_container marg-top-0`}>
        <div id="birthday">
          {editing ?
            <>
              <p className={`${styles.label} marg-top-0`}>Enter your birthday</p>
              <div className={`material-input marg-top-0`}>
                <DatePickerWrapper
                  selected={birthday !== null ? new Date(birthday) : ''}
                  onChange={date => validateBirthday(date)}
                  minDate={new Date().setFullYear(new Date().getFullYear() - 119)}
                  maxDate={new Date()}
                  wrapperClassName={`material-input ${styles.input_txt}`}
                  placeholderText={'MM/DD/YYYY'}
                  label={false}
                />
              </div>
              <ErrorMessage error={birthdateError}/>
            </>
            :
            <>
              <div className={styles.bio}>{(age.current && age.current !== null) ?
                <>
                  <p className={`${styles.label} marg-top-0`}>Age</p>
                  <p className={styles.input_txt}>{age.current}</p>
                </>
                :
                (profileIsCurrentUser ?
                  <p onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your age
                  </p>
                  :
                  <p className={styles.bio}>No age yet</p>
                )
              }</div>
            </>
          }
        </div>
        <Divider />
        
          {editing ?
            <div id="height" className="container">
              <div className="row" style={{maxWidth:'400px'}}>
                <div className={`material-input marg-top-0 col ${styles.inputs}`} style={{padding: '0px', paddingRight: '5px'}}>
                  <p className={`${styles.label}`}>Feet</p>
                  <input
                    className={styles.input_txt}
                    type="zipcode"
                    defaultValue={feet.current ? feet.current : null}
                    placeholder="ft"
                    maxLength="1"
                    pattern="[0-9]+"
                    onChange={(e) => {
                      validateFeet(e.target.value)
                    }}
                  />
                  <span className="highlight"></span>
                </div>
                <div className={`material-input marg-top-0 col ${styles.inputs}`} style={{ padding: '0px', paddingLeft: '5px'}}>
                  <p className={`${styles.label}`}>Inches</p>
                  <input
                    className={styles.input_txt}
                    type="zipcode"
                    value={inches.current ? inches.current : null}
                    placeholder="in"
                    maxLength="2"
                    pattern="[0-9]+"
                    onChange={(e) => {
                      validateInches(e.target.value)
                    }}
                    onBlur={() => validateHeight()}
                  />
                </div>
              </div>
            </div>
            :
            <div id="height">
              {height !== null && height !== '' ?
                <div className={styles.bio}>
                  <p className={`${styles.label}`}>height</p>
                  <p className={styles.input_txt}>{`${Math.floor(height / 12)} ft ${height - (Math.floor(height / 12) * 12)} in`}</p>
                </div>
                :
                (profileIsCurrentUser ?
                  <p style={{ marginTop: '15px' }} onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your height
                  </p>
                  :
                  <p style={{ marginTop: '15px' }} className={styles.bio}>No height yet</p>
                )
              }
            </div>
          }
          <ErrorMessage error={feetError}/>
          <ErrorMessage error={inchesError}/>

        <Divider />
        <div id="weight">
          {editing ?
            <div style={{maxWidth: '400px'}} className={`material-input marg-top-0 ${styles.inputs}`}>
              <p className={styles.label}>Weight in lbs</p>
              <input
                className={styles.input_txt}
                type="zipcode"
                value={weight}
                placeholder={'lbs'}
                onChange={(e) => {
                  validateWeight(e.target.value);
                }}
              />
              <ErrorMessage error={weightError}/>
            </div>
            :
            <>
              {weight !== null && weight !== '' ? <>
                <p className={styles.label}>Weight</p>
                <p className={styles.bio}>
                  {weight}
                </p>
              </>
                :
                (profileIsCurrentUser ?
                  <p style={{ marginTop: '15px' }} onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your weight
                  </p>
                  :
                  <p style={{ marginTop: '15px' }} className={styles.bio}>No weight yet</p>
                )
              }
            </>
          }
        </div>
        <Divider />
        <div id="gender-identity">
          {editing ?
            <>
              <p className={styles.label}>Gender Identity</p>
              <DropdownInput
                type='single'
                options={[
                  {
                    value: 'Gender'
                  },
                  {
                    value: 'Female'
                  },
                  {
                    value: 'Male'
                  },
                  {
                    value: 'Non-Binary'
                  }
                ]}
                values={[gender]}
                setValues={validateGender}
                light={false}
                wrapperClassname={styles.input_txt}
                pendoTag="profile personal information - dropdown"
              />
            </>
            :
            <>
              {gender !== null && gender !== '' && gender !== 'Gender' && gender !== 'gender' ?
                <>
                  <p className={styles.label}>Gender Identity</p>
                  <p className={styles.bio}>{gender.charAt(0).toUpperCase() + gender.slice(1)}</p>
                </>
                :
                (profileIsCurrentUser ?
                  <p style={{ marginTop: '15px' }} onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your gender
                  </p>
                  :
                  <p style={{ marginTop: '15px' }} className={styles.bio}>No gender yet</p>
                )
              }
            </>}
        </div>
        <Divider />
        <div id="marital-status">
          {editing ? <>
            <p className={styles.label}>Marital Status</p>
            <DropdownInput
              type='single'
              options={[
                {
                  value: 'Marital status'
                },
                {
                  value: 'Married'
                },
                {
                  value: 'Single'
                }
              ]}
              values={[maritalStatus]}
              setValues={validateMaritalInput}
              light={false}
              wrapperClassname={styles.input_txt}
              pendoTag="profile marital status - dropdown"
            />
          </>
            :
            <>
              {maritalStatus !== null && maritalStatus !== '' && maritalStatus !== 'Marital status' && maritalStatus !== 'marital status' && maritalStatus !== 'Marital Status' ?
                <>
                  <p className={styles.label}>Marital Status</p>
                  <p className={styles.bio}>{maritalStatus}</p>
                </>
                :
                (profileIsCurrentUser ?
                  <p style={{ marginTop: '15px' }} onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your marital status
                  </p>
                  :
                  <p style={{ marginTop: '15px' }} className={styles.bio}>No marital status yet</p>
                )
              }
            </>
          }
        </div>
      </div>
    </>)
}
