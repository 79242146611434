export const createWebinarContacts = (
  email,
  webinar,
  fullName,
  phone,
  currentJourney,
  zipcode,
  extraInfo,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/webinar_contacts',
    {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        ...window.utmInfo,
        email: email,
        webinar: webinar,
        full_name: fullName,
        phone: phone,
        current_journey: currentJourney,
        new_zipcode: zipcode,
        extra_info: extraInfo,
      })
    }
  )
    .then(response => response.json())
    .then(data => {
      successFunction(data);
    })
}