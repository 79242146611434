import React, { useState, useContext, useEffect } from "react";
import RequestButton from "../../ui_kit/utilities/request_button";
import styles from "../style.module.css";
import styles1 from "../../posts/createpost.module.css";
import Context from "../../../utils/context";
import { successToast } from "../../ui_kit/utilities/success_toast";

export default function Notifications() {
  const [checkedItems, setCheckedItems] = useState([]);
  const [check, setCheck] = useState(false);
  const context = useContext(Context);
  const [user, setUser] = useState(context.auth.getCurrentUser());

  const notificationOptions = [
    { option: "Comments on my own posts" },
    { option: "Replies to my comments" },
    { option: "Likes on my own posts and comments" },
  ];

  const getNotified = [{ option: "SMS*" }, { option: "Email" }];

  let disabled = "disabled";

  const checkedOptions = (option) => {
    if (checkedItems.map((i) => i.includes(option)).includes(true)) {
      setCheckedItems(checkedItems.filter((c) => c != option));
    } else {
      var arr = [...checkedItems];
      arr.push(option);
      setCheckedItems(arr);
    }
    setCheck(!check);
  };

  useEffect(() => {
    setCheckedItems([]);
    if (user.new_comment_notification) {
      checkedItems.push("Comments on my own posts");
    }
    if (user.new_reply_notification) {
      checkedItems.push("Replies to my comments");
    }
    if (user.new_like_notification) {
      checkedItems.push("Likes on my own posts and comments");
    }
    if(user.email){
      setCheckedItems([...checkedItems, "Email"])
    }
    if (user.phone){
      setCheckedItems([...checkedItems, "SMS*"])
    }
  }, []);

  const onClick = (afterAction) => {
    let params = {};
    checkedItems.includes("Comments on my own posts")
      ? (params.new_comment_notification = true)
      : (params.new_comment_notification = false);

    checkedItems.includes("Replies to my comments")
      ? (params.new_reply_notification = true)
      : (params.new_reply_notification = false);

    checkedItems.includes("Likes on my own posts and comments")
      ? (params.new_like_notification = true)
      : (params.new_like_notification = false);

    context.auth.userUpdate(
      params,
      (result) => {
        successToast("Your changes have been saved.");
        setUser(result);
      },
      (errors) => {
        console.log(errors.join(","));
      }
    );
    afterAction();
  };

  if (
    user.new_comment_notification == false &&
    checkedItems.includes("Comments on my own posts")
  ) {
    disabled = "";
  } else if (
    user.new_comment_notification == true &&
    !checkedItems.includes("Comments on my own posts")
  ) {
    disabled = "";
  }
  if (
    user.new_reply_notification == false &&
    checkedItems.includes("Replies to my comments")
  ) {
    disabled = "";
  } else if (
    user.new_reply_notification == true &&
    !checkedItems.includes("Replies to my comments")
  ) {
    disabled = "";
  }
  if (
    user.new_like_notification == false &&
    checkedItems.includes("Likes on my own posts and comments")
  ) {
    disabled = "";
  } else if (
    user.new_like_notification == true &&
    !checkedItems.includes("Likes on my own posts and comments")
  ) {
    disabled = "";
  }

  return (
    <div className={`container-fluid ${styles.account_cont}`}>
      <div className="row justify-content-center">
        <div className="col">
          <div className="container">
            <div className="row">
              <h3 className={styles.header}>Notifications</h3>
            </div>

            <div className="container marg-top-20">
              {notificationOptions.map((n, index) => (
                <div key={index} className="row">
                  <button
                    className={styles.notification_button}
                    onClick={() => checkedOptions(n.option)}
                    style={{ background: "none" }}
                  >
                    <div className="col de-pad" style={{ display: "flex" }}>
                      {checkedItems.includes(n.option) ? (
                        <img
                          className={styles.no_trans}
                          style={{
                            marginLeft: "-2px",
                            marginRight: "-2px",
                          }}
                          src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+19/checkbox+checked.svg"
                        />
                      ) : (
                        <img
                          className={styles.no_trans}
                          style={{ marginLeft: "0px" }}
                          src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+18/Rectangle.svg"
                        />
                      )}
                      <p
                        className={styles.notification_txt}
                        style={{ marginLeft: "10px" }}
                        key={n.option}
                      >
                        {n.option}
                      </p>
                    </div>
                  </button>
                </div>
              ))}
              <div className="row marg-top-20">
                <h4 className={styles.subheader}>Get notified by:</h4>

                {getNotified.map((n, index) => (
                  <div key={index} className="col-sm-3 col-md-3 col-lg-2">
                    <button
                      className={styles.notification_button}
                      onClick={() => checkedOptions(n.option)}
                      style={{ background: "none" }}
                    >
                      <div className="col de-pad" style={{ display: "flex" }}>
                        {checkedItems.includes(n.option) ? (
                          <img
                            className={styles.no_trans}
                            style={{
                              marginLeft: "-2px",
                              marginRight: "-2px",
                            }}
                            src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+19/checkbox+checked.svg"
                          />
                        ) : (
                          <img
                            className={styles.no_trans}
                            style={{ marginLeft: "0px" }}
                            src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+18/Rectangle.svg"
                          />
                        )}
                        <p
                          className={styles.notification_txt}
                          style={{ fontWeight: "500", marginLeft: "10px" }}
                        >
                          {n.option}
                        </p>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
              <div className="row">
                <p className={styles.subtext}>*Msg & data rates may apply</p>
              </div>
              <div
                className={`row ${styles1.cta_wrap}`}
                style={{ display: "flex", marginBottom: "15px" }}
              >
                <RequestButton
                  classnames={`${styles1.create_btn} submit`}
                  style={{ float: "right" }}
                  cta="Save"
                  request={onClick}
                  disabled={disabled}
                  pendoTag="Notifications - Save Button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
