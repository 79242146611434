import React from 'react'
import OptionsAndOther from '../choose_an_option';

export default function TalkTopics({
  answers,
  onSubmit,
  onBack,
  gtm
}) {
  return (
    <div className={`row activity-question`}>
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <OptionsAndOther
          answers={{conversationTopics: answers.conversationTopics}}
          onSubmit={(answer) => onSubmit({ ...answers, conversationTopics: answer.conversationTopics })}
          onBack={onBack}
          title={`What would you like to talk to your mentor about? (Select all that apply.)`}
          para={`We’ll let your mentor know some topics you’d like to talk about.`}
          keyForAnswer={`conversationTopics`}
          choices={[
            'Pros and cons of the procedure',
            'Procedure preparation tips',
            'Recovery tips',
            'Tips for finding the best doctor',
          ]}
          gtm={`${gtm} Conversation Topics`}
          otherTxt="Type other topics"
        />
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
