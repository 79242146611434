import React, { useState } from 'react'

export default function OptionsAndOther({
    onBack,
    onSubmit,
    title,
    para,
    keyForAnswer,
    yesState = null,
    answers,
    choices = [
        'foo',
        'bar',
        'baz'
    ],
    gtm,
    showOther = true,
    otherTxt = "",
}) {
    const [selected, setSelected] = useState(answers[keyForAnswer] || []);
    const [other, setOther] = useState('');

    const submit = (e) => {
        e.preventDefault();
        let newSelected = Object.assign(selected);
        if (other !== '') {
            newSelected.push(other)
        }
        onSubmit({ ...answers, [keyForAnswer]: Object.assign(newSelected) }, yesState);
    }

    const onClick = (choice) => () => {
        if (selected.includes(choice)) {
            setSelected(selected.filter((c) => c !== choice))
        }
        else {
            setSelected(selected.concat([choice]));
        }
    }

    return (
        <div>
            <form id="plans">
                <h5 className="question">
                    {title}
                </h5>
                <p className="question">
                    {para}
                </p>
                {choices.map((choice) => (
                    <div key={choice} className="tile two-column">
                        <label key={choice} className={`preexisting ${selected.includes(choice) ? 'activeCheckbox' : ''}`}><input onClick={onClick(choice)} type="checkbox" name={choice} value="value" />{choice}</label>
                    </div>
                ))}
                {
                  showOther &&
                  <div className="marg-top-20 marg-bottom-20 preexisting-conditions material-input light marg-center">
                      <input placeholder={otherTxt} type="text" name="other" value={other} onChange={(event) => setOther(event.target.value)} />
                      <span className="bar"></span>
                  </div>
                }
                <div className="container">
                    <div className="row button-holder">
                        <button className="back col-lg-6" type="button" onClick={onBack} data-gtm-click-label={`${gtm} - Back`}>Back</button>
                        <button disabled={`${selected.length === 0 ? 'disabled' : ''}`} className={`gtm-preexisting-conditions submit col-lg-6`} type="submit" onClick={(e) => submit(e)} data-gtm-click-label={`${gtm} - Next`}>Next</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
