import React, { Component, useState, useEffect } from 'react'
import './countdown_timer.css'


export default function({
  date='Feb 11, 2021 11:00:00'
}) {

  const [webinarDate, setWebinarDate] = useState(new Date(date).getTime());
  const [timer, setTimer] = useState(new Date().getTime());
  const [distance, setDistance] = useState(webinarDate - timer);
  const [whenIsIt, setWhenIsIt] = useState({
      "days" : 0,
      "hours" : 0,
      "minutes" : 0,
      "seconds" : 0,
    }
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimer(new Date().getTime());
      setDistance(webinarDate - timer);
      setWhenIsIt({
        "days" : Math.floor(distance / (1000 * 60 * 60 * 24)),
        "hours" : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        "minutes" : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        "seconds" : Math.floor((distance % (1000 * 60)) / 1000),
      });
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [whenIsIt]);

  return (
      <div className="row countdown-timer">
        <div className="col-6 col-sm-3 col-lg-3">
          <div className="date-indv-holder">
            <p className="date-title">Days</p>
            <p className="time">{whenIsIt.days}</p>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-lg-3">
          <div className="date-indv-holder">
            <p className="date-title">
              Hours
            </p>
            <p className="time">
              {whenIsIt.hours}
            </p>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-lg-3">
          <div className="date-indv-holder">
            <p className="date-title">
              Minutes
            </p>
            <p className="time">
              {whenIsIt.minutes}
            </p>
          </div>
        </div>
        <div className="col-6 col-sm-3 col-lg-3">
          <div className="date-indv-holder">
            <p className="date-title">
              Seconds
            </p>
            <p className="time">
              {whenIsIt.seconds}
            </p>
          </div>
        </div>
      </div>
  )
}
