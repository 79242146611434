import React, { Component, useState, useEffect } from 'react'
import './testimonials.css'

export default function Testimonials({
  testimonials = [
    {
      "name" : "Angela",
      "bio" : "Talking to a PatientPartner™ before going through surgery was extremely helpful and I’m so glad I found this resource.",
      "photo" : "https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/angela.jpg"
    },
    {
      "name" : "Mario",
      "bio" : "Everybody going through surgery has a myriad of questions, talking to a PatientPartner™ helped me addresses those questions",
      "photo" : "https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/mario.jpg"
    },
    {
      "name" : "Natasha",
      "bio" : "It was so nice to have a PatientPartner™. She was very positive and supportive and made me feel secure in my decisions.",
      "photo" : "https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/natasha.jpg"
    },
  ],
  lilsubtitle = "",
  headline = ""
}) {
//functions here

  return (
  <div className="container-fluid testimonials marg-bottom-100">
    <div className="semibg"></div>
    <div className="container innr-testimonials">
      <div className="row">
        <div className="col move-me-up-mobile">
          <h6 className="text-center marg-top-70 marg-bottom-5 detail">{lilsubtitle}</h6>
          <h2 className="text-center marg-bottom-30">{headline}</h2>
        </div>
      </div>
      <div className="row h-100 move-me-up-mobile">
        {testimonials.map((testimonial) =>
        <div key={testimonial.name} className="col-lg-4 my-auto testimonial-wrap-otr">
          <div className="testimonial-wrap">
            <p className="quotation marg-bottom-20">
              <svg xmlns="http://www.w3.org/2000/svg" width="18.824" height="14.612" viewBox="0 0 18.824 14.612"><path id="Path_532" data-name="Path 532" d="M9.412-34.684l-3.38-1.4-6.4,12.844,4.472,1.768Zm-9.048,0-3.38-1.4-6.4,12.844,4.472,1.768Z" transform="translate(9.412 36.088)" fill="#183859"/></svg>
            </p>
            <p className="quote bold">"{testimonial.bio}"</p>
            <div className="info-container">
              <span className="liner marg-bottom-15"></span>
              {testimonial.photo &&
                <div className="photo marg-bottom-5" style={{backgroundImage: "url(" + testimonial.photo + ")"}}></div>
              }
              <p className="quote name">{testimonial.name}</p>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  </div>
  )
}