export const getInfusionsoftContact = (
  id,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch(`/api/v1/infusionsoft_contacts/${id}`,
    {
      headers: { 
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      }
    })
    .then(response => response.json())
    .then(data => {
      successFunction(data);
    })
}

export const createInfusionsoftContactPPSurvey = (
  id,
  answers,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch(`/api/v1/infusionsoft_contacts/${id}/patient_partner_surveys`,
    {
      method: 'POST',
      headers: { 
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        infusionsoft_contact_id: id,
        patient_partner_survey: answers,
      })
    }
  )
    .then(response => response.json())
    .then(data => {
      successFunction(data);
    })
}