import React, { useContext } from 'react'
import {
    Link
} from "react-router-dom";
import SurgeonAdminPatientPanel from './surgeon_admin_patient_panel'
import PatientOtherUserPanel from './patient_other_user_panel'
import PatientSurgeonAdminPanel from './patient_surgeon_admin_panel';
import Context from '../../../../utils/context';

export default function ConversationOtherUserProfile({
    userProfileType = null,
    otherUser,
    backPath,
    setShowSurgeonModal,
}) {
    const context = useContext(Context);
    const isMobile = context.viewport.getIsMobile();

    let RightPanel = PatientOtherUserPanel;
    switch (userProfileType) {
        case 'SurgeonAdministrativeAssistant':
            RightPanel = SurgeonAdminPatientPanel
            break
        case 'Patient':
            if (otherUser && otherUser.profile.type === 'SurgeonAdministrativeAssistant') {
                RightPanel = PatientSurgeonAdminPanel;
            }
            break;
        default:
            RightPanel = PatientOtherUserPanel
    }

    return (
        <div className="right-side-wrap container h-100">
            <div className="row h-100">
                <div className="col">
                    {isMobile &&
                        <Link to={backPath}>
                            <div style={{ cursor: 'pointer' }} className="col-2 my-auto marg-top-20">
                                <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.183 2.56066C12.7688 1.97487 12.7688 1.02513 12.183 0.43934C11.5972 -0.146447 10.6475 -0.146447 10.0617 0.43934L0.43934 10.0617C-0.146447 10.6475 -0.146447 11.5972 0.43934 12.183L10.0617 21.8054C10.6475 22.3912 11.5972 22.3912 12.183 21.8054C12.7688 21.2196 12.7688 20.2699 12.183 19.6841L3.62132 11.1224L12.183 2.56066Z" fill="#59B6BE" /></svg>
                            </div>
                        </Link>
                    }
                    <RightPanel
                        otherUser={otherUser}
                        isMobile={isMobile}
                        setShowSurgeonModal={setShowSurgeonModal}
                    />
                </div>
            </div>
        </div>
    )
}
