import React from 'react'

export default function StarRating({averageRating}){
  
  const starRow = () => {
    let stars = []
    for(let i=0; i < averageRating; i++){
      stars.push(
      <div>
        ⭐
      </div>)
    }
    return stars

}
  
  return(
    <div className='row justify-content-center'>
      {starRow()}
    </div>
  )
}