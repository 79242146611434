import React, { useState } from 'react'
import InputWithOptions from '../../ui_kit/utilities/input_with_options';
import RequestButton from '../../ui_kit/utilities/request_button';

export default function WhatProcedure({
  selected,
  onSubmit,
  onBack,
  choices = ["I don't see my provider",...gon.all_insurances],
  label = 'Type procedure here...',
  head = 'What surgery are you considering?',
  para = 'We’ll match you with someone who has had the same surgery. Select any that apply.',
  gtm,
}) {
  const [procedure, setProcedure] = useState((selected && selected[0]) || '');
  const onClick = (e) => {
    onSubmit([procedure]);
  }

  const disabled = procedure !== '' ? '' : 'disabled';

  return (
    <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <h5 className="mobile-marg-20 marg-bottom-10">
          {head}
        </h5>
        <p className="question-sub-headline marg-bottom-30">
          {para}
        </p>
        <InputWithOptions
          value={procedure}
          setValue={setProcedure}
          options={choices}
          name={''}
          label={label}
          noMatchTxt={null}
          showOptionsFromStart
          fullWidth
        />
        <div style={{display: 'flex'}} className="button-holder">
          {
            onBack ? 
            <button className="back col-6" type="button" onClick={() => onBack()} data-gtm-click-label={`${gtm} What Procedure Back`}>Back</button>
            :
            <div className="col-6"></div>
          }
          <RequestButton
            request={onClick}
            disabled={disabled}
            classnames={'gtm-preexisting-conditions submit col-6'}
            cta={`Next`}
            gtm={`${gtm} What Procedure Submit`}
          />
        </div>
      </div>
      <div className="col-md-2"></div>
    </div>
  );
}
