import React, { useState, useEffect } from 'react'
import styles from './index.module.css'

export default function Pagination({
    length = 0,
    amountPerPage = 10,
    setMax,
    setMin
}) {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setMax(((counter + 1) * amountPerPage) - 1)
    }, []);

    useEffect(() => {
        if (counter === 0) {
            setMin(0)
            setMax(amountPerPage)
        } else if (counter > 0) {
            setMin(counter * amountPerPage)
            setMax((counter + 1) * amountPerPage)
        }
    }, [counter]);

    return (
        <div className={`text-right`}>
            {length < amountPerPage && <>
                <p className={styles.para}>1 - {length} of {length}</p>
            </>}
            {length > amountPerPage && <>
                {counter === 0 &&
                    <p className={styles.para}>1 - {amountPerPage} of {length}</p>
                }
                {counter !== 0 &&
                    <p className={styles.para}>{counter * amountPerPage} - {(counter + 1) * amountPerPage > length ? length : (counter + 1) * amountPerPage} of {length}</p>
                }
                <div className={styles.arrow_cont}>
                    <button className={`${styles.left_arrow} ${styles.arrow}`} disabled={counter === 0 ? 'disabled' : ''} onClick={() => setCounter(counter - 1)}><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13L1.07071 7.07071C1.03166 7.03166 1.03166 6.96834 1.07071 6.92929L7 1" stroke="#9FA2B4" strokeWidth="2" strokeLinecap="round" /></svg></button>
                    <button className={`${styles.right_arrow} ${styles.arrow}`} disabled={(counter + 1) * amountPerPage > length ? 'disabled' : ''} onClick={() => setCounter(counter + 1)}><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 13L6.92929 7.07071C6.96834 7.03166 6.96834 6.96834 6.92929 6.92929L1 1" stroke="#9FA2B4" strokeWidth="2" strokeLinecap="round" /></svg></button>
                </div>
            </>}
        </div>
    )
}