import React, { useState, useEffect } from 'react'
import ErrorMessage from '../../ui_kit/utilities/error_message'
import { validateText } from '../../../helpers';

export default function ChooseManyOptions({
  answers,
  keyForAnswer,
  onSubmit,
  onBack,
  gtm,
  options,
  title,
  para,
  otherItemLabel,
  submitCta = "Submit",
  otherInput = true,
  chooseMax
}) {
  const [values, setValues] = useState(answers[keyForAnswer] ? answers[keyForAnswer].filter(x => options.includes(x)) : []);
  const [other, setOther] = useState(answers[keyForAnswer] ? answers[keyForAnswer].filter(x => !options.includes(x)).join(',') : '');
  let disabledTile = 'disabled'

  const onClick = (option) => () => {
    if (values.includes(option)) {
      setValues(values.filter((c) => c !== option))
    }
    else {
      setValues(values.concat([option]));
    }
  }

  const onBackClick = (e) => {
    e.preventDefault();
    onBack();
  }

  const onSubmitClick = (e) => {
    e.preventDefault();

    let newValues = [...values];
    if (other !== '') {
      newValues.push(other)
    }
    const newAnswer = { ...answers, [keyForAnswer]: newValues };
    onSubmit(newAnswer);
  }

  let disabled = 'disabled';
  if(other !== ''){
    if (validateText(other)){
      disabled = ''
    } else {
      disabled = 'disabled'
    }
  }
  if (values.length > 0){
    disabled = ''
    if (other !== ''){
      if (validateText(other)){
        disabled = ''
      } else {
        disabled = 'disabled'
      }
    }
  }

  return (
    <div className="row question-five">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <form id="plans">
          <h5 className="question">
            {title}
          </h5>
          <p className="marg-bottom-20">
            {para}
          </p>
          {options.map((option) => (
            <div key={option}  className='tile two-column'>
              <label disabled={chooseMax ? ((values.length === chooseMax && !values.includes(option)) ? disabledTile : '') : '' } key={option} className={`preexisting ${values.includes(option) ? 'activeCheckbox' : ''}`}><input onClick={onClick(option)} type="checkbox" name={option} value="value"/>{option}</label>
            </div>
          ))}
          {otherInput && <>
            <div className="marg-top-20 marg-bottom-20 preexisting-conditions material-input light marg-center">
              <h5 className="question other">
                  Other:
              </h5>
              <input placeholder={`Other ${otherItemLabel} type here`} type="text" name="other" value={other} onChange={(event) => setOther(event.target.value)}/>
              <span className="bar"></span>
            </div>
            {(other !== '' && !validateText(other)) &&
              <ErrorMessage
                error = {`Please enter other ${otherItemLabel} without special characters`}
              />
            }
          </>}
          <div className="row button-holder">
            <button className="back col-6" type="button" onClick={onBackClick} data-gtm-click-label={`${gtm} Back`}>Back</button>
            <button disabled={disabled} className={`gtm-preexisting-conditions submit col-6`} type="submit" onClick={onSubmitClick} data-gtm-click-label={`${gtm} Submit`}>{submitCta}</button>
          </div>
        </form>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
