import React, { useState } from 'react'
import ErrorMessage from '../ui_kit/utilities/error_message'
import { validateText } from '../../helpers';

export default function FirstName({
  defaultFirstName,
  onSubmit,
  gtm,
  head,
}) {
  const [error, setError] = useState('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [name, setName] = useState(defaultFirstName || '');
  let disabled = 'disabled';
  var checkedName = validateText(name);

  if (checkedName === true) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  const onClick = (event) => {
    if (hasBeenSubmitted === false) {
      setHasBeenSubmitted(true);
      if (checkedName === false) {
        setError('Please enter a name without special characters');
        setHasBeenSubmitted(false);
      }
      else {;
        onSubmit(name);
        setError('');
      }
    }
  }
  const validate = (event) => {
    event.preventDefault();

    if (checkedName === false && name !== '') {
      setError('Please enter a name without special characters');
    }
    else {
      setError('');
    }
  }

  return (
    <div className="row">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8 intro-question">
        <h5 className="text-left mobile-marg-20 marg-bottom-20">{head}</h5>
        <form id="infoCap">
          <div className="material-input light">
            <input style={{paddingLeft: '15px'}} className="autofocus" type="text" value={name} onChange={(event) => setName(event.target.value)} onBlur={validate} required />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>First Name</label>
          </div>
          {error &&
            <ErrorMessage
              error={error}
            />
          }
          <div className="row button-holder">
              <button disabled={disabled} className={`wl-tell-us-about-yourself submit col-6`} type="submit" onClick={onClick} data-gtm-click-label={`${gtm} Name Submit`}>Next</button>
          </div>
        </form>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
