import React from 'react'
import './error_message.css'

export default function ErrorMessage({
  error = "Enter a valid email",
  elemColor = '#DF6D48',
  left = false
}) {

  if (error === ''){
    return <></>
  }

  return (
    <div className={`error-message ${left ? 'text-left' : ''}`}>
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.55 12.35H10.45V14.25H8.55V12.35ZM8.55 4.75H10.45V10.45H8.55V4.75ZM9.4905 0C4.2465 0 0 4.256 0 9.5C0 14.744 4.2465 19 9.4905 19C14.744 19 19 14.744 19 9.5C19 4.256 14.744 0 9.4905 0ZM9.5 17.1C5.301 17.1 1.9 13.699 1.9 9.5C1.9 5.301 5.301 1.9 9.5 1.9C13.699 1.9 17.1 5.301 17.1 9.5C17.1 13.699 13.699 17.1 9.5 17.1Z" fill={elemColor}/></svg>
        <p style={{color: elemColor}} className="error-text">{error}</p>
    </div>
  )
}
