import React, { useState } from 'react'
import styles from './styles.module.css'
import ReCAPTCHA from "react-google-recaptcha";

export default function RequestButton({
    request,
    recaptcha = false,
    disabled,
    classnames,
    style,
    cta = '+',
    gtm,
    pendoTag = ''
}) {
    const [loading, setLoading] = useState(false);
    const recaptchaRef = React.createRef();

    const startRequest = (e) => {
      e.preventDefault();
        setLoading(true)
        if (recaptcha) {
            runRecaptcha()
            return
        } else {
            makeRequest()
        }
    }

    const runRecaptcha = () => {
        recaptchaRef.current.reset();
        recaptchaRef.current.execute();
    }

    const makeRequest = () => {
        request(() => setLoading(false))
    }


    return (<>
        {loading &&
            <button style={style} className={`${classnames} ${styles.loading_button}`} type="submit"><div className={styles.dot_flashing}></div></button>
        }
        {!loading &&
            <button data-pendo-tag={pendoTag} data-gtm-click-label={gtm} style={style} className={classnames} disabled={disabled} onClick={(e) => startRequest(e)} type="submit">{cta}</button>
        }
        {recaptcha &&
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={gon.recaptcha_sitekey}
                onChange={makeRequest}
            />
        }
    </>)
}
