import React, { useState } from 'react'
import ChooseManyOptionsWithNone from './choose_many_options_with_none';

export default function PreexistingConditions({
  answers,
  onSubmit,
  onBack,
  ctaPreConditions="Next",
  gtm
}) {
  return (
    <ChooseManyOptionsWithNone
      answers={answers}
      keyForAnswer={`preConditions`}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} Preexisting Conditions`}
      options={[
        'Acid Reflux',
        'Diabetes',
        'High Cholesterol',
        'High Blood Pressure',
        'Sleep Apnea',
        'None Of These',
      ]}
      title={`Have you been diagnosed with any of these health conditions?`}
      para={`We’ll match you with someone who has had similar health conditions. Select any that apply.`}
    />
  );
}
