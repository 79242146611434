import React, { useState } from 'react'
import ErrorMessage from '../ui_kit/utilities/error_message'
import { validateText } from '../../helpers';

export default function ConversationTopics({
  answers,
  onSubmit,
  onBack,
  headline = "What would you like to talk about with your PatientPartner?",
  para = 'Sharing some topics will help prepare your PatientPartner™ for your conversation.',
  cta = 'Submit',
  placeholder = "Tell us here...",
  gtm,
  allowEmpty = false
}) {
  const [conversationTopics, setConversationTopics] = useState(answers.extraInfo || '')

  const onBackClick = (event) => {
    event.preventDefault();
    onBack();
  }

  const onClick = () => {
    event.preventDefault();
    const newAnswer = { ...answers, extraInfo: conversationTopics };
    onSubmit(newAnswer);
  }

  let disabled = 'disabled';
  let error = ''
  if (!allowEmpty) {
    if (conversationTopics) {
      if (!validateText(conversationTopics, 1000)) {
        error = 'Please enter this information without special characters';
      }
      else {
        disabled = '';
      }
    }
  }
  else {
    disabled = '';
  }

  return (
    <div className="row">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <form className="anythingElse">
          <h5 className="text-left mobile-marg-20 marg-bottom-10">
          {headline}
          </h5>
          <p className="marg-bottom-20">
          {para}
          </p>
          <div className="text-box-wrap">
            <textarea className="autofocus" placeholder={placeholder} value={conversationTopics} onChange={(e) => setConversationTopics(e.target.value)}></textarea>
          </div>
          {error &&
            <ErrorMessage
              error = {error}
            />
          }
          <div className="row button-holder">
            <button className="back col-6" type="button" onClick={(e) => onBackClick(e)} data-gtm-click-label={`${gtm} What would you like to talk about - Back`}>Back</button>
            <button disabled={disabled} className={`gtm-preexisting-conditions submit col-6`} type="submit" onClick={onClick} data-gtm-click-label={`${gtm} What would you like to talk about - Submit`}>{cta}</button>
          </div>
        </form>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
