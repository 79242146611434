import React, { useState, useContext } from 'react'
import RequestButton from '../../ui_kit/utilities/request_button';
import { validateEmail, validatePhone } from '../../../helpers';
import ErrorMessage from '../../ui_kit/utilities/error_message';
import AgreeToTerms from '../../ui_kit/utilities/agree_to_terms'
import Context from '../../../utils/context';
import Zendesk from "react-zendesk";
import styles from './index.module.css'

export default function SubmitRequestEmailPhone({
  head = 'x',
  subheader = '',
  description = 'x',
  getInfo = true,
  answers,
  onSubmit
}) {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(answers.acceptedTerms || false);
  const context = useContext(Context);
  const facebookConversionApi = context.facebookConversionApi;

  let disabled = 'disabled';

  var checkedPhone = validatePhone(phone);
  var checkedEmail = validateEmail(email);
  if (checkedEmail === true && checkedPhone && acceptedTerms) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }
  const validate = (event) => {
    event.preventDefault();

    if (checkedEmail === false && email !== '') {
      if (email.indexOf('@') == '-1') {
        setEmail('')
      }
      setError('Please enter a valid email');
    }
    else if (checkedPhone == false && phone !== '') {
      setError('Please enter a valid phone number');
    }
    else {
      setError('');
    }
  }
  if (phone !== "") {
    checkedPhone = validatePhone(phone);
  }

  const onClick = () => {
    if (hasBeenSubmitted == false) {
      setHasBeenSubmitted(true);
      if (checkedEmail === false) {
        setError('Please enter a valid email');
        setHasBeenSubmitted(false);
      }
      else if (checkedPhone == false) {
        setError('Please enter a valid phone number');
        setHasBeenSubmitted(false);
      }
      else {
        const newAnswer = { ...answers, phone, email, acceptedTerms };
        facebookConversionApi.leadEvent({
          email: email
        });
        onSubmit(newAnswer);
        setError('');
      }
    }
  }

  return (
    <>
      <h5 className="text-left mobile-marg-20 marg-top-20 marg-bottom-20">
        {head}
      </h5>
      <img alt='avatars' className='marg-bottom-20' style={{ width: '100%' }} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/avatars.png' />
      <p style={{ fontSize: '20px', fontWeight: '600', color: 'black' }}>{subheader}</p>
      <div className='marg-top-20 marg-bottom-20' style={{ background: '#ECF7F8', borderRadius: '10px' }}>
        <div className='row'>
          <p className={`col ${styles.timeline}`}>
            We'll email you results within
            <span>
              <b className={styles.b_0}>1-2</b>
              <b className={styles.b_1}>days</b>
            </span>
          </p>
        </div>
      </div>
      <p className='marg-bottom-20'>{description}</p>
      {getInfo && <>
        <h5 className='text-left mobile-marg-20 marg-bottom-20'>Your contact information</h5>
        <div style={{ maxWidth: '100%' }} className="material-input light">
          <input type="email" value={email} onChange={(event) => setEmail(event.target.value)} onBlur={validate} required />
          <span className="bar"></span>
          <label>Email</label>
        </div>
        <div style={{ maxWidth: '100%' }} className="material-input light marg-bottom-20">
          <input type="tel" name="phone" value={phone} onChange={(event) => setPhone(event.target.value)} onBlur={validate} required />
          <span className="highlight"></span>
          <span className="bar"></span>
          <label>Phone Number</label>
        </div>
        <AgreeToTerms parentClass={'marg-bottom-20'} acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
        {error !== '' &&
          <ErrorMessage
            error={error}
          />
        }
        <RequestButton
          disabled={disabled}
          cta='Submit'
          request={onClick}
        />
      </>}
      <Zendesk defer zendeskKey={gon.zendesk_key} />
    </>
  )
}
