import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import '../terms.css'
import './index.css'

export default function ReferralPartnerAgreement() {
  return (
    <div class="container-fluid nopadding marg-top-for-transparent-header">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <section class="terms-section">
            <h1><b>REFERRAL PARTNER AGREEMENT</b></h1>
            <p>WHEREAS, PatientPartner is in the business of providing a full service software platform with related services (“PatientPartner Products and Services”), PatientPartner Products and Services includes any other product and similar services which PatientPartner or its Affiliates (as defined below) provide to or implements for a Customer (as defined below).</p>
            <p>WHEREAS, Referral Partner will act as an independent contractor for the limited purpose of introducing and then referring prospective clients (“Prospect” or “Prospects”) to PatientPartner for the sale and/or implementation of PatientPartner Products and Services, in exchange for a referral fee, pursuant to the terms and conditions set forth in this Agreement.</p>
            <p>WHEREAS, Referral Partner will act as an independent contractor for the limited purpose of introducing and then referring prospective clients (“Prospect” or “Prospects”) to PatientPartner for the sale and/or implementation of PatientPartner Products and Services, in exchange for a referral fee, pursuant to the terms and conditions set forth in this Agreement.</p>

            <ol>
              <li>
                <b>Agreement.</b> PatientPartner hereby appoints Referral Partner, and Referral Partner hereby accepts, on a non-exclusive right to market and to refer Prospects to PatientPartner for the implementation of PatientPartner Products and Services in the Territory defined in Exhibit A in accordance with the terms and conditions set forth in this Agreement.  Referral Partner shall not represent Referral Partner is authorized to enter into agreements on behalf of PatientPartner.  Each party must comply with all applicable laws in performance of its duties under this Agreement.  All rights not expressly granted to Referral Partner hereunder are hereby reserved to PatientPartner.  The rights granted to Referral Partner are not assignable.  In the event Referral Partner desires to retain or employ others (or grant sub-agent rights) to perform under this Agreement, then Referral Partner shall obtain PatientPartner’s prior written approval.
                <ol>
                  <li>
                    <b>PatientPartner’s Obligations.</b>PatientPartner’s obligation hereunder is to pay the agreed upon remuneration as provided for below and in Exhibit A to this Agreement and comply with its other obligations as specifically set forth in this Agreement.
                  </li>
                  <li>
                    <b>Referral Partner’s Obligations.</b>
                    <ol>
                      <li><b>Referrals.</b> Pursuant to the procedures set forth in Section 2 below, in order to be entitled to a Referral Fee, Referral Partner may elect to introduce PatientPartner to members of management of Prospects and coordinate the initial verbal or face-to-face meeting between Prospect and PatientPartner.  Referral Partner will use reasonable best efforts to promote and market PatientPartner Products and Services to potential Prospects.  When Referral Partner has a viable potential Prospect for PatientPartner, Referral Partner will submit a Registration Request as described in Section 2 below.</li>
                      <li><b>Expense and Conduct.</b> Each party shall be responsible for their own expenses.  Neither party shall have the authority to incur any obligations or liabilities on behalf of the other party.</li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <b>Procedures for Referring a Prospect.</b> PatientPartner and Referral Partner agree to the following process: Referral Partner shall confirm interest by a Prospect in PatientPartner Products and Services and then submit a Registration Request to PatientPartner via [a portal made available by PatientPartner, or other person as may be directed by PatientPartner.
                <ol>
                  <li>Subsequent to the receipt of the Registration Request (the “Request Date”), PatientPartner will notify Referral Partner, of acceptance or denial of the Registration Request, indicating the Prospect has been accepted for consideration as a potential Customer (“Prospect Acceptance”) or denied.  This step is taken to ensure the Prospect was initiated by Referral Partner, is not being called on by PatientPartner, any affiliate thereof, or by any partner, reseller, representative, distributor of PatientPartner or any Affiliate thereof, and is not a direct or indirect customer of PatientPartner or any Affiliate thereof, or other business reasons at the discretion of PatientPartner and PatientPartner reserves the right to reject any such prospect referral in its sole discretion.  Failure of PatientPartner to provide a Notification will be deemed a denial.</li>
                  <li>Upon any Acceptance of a Prospect, Referral Partner will introduce PatientPartner to Prospect’s management and coordinate the initial verbal (in which there is a substantive discussion of PatientPartner Products and Services functionality), demonstration, or face-to-face meeting between PatientPartner and the Prospect and the Prospect becomes a qualified opportunity as a result (a “Referral”).  PatientPartner will reasonably cooperate in scheduling and participating in these meetings.  Referral Partner will reasonably cooperate with PatientPartner in the process of PatientPartner’s determination whether to accept Prospect as a Customer.</li>
                  <li>In the event no Referral is made within ninety (90) days of Prospect Acceptance, the confirmed Registration Request will no longer be valid, and Referral Partner shall not be eligible for a Referral Fee for such Prospect except as provided in Section 7; provided, however, if the Prospect has demonstrated in writing it is to have a verbal or face-to-face meeting with PatientPartner within the first ninety (90) days and PatientPartner does not meet with the Prospect, this period shall be extended until such a meeting occurs.</li>
                  <li>Subject to Section 2.4, any Referral with whom PatientPartner enters into a binding written contract for the provision of PatientPartner Products and Services, within ninety (90) days after Prospect Acceptance and Referral Partner has performed such other actions with respect to the pursuit and closing of the contract with the Customer as reasonably requested by PatientPartner shall be known as a “Customer.”  If the criteria in the foregoing sentence and the other terms and conditions in this Agreement are met, Referral Partner shall be entitled to Referral Fees, otherwise no referral fees are due to Referral Partner.</li>
                  <li>PatientPartner reserves the right, in its sole discretion and at any time, to modify any function or feature of PatientPartner Products and Services or to cease making any portion of or all of PatientPartner Products and Services available to Prospects or Customers.</li>
                </ol>
              </li>
              <li>
                <b>Independent Contractor Relationship.</b> The relationship between PatientPartner and Referral Partner is that of an independent contractor to each other.  The parties are not partners nor joint venturers.  Neither party shall be deemed to be the representative, partner, joint venture or employee of the other, and neither shall have any authority to make any agreements or representations on the other’s behalf.  Each party shall be solely responsible for the payment of compensation, insurance and taxes of its own personnel, and such personnel are not entitled to the provisions of any employee benefits from the other party.  This Agreement does not create any relationship other than specifically described herein.
              </li>
              <li>
                <b>Referral Fee.</b> For each Prospect that becomes a Customer, Referral Partner’s sole remuneration under this Agreement shall be the Referral Fees earned as set forth on Exhibit A.  Payment of each Referral Fee shall be made [within thirty (30) days following the month] in which compensation from Customer was collected by PatientPartner for which a Referral Fee was earned.  PatientPartner agrees to provide Referral Partner with reports, on a quarterly basis, describing the sales and payment activity relating to each Customer (reasonably itemized by Customer name and type of transaction).
              </li>
              <li>
                <b>Term of the Agreement.</b> The term of this Agreement shall begin on the date this Agreement is mutually executed and continue for thirty-six (36) months (the “Term”).  The Term of the Agreement will automatically renew for an additional thirty-six (36) months unless terminated by either Referral Partner or PatientPartner with at least thirty (30) days written notice prior to the end of any Term.
                <ol>
                  <li>Either party may terminate this Agreement (a) without cause and without penalty upon ten (10) days written notice to the other party, (b) on any insolvency, bankruptcy, assignment for the benefit of creditors, appointment of a trustee or receiver or similar event with respect to the other party; (c) on any governmental prohibition or required alteration of PatientPartner Products and Services or any services provided by PatientPartner hereunder or any violation by the other party of applicable law, rule or regulation; or (d) on any breach by Referral Partner of any of its confidentiality obligations hereunder or violation of any of PatientPartner’s intellectual property rights.</li>
                  <li>PatientPartner may terminate this Agreement upon ten (10) days written notice to the Referral Partner where the Referral Partner breaches any terms of this Agreement.</li>
                </ol>
              </li>
              <li>
                <b>Effect of Termination.</b> Upon termination or expiration of this Agreement for any reason (except pursuant to Sections 5.1(b)(c) or (d), and 5.2 in which case no further Referral Fees will be paid), in which case payments shall cease on termination or expiration, PatientPartner shall make Referral Fee payments to Referral Partner, with respect to any Customer or any approved Prospect who becomes a Customer within ninety (90) days after termination or expiration, in any case where the terms of Section 2 of this Agreement has been complied with, and absent a breach of this Agreement by Referral Partner (in which case compensation ceases).
              </li>
              <li>
                <b>Marketing Practices and Intellectual Property Rights.</b>
                <ol>
                  <li>At all times, Referral Partner shall (i) conduct business in a manner that reflects favorably at all times on PatientPartner Products and Services and the good name, goodwill, and reputation of PatientPartner; (ii) avoid deceptive, misleading, or unethical practices that are or might be detrimental to PatientPartner, PatientPartner Products and Services, or the public, including but not limited to disparagement of PatientPartner or PatientPartner Products and Services; (iii) make no false or misleading representations with respect to PatientPartner Products and Services; (iv) not publish or use any misleading or deceptive advertising material; (v) comply in full with the provisions of the United States' Foreign Corrupt Practices Act ("FCPA"); (vi) shall not solicit or accept any undisclosed payments for services performed under this Agreement, or other benefit of any kind, and (vii) make no representations with respect to PatientPartner Products and Services that are inconsistent with those made by PatientPartner, including all warranties and disclaimers of PatientPartner.</li>
                  <li>Referral Partner acknowledges and agrees, as between PatientPartner and Referral Partner, PatientPartner Products and Services and all worldwide rights under patent, trademark (including those related to PatientPartner and its Affiliates’ Marks), copyright and trade secret laws, and any other intellectual property or proprietary rights recognized in any country or jurisdiction worldwide, including, without limitation, moral rights (where applicable) and similar rights and any related know-how that are embodied in, related to, or represented by, PatientPartner Products and Services (and all portions thereof) are, and at all times will be, the sole and exclusive property of PatientPartner or its Affiliates.  Likewise, Referral Partner acknowledges and agrees that any Prospect's materials and information are the intellectual property of the Prospect.</li>
                  <li>Title to and ownership of trademarks, service marks, trade names, logos or other commercial or product designation(s) of PatientPartner or its Affiliates (the “Marks”), shall remain with PatientPartner.  Except as expressly provided in this Agreement, Referral Partner shall use the Marks in the form provided and in conformance with any trademark usage policies applicable to the Marks so provided.  Partner shall not use the Marks unless and only in the capacity permitted under this Agreement and for the term hereof, and then only in direct relation to PatientPartner Products and Services, and Referral Partner shall never be entitled to act, in any way whatsoever, directly and indirectly, against PatientPartner or its Affiliates rights in Marks including without limitation claiming ownership in any Mark, or initiating a proceeding asserting a right to cancel, oppose or otherwise determine the invalidity of a Mark or application or registration thereof.</li>
                  <li>During the term of this Agreement and for a period of one (1) year following the later to occur of (i) the termination or expiration of this Agreement, or (ii) the final payment to Referral Partner pursuant to this Agreement, neither Referral Partner nor any Affiliate shall, directly or indirectly, (x) solicit on its own or others’ behalf to offer to sell, sell, or contract with any Customer or Prospect, or end user of PatientPartner Products and Services, for services which replaces or supplements PatientPartner Products and Services, (y) induce with any Customer or Prospect, or end user of PatientPartner Products and Services to terminate any portion of PatientPartner Products and Services, or to purchase services similar to or competitive with PatientPartner Products and Services, nor (z) solicit on its own or others’ behalf, or hire any employees, contractors or other representatives of PatientPartner or its Affiliates.</li>
                </ol>
              </li>
              <li>
                <b>Limitation of Liability.</b>
                <ol>
                  <li>IN NO EVENT SHALL PATIENTPARTNER OR ITS AFFILIATES HAVE ANY LIABILITY TO THE OTHER FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR SPECIAL DAMAGES WHATSOEVER (INCLUDING WITHOUT LIMITATION ANY DAMAGES CLAIMED FOR LOSS OF INCOME, REVENUE, OR PROFITS OR FOR LOSS OF GOOD WILL) ARISING FROM OR RELATED TO THIS AGREEMENT.  IN NO EVENT SHALL PATIENTPARTNER BE LIABLE TO REFERRAL AGENT FOR AMOUNTS GREATER THAN REFERRAL FEES PAID TO THE REFERRAL PARTNER IN THE ONE-YEAR PERIOD PRIOR TO WHEN THE CAUSE OF ACTION AROSE.</li>
                  <li>THE FOREGOING LIMITATIONS APPLY TO ALL CAUSES OF ACTION IN THE AGGREGATE, INCLUDING WITHOUT LIMITATION TO BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, PERSONAL INJURY, MISREPRESENTATIONS, AND OTHER TORTS.</li>
                </ol>
              </li>
              <li>
                <b>Additional Agreements.</b> Referral Partner agrees if any claims, demand, suits, or legal proceedings are threatened or arise out of any matters relating to this Agreement, copies of all pertinent information known and/or possessed by PatientPartner shall be promptly provided to PatientPartner.  Referral Partner will indemnify, defend and hold PatientPartner and its Affiliates harmless from and against any and all liabilities, costs, damages and expenses (including legal fees and expenses, if any, as awarded by a court of competent jurisdiction) associated with any claim or action brought against PatientPartner, its affiliates or its suppliers that arises from or relates to: (i) any failure of Referral Partner to comply with any applicable law, regulation or administrative rule; (ii) the wrongful acts (including misrepresentations) or omissions of Referral Partner; and (iii) breach of any terms of this Agreement.
              </li>
              <li>
                <b>Miscellaneous.</b>
                <ol>
                  <li><b>Parties In Interest; Assignment.</b> This Agreement shall bind and inure to the benefit of the parties hereto and their respective successors and any permitted assigns.  Referral Partner shall not assign or transfer this Agreement or any rights hereunder to a third party without the written consent of PatientPartner.  Any attempt to do so shall be void.</li>
                  <li><b>Captions.</b> Captions and headings in the Agreement are for convenience and reference only and shall in no way be held to explain, modify, amplify or aid in the interpretation, construction or meaning of the provisions of the Agreement.</li>
                  <li><b>Waiver.</b> The waiver by either party of a breach of any provision of this Agreement will not operate or be interpreted as a waiver of any other or subsequent breach.  A failure of either party to exercise any right provided for herein shall not be deemed to be a waiver of any right hereunder.</li>
                  <li><b>Legal Fees and Applicable State Law.</b> This Agreement shall be construed in accordance with the laws of the State of California.  If any legal action is necessary to enforce any of the terms of this agreement, the prevailing party shall be entitled to payment of its reasonable attorneys’ fees by the non-prevailing party, in addition to any other relief to which it may be entitled.</li>
                  <li><b>Notices.</b> Any notices provided or permitted under this Agreement shall be given by U.S.  Certified Mail, postage paid, to be sent to the party's address listed at the beginning of the Agreement, or the last address designated by the party in writing:</li>
                  <li><b>Severability.</b> Each provision of this Agreement is severable from the whole, and if one provision is declared invalid, the other provisions shall remain in full force and effect.  In the event any provision of this Agreement is held invalid or unenforceable by a court having jurisdiction over the parties, the parties agree the invalid or unenforceable provision shall be replaced with a valid provision which most closely approximates the intent and economic effect of the invalid provision.</li>
                  <li><b>Modifications.</b> Modifications, amendments, supplements to or waivers of this Agreement, or any addendum or amendment thereof are valid only if in writing and executed by authorized agents of both parties.</li>
                </ol>
              </li>
              <li><b>Confidential Information and Employee Non-solicitation.</b>
                <ol>
                  <li><b>Confidentiality.</b> Referral Partner understands and agrees during the course of this Agreement it may be furnished with or otherwise have access to information PatientPartner considers to be confidential, including but not limited to, PatientPartner Products and Services, business, technology, software (whether in source code, embedded, object code or in documentation), solutions, hardware, specifications, designs, plans, drawings, innovations, benchmark and evaluation testing and data and associated results and conclusions, data, prototypes, demonstration materials, customer and/or prospective customer information, pricing and financial information of the parties including without limitation data, testing, analysis, evaluations and conclusions created by the Referral Partner resulting from the disclosure herein; or any other information by its very nature constitutes information of a type that any reasonable business person would conclude was intended by PatientPartner to be treated as proprietary, confidential, or private (the “Confidential Information”).  Referral Partner agrees to secure and protect the Confidential Information of PatientPartner in a manner consistent with the maintenance of PatientPartner’s rights therein, using at least as great a degree of care as it uses to maintain the confidentiality of its own confidential information of a similar nature, but in no event use less than reasonable efforts.  Likewise, Referral Partner acknowledges and agrees that any Prospect's materials and information are the confidential information of the Prospect and will be secured and protected by Referral Partner as required for all Confidential Information pursuant to this Section 11.1.</li>
                  <li><b>Non-Confidential Information.</b> Notwithstanding Section 11.1, Confidential Information of a party shall not include information which: (i) is, as of the time of its disclosure or thereafter becomes part of the public domain other than through the receiving party; (ii) was rightfully known to the receiving party as of the time of its disclosure without obligation of confidentiality, as proved by clear and convincing evidence; (iii) is independently developed by the receiving party prior to the date of this Agreement as shown by contemporaneous; or (iv) is required to be disclosed pursuant to a duly authorized subpoena, court order, or government authority, whereupon the party subject to same shall provide prompt written notice to the other party prior to such disclosure, so that such party may seek a protective order or other appropriate remedy.</li>
                  <li><b>Ownership.</b> All Confidential Information shall remain the property of PatientPartner or the Prospect, as applicable.  Nothing in this Agreement is intended to grant any rights in or to the Confidential Information of the other party except as expressly set forth herein.  All Confidential Information shall be destroyed, or returned to the disclosing party upon written request or termination of this Agreement.</li>
                  <li><b>Equitable Relief.</b> In the event of a breach by the other party of Section 7.4 or this Section 11, the non-breaching party may not have an adequate remedy solely in money damages and any such breach will cause the non-breaching party irreparable harm.  In the event of such breach, the non-breaching party shall be entitled, without the requirement of posting a bond or other security, to seek equitable relief, including an injunction or specific performance.</li>
                </ol>
              </li>
              <li>
                <b>Entire Agreement.</b> The parties have read this Agreement and agree to be bound by its terms, and further agree that it, together with all Schedules, Exhibits and attachments hereto (the terms of which are incorporated herein by this reference), constitutes the complete and entire agreement of the parties and supersedes all and merges all previous communications, oral or written, and all other communications between them relating to the subject matter hereof.  Sections 3, 6, 7.2, 7.3, 7.4, 8, 9, 10, 11 and 12 shall survive termination or expiration of this Agreement.
              </li>
            </ol>
          </section>
          <section class="terms-section">
            <h1><b>EXHIBIT A</b></h1>
            <h1><b>REFERRAL FEE</b></h1>
            <ol>
              <li><b>Referral Fee.</b> Referral Partner is eligible for the referral fee (“Referral Fee”) as outlined below:</li>
              <li><b>Conditions to Payment of Referral Fee.</b> Pursuant to the terms of the Agreement and subject to Section 2.5 of this Agreement, a Prospect must become a Customer by entering into an agreement with PatientPartner and pay for Eligible Commissionable Revenue.</li>
              <li><b>Conditions to Introduction Fee.</b> Pursuant to the terms of the agreement and subject to Section 2.5 of this agreement. A prospect must accept and attend a meeting with a representative from PatientPartner to be eligible for any commissionable revenue.</li>
              <li><b>Computation of Referral Fee:</b>
                <ol>
                  <li>$2,500</li>
                </ol>
              </li>
              <li><b>Computation of Introduction Fee:</b>
                <ol>
                  <li>$500</li>
                </ol>
              </li>
            </ol>
          </section>
        </div>
      </div>
    </div>
  )
}
