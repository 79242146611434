import React, { useState, useEffect } from "react";
import Post from "../posts/post";
import { getPost } from '../../api/posts';
import { useParams, useHistory } from 'react-router-dom'

export default function SinglePostPage() {
  const { id } = useParams();
  let history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const commentToHightlight = queryParams.get('comment');

  const removePost = (post) => {
    history.push(`/member/newsfeed`);
  };

  return (
    <div style={{backgroundColor:'#FAFBFC', paddingTop: '30px', minHeight:'100vh', paddingBottom: '20px'}}>
      <div style={{ maxWidth: '700px', margin: '0px auto', padding: '0px 20px'}} >
        {<Post defaultShowComments id={id} removePost={removePost} commentToHightlight={commentToHightlight} />}
      </div>
    </div>
  );
}
