var saveAs = require('file-saver');

export const getExport = (successCallback) => {
  fetch(`/api/v1/patient_demographics_exports`, {
    method: 'GET',
    headers: {
        "Content-Type": "application/csv",
      },
  })
  .then(response => response.blob())
  .then(blob => saveAs(blob, 'export.csv'));
}
