import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { getInfusionsoftContact, createInfusionsoftContactPPSurvey } from '../../../api/infusionsoft_contacts'
import LoadingScreen from '../../loading_screen';
import HoursAndMinutes from '../../questionnaire/feedback_questions/hours_and_minutes';
import OptionsAndOther from '../../questionnaire/feedback_questions/options_and_other';
import OtherTextfield from '../../questionnaire/feedback_questions/other_textfield';
import YesNoSubmit from '../../questionnaire/feedback_questions/yes_no_submit';

export default function PatientPartnerSurvey({}) {
  const { id } = useParams();
  const [patientName, setPatientName] = useState('');
  const [ppName, setPpName] = useState('');
  const [showLoading, setShowLoading] = useState(true);
  const [questionnaireState, setQuestionnaireState] = useState('intro');
  const [fade, setFade] = useState(false);
  const [slideLeft, setSlideLeft] = useState(false);
  const [slideRight, setSlideRight] = useState(false);
  const [answers, setAnswers] = useState({});
  const [questionnaireHistory, setQuestionnaireHistory] = useState(['intro']);

  useEffect(() => {
    getInfusionsoftContact(id, (data) => { setPatientName(data.infusionsoft_contact.first_name); setPpName(data.infusionsoft_contact.patient_partner_name); setShowLoading(false); });
  }, [id]);

  const startFadeOut = () => {
    setFade(true);
    setSlideLeft(true);
    setShowLoading(true);
  }

  const startFadeIn = () => {
    setShowLoading(false);
    setSlideLeft(false);
    setSlideRight(true);
    setSlideRight(false);
    setFade(false);
  }

  const contactSyncWrapper = (contactId, answers, nextState, callback) => {
    if (nextState !== null) {
      setQuestionnaireHistory([...questionnaireHistory, nextState])
    }
    createInfusionsoftContactPPSurvey(
      contactId,
      answers,
      (data) => {
        callback(data)
      }
    );
  }

  const onSubmit = (newAnswer, nextState) => {
    if (questionnaireState === 'intro') {
      nextState = 'have_phone_call'
      startFadeOut();
      setQuestionnaireState(nextState);
      startFadeIn();
      return
    }
    if (questionnaireState === 'how_are_you_feeling') {
      contactSyncWrapper(id, { ...answers, ...newAnswer, finished_survey: true }, nextState, () => { startFadeIn(); });
    }
    setAnswers({ ...answers, ...newAnswer })
    startFadeOut();
    setQuestionnaireState(nextState);
    contactSyncWrapper(id, { ...answers, ...newAnswer }, nextState, () => { startFadeIn(); });
  }

  const onBackClick = () => {
    setFade(true);
    setSlideRight(true);
    var arr = questionnaireHistory;
    if (questionnaireHistory.length > 1) {
      arr.splice(-1)
    }
    setQuestionnaireHistory(arr);
    setQuestionnaireState(questionnaireHistory[questionnaireHistory.length - 1]);
    setSlideLeft(false);
    setSlideRight(false);
    setFade(false);
  }

  return (
    <div>
      <div id={`questionnaire-scroll-to`} className={`container questionnaire-wrapper surgeon-match-feedback marg-bottom-20`}>
        {showLoading &&
          <div className="load-screen-wrapp-full-size">
            <LoadingScreen />
          </div>
        }
        <div className="row h-100 questionnaire-content">
          <div className="col-lg-4 col-1"></div>
          <div className="col-lg-4 col-10">
            <div className={`fadeOp ${fade ? 'fadeOut' : ''} ${slideLeft ? 'slideLeft' : ''} ${slideRight ? 'slideRight' : ''}`}>
              {questionnaireState === 'intro' && showLoading !== true &&
                <>
                  <h2 className="marg-bottom-20">Share your feedback</h2>
                  <p className="marg-bottom-15">Hi {ppName}, thank you for sharing feedback about your most recent call with {patientName}.</p>
                  <p className="marg-bottom-30">This survey should only take a minute and will help us ensure improve the program.</p>
                  <button onClick={() => onSubmit()} data-gtm-click-label={`Patient Partner Survey Start Survey`}>Start survey</button>
                </>
              }
              {questionnaireState === 'have_phone_call' &&
                <>
                  <YesNoSubmit
                    onBack={onBackClick}
                    onSubmit={onSubmit}
                    answers={answers}
                    title={`Did you have a phone call with ${patientName}?`}
                    para={'We want to make sure you were able to connect with the patient.'}
                    keyForAnswer={'have_phone_call'}
                    yesState={'call_length'}
                    noState={'why_didnt_you_have_call'}
                    gtm={'Patient Partner Survey Did You Have A Phone Call'}
                  />
                </>
              }
              {questionnaireState === 'call_length' &&
                <HoursAndMinutes
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`How long was the call?`}
                  para={'This will help us better estimate time committments for future call requests.'}
                  keyForAnswer={'call_length'}
                  choices={[
                    'Pre-op questions',
                    'Recovery and post-op',
                    'My personal experience',
                  ]}
                  yesState={'conversation_topics'}
                  gtm={'Patient Partner Survey Call Length'}
                />
              }
              {questionnaireState === 'conversation_topics' &&
                <OptionsAndOther
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`What are some things you discussed during the call?`}
                  para={'We want to make sure you feel supported. Select all that apply.'}
                  keyForAnswer={'conversation_topics'}
                  choices={[
                    'Pre-op questions',
                    'Recovery and post-op',
                    'My personal experience',
                  ]}
                  yesState={'how_are_you_feeling'}
                  gtm={'Patient Partner Survey Conversation Topics'}
                />
              }
              {questionnaireState === 'why_didnt_you_have_call' &&
                <OptionsAndOther
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`Did something happen that prevented the connection?`}
                  para={'We want to make sure we’re doing everything we can to make meaningful connections.'}
                  keyForAnswer={'why_didnt_you_have_call'}
                  choices={[
                    'Patient didn’t answer',
                    'Patient needed to reschedule',
                    'I needed to reschedule',
                  ]}
                  yesState={'how_are_you_feeling'}
                  gtm={'Patient Partner Survey Why Didnt You Have Call'}
                />
              }
              {questionnaireState === 'how_are_you_feeling' &&
                <OtherTextfield
                  onBack={onBackClick}
                  onSubmit={onSubmit}
                  answers={answers}
                  title={`How are you feeling after the call?`}
                  para={'We want to make sure you have a positive experience. Share any feedback you might have!'}
                  keyForAnswer={'how_are_you_feeling'}
                  yesState={'thank_you'}
                  cta={'Submit'}
                  gtm={'Patient Partner Survey How Are You Feeling'}
                  placeholder={'Let us know how you are feeling...'}
                />
              }
              {questionnaireState === 'thank_you' &&
                <>
                  <h2 className="marg-bottom-20">Thank you!</h2>
                  <p>We’ve received your feedback and will use the information you shared to improve the program.</p>
                </>
              }
            </div>
          </div>
          <div className="col-lg-4 col-1"></div>
        </div>
      </div>
    </div>
  )
}
