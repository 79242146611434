import React, { useState } from 'react'
import styles from './index.module.css'

export default function MinimalTestimonials({
  testimonials = [
    {
      name: 'Martin',
      location: 'Phoenix, AZ',
      content: 'I used to think reviews were enough until I found out some reviews are bogus. PatientPartner helped me find a doctor that performed over 1,300 ACL repairs which really made me feel good'
    },
    {
      name: 'Sarah',
      location: 'San Francisco, CA',
      content: 'Getting peace of mind is worth every penny. '
    }
  ],
  autoplay = false
}) {
  const [selectedTestimonial, setSelectedTestimonial] = useState(0);
  let testimonial = testimonials[selectedTestimonial]

  setTimeout(() => {
    if (autoplay) selectedTestimonial < (testimonials.length - 1) ? setSelectedTestimonial(selectedTestimonial + 1) : setSelectedTestimonial(0)
  }, 4000);

  return (
    <div className='container-fluid' style={{ background: '#DDF7F9' }}>
      <div className={`container ${styles.testimonials}`}>
        <div className='row'>
          <div className='col'>
            <p className={styles.head}>In our patients' words</p>
            <div className={styles.testimonial}>
              <p className={styles.content}>{testimonial.content}</p>
              <p className={styles.name}>{testimonial.name} {testimonial.location && `, ${testimonial.location}`}</p>
            </div>
            <div className={styles.pagination_wrapper}>
              {testimonials.map((t, i) =>
                <div className={`${styles.pagination} ${selectedTestimonial === i && styles.selected}`} onClick={() => setSelectedTestimonial(i)}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}