import React, { useState, useContext, useEffect } from "react";
import Context from '../../../utils/context';
import styles from '../style.module.css';
import { Link } from "react-router-dom";
import LoadingScreen from '../../loading_screen';
import Post from "../../posts/post";
import { getPosts } from "../../../api/posts";

export default function PostSection() {
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const user = context.auth.getCurrentUser();

  const getTopTwoPosts = () => {
    setIsLoading(true);
    getPosts({ offset: 0, top_two: true }, (topTwoPosts) => {
      setPosts([...posts, ...topTwoPosts]);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getTopTwoPosts();
  }, [])

  return(
  <>
    <div className="container">
      <div className='container'>
        <div className='row' style={{display: 'flex', flexWrap:'wrap'}}>
          <img alt='community-icon' className='marg-right-10' src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/friends.svg'/>
          <p className={`marg-bottom-10 col ${styles.subheader}`}>{user.sign_in_count === 1 ? `Join the discussion` : `Here's what you missed`}</p>
            {!isMobile &&
              <Link to='/member/discussions'>
                <p className={`${styles.see_more_btn} text-link no-arrow`}>See all discussions</p>
              </Link>
            }
        </div>
        <div className='row marg-top-10 marg-bottom-10'>
          <p className={styles.light_text}>{user.sign_in_count === 1 ? `Have questions? Wondering what recovery is like? looking for tips or resources? Join the discussion and connect with others on the platform.` : `Catch up on hot topics and popular posts in the PatientPartner community. Rejoin the discussion today.`}</p>
        </div>
      </div>
    {
      isLoading && <LoadingScreen/>
    }
    {
      !isLoading && posts.map((post, i) => (
        <div key={`post-${i}`} className='row' style={{display: 'flex', flexWrap:'wrap'}}>
          <Post key={`newsfeed-post-${post.uuid}`} id={post.uuid} post={post} />
        </div>
        ))
    }
    </div>
    {isMobile &&
      <Link to='/member/discussions'>
        <button className='col marg-bottom-30 tertiary_hov'>See all discussions</button>
      </Link>
    }
</>
  )
}