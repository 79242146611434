import React, { Component, useEffect, useState } from 'react'
import './index.css'

export default function PatientPartnerColumn({
  partner,
  actionContent,
  index,
  showWeightLoss = false,
  showAge = false,
  showSurgerySpecialty = false,
  showTitle = false,
  showShowMore = true,
  article = false,
}) {
  const [showLongBio, setShowLongBio] = useState(false);
  const showLessClick = () => {
    setShowLongBio(false);
  }
  const showMoreClick = () => {
    setShowLongBio(true);
  }

  const renderBio = (bio) => {
    const shortBio = bio.substring(0, 100) + "...";
    if (shortBio === bio) {
      return bio;
    }
    if (showLongBio) {
      return (
        <>
          <span>{bio}</span>
          {showShowMore == true &&
              <p className="text-link no-arrow marg-top-20" onClick={showLessClick}>Show less</p>
          }
        </>

      );
    }

    return (
      <>
        <span className="short-bio">{bio}</span>
        {showShowMore == true &&
          <p className="text-link no-arrow marg-top-20" onClick={showMoreClick}>Show more</p>
        }
      </>
    );
  }

  if(index < 10){
    index = "0" + (index);
  }

  return (
    <div className="pp-pre-wrapper">
      <div className={`pp-wrapper ${article ? 'pp-grow' : ''}`}>
        <div className="pp-image" style={{backgroundImage: `url(${partner.image})`}}></div>
        <div className="content">
          {/* <h6 className="index-of-slide">{index}</h6> */}
          {!article &&
            <h5 className="name">{partner.name}</h5>
          }
          {article &&
            <h6 className="name">{partner.date}</h6>
          }
          {article &&
            <h5 className="name">{partner.name.substring(0, 50) + "..."}</h5>
          }
          <div className="pp-details">
          {
            showSurgerySpecialty && (
              <p className="specialty">Specialty: <b>{partner.surgery_specialty}</b></p>
            )
          }
          {
            showTitle && (
              <p className="title">Title: <b>{partner.title}</b></p>
            )
          }
          {
            showAge && partner.extra && partner.extra.age && (
              <p className="age">Age: <b>{partner.extra.age}</b></p>
            )
          }
          {
            showWeightLoss && partner.extra && partner.extra.weight_loss && (
              <p className="weightloss">Weight-loss: <b>{partner.extra.weight_loss}</b></p>
            )
          }
          </div>
          <div className="bio">{renderBio(partner.bio)}</div>
        </div>
        <div className="action-content">
          {actionContent}
        </div>
      </div>
    </div>
  )
}
