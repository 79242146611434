import React, { useState } from 'react'
import FAQ from './faq'

export default function FAQs({
    title = "FAQ's",
    faqs = [
        {
            headline: 'What is PatientPartner?',
            para: 'Think of PatientPartner as an advocate for your health. A friend, a resource, and a guide through surgery. We are a community of like-minded patients and verified doctors who are here to guide you every step of the way. Learn from personal insights, success stories, and advice on specific surgery, treatment, or condition. Our goal is to create a better healthcare experience for everyone.'
        },
        {
            headline: 'How much time is required to be a PatientPartner Mentor?',
            para: 'As much time as you are willing to dedicate! As a PatientPartner Mentor, you have the freedom to accept or pass any connection request. The commitment for each accepted connection is simply messaging back and forth on our platform or a phone call through our platform. On average, current mentors say they spend between 1-3 hours a week providing support to patients.'
        },
        {
            headline: 'How do PatientPartner Mentors communicate with patients?',
            para: 'All communication happens within your PatientPartner account where you can chat or call the patient. Your personal contact details are never shared.'
        },
        {
            headline: 'Are there guidelines for what I can and cannot say to a patient?',
            para: 'Our goal is to create a safe space where patients can have authentic connections and ask personal questions. While we do not provide scripts or strict guidelines, we ask that mentors focus on their personal experience and do not provide medical or insurance advice.'
        },
        {
            headline: 'Who can I talk to if I have more questions about becoming a PatientPartner Mentor?',
            para: 'You can contact the PatientPartner team directly here and we will get back to you within 24 hours. We look forward to discussing the opportunity with you further!'
        }
    ]
}) {
    //functions here

    return (
        <div>
            <h2 style={{ 'color': '#314D69' }} className='text-center marg-top-90'>
                {title}
            </h2>
            {faqs.map((faq) =>
                <FAQ
                    headline={faq.headline}
                    para={faq.para}
                    content={faq.content}
                    openHeight={faq.openHeight}
                    headlineContent={faq.headlineContent}
                />
            )}
        </div>
    )
}