import React from 'react'
import './index.css'

export default function SimpleHeader({
  logoClickable,
  openInNewTab,
  logo = false,
}) {
  const renderLogo = () => {
    return (
      <>
        <img alt="logo" id="logo" src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/PatientPartner+Logo_Teal.png" data-gtm-click-label="Nav Logo Embedded/iFrame"/>
      </>
    );
  }

  return (
    <header className="no-shadow">
      <div className="container">
        <div className="header-outr">
          <div className={logo ? 'hdr-logo-lg': 'hdr-logo'}>
          {
            logoClickable
              ? openInNewTab ? <a target="_blank" href="https://www.patientpartner.com/">{renderLogo()}</a> : <a href="/">{renderLogo()}</a>
              : renderLogo()
          }
          {
            logo && <img src={logo} alt="logo" />
          }
          </div>
        </div>
      </div>
    </header>
  )
}
