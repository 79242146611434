import React, { useState } from "react";
import SimpleAvatar from "../ui_kit/utilities/simple_avatar";
import PostCommentButtonRow from "./post_comment_button_row";
import { getPost } from "./../../api/posts";
import styles from "./style.module.css";
import Comment from "./comments";
import ImageGridDisplay from "../ui_kit/utilities/image_grid_display";
import ProfileTooltipWrapper from '../users/profile_tooltip_wrapper';
import UrlPreview from "./url_preview";
import DOMPurify from '../../utils/purify.min.js'

export default function PostComment({
  comment,
  index,
  length,
  refreshParentPost,
  commentToHightlight,
  showPath = true
  }) {
  const [reply, setReply] = useState(false);
  const childComments = comment.comment_tree || [];
  const [edit, setEdit] = useState(false);
  const linkifyHtml = require('linkifyjs/html');

  const nestedComments = childComments.map((comment, i) => {
    return (
      <PostComment
        key={comment.uuid}
        comment={comment}
        refreshParentPost={refreshParentPost}
        index={i}
        length={childComments.length}
        showPath
      />
    );
  });

  return (
    <div className={`${styles.wrapper}`} style={{position: 'relative'}}>
      {showPath && index !== length - 1 &&
        <div className={`${styles.left_line}`}></div>
      }
      <div id={comment.uuid.replace(/[0-9]/g, '')} style={{position: 'relative'}} className={`row marg-top-5 marg-bottom-10 ${commentToHightlight === comment.uuid ? `${styles.highlight_comment} scroll-to-comment` : ''}`}>
        {showPath &&
          <div className={`${styles.left_hook}`}></div>
        }
        {showPath && index === length - 1 &&
          <div className={`${styles.left_line_blocker}`}></div>
        }
        <div className="col">
        <SimpleAvatar
          profile={comment.profile}
          showInfo
          showTime
          post={comment}
          showProfileTooltip
          showTypeIcon={true}
          userSlug={comment.user_slug}
          showTypeIcon
        />
          {
            edit && (
              <Comment
                commentToEdit={comment}
                defaultExpandComment
                refresh={(callback = () => { }) => {
                  setEdit(false);
                  refreshParentPost();
                  callback()
                }}
                placeholder={'Leave a reply...'}
                cta="Send reply"
              />
            )
          }
          {
            !edit && (
              <>
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(linkifyHtml(comment.body.replaceAll('\n', '<br/>'), {target: 'blank'}))}}></p>
                {comment.post_images.length < 1 && comment.url_preview_metadata &&
                  <UrlPreview metadata={comment.url_preview_metadata} />
                }
                <div>
                  <ImageGridDisplay
                    photos={comment.post_images.map(i => i.image)}
                    type={'inline'}
                  />
                </div>
              </>
            )
          }
          <PostCommentButtonRow
            post={comment}
            clickReply={() => setReply(!reply)}
            refreshPost={refreshParentPost}
            onEdit={() => setEdit(true)}
          />
          {/*innrComment.descendant_count > 0 && childComments.length === 0 &&
            <div className={`${styles.replies_btn_wrap}`}>
              <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/replies.svg" />
              <p className={`${styles.replies_txt}`}>{innrComment.descendant_count} Replies</p>
            </div>
          */}
        </div>
      </div>
      <div style={{ marginLeft: '20px', position:'relative' }}>
        {reply && (
          <Comment
            post={comment}
            defaultExpandComment
            refresh={() => { refreshParentPost(); setReply(!reply); }}
            focus={true}
            placeholder={'Leave a reply...'}
            cta="Send reply"
          />
        )}
        {nestedComments}
      </div>
    </div>
  );
}
