import React, { Component, useEffect, useState } from 'react'
import './index.css'

export default function({
  article,
  actionContent,
  index,
  showTitle = false,
}) {
 

  return (
    <div className="small-article-pre-wrapper marg-top-40">
      <div className="small-article-wrapper row">
        <div className="article-preview-image" style={{backgroundImage: `url(${article.image})`}}></div>
        <div className="article-preview-content">
          <h6 className="small-article-date marg-bottom-5">{article.date}</h6>
          <h5 className="small-article-title marg-bottom-10">{article.name}</h5>
          <a href={article.link} target="_blank"><p className="text-link">Read more</p></a>
        </div>
        <div className="action-content">
          {actionContent}
        </div>
      </div>
    </div>
  )
}
