import React from 'react'
import PhotoWBlueBg from '../photo_w_blue_bg';
import './index.css'

export default function ImgLeftTextRight({
    img,
    headline,
    subheadline,
    littlePara,
    list,
    cta,
    blueBg = true,
    bgCover = false,
    containerClassNames = "marg-top-100 marg-bottom-100 h-100",
    afterLittlePara = null,
    smallheadline = null
}) {
    return (
      <div className="container second-sec">
        <div className={`row ${containerClassNames}`}>
          <div className="col-md-5 leftside my-auto">
            <PhotoWBlueBg img={img} blueBg={blueBg} bgCover={bgCover} />
          </div>
          <div className="col-md-7 my-auto rightside">
            <h2 className="marg-bottom-30">
              {headline}
            </h2>
            {
              smallheadline &&
              <h6 className="marg-bottom-10">
                {smallheadline}
              </h6>
            }
            <h5 className="marg-bottom-10">
              {subheadline}
            </h5>
            <ol className={`${littlePara ? 'marg-bottom-15' : 'marg-bottom-40'}`}>
              {
                list.map((l, index) => {
                  return (
                    <li key={`img-left-text-right-${index}`}>{l}</li>
                  )
                })
              }
            </ol>
            {littlePara &&
              <p className='marg-bottom-40'>{littlePara}</p>
            }
            { afterLittlePara }
            {
              cta
            }

          </div>
        </div>
      </div>
    )
}
