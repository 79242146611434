import React, { useRef } from "react";
import styles from "./index.module.css";
import { useClickAway } from "react-use";

export default function PlatformModal({
  setModal,
  onSubmit,
  submitCta = 'Submit',
  submitGtmClickLabel = '',
  cancelCta = 'Cancel',
  onCancel = () => { setModal(false) },
  submitDisabled = '',
  title = 'title',
  content = <p>hello world</p>,
  width = 5
}) {
  const ref = useRef(null);
  const ref_1 = useRef(null);

  useClickAway(ref_1, () => {
    setModal(true);
  });

  useClickAway(ref, () => {
    setModal(false);
  });

  return (
    <div className={`${styles.create_post_otr} platform-modal container-fluid`}>
      <div className={`container ${styles.cp_cont}`}>
        <div className="row justify-content-center">
          <div ref={ref} className={`col-lg-${width} ${styles.cp_col}`}>
            <div className={styles.create_post_wrap}>
              <div className={`${styles.create_new_banner}`}>
                <p className={`${styles.nb_para}`}>{title}</p>
                <span
                  onClick={() => setModal(false)}
                  className={`${styles.close}`}
                >
                  <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg" />
                </span>
              </div>
              <div className={`${styles.content}`}>
                {content}
              </div>
              <div className={`${styles.cta_wrap}`}>
              {
                onCancel &&
                  <button onClick={onCancel} className={`${styles.create_btn} ${styles.cancel} liner`}>
                    {cancelCta}
                  </button>
              }
              {
                onSubmit &&
                  <button data-gtm-click-label={submitGtmClickLabel} disabled={submitDisabled} className={`${styles.create_btn}`} onClick={() => onSubmit(false)}>
                    {submitCta}
                  </button>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
