import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import '../terms.css'
import './index.css'

export default function ServiceAgreement() {
  return (
    <div class="container-fluid nopadding marg-top-for-transparent-header">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <section class="terms-section">
            <h1><b>SUBSCRIPTION SERVICE AGREEMENT</b></h1>
            <h1><b>TERMS & CONDITIONS</b></h1>
            <ol>
              <li><b>DELIVERABLES AND INTELLECTUAL PROPERTY.</b> Company shall have exclusive, unlimited ownership for all content, services, or products (including methods, concepts, code sequences, source codes, format, sequence structure, organization, menu command hierarchy, templates, masks, user interface, techniques, program organization, database structuring techniques, and the like) created by Company for Customer under this Agreement and it shall be deemed Company Intellectual Property. As a result, Company, has the sole right to obtain, hold, and renew, in its own name or for its own benefit, patents, copyrights, registrations, or other appropriate protection and all such items are proprietary to Company.  Company grants Customer a non-exclusive, non-transferable or marketable paid-up license to use Company Intellectual Property during the performance of this Agreement and only for the purposes set forth in this Agreement. In addition, Customer shall not copy or modify any of the Company Intellectual Property and shall keep it confidential.</li>
              <li><b>ACCEPTANCE.</b> The work under the SOW, if any, shall be deemed accepted by Customer upon completion unless Customer promptly notifies Company, in writing and within five (5) days of delivery of any specific non-conformities or deficiencies.  Unless otherwise agreed to in writing by the parties, Company will re-deliver the corrected work to Customer within a reasonable amount of time after receipt of such statement of nonconformities or deficiencies. Customer shall have five (5) days after delivery of the corrected work to accept such work, otherwise it shall be deemed accepted.</li>
              <li><b>LIMITATION OF LIABILITY AND STATUTE OF LIMITATIONS.</b> The total liability of Company to Customer from any cause whatsoever, will be limited to the lesser of Customer’s actual damages or the Project price paid to Company for those Services and Deliverables in a Project that are the subject of Customer’s claim. In no event will either party be liable for SPECIAL, INDIRECT, CONSEQUENTIAL, OR INCIDENTAL DAMAGES, including but not limited to loss of profits, revenues, data or power, damage to or loss of the use of products, damage to property, claims of third parties, including personal injury or death, suffered as a result of provision of the Services or use of Deliverables. <i>All claims against Company must be brought within one (1) year after the cause of action arises and Customer waives any statute of limitations which might apply by operation of law or otherwise.</i></li>
              <li><b>INDEMNIFICATION.</b> Customer shall defend, indemnify, and save the Company harmless, at Customer’s own expense, against any action or suit brought for any loss, damage, expense or liability that may result by reason of an infringement of any patent, trademark, copyright, or trade secret based upon the normal and intended use of the Deliverables furnished by the Company hereunder. In addition, Customer is solely responsible for providing the company with all information either pursuant to the Agreement or under the Statement of Work (“Customer Information”) and represents and warrants that the Customer Information is true and accurate at the time it is provided to the Company. Customer also has an ongoing responsibility to keep current and accurate the Customer Information during the Term of this Agreement or any subsequent agreements between the Parties. Customer agrees to indemnify and hold the Company harmless against all claims, liabilities, demands, damages, or expenses (including attorneys’ fees and expenses) arising out of or in connection with Customer’s use of the Deliverables or use of the Customer Information.</li>
              <li><b>FORCE MAJEURE.</b> Neither Party shall be liable for failure to perform, nor be deemed to be in default, under this Agreement for any delay or failure in performance resulting from causes beyond its reasonable control, including but not limited to failure of performance by the other Party, acts of state or governmental authorities, acts of terrorism, natural catastrophe, storm, flood, earthquake, riot, insurrection, civil disturbance, sabotage, embargo, blockade, acts of war, or power failure. In the event of such delay, the date of delivery or time of completion will be extended by a period of time reasonably necessary to overcome the effect of any such delay.</li>
              <li><b>DELAY OR SUSPENSION OF WORK.</b> If Customer’s acts or failure to act causes the Company to delay or suspend performance of the Project, the Company will have no further obligation to take any additional actions to provide the Services or Deliverables or to complete the Project until such time as Customer has remedied any and all inaction(s) or failure(s) on Customer’s part, to the Company’s satisfaction, and the Company agrees to continue towards completion of the Project.</li>
              <li>
                <b>CONFIDENTIALITY</b>
                <ol>
                  <li>In this Agreement, the term “Confidential Information” means any and all tangible and intangible information either Party may disclose or make available to the other Party information about its business affairs, products, confidential intellectual property, trade secrets, third party confidential information, and other sensitive or proprietary information, whether orally or in written, electronic, or other form or media, and whether or not marked, designated, or otherwise identified as “confidential”. Confidential Information includes, without limitation: (i) a Party’s intellectual property; (ii) Customer’s data; (iii) non-public information relating to any Party’s technology, customers, business plans, promotional / marketing activities, finances, and other business affairs; (iv) third party information that a Party is obligated to keep confidential; (v) the material terms and conditions of this Agreement; and (vi) any non-public information relating to any activities conducted hereunder.</li>
                  <li><b>Exclusions.</b> Notwithstanding the above, the term “Confidential Information” does not include any information that, at the time of disclosure, is: (i) in the public domain; (ii) known to the receiving Party at the time of disclosure; (iii) rightfully obtained by the receiving Party on a non-confidential basis from a third party; or (iv) independently developed by the receiving Party.</li>
                  <li><b>Use of Confidential Information.</b> Each Party shall only use Confidential Information furnished to it hereunder in furtherance of the activities contemplated by this Agreement, and it shall not disclose the Confidential Information to any other person or entity, except to the receiving Party’s employees who have a need to know the Confidential Information for the receiving Party to exercise its rights or perform its obligations hereunder.</li>
                  <li><b>Required Disclosures.</b> Notwithstanding the foregoing, a receiving Party may disclose Confidential Information of the disclosing Party to the limited extent required in order to comply with the order of a court or other governmental body, or as otherwise necessary to comply with applicable law, provided that: (i) the Party making the disclosure pursuant to the order shall first have given written notice to the other Party and made a reasonable effort to obtain a protective order or other appropriate remedy (except to the extent compliance with the foregoing would cause the such Party to violate a court order or other legal requirement); (ii) the Party making the disclosure discloses only such information as is required by the court order or governmental body or other legal requirement; and (iii) the Party making the disclosure uses its best efforts to obtain confidential treatment for any Confidential Information so disclosed.</li>
                  <li><b>Return of Information.</b> On the expiration or termination of this Agreement, the receiving Party shall promptly return to the disclosing Party all copies, whether in written, electronic, or other form or media, of the disclosing Party’s Confidential Information, or destroy all such copies and certify in writing to the disclosing Party that such Confidential Information has been destroyed.</li>
                </ol>
              </li>
              <li>
                <b>PUBLICITY.</b> The Company may use Customer’s name or mark and identify Customer as a client of the Company, on the Company’s website and/or marketing materials. Neither Party will use the other Party’s name or marks, refer to or identify the other Party for any other reason, except as established in this section, without such other Party’s written approval. Any approval required under this Section shall not be unreasonably withheld or delayed by either Party.
              </li>
              <li>
                <b>CHOICE OF LAW AND JURISDICTION.</b> This Agreement is governed by and construed in accordance with the internal laws of California, without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any other jurisdiction. Any dispute or claim under this Agreement shall only be brought in the state or federal courts sitting in San Diego County, California.
              </li>
              <li>
                <b>COMPLIANCE WITH LAWS.</b>
                <ol>
                  <li>Each Party agrees to and intends for this Agreement to at all times comply with all applicable federal and state laws and regulations in performing its obligations hereunder.</li>
                  <li>If applicable, the Parties intend for this Agreement to comply with the federal Anti-Kickback Statute (42 U.S.C. § 1320a-7b(b)) and state equivalents, the federal physician self-referral or “Stark” law (42 U.S.C. § 1395nn) and state equivalents, and any other federal or state laws governing health care fraud and abuse. The Fees contemplated by this Agreement have been determined through good faith negotiation and arm’s length bargaining, and are commercially reasonable and consistent with the fair market value of the Services provided hereunder.</li>
                  <li>The Parties affirm that none of the benefits hereunder are conditioned on any requirement that one Party make referrals to, be in a position to make or influence referrals to, or otherwise generate business for, the other Party. The Fees contemplated by this Agreement do not include any discounts, rebates, kickbacks, or other reductions in charges, nor are the Fees contemplated by this Agreement intended to be an inducement or payment for the referral of patients from one Party to the other Party.</li>
                  <li>The Parties agree that Services to be provided pursuant to this Agreement will comply with all applicable federal and state laws and regulations relating to the maintenance, uses and disclosures of PHI and PII, including without limitation the Health Insurance Portability and Accountability Act of 1996, as codified at 42 U.S.C. § 1320d through d-8 (“HIPAA”), as amended by the Health Information Technology for Economic and Clinical Health Act (“HITECH”), as incorporated in the American Recovery and Reinvestment Act of 2009, and any current and future regulations promulgated thereunder, including those published at 45 C.F.R. Parts 160, 162, and 164 (all collectively referred to herein as “HIPAA and HITECH Requirements”).</li>
                </ol>
              </li>
              <li>
                <b>SEVERABILITY.</b> If any provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or unenforceable, the Parties shall negotiate in good faith to modify this Agreement so as to affect their original intent as closely as possible in a mutually acceptable manner in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.
              </li>
              <li>
                <b>AMENDMENT/MODIFICATION/WAIVER.</b> No amendment to or modification of this Agreement is effective unless it is in writing and signed by an authorized representative of each Party.  No waiver by any Party of any of the provisions hereof will be effective unless explicitly set forth in writing and signed by the Party so waiving.  Except as otherwise set forth in this Agreement: (i) no failure to exercise, or delay in exercising, any rights, remedy, power, or privilege arising from this Agreement will operate or be construed as a waiver thereof, and (ii) no single or partial exercise of any right, remedy, power, or privilege hereunder will preclude any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.
              </li>
              <li>
                <b>ENTIRE AGREEMENT.</b> This Agreement, together with any other materials referenced in or expressly made a part of the Agreement, constitutes the Agreement between the Company and Customer and supersedes all prior and contemporary agreements, oral or written.
              </li>
              <li>
                <b>COUNTERPARTS.</b> The Parties hereto agree that facsimile signatures shall be as effective as if originals. This Agreement may be executed via facsimile in any number of counterparts, all of which taken together shall constitute one and the same agreement.
              </li>
              <li>
                <b>ASSIGNMENT.</b> Customer may not assign any of its rights or delegate any of its obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without the prior written consent of the Company.  For avoidance of doubt, any “change of control” transaction involving Customer shall be deemed an assignment of this Agreement. Any purported assignment or delegation by Customer in violation of this Section 17.d. will be null and void.  No assignment or delegation will relieve the assigning or delegating Party of any of its obligations hereunder.  This Agreement is binding upon and will inure to the benefit of the Parties and their respective permitted successors and assigns.
              </li>
            </ol>
          </section>
        </div>
      </div>
    </div>
  )
}
