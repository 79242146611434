import React from 'react'
import styles from './index.module.css'

export default function CheckboxWLabel({
  setCheck,
  wrapperStyles,
  check = false,
  label = 'hello'
}) {

  return (
    <div style={wrapperStyles} className={styles.click_wrap} onClick={() => setCheck(!check)}>
      <p className={styles.label}>{label}</p>
      <input type="checkbox" className={styles.custom_check} checked={check} readOnly/>
    </div>
  )
}
