import React, { useContext, useEffect, useState } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useParams,
  useLocation,
} from "react-router-dom";
import { getUser } from './../../api/users';
import ProfilePanel from './profile_panel/index'
import Library from './profile_panel/profile_panel_sections/library'
import styles from './index.module.css'
import LoadingScreen from '../loading_screen';
import Post from '../posts/post';
import Newsfeed from '../newsfeed';
import NotFoundPage from '../not_found_page';
import SimpleSlidingMenu from '../ui_kit/utilities/simple_sliding_menu';
import Context from '../../utils/context';
import About from './about_me_sections/about';
import PersonalInformation from './about_me_sections/personal_information';
import OtherDetails from './about_me_sections/other_details';
import HealthProfile from './health_profile';
import ProfileProcedures from './health_profile/procedures';
import AboutMe from './profile_panel/profile_panel_sections/about_me';
import Notifications from '../notifications';

export default function Profile() {
  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  let { id } = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  let { path } = useRouteMatch();
  path = path.replace(`:id`, id);

  console.log("in profile");

  let menuItems = [
    {
      "linkText": "Posts",
      "idToScrollTo": "posts",
      "href": `${path}/posts`,
      pendoTag: "profile page navigation - posts"
    },
    {
      "linkText": "About me",
      "idToScrollTo": "about-me",
      "href": `${path}/about-me`,
      pendoTag: "profile page navigation - about me"
    },
    {
      "linkText": "Health profile",
      "idToScrollTo": "health-profile",
      "href": `${path}/health-profile`,
      pendoTag: "profile page navigation - health profile"
    },
    // {
    //   "linkText": "My Community",
    //   "idToScrollTo": "community",
    //   "href": `${path}/community`,
    // },
    // {
    //   "linkText": "Library",
    //   "idToScrollTo": "library",
    //   "href": `${path}/library`,
    // },
  ]

  useEffect(() => {
    setLoading(true)
    getUser(id, (user) => {
      setUser(user);
      if (!user) {
        setNotFound(true);
        return <NotFoundPage />
      }
      setLoading(false)
    })
  }, [id]);

  let profileIsCurrentUser = false
  if (context.auth.isLoggedIn() && currentUser.slug === id) {
    profileIsCurrentUser = true
  }
  if (profileIsCurrentUser) {
    menuItems.push({
      "linkText": "Activity",
      "idToScrollTo": "activity",
      "href": `${path}/activity`,
      pendoTag: "profile page navigation - activity"
    })
  }

  const location = useLocation();
  let defaultSelectedMenuItem = menuItems[0];
  menuItems.forEach((item) => {
    if (item.href === location.pathname) {
      defaultSelectedMenuItem = item;
    }
  })
  const [selectedMenuItem, setSelectedMenuItem] = useState(defaultSelectedMenuItem);

  if (!user || loading) {
    return <LoadingScreen />
  }

  return (
    <div className={`${styles.profile_wrapper} container-fluid`}>
      <div className={`container`}>
        <div className="row">
          <div className="col-lg-4">
            {user.current_profile &&
              <ProfilePanel profile={user.current_profile} />
            }
            {user.current_profile &&
              <AboutMe user={user} />
            }
          </div>
          <div className="col-lg-6">
            <SimpleSlidingMenu
              menuItems={menuItems}
              setMarker={selectedMenuItem}
              setMarkerItem={setSelectedMenuItem}
            />
            <Switch>
              <Route path={`${path}/posts`}>
                <Newsfeed classNames={'de-pad'} profileId={user.profile_id} location={'profile_page'} redirectToConversations={false} />
              </Route>
              <Route path={`${path}/about-me`}>
                <About
                  user={user}
                />
                <PersonalInformation
                  user={user}
                />
                <OtherDetails
                  user={user}
                />
              </Route>
              <Route path={`${path}/health-profile`}>
                <HealthProfile
                  user={user}
                />
              </Route>
              <Route path={`${path}/community`}>
                <h2 className={styles.profile_section_headline}>Community</h2>
              </Route>
              <Route path={`${path}/library`}>
                <h2 className={styles.profile_section_headline}>Library</h2>
              </Route>
              <Route path={`${path}/activity`}>
                <Notifications/>
              </Route>
              <Route
                path={`${path}`}
                render={() => {
                  return (
                    <Redirect to={`${path}/posts`} />
                  )
                }}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}
