import React from 'react'
import { Link } from 'react-router-dom'
import './about_headline_three_icons.css'

export default function AboutHeadlineThreeIcons({
    headline = 'Get the information you need to take the proper steps in your healthcare journey. PatientPartner is free and easy to use!',
    infoTiles = [
        {
            "icon": <svg className="beni-icon" xmlns="http://www.w3.org/2000/svg" width="76.838" height="48.897" viewBox="0 0 76.838 48.897"><path id="Icon_material-people-outline" data-name="Icon material-people-outline" d="M55.636,35.441a41.865,41.865,0,0,0-15.717,3.493A41.2,41.2,0,0,0,24.2,35.441c-7.579,0-22.7,3.772-22.7,11.351v9.6H78.338v-9.6C78.338,39.213,63.215,35.441,55.636,35.441ZM41.665,51.158H6.739V46.792c0-1.886,8.941-6.112,17.463-6.112s17.463,4.226,17.463,6.112Zm31.434,0H46.9V46.792a6.355,6.355,0,0,0-1.816-4.261A33.7,33.7,0,0,1,55.636,40.68c8.522,0,17.463,4.226,17.463,6.112ZM24.2,31.948A12.224,12.224,0,1,0,11.978,19.724,12.238,12.238,0,0,0,24.2,31.948Zm0-19.209a6.985,6.985,0,1,1-6.985,6.985A7.006,7.006,0,0,1,24.2,12.739ZM55.636,31.948A12.224,12.224,0,1,0,43.411,19.724,12.238,12.238,0,0,0,55.636,31.948Zm0-19.209a6.985,6.985,0,1,1-6.985,6.985A7.006,7.006,0,0,1,55.636,12.739Z" transform="translate(-1.5 -7.5)" fill="#59B6BE" /></svg>,
            "title": "Free 1:1 support from similar patients"
        },
        {
            "icon": <svg className="beni-icon" xmlns="http://www.w3.org/2000/svg" width="51.865" height="55.936" viewBox="0 0 51.865 55.936"><path id="Icon_ionic-ios-thumbs-up" data-name="Icon ionic-ios-thumbs-up" d="M50.775,46.316a5.116,5.116,0,0,0,2.1-3.957,5.532,5.532,0,0,0-1.374-3.667c-.076-.151-.139-.29-.2-.428l-.025-.139a1.645,1.645,0,0,1,.252-1.26,5.524,5.524,0,0,0,.731-3.239A3.829,3.829,0,0,0,51.04,30.7a2.4,2.4,0,0,1-.517-2.306,4.86,4.86,0,0,0-1.033-4.763c-1.323-1.487-2.558-1.663-5.847-1.474-2.268.139-6.994.87-10.107,1.361-1.273.2-2.382.378-2.722.4-1.6.164-1.915,0-2.067-.617a4.086,4.086,0,0,1,.617-1.638,27.024,27.024,0,0,0,1.827-4.511c1.273-4.184,1.134-8.72-.34-11.367a4.118,4.118,0,0,0-3.314-2.407c-1.449,0-2.936.3-3.516,1-.428.5-.29,1.462-.781,3.377a36.743,36.743,0,0,1-1.512,5.293c-.945,2.092-5,5.683-7.183,7.611-.517.454-.958.844-1.273,1.147a25.975,25.975,0,0,0-3.213,4.121,21.935,21.935,0,0,1-1.726,2.331,4.552,4.552,0,0,1-2.81,1.374A1.011,1.011,0,0,0,4.514,30.64v20.1A1.043,1.043,0,0,0,5.56,51.785a19.859,19.859,0,0,1,8.368,1.487A21.778,21.778,0,0,0,19.775,54.8a121.045,121.045,0,0,0,14.19,1.021,62.8,62.8,0,0,0,7.133-.491c3.907-.454,8.09-1.361,9.476-4.663a3.559,3.559,0,0,0-.063-2.987.991.991,0,0,1-.1-.328A1.068,1.068,0,0,1,50.775,46.316Z" transform="translate(-2.764 -1.632)" fill="none" stroke="#59B6BE" strokeWidth="3.5" /></svg>,
            "title": "Insurance check for your surgery"
        },
        {
            "icon": <svg className="beni-icon" xmlns="http://www.w3.org/2000/svg" width="56.436" height="56.436" viewBox="0 0 56.436 56.436"><path id="Icon_map-health" data-name="Icon map-health" d="M53.156,18.2a1.1,1.1,0,0,0-1.092-1.092h-14.2a1.1,1.1,0,0,1-1.092-1.092V1.812A1.1,1.1,0,0,0,35.677.72H18.2a1.1,1.1,0,0,0-1.092,1.092v14.2a1.1,1.1,0,0,1-1.092,1.092H1.812A1.1,1.1,0,0,0,.72,18.2V35.677A1.1,1.1,0,0,0,1.812,36.77h14.2a1.1,1.1,0,0,1,1.092,1.092v14.2A1.1,1.1,0,0,0,18.2,53.156H35.677a1.1,1.1,0,0,0,1.092-1.092v-14.2a1.1,1.1,0,0,1,1.092-1.092h14.2a1.1,1.1,0,0,0,1.092-1.092Z" transform="translate(1.28 1.28)" fill="none" stroke="#59B6BE" strokeWidth="4" /></svg>,
            "title": "Surgeon connection in your area"
        },
    ],
    bgColor = "#DDF7F9",
    tileColor = "#fff",
    textColor = "#173959",
    headlineStyles = {}
}) {

    return (
        <div style={{ backgroundColor: bgColor }} className="container-fluid about-icon-sec">
            <div className="container about-icon-sec-innr-container">
                <div className="row h-100">
                    <div className="col">
                        <h2 style={headlineStyles} className="text-center marg-bottom-50">
                            {headline}
                        </h2>
                    </div>
                </div>
                <div className="row h-100 beni-row">
                    {infoTiles.map((tile, index) =>
                        <>
                            {tile.link &&
                                    <div className={`my-auto beni ${infoTiles.length === 2 ? 'col-md-6' : 'col-md-4'}`}>
                                        <Link data-gtm-click-label={tile.gtm} to={tile.link}>
                                            <div data-gtm-click-label={tile.gtm} style={{ backgroundColor: tileColor }} className="container about-icon-sec-tile-wrapper">
                                                <div data-gtm-click-label={tile.gtm} className="row h-100">
                                                    <div data-gtm-click-label={tile.gtm} className="col-3 my-auto">
                                                        {tile.icon}
                                                    </div>
                                                    <div data-gtm-click-label={tile.gtm} className="col-9 my-auto">
                                                        {tile.heading &&
                                                            <h5 data-gtm-click-label={tile.gtm} style={{ color: textColor }} className="icon-txt bold">
                                                                {tile.heading}
                                                            </h5>
                                                        }
                                                        <p data-gtm-click-label={tile.gtm} style={{ color: textColor }}>
                                                            {tile.title}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                            }
                            {!tile.link &&
                                <div className={`my-auto beni ${infoTiles.length === 2 ? 'col-md-6' : 'col-md-4'}`}>
                                    <div style={{ backgroundColor: tileColor }} className="container about-icon-sec-tile-wrapper">
                                        <div className="row h-100">
                                            <div className="col-3 my-auto">
                                                {tile.icon}
                                            </div>
                                            <div className="col-9 my-auto">
                                                {tile.heading &&
                                                    <h5 style={{ color: textColor }} className="icon-txt bold">
                                                        {tile.heading}
                                                    </h5>
                                                }
                                                <p style={{ color: textColor }}>
                                                    {tile.title}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
