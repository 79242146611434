import { connect } from 'react-redux'
import {
  templateAction
} from '../../actions/surgeons'
import Surgeons from '../../components/surgeons'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Surgeons)
