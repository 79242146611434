import { connect } from 'react-redux'
// import {
//
// } from '../../actions/surgeon'
import SurgeonPartners from '../../components/surgeon_partners'

const mapStateToProps = state => state

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SurgeonPartners)
