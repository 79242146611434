import React, { useState } from 'react'
import styles from './index.module.css'
import {
  useTransition,
  useChain,
  animated,
  useSpringRef,
} from '@react-spring/web'
import { Link } from 'react-router-dom';

export default function DropdownMenu({
  parentElement,
  navItemsArr = [
    {
      title: 'Invite patient',
      icon: <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10.083C0.999971 9.43985 1.15502 8.80619 1.452 8.23573C1.74898 7.66527 2.17914 7.17483 2.706 6.80599L8.706 2.60599C9.37836 2.13529 10.1792 1.88281 11 1.88281C11.8208 1.88281 12.6216 2.13529 13.294 2.60599L19.294 6.80599C19.8209 7.17483 20.251 7.66527 20.548 8.23573C20.845 8.80619 21 9.43985 21 10.083V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H3C2.46957 20 1.96086 19.7893 1.58579 19.4142C1.21071 19.0391 1 18.5304 1 18V10.083Z" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M1 8L8.501 14.001C9.21029 14.5685 10.0916 14.8777 11 14.8777C11.9084 14.8777 12.7897 14.5685 13.499 14.001L21 8" stroke="#59B6BE" strokeWidth="2" strokeLinejoin="round" /></svg>,
      props: {
        onClick: () => console.log(''),
      }
    },
    {
      title: 'Import patients',
      icon: <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 9L11 13L15 9M11 1V13V1Z" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M7 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H15" stroke="#59B6BE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>,
      props: {
        onClick: () => console.log(''),
      }
    }
  ],
  click = false
}) {

  const [open, setOpen] = useState(false);
  const dropDownAnimation = useSpringRef();
  const dropdown = useTransition(open ? 1 : [], {
    ref: dropDownAnimation,
    config: { mass: 1, tension: 500, friction: 30 },
    from: { opacity: 0, transform: 'translateY(-10px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' }
  });

  const navItemsAnimation = useSpringRef();
  const navItems = useTransition(open ? navItemsArr : [], {
    ref: navItemsAnimation,
    trail: open ? 100 / navItemsArr.length : 0,
    config: { mass: 1, tension: 1000, friction: 100 },
    from: { opacity: 0, transform: 'translateX(30px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' }
  });
  useChain(open ? [dropDownAnimation, navItemsAnimation] : [navItemsAnimation, dropDownAnimation], [
    0,
    open ? 0.1 : 0.05
  ]);

  return (
    <div className={`${styles.portal_menu} portal-menu`} onMouseOver={() => click ? null : setOpen(true)} onMouseLeave={() => click ? null : setOpen(false)} onClick={() => click ? null : setOpen(false)}>
      <div onClick={() => click ? setOpen(!open) : null}>
        {parentElement}
      </div>
      <div className={styles.portal_menu_wrapper}>
        {dropdown((style) => (
          <animated.div style={{ ...style }} className={styles.dropdown}>
            {navItems((style, dropdownItem) => (
              <div onClick={() => click ? setOpen(false) : null}>
                {dropdownItem.hr_top &&
                  <hr />
                }
                <Link {...dropdownItem.props} className={styles.dropdown_parent_link} data-gtm-click-label={`Nav ${dropdownItem.title}`} data-pendo-tag={dropdownItem.pendoTag ? dropdownItem.pendoTag : ''}>
                  <animated.div style={{ ...style, display: 'flex' }} data-gtm-click-label={`Nav ${dropdownItem.title}`} className={styles.dd_item}>
                    {dropdownItem.img &&
                      <img className={styles.menu_icon} src={dropdownItem.img} alt={`Nav ${dropdownItem.title}`} />
                    }
                    {dropdownItem.icon &&
                      <div className={styles.icon}>
                        {dropdownItem.icon}
                      </div>
                    }
                    <div className={`dd-item-text ${styles.menu_item}`} data-gtm-click-label={`Nav ${dropdownItem.title}`}>
                      <p className={styles.title} style={{ marginBottom: '-3px', color: dropdownItem.color }}>
                        {dropdownItem.title}
                      </p>
                    </div>
                  </animated.div>
                </Link>
              </div>
            ))}
          </animated.div>
        ))}
      </div>
    </div>
  )
}
