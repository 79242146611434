import React, { Component } from 'react'
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function MfQolAffect({
  value,
  onSubmit,
  onBack
}) {
  return (
    <OptionsAndOther
      answers={{ mfQolAffect: value}}
      keyForAnswer={'mfQolAffect'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={[
        'Feeling depressed and/or discouraged',
        'Have missed and/or avoided social interactions',
        'Feeling anxious or worried',
        'Struggling with personal relationships',
        'Have reduced work hours or quit completely',
        'Have not noticed any major impact'
      ]}
      title={`How is Myelofibrosis (MF) impacting your (or the patient’s) life? (Select all that apply)`}
      para={``}
      showOther
      ctaTxt='Next'
    />
  )
}
