import React from 'react'
import styles from './index.module.css'

export default function ValueOffer() {

  return (
    <div className={`container-fluid ${styles.wrapper}`}>
      <div className='container'>
        <div className='row'>
          <div className={`col-md-6 ${styles.de_pad_left}`}>
            <h2 className={styles.headline}>Not everything is what it seems.</h2>
            <p className={styles.para}>Online reviews aren't always reliable or accurate. That's why we examine all aspects of a specialist to help sift through the noise and find you a credible doctor.  </p>
          </div>
          <div className={`col-md-6 ${styles.de_pad_right} ${styles.de_pad_left}`}>
            <div className='container'>
              <div className={`row`}>
                <div className={`col-md-6 ${styles.de_pad_left} ${styles.de_pad_mob}`}>
                  <div className={`${styles.doc_box}`}>
                    <p style={{ background: '#358F97' }} className={`${styles.doc_box_headline}`}>
                      Online review
                    </p>
                    <div className={`${styles.doc_box_inner}`}>
                      <div className={styles.doc_img} style={{ backgroundImage: 'url("https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/drphoto.jpg")' }}></div>
                      <div className={styles.doc_info}>
                        <p className={styles.name}>Doctor Name</p>
                        <p className={styles.sm_info}>Orthopedic Surgeon</p>
                        <p className={styles.sm_info}>New York, NY</p>
                        <div className={styles.bc} style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                          <svg style={{ marginRight: '4.5px' }} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_96_299)"><path d="M9.93093 0H1.99627C1.26617 0 0.673828 0.587891 0.673828 1.3125V12.6875C0.673828 13.4121 1.26617 14 1.99627 14H9.93093C10.661 14 11.2534 13.4121 11.2534 12.6875V1.3125C11.2534 0.587891 10.661 0 9.93093 0ZM9.93093 12.6875H1.99627V1.3125H9.93093V12.6875ZM4.64116 3.0625H7.28604C7.52849 3.0625 7.72686 2.86563 7.72686 2.625C7.72686 2.38437 7.52849 2.1875 7.28604 2.1875H4.64116C4.39871 2.1875 4.20034 2.38437 4.20034 2.625C4.20034 2.86563 4.39871 3.0625 4.64116 3.0625ZM5.9636 7.875C6.93615 7.875 7.72686 7.09023 7.72686 6.125C7.72686 5.15977 6.93615 4.375 5.9636 4.375C4.99105 4.375 4.20034 5.15977 4.20034 6.125C4.20034 7.09023 4.99105 7.875 5.9636 7.875ZM3.49504 11.375H8.43216C8.77379 11.375 9.0493 11.1398 9.0493 10.85V10.325C9.0493 9.45547 8.22002 8.75 7.19788 8.75C6.90033 8.75 6.68268 8.96875 5.9636 8.96875C5.22248 8.96875 5.0434 8.75 4.72932 8.75C3.70718 8.75 2.8779 9.45547 2.8779 10.325V10.85C2.8779 11.1398 3.15341 11.375 3.49504 11.375V11.375Z" fill="#232323" /></g><defs><clipPath id="clip0_96_299"><rect width="10.5795" height="14" fill="white" transform="translate(0.673828)" /></clipPath></defs></svg>
                          <p>Board certified</p>
                        </div>
                        <div className={styles.rating} style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                          <svg style={{ marginRight: '4px' }} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.72686 0L8.51779 4.55353L13.4347 4.83688L9.62464 7.93447L10.8725 12.6631L6.72686 10.024L2.58119 12.6631L3.82908 7.93447L0.0190282 4.83688L4.93593 4.55353L6.72686 0Z" fill="#FF6472" /></svg>
                          <p style={{ marginRight: '4px' }} className={styles.number}>4.69</p>
                          <p className={styles.number_rev}>(2,327 reviews)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-6 ${styles.de_pad_right} ${styles.de_pad_mob}`}>
                  <div className={`${styles.doc_box}`}>
                    <p style={{ background: '#DF6D48' }} className={`${styles.doc_box_headline}`}>
                      REALITY
                    </p>
                    <div className={`${styles.doc_box_inner}`}>
                      <div className={styles.doc_img} style={{ backgroundImage: 'url("https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/drbaw.jpg")' }}></div>
                      <div className={styles.doc_info}>
                        <p className={styles.name}>Doctor Name</p>
                        <div className={styles.info_warn}>
                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.77893 1.08262C5.68233 0.88297 5.53086 0.714467 5.34197 0.596528C5.15309 0.47859 4.93447 0.416016 4.71132 0.416016C4.48816 0.416016 4.26954 0.47859 4.08066 0.596528C3.89177 0.714467 3.74031 0.88297 3.64371 1.08262L0.236421 7.73471C-0.171227 8.52887 0.343896 9.58346 1.30361 9.58346H8.1186C9.07874 9.58346 9.59302 8.52929 9.18621 7.73471L5.77893 1.08262ZM4.71132 3.33346C4.82266 3.33346 4.92944 3.37735 5.00818 3.45549C5.08691 3.53363 5.13114 3.63962 5.13114 3.75012V5.83346C5.13114 5.94396 5.08691 6.04994 5.00818 6.12808C4.92944 6.20622 4.82266 6.25012 4.71132 6.25012C4.59997 6.25012 4.49319 6.20622 4.41446 6.12808C4.33572 6.04994 4.29149 5.94396 4.29149 5.83346V3.75012C4.29149 3.63962 4.33572 3.53363 4.41446 3.45549C4.49319 3.37735 4.59997 3.33346 4.71132 3.33346V3.33346ZM4.71132 6.87512C4.82266 6.87512 4.92944 6.91902 5.00818 6.99716C5.08691 7.0753 5.13114 7.18128 5.13114 7.29179V7.50012C5.13114 7.61063 5.08691 7.71661 5.00818 7.79475C4.92944 7.87289 4.82266 7.91679 4.71132 7.91679C4.59997 7.91679 4.49319 7.87289 4.41446 7.79475C4.33572 7.71661 4.29149 7.61063 4.29149 7.50012V7.29179C4.29149 7.18128 4.33572 7.0753 4.41446 6.99716C4.49319 6.91902 4.59997 6.87512 4.71132 6.87512V6.87512Z" fill="#DF6D48" /></svg>
                          <p>Highest 30-day readmission rate in San Diego County</p>
                        </div>
                        <div className={styles.info_warn}>
                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.77893 1.08262C5.68233 0.88297 5.53086 0.714467 5.34197 0.596528C5.15309 0.47859 4.93447 0.416016 4.71132 0.416016C4.48816 0.416016 4.26954 0.47859 4.08066 0.596528C3.89177 0.714467 3.74031 0.88297 3.64371 1.08262L0.236421 7.73471C-0.171227 8.52887 0.343896 9.58346 1.30361 9.58346H8.1186C9.07874 9.58346 9.59302 8.52929 9.18621 7.73471L5.77893 1.08262ZM4.71132 3.33346C4.82266 3.33346 4.92944 3.37735 5.00818 3.45549C5.08691 3.53363 5.13114 3.63962 5.13114 3.75012V5.83346C5.13114 5.94396 5.08691 6.04994 5.00818 6.12808C4.92944 6.20622 4.82266 6.25012 4.71132 6.25012C4.59997 6.25012 4.49319 6.20622 4.41446 6.12808C4.33572 6.04994 4.29149 5.94396 4.29149 5.83346V3.75012C4.29149 3.63962 4.33572 3.53363 4.41446 3.45549C4.49319 3.37735 4.59997 3.33346 4.71132 3.33346V3.33346ZM4.71132 6.87512C4.82266 6.87512 4.92944 6.91902 5.00818 6.99716C5.08691 7.0753 5.13114 7.18128 5.13114 7.29179V7.50012C5.13114 7.61063 5.08691 7.71661 5.00818 7.79475C4.92944 7.87289 4.82266 7.91679 4.71132 7.91679C4.59997 7.91679 4.49319 7.87289 4.41446 7.79475C4.33572 7.71661 4.29149 7.61063 4.29149 7.50012V7.29179C4.29149 7.18128 4.33572 7.0753 4.41446 6.99716C4.49319 6.91902 4.59997 6.87512 4.71132 6.87512V6.87512Z" fill="#DF6D48" /></svg>
                          <p>4 pending malpractice lawsuits</p>
                        </div>
                        <div className={styles.info_warn}>
                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.77893 1.08262C5.68233 0.88297 5.53086 0.714467 5.34197 0.596528C5.15309 0.47859 4.93447 0.416016 4.71132 0.416016C4.48816 0.416016 4.26954 0.47859 4.08066 0.596528C3.89177 0.714467 3.74031 0.88297 3.64371 1.08262L0.236421 7.73471C-0.171227 8.52887 0.343896 9.58346 1.30361 9.58346H8.1186C9.07874 9.58346 9.59302 8.52929 9.18621 7.73471L5.77893 1.08262ZM4.71132 3.33346C4.82266 3.33346 4.92944 3.37735 5.00818 3.45549C5.08691 3.53363 5.13114 3.63962 5.13114 3.75012V5.83346C5.13114 5.94396 5.08691 6.04994 5.00818 6.12808C4.92944 6.20622 4.82266 6.25012 4.71132 6.25012C4.59997 6.25012 4.49319 6.20622 4.41446 6.12808C4.33572 6.04994 4.29149 5.94396 4.29149 5.83346V3.75012C4.29149 3.63962 4.33572 3.53363 4.41446 3.45549C4.49319 3.37735 4.59997 3.33346 4.71132 3.33346V3.33346ZM4.71132 6.87512C4.82266 6.87512 4.92944 6.91902 5.00818 6.99716C5.08691 7.0753 5.13114 7.18128 5.13114 7.29179V7.50012C5.13114 7.61063 5.08691 7.71661 5.00818 7.79475C4.92944 7.87289 4.82266 7.91679 4.71132 7.91679C4.59997 7.91679 4.49319 7.87289 4.41446 7.79475C4.33572 7.71661 4.29149 7.61063 4.29149 7.50012V7.29179C4.29149 7.18128 4.33572 7.0753 4.41446 6.99716C4.49319 6.91902 4.59997 6.87512 4.71132 6.87512V6.87512Z" fill="#DF6D48" /></svg>
                          <p>4 years in practice</p>
                        </div>
                        <div className={styles.info_warn}>
                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.77893 1.08262C5.68233 0.88297 5.53086 0.714467 5.34197 0.596528C5.15309 0.47859 4.93447 0.416016 4.71132 0.416016C4.48816 0.416016 4.26954 0.47859 4.08066 0.596528C3.89177 0.714467 3.74031 0.88297 3.64371 1.08262L0.236421 7.73471C-0.171227 8.52887 0.343896 9.58346 1.30361 9.58346H8.1186C9.07874 9.58346 9.59302 8.52929 9.18621 7.73471L5.77893 1.08262ZM4.71132 3.33346C4.82266 3.33346 4.92944 3.37735 5.00818 3.45549C5.08691 3.53363 5.13114 3.63962 5.13114 3.75012V5.83346C5.13114 5.94396 5.08691 6.04994 5.00818 6.12808C4.92944 6.20622 4.82266 6.25012 4.71132 6.25012C4.59997 6.25012 4.49319 6.20622 4.41446 6.12808C4.33572 6.04994 4.29149 5.94396 4.29149 5.83346V3.75012C4.29149 3.63962 4.33572 3.53363 4.41446 3.45549C4.49319 3.37735 4.59997 3.33346 4.71132 3.33346V3.33346ZM4.71132 6.87512C4.82266 6.87512 4.92944 6.91902 5.00818 6.99716C5.08691 7.0753 5.13114 7.18128 5.13114 7.29179V7.50012C5.13114 7.61063 5.08691 7.71661 5.00818 7.79475C4.92944 7.87289 4.82266 7.91679 4.71132 7.91679C4.59997 7.91679 4.49319 7.87289 4.41446 7.79475C4.33572 7.71661 4.29149 7.61063 4.29149 7.50012V7.29179C4.29149 7.18128 4.33572 7.0753 4.41446 6.99716C4.49319 6.91902 4.59997 6.87512 4.71132 6.87512V6.87512Z" fill="#DF6D48" /></svg>
                          <p>Bottom 1% in San Diego</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.line}></div>
          <p className={styles.hidden_head}>Hidden information you won't find anywhere else</p>
          <div className='container'>
            <div className={`row ${styles.de_pad}`}>
              <div className={`col ${styles.detail_col}`}>
                <div className={styles.detail}>
                  <svg height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9875 12.4911L12.4536 9.95725L10.5046 9.15203C11.1536 8.22917 11.5013 7.12819 11.5 6C11.5 2.96728 9.03272 0.5 6 0.5C2.96728 0.5 0.5 2.96728 0.5 6C0.5 9.03272 2.96728 11.5 6 11.5C7.13801 11.5013 8.24809 11.1476 9.17559 10.4882L9.97872 12.432L12.5125 14.966C12.675 15.1285 12.8679 15.2574 13.0802 15.3453C13.2926 15.4333 13.5201 15.4786 13.7499 15.4786C13.9798 15.4786 14.2073 15.4333 14.4197 15.3454C14.632 15.2574 14.8249 15.1285 14.9874 14.966C15.1499 14.8035 15.2788 14.6106 15.3668 14.3983C15.4548 14.186 15.5 13.9584 15.5 13.7286C15.5 13.4988 15.4548 13.2712 15.3668 13.0589C15.2789 12.8466 15.15 12.6536 14.9875 12.4911V12.4911ZM1.5 6C1.5 3.51875 3.51875 1.5 6 1.5C8.48125 1.5 10.5 3.51875 10.5 6C10.5 8.48125 8.48125 10.5 6 10.5C3.51875 10.5 1.5 8.48125 1.5 6ZM14.2803 14.2589C14.1396 14.3993 13.9488 14.4782 13.75 14.4782C13.5512 14.4782 13.3604 14.3993 13.2197 14.2589L10.8268 11.866L10.0801 10.0587L11.8875 10.8053L14.2804 13.1982C14.4208 13.339 14.4997 13.5297 14.4997 13.7286C14.4997 13.9274 14.4208 14.1181 14.2803 14.2589V14.2589Z" fill="#59B6BE" /></svg>
                  <p>Outcome ratings</p>
                </div>
              </div>
              <div className={`col ${styles.detail_col}`}>
                <div className={styles.detail}>
                  <svg height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9875 12.4911L12.4536 9.95725L10.5046 9.15203C11.1536 8.22917 11.5013 7.12819 11.5 6C11.5 2.96728 9.03272 0.5 6 0.5C2.96728 0.5 0.5 2.96728 0.5 6C0.5 9.03272 2.96728 11.5 6 11.5C7.13801 11.5013 8.24809 11.1476 9.17559 10.4882L9.97872 12.432L12.5125 14.966C12.675 15.1285 12.8679 15.2574 13.0802 15.3453C13.2926 15.4333 13.5201 15.4786 13.7499 15.4786C13.9798 15.4786 14.2073 15.4333 14.4197 15.3454C14.632 15.2574 14.8249 15.1285 14.9874 14.966C15.1499 14.8035 15.2788 14.6106 15.3668 14.3983C15.4548 14.186 15.5 13.9584 15.5 13.7286C15.5 13.4988 15.4548 13.2712 15.3668 13.0589C15.2789 12.8466 15.15 12.6536 14.9875 12.4911V12.4911ZM1.5 6C1.5 3.51875 3.51875 1.5 6 1.5C8.48125 1.5 10.5 3.51875 10.5 6C10.5 8.48125 8.48125 10.5 6 10.5C3.51875 10.5 1.5 8.48125 1.5 6ZM14.2803 14.2589C14.1396 14.3993 13.9488 14.4782 13.75 14.4782C13.5512 14.4782 13.3604 14.3993 13.2197 14.2589L10.8268 11.866L10.0801 10.0587L11.8875 10.8053L14.2804 13.1982C14.4208 13.339 14.4997 13.5297 14.4997 13.7286C14.4997 13.9274 14.4208 14.1181 14.2803 14.2589V14.2589Z" fill="#59B6BE" /></svg>
                  <p>Authenticity of online reviews</p>
                </div>
              </div>
              <div className={`col ${styles.detail_col}`}>
                <div className={styles.detail}>
                  <svg height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9875 12.4911L12.4536 9.95725L10.5046 9.15203C11.1536 8.22917 11.5013 7.12819 11.5 6C11.5 2.96728 9.03272 0.5 6 0.5C2.96728 0.5 0.5 2.96728 0.5 6C0.5 9.03272 2.96728 11.5 6 11.5C7.13801 11.5013 8.24809 11.1476 9.17559 10.4882L9.97872 12.432L12.5125 14.966C12.675 15.1285 12.8679 15.2574 13.0802 15.3453C13.2926 15.4333 13.5201 15.4786 13.7499 15.4786C13.9798 15.4786 14.2073 15.4333 14.4197 15.3454C14.632 15.2574 14.8249 15.1285 14.9874 14.966C15.1499 14.8035 15.2788 14.6106 15.3668 14.3983C15.4548 14.186 15.5 13.9584 15.5 13.7286C15.5 13.4988 15.4548 13.2712 15.3668 13.0589C15.2789 12.8466 15.15 12.6536 14.9875 12.4911V12.4911ZM1.5 6C1.5 3.51875 3.51875 1.5 6 1.5C8.48125 1.5 10.5 3.51875 10.5 6C10.5 8.48125 8.48125 10.5 6 10.5C3.51875 10.5 1.5 8.48125 1.5 6ZM14.2803 14.2589C14.1396 14.3993 13.9488 14.4782 13.75 14.4782C13.5512 14.4782 13.3604 14.3993 13.2197 14.2589L10.8268 11.866L10.0801 10.0587L11.8875 10.8053L14.2804 13.1982C14.4208 13.339 14.4997 13.5297 14.4997 13.7286C14.4997 13.9274 14.4208 14.1181 14.2803 14.2589V14.2589Z" fill="#59B6BE" /></svg>
                  <p>Expertise with specific conditions</p>
                </div>
              </div>
              <div className={`col ${styles.detail_col}`}>
                <div className={styles.detail}>
                  <svg height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9875 12.4911L12.4536 9.95725L10.5046 9.15203C11.1536 8.22917 11.5013 7.12819 11.5 6C11.5 2.96728 9.03272 0.5 6 0.5C2.96728 0.5 0.5 2.96728 0.5 6C0.5 9.03272 2.96728 11.5 6 11.5C7.13801 11.5013 8.24809 11.1476 9.17559 10.4882L9.97872 12.432L12.5125 14.966C12.675 15.1285 12.8679 15.2574 13.0802 15.3453C13.2926 15.4333 13.5201 15.4786 13.7499 15.4786C13.9798 15.4786 14.2073 15.4333 14.4197 15.3454C14.632 15.2574 14.8249 15.1285 14.9874 14.966C15.1499 14.8035 15.2788 14.6106 15.3668 14.3983C15.4548 14.186 15.5 13.9584 15.5 13.7286C15.5 13.4988 15.4548 13.2712 15.3668 13.0589C15.2789 12.8466 15.15 12.6536 14.9875 12.4911V12.4911ZM1.5 6C1.5 3.51875 3.51875 1.5 6 1.5C8.48125 1.5 10.5 3.51875 10.5 6C10.5 8.48125 8.48125 10.5 6 10.5C3.51875 10.5 1.5 8.48125 1.5 6ZM14.2803 14.2589C14.1396 14.3993 13.9488 14.4782 13.75 14.4782C13.5512 14.4782 13.3604 14.3993 13.2197 14.2589L10.8268 11.866L10.0801 10.0587L11.8875 10.8053L14.2804 13.1982C14.4208 13.339 14.4997 13.5297 14.4997 13.7286C14.4997 13.9274 14.4208 14.1181 14.2803 14.2589V14.2589Z" fill="#59B6BE" /></svg>
                  <p>Total number of procedures completed</p>
                </div>
              </div>
              <div className={`col ${styles.detail_col}`}>
                <div className={styles.detail}>
                  <svg height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.9875 12.4911L12.4536 9.95725L10.5046 9.15203C11.1536 8.22917 11.5013 7.12819 11.5 6C11.5 2.96728 9.03272 0.5 6 0.5C2.96728 0.5 0.5 2.96728 0.5 6C0.5 9.03272 2.96728 11.5 6 11.5C7.13801 11.5013 8.24809 11.1476 9.17559 10.4882L9.97872 12.432L12.5125 14.966C12.675 15.1285 12.8679 15.2574 13.0802 15.3453C13.2926 15.4333 13.5201 15.4786 13.7499 15.4786C13.9798 15.4786 14.2073 15.4333 14.4197 15.3454C14.632 15.2574 14.8249 15.1285 14.9874 14.966C15.1499 14.8035 15.2788 14.6106 15.3668 14.3983C15.4548 14.186 15.5 13.9584 15.5 13.7286C15.5 13.4988 15.4548 13.2712 15.3668 13.0589C15.2789 12.8466 15.15 12.6536 14.9875 12.4911V12.4911ZM1.5 6C1.5 3.51875 3.51875 1.5 6 1.5C8.48125 1.5 10.5 3.51875 10.5 6C10.5 8.48125 8.48125 10.5 6 10.5C3.51875 10.5 1.5 8.48125 1.5 6ZM14.2803 14.2589C14.1396 14.3993 13.9488 14.4782 13.75 14.4782C13.5512 14.4782 13.3604 14.3993 13.2197 14.2589L10.8268 11.866L10.0801 10.0587L11.8875 10.8053L14.2804 13.1982C14.4208 13.339 14.4997 13.5297 14.4997 13.7286C14.4997 13.9274 14.4208 14.1181 14.2803 14.2589V14.2589Z" fill="#59B6BE" /></svg>
                  <p>Any pending malpractice suits</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}