import React, { useState } from 'react'
import styles from './index.module.css'
import { validateEmail } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import SuccessMessage from '../ui_kit/utilities/success_message';
import { createDownloadGuideContact } from '../../api/download_guide_contacts';
import { successToast } from '../ui_kit/utilities/success_toast';
import RequestButton from '../ui_kit/utilities/request_button';

export default function EmailSignupPanel({
    headline = 'Subscribe to our newsletter!',
    para = "Receive monthly tips, tricks, news, and updates in your inbox!",
    type,
    gtm,
    pendoTag = 'email newsletter signup panel',
    disclaimer = 'By submitting this form, you acknowledge PatientPartner uses your information in accordance with its Privacy Policy. You may unsubscribe from our communications at any time.',
    cta = "Sign me up"
}) {

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [procedures, setProcedures] = useState('');
    let disabled = 'disabled'
    if (validateEmail(email)) {
        disabled = ''
    }

    const validateUserEmail = () => {
        if (!validateEmail(email)) {
            setError('Please enter a valid email')
        } else {
            setError('')
        }
    }

    const onSubmit = (callback) => {
        setError('')
        createDownloadGuideContact({ email, type, procedures }, () => {
            setEmail('')
            setProcedures('')
            successToast('Signed up successfully!')
            callback()
        })
    }

    return (
        <div className={`container-fluid ${styles.cont}`}>
            <div className="row">
                <div className="col">
                    <h2 className={`text-center darkprim marg-bottom-10 ${styles.headline}`}>{headline}</h2>
                    <p className={`text-center darkprim marg-bottom-20 ${styles.para}`}>{para}</p>
                    <div onBlur={() => validateUserEmail()} style={{ marginBottom: '20px' }} className="material-input light">
                        <input value={email} onChange={(e) => setEmail(e.target.value)} required />
                        <span className="bar"></span>
                        <label>Email</label>
                    </div>
                    {error &&
                        <ErrorMessage error={error} />
                    }
                    <div style={{ marginBottom: '20px' }} className="material-input light">
                        <input value={procedures} onChange={(e) => setProcedures(e.target.value)} required />
                        <span className="bar"></span>
                        <label>Procedure(s) you’re interested in</label>
                    </div>
                    <p style={{ margin: '20px 0px' }} className={styles.disclaimer}>
                        {disclaimer !== '' &&
                            disclaimer
                        }
                    </p>
                    <RequestButton
                        disabled={disabled}
                        classnames={styles.cta}
                        style={{ width: '100%' }}
                        pendoTag={pendoTag}
                        request={(callback) => onSubmit(callback)}
                        data-gtm-click-label={gtm}
                        cta={cta}
                    />
                </div>
            </div>
        </div>
    )
}
