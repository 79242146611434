import React, { useState } from 'react'
import { validateEmail, validateText, validatePhone } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import DatePickerWrapper from '../date_picker_wrapper';
import Todpicker from '../todpicker';
import RequestButton from '../ui_kit/utilities/request_button';

export default function AppointmentConfirmationForm({
    surgeons,
    answers,
    onSubmit,
    onBack,
    head = `Confirm your information below for your appointment with ${surgeons.name}`,
    para,
    cta = 'Submit appointment request',
    gtm,
    datePlaceholder,
    timePlaceholder,
    light = false,
    wrapperClassName,
    capturePhone = false,
}) {
    const [error, setError] = useState('');

    // Datepicker form functions
    const getNextWork = (d) => {
        d.setDate(d.getDate() + 1); // tomorrow
        if (d.getDay() == 0) d.setDate(d.getDate() + 1);
        else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
        return d;
    }
    const isWeekday = date => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const minDate = getNextWork(getNextWork(new Date()));
    const [apptDate, setApptDate] = useState(answers.apptDate || '');
    const [apptTimeOfDay, setApptTimeOfDay] = useState(answers.apptTimeOfDay || timePlaceholder);
    const [conversationTopics, setConversationTopics] = useState(answers.extraInfo || '')
    const [phone, setPhone] = useState(answers.phone || '');
    var checkedPhone = validatePhone(phone);

    const validatePhoneOnBlur = () => {
      if (phone !== '' && !checkedPhone) {
        setError('Enter a valid phone number');
      }
      else {
        setError('');
      }
    }

    var checkedApptDate = apptDate !== '' && apptDate !== null;
    var checkedApptTimeOfDay = apptTimeOfDay !== timePlaceholder;

    let disabled = 'disabled'

    if (checkedApptDate && checkedApptTimeOfDay && (checkedPhone || !capturePhone)) {
        disabled = '';
    } else {
        disabled = 'disabled';
    }

    const onClick = (event) => {
      let newAnswer = { ...answers, apptDate, apptTimeOfDay, extraInfo: conversationTopics };
      if (capturePhone) {
        newAnswer = { ...newAnswer, phone };
      }
      onSubmit(newAnswer);
      setError('');
    }

    const validate = (event) => {
        if(event){
            event.preventDefault();
        }
        if (apptDate == null || apptDate == '') {
            setError('Please enter an appointment date');
        }
        else if (apptTimeOfDay == '') {
            setError('Please enter a time of day');
        }
        else {
            setError('');
        }
    }

    const todPickerOptions = [timePlaceholder, 'Morning (9:00-12:00pm)', 'Afternoon (1:00-5:00pm)'];

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-2"></div>
                <div className={`col-sm-8 ${wrapperClassName}`}>
                    <h5 className={`${para ? 'marg-bottom-10' : 'marg-bottom-30'} text-left`}>
                        {head}
                    </h5>
                    {para &&
                        <p className="marg-bottom-40">
                            {para}
                        </p>
                    }
                    <div className="weight-loss-surgeon-match-confirmation-form ">
                        <DatePickerWrapper
                            selected={apptDate}
                            onChange={(date) => setApptDate(date)}
                            minDate={minDate}
                            filterDate={isWeekday}
                            wrapperClassName={`material-input ${light ? 'light' : ''} text-left`}
                            placeholderText={datePlaceholder}
                            onBlur={validate}
                        />
                        <Todpicker
                            onBlur={validate}
                            value={apptTimeOfDay}
                            onChange={(event) => {setApptTimeOfDay(event.target.value);}}
                            options={todPickerOptions}
                            light={light}
                        />
                        {
                          capturePhone && (
                            <div style={{maxWidth: '100%', marginBottom: '20px'}} className={`material-input`}>
                              <input type="tel" name="phone" value={phone} onChange={(event) => setPhone(event.target.value)} onBlur={validatePhoneOnBlur} required />
                              <span className="highlight"></span>
                              <span className="bar"></span>
                              <label>Phone Number</label>
                            </div>
                          )
                        }
                        <div className="text-box-wrap">
                            <p style={{fontSize: '14px',color: '#333333', marginLeft: '10px', color: '#333333'}}>Notes to doctor (optional)</p>
                          <textarea className={`autofocus message-box material-input ${light ? 'light' : ''}`} placeholder="Tell us here..." value={conversationTopics} onChange={(e) => setConversationTopics(e.target.value)}></textarea>
                        </div>
                        {error && disabled != '' &&
                            <ErrorMessage
                                error={error}
                            />
                        }
                        <div className="container">
                            <div className="row">
                                <RequestButton
                                    request={onClick}
                                    disabled={disabled}
                                    classnames={'marg-top-20 marg-center'}
                                    style={{width: '100%'}}
                                    cta={cta}
                                    gtm={`${gtm} Appointment Confirmation Form Submit`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2"></div>
            </div>
        </div>
    )
}
