export const createContactUsContacts = (
  name,
  email,
  phone,
  message,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  fetch('/contact_us_contacts',
    {
      method: 'POST',
      headers: { 
        "Content-Type": "application/json",
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: message
      })
    }
  ).then(data => {
    successFunction(data);
  })
}
