import React, { useState, useEffect, useContext } from 'react'
import {
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { findConversation, findConversations, createConversation } from '../../../api/conversations';
import { getUsers } from '../../../api/users';
import ActionCable from 'actioncable';
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider'
import Context from '../../../utils/context';
import ConversationOtherUserProfile from './profile_panel/conversation_other_user_profile';
import UserDropdownSelector from '../../users/dropdown_selector';
import Conversation from './conversation';
import Modal from '../../modal'
import ConversationList from './conversation_list';
import LoadingScreen from './../../loading_screen'
import './conversation_room.css'
import ConversationRoomLoadingSkeleton from './loading_screens/conversation_room_loading_skeleton';
import RequestAppointmentModal from '../../surgeon/request_appointment_modal';
import DisplayMatchModal from '../../surgeon/display_match_modal';
import PostConversationModal from '../../surgeon/referred_mentor_match/post_conversation_modal';
import styles from './index.module.css'

export default function ConversationRoom({
  match
}) {
  let conversation_id = match.params.conversation_id
  let { path } = useRouteMatch(); // path === '/member/conversations/conversation_id?'
  let history = useHistory();
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();
  const cable = ActionCable.createConsumer(gon.action_cable_url);
  const [conversations, setConversations] = useState(null);
  const [conversation, setConversation] = useState(null);
  const [createConversationUser, setCreateConversationUser] = useState();
  const [showAddConversation, setShowAddConversation] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showSurgeonModal, setShowSurgeonModal] = useState(false);
  const [showDisplaySurgeonMatchModal, setShowDisplaySurgeonMatchModal] = useState(false);
  const [showPostConversationSurveyModal, setShowPostConversationSurveyModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let numConversationsWithUnreadMessages = 0;
  if (conversations) {
    numConversationsWithUnreadMessages = conversations.filter(x => x.unread_messages > 0).length;
  }

  useEffect(() => {
    if (user.show_welcome_modal) {
      context.auth.userUpdate({ show_welcome_modal: false });
      setShowWelcomeModal(true);
    }
  }, []);

  useEffect(() => {
    if (user.show_surgeon_modal) {
      context.auth.userUpdate({ show_surgeon_modal: false });
      setShowDisplaySurgeonMatchModal(true);
    }
  }, []);

  const fetchAllConversations = () => {
    findConversations((conversations) => {
      if (user.current_profile_type === 'PatientPartner') {
        conversations = conversations.filter((c) => c.messages.length > 0)
      }
      let sortedConversations = conversations.sort((a, b) => ((a.messages.length > 0 ? new Date(a.messages[a.messages.length - 1].created_at).getTime() : new Date(a.created_at).getTime()) - (b.messages.length > 0 ? new Date(b.messages[b.messages.length - 1].created_at).getTime() : new Date(b.created_at).getTime())))
      setConversations(sortedConversations.reverse());
    })
  }

  const fetchConversation = (showLoad, triggerShowSurgeonModal) => {
    if (conversation_id) {
      if(showLoad){
        setLoading(true)
      }
      findConversation(conversation_id, (conversation) => {
        setConversation(conversation);
        scrollToBottomOfChat();
        setLoading(false);
        if (
          triggerShowSurgeonModal &&
          user.show_surgeon_modal_in_pp_conversations &&
          conversation.surgeon_referred_mentor_match_id &&
          user.current_profile_type === 'Patient' &&
          conversation.messages.length === 5
        ) {
          setShowDisplaySurgeonMatchModal(true);
        }
        else if (
          user.current_profile_type === 'Patient' &&
          conversation.surgeon_referred_mentor_match_id &&
          conversation.post_conversation_url &&
          conversation.messages.length >= 3 &&
          conversation.patient_mentor_connection_completed &&
          !conversation.post_survey_completed
        ) {
          setShowPostConversationSurveyModal(true);
        }
      },

      () => {
        setConversation(null);
        setLoading(false);
          history.push('/member/conversations')
      });
    }
  }

  useEffect(() => {
    fetchAllConversations()
    fetchConversation(true)
  }, [conversation_id]);

  const scrollToBottomOfChat = () => {
    var messageBody = document.querySelector('.message-container');
    if (messageBody) {
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
  }

  const updateRoom = (data) => {
    switch (data.type) {
      case 'message':
        fetchConversation(false, true)
        fetchAllConversations()
        break;
      default:
        return
    }
  }

  const createConversationButtonClick = () => {
    createConversation({ user_id: createConversationUser.id }, (conversation) => {
      setShowAddConversation(false);
      history.push(`/member/conversations/${conversation.uuid}`);
    });
  };

  const impersonateButtonClick = () => {
    context.auth.impersonate(createConversationUser.id, (user) => {
      history.push(`/member/conversations`);
    });
  }

  const newConversationButtonClick = () => {
    setShowAddConversation(true);
  }

  if (isMobile) {
    return (
      <ActionCableProvider cable={cable}>
        {conversations !== null &&
          <ActionCableConsumer channel={{ channel: 'ConversationsChannel', ids: conversations.map((e) => e.uuid) }} onReceived={(data) => { console.log(data); updateRoom(data); }} />
        }
        <Switch>
          <Route exact path='/member/conversations'>
            {conversations === null ?
              <div style={{position:'absolute', top: '160px', width: '100vw'}}>
                <LoadingScreen />
              </div>
            :
              <ConversationList
                newConversationButtonClick={newConversationButtonClick}
                conversations={conversations}
                pathToMatch={'/member/conversations/'}
              />
            }
          </Route>
          <Route exact path={path}>
            {loading &&
              <ConversationRoomLoadingSkeleton type={'mobile'}/>
            }
            {!showAddConversation && conversation && !loading && (
              <>
                <Conversation
                  conversation={conversation}
                  user={user}
                  fetchConversation={fetchConversation}
                  otherUser={conversation.other_user_conversations[0]}
                  numConversationsWithUnreadMessages={numConversationsWithUnreadMessages}
                  backPath={'/member/conversations'}
                  profilePath={`/member/profile/${conversation.other_user_conversations[0].profile.slug}`}
                />
                {
                  showDisplaySurgeonMatchModal && conversation && conversation.surgeon_referred_mentor_match_id &&
                    <DisplayMatchModal
                      surgeonReferredMentorMatchId={conversation.surgeon_referred_mentor_match_id}
                      setModal={setShowDisplaySurgeonMatchModal}
                      patientName={user.name}
                    />
                }
              </>
            )}
          </Route>
          <Route exact path={`${path}/profile`}>
            {conversation &&
              <ConversationOtherUserProfile
                userProfileType={user.current_profile_type}
                otherUser={conversation.other_user_conversations[0]}
                backPath={`/member/conversations/${conversation.uuid}`}
              />
            }
          </Route>
        </Switch>
      </ActionCableProvider >
    )
  }

  return (<>
    <ActionCableProvider cable={cable}>
      {conversations !== null &&
        <ActionCableConsumer channel={{ channel: 'ConversationsChannel', ids: conversations.map((e) => e.uuid) }} onReceived={(data) => { console.log(data); updateRoom(data); }} />
      }
      <div className={`container-fluid ${styles.conversation_room_bg}`}>
        <div className="container conversation-room-innr h-100">
          <div className="row h-100">
            <div className="col-md-3 de-pad height-fill">
              <ConversationList
                newConversationButtonClick={newConversationButtonClick}
                conversations={conversations}
                pathToMatch={'/member/conversations/'}
                selectedConversation={match.params.conversation_id}
              />
            </div>
            {loading &&
              <div className={`${conversation == null ? 'col' : 'col-md-9'} chat-room h-100`}>
                <ConversationRoomLoadingSkeleton />
              </div>
            }
            {!loading && <>
              <div className={`${conversation == null ? 'col' : 'col-md-6'} ${styles.chat_room}`}>
                {!showAddConversation && conversation == null &&
                  <div className={styles.splash_content}>
                    <img className={styles.splash_icon} src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/messages_splash.svg' />
                    <p className={styles.splash_copy}>Select a conversation on the left to view and<br /> send messages to another community member.</p>
                  </div>
                }
                {!showAddConversation && conversation && !loading &&
                  <Conversation
                    conversation={conversation}
                    user={user}
                    fetchConversation={fetchConversation}
                    otherUser={conversation.other_user_conversations[0]}
                    numConversationsWithUnreadMessages={numConversationsWithUnreadMessages}
                  />
                }
              </div>
              {showAddConversation &&
                <div className="col-md-6 chat-room">
                  <>
                    <UserDropdownSelector
                      user={createConversationUser}
                      setUser={setCreateConversationUser}
                    />
                    {
                      createConversationUser && (
                        <>
                          <button onClick={createConversationButtonClick}>Create conversation</button>
                          <button onClick={impersonateButtonClick}>Impersonate</button>
                        </>
                      )
                    }
                  </>
                </div>
              }
              {!showAddConversation && conversation != null &&
                <div style={{ overflowX: 'hidden', overflowY: 'auto', height: '100%' }} className="col-md-3">
                  <ConversationOtherUserProfile
                    userProfileType={user.current_profile_type}
                    otherUser={conversation.other_user_conversations[0]}
                    setShowSurgeonModal={setShowSurgeonModal}
                  />
                </div>
              }
            </>}
            {
              showWelcomeModal && (
                <Modal
                  className="welcome-message-modal pp-modal"
                  content={(
                    <>
                      <h1 style={{ fontSize: '48px' }} className="marg-bottom-10">Welcome to PatientPartner</h1>
                      <p className="marg-bottom-15"><b>PatientPartner mentors are real people and volunteers</b> here to share their personal experience and answer questions you might have about being a patient.</p>
                      <h5 style={{ color: '#59B6BE', letterSpacing: '1px', fontSize: '16px' }} className="marg-bottom-10">WHAT'S NEXT</h5>
                      <ol>
                        <li>
                          <p className="bold" style={{ fontSize: '16px' }}>Send your first message</p>
                          <p style={{ fontSize: '16px' }} className="marg-bottom-10">Introduce yourself to your mentor and ask your first question!</p>
                        </li>
                        <li>
                          <p className="bold" style={{ fontSize: '16px' }}>Replies from real people</p>
                          <p style={{ fontSize: '16px' }} className="marg-bottom-10">Mentors typically reply within 24 hours.</p>
                        </li>
                        <li>
                          <p className="bold" style={{ fontSize: '16px' }}>SMS Notification</p>
                          <p style={{ fontSize: '16px' }} className="marg-bottom-10">We'll send you a text notification letting you know when your mentor replies.</p>
                        </li>
                      </ol>
                      <button onClick={() => setShowWelcomeModal(false)} data-gtm-click-label="Welcome Modal Get Started">Get Started</button>
                    </>
                  )}
                  onClose={() => {
                    setShowWelcomeModal(false)
                  }}
                />
              )
            }
            {
              showSurgeonModal && conversation && conversation.other_user_conversations[0].profile.other_data.previous_surgeon && conversation.other_user_conversations[0].profile.other_data.previous_surgeon.surgeon_administrative_assistant_id &&
                <RequestAppointmentModal
                  surgeonId={conversation.other_user_conversations[0].profile.other_data.previous_surgeon.id}
                  surgeonName={conversation.other_user_conversations[0].profile.other_data.previous_surgeon.name}
                  setModal={setShowSurgeonModal}
                  patientName={conversation.other_user_conversations[0].profile.full_name}
                />
            }
            {
              showDisplaySurgeonMatchModal && conversation && conversation.surgeon_referred_mentor_match_id &&
                <DisplayMatchModal
                  surgeonReferredMentorMatchId={conversation.surgeon_referred_mentor_match_id}
                  setModal={setShowDisplaySurgeonMatchModal}
                  patientName={user.name}
                />
            }
            {
              showPostConversationSurveyModal && conversation && conversation.post_conversation_url &&
                <PostConversationModal
                  postConversationUrl={conversation.post_conversation_url}
                  setModal={setShowPostConversationSurveyModal}
                />
            }
          </div>
        </div>
      </div>
    </ActionCableProvider >
  </>)
}
