const initialState = {
  getStartedAnswers: {},
  getStartedQuestionnaireState: 1,
  getStartedContactId: null,
};

const getStarted = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_GET_STARTED_ANSWERS':
      return {
        ...state,
        getStartedAnswers: action.item
      }
    case 'SET_GET_STARTED_QUESTIONNAIRE_STATE':
      return {
        ...state,
        getStartedQuestionnaireState: action.item,
      }
      case 'SET_GET_STARTED_CONTACT_ID':
        return {
          ...state,
          getStartedContactId: action.item,
        }
    default:
      return state
  }
}

export default getStarted
