import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Context from '../../../../../../utils/context';
import styles from './index.module.css'

export default function MentorCard({
  mentor,
  id,
  callback = null,
}) {
  const [showBio, setShowBio] = React.useState(false);
  const context = useContext(Context);
  let history = useHistory();

  const scheduleCall = () => {
    if (context.auth.isLoggedIn()) {
      history.push(`/member/mentor-matches/${id}/spv/thank-you-success`);
    } else {
      history.push(`/member/join/support?patient_partner_id=${mentor.id}`)
    }
  }

  return (
    <div className={`${styles.mentor_card} content_container container`}>
      <div className='row'>
        <div className='col col-md-7' style={{ paddingRight: '0px' }}>
          <div style={{ display: 'flex' }}>
            <div className={styles.photo} style={{ backgroundImage: `url(${mentor.image})` }} alt="mentor" />
            <div style={{ marginLeft: '16px' }}>
              <p className={styles.name}>{mentor.name} <span className={styles.mentor_tag}>mentor</span></p>
              <p className={styles.location}>{mentor.display_location}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='row' style={{ marginTop: '10px' }}>
        <div className='col'>
          <p>{mentor.surgeries_or_treatments.map((sot, i) => <span key={i} className={styles.sot}>{i !== 0 && `, `}{sot}</span>)}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <p className={`${styles.para} ${showBio ? '' : styles.short}`}>{mentor.bio}</p>
          <p style={{ cursor: 'pointer', marginTop: '10px' }} onClick={() => setShowBio(!showBio)} className={styles.sot}>{showBio ? 'Show less' : 'Read More'}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <button style={{ width: '100%', display: 'block', marginTop: '15px' }} className={styles.btn} onClick={() => scheduleCall()}>Schedule a Call</button>
        </div>
      </div>
    </div>
  )
}
