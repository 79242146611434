import React, { useState, useEffect, useContext } from 'react'
import styles from './index.module.css'
import CheckboxWLabel from '../../../../ui_kit/utilities/checkbox_w_label';
import RequestButton from '../../../../ui_kit/utilities/request_button';
import SelectSearch from '../../../../ui_kit/inputs/select_search';
import Context from '../../../../../utils/context';
import { successToast } from '../../../../ui_kit/utilities/success_toast';
import ErrorMessage from '../../../../ui_kit/utilities/error_message';
import { addNewProcedure } from '../../../../../api/profiles';
import { createActivity } from '../../../../../api/activities';

export default function AddProcedure({
  recovered = false,
  setProcedures,
  setAddProcedure,
  procedures
}) {
  const [isPrivate, setIsPrivate] = useState(false);
  const [chosenProcedure, setChosenProcedure] = useState('');
  const [show, setShow] = useState(false);
  const context = useContext(Context);
  let isMobile = context.viewport.getIsMobile()

  let selectableProcedures = []
  for (const [key, value] of Object.entries(gon.procedure_surgery_mappings)) {
    if (key !== 'Back/neck treatment' && key !== 'Plastic surgery') {
      selectableProcedures.push(key, ...value)
    }
  }

  useEffect(() => {
    setShow(true)
  }, []);

  const addProcedure = (afterAction) => {
    addNewProcedure({ id: context.auth.getCurrentUser().profile_id, procedure: chosenProcedure, private: isPrivate, recovered: recovered }, (data) => {
      setProcedures(data)
      setAddProcedure(false)
      successToast("New procedure added.");
      createActivity("Activity::EditProfile", { fields: 'add_procedure' });
      afterAction()
      window.location.reload();
    });
  }

  return (
  <div className="container">
    <div style={{padding: '20px'}} className={`${styles.add_procedure} ${show ? styles.show : null} row`}>
      <div className={`${styles.input} col-12 col-md-6 de-pad`}>
        <p className={styles.label}>Procedure type</p>
        <SelectSearch
          label='Procedure type'
          choices={selectableProcedures}
          selected={chosenProcedure}
          setSelected={setChosenProcedure}
          light={false}
        />
      </div>
      <div className={`col-md-3 col-5 de-pad`} style={isMobile ? {marginTop: '20px'} : {marginLeft:'10px'}}>
        <p className={styles.label}>Visibility</p>
        <CheckboxWLabel
          setCheck={setIsPrivate}
          check={isPrivate}
          label='Private'
          wrapperStyles={isMobile ? { padding: '10.5px 20px', width: '100%' } : { padding: '10.5px 20px' }}
        />
      </div>
      <RequestButton
        classnames={styles.add_button}
        cta="Add"
        request={(afterAction) => addProcedure(afterAction)}
        disabled={chosenProcedure === '' || procedures.filter(procedure => ((procedure.surgery === null && procedure.procedure === chosenProcedure) || (procedure.surgery !== null && procedure.surgery === chosenProcedure))).length > 0}
      />
      <ErrorMessage
        error={procedures.filter(procedure => ((procedure.surgery === null && procedure.procedure === chosenProcedure) || (procedure.surgery !== null && procedure.surgery === chosenProcedure))).length > 0 ? 'Procedure already added to your profile' : ''}
      />
    </div>
  </div>
  )
}
