import React from 'react';
import { getUsers } from '../../../api/users';
import InputWithRemoteOptions from '../../ui_kit/utilities/input_with_remote_options';

export default function UserDropdownSelector({
  user,
  setUser,
  light = true,
  placeholder = '',
}) {
  const toUserString = u => `${u.id} | ${u.email} | ${u.name}`;
  const fromUserString = (str) => {
    if (str === "") {
      return null;
    }

    const matches = str.match(/(\d+) | (.+) | (.+)/);
    return {
      id: matches[0],
      email: matches[1],
      name: matches[2]
    };
  };

  return (
    <InputWithRemoteOptions
      value={user ? toUserString(user) : ''}
      setValue={(value) => setUser(fromUserString(value))}
      getOptions={(value, successCallback) => {
        getUsers({ search: value }, (users) => {
          successCallback(users.map(toUserString))
        });
      }}
      name={'createConversationUser'}
      light={light}
      placeholder={placeholder}
    />
  )
}
