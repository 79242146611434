import React, { useContext } from "react";
import styles from "../index.module.css";
import styles1 from "../../posts/createpost.module.css";
import Context from "../../../utils/context";

export default function WelcomePopup({ removeWelcomeWidgetOnClick }) {
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();

  return user.current_profile_type === "PatientPartner" ? (
    <div
      className="content_container marg-top-20"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div className={styles.container_edge}></div>
      <div className="container">
        <div
          className={`${styles1.create_new_banner}`}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <h4 className={styles.title}>Welcome to PatientPartner</h4>
          <span
            onClick={removeWelcomeWidgetOnClick}
            className={`${styles1.close}`}
          >
            <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg" />
          </span>
        </div>
        <p className={`${styles.body} marg-top-10`}>
          This is a safe space where you can provide support to other patients
          going through a significant healthcare journey.
        </p>
        <div className="row" style={{ display: "flex" }}>
          <div className={`${styles.icon_text} marg-top-20`}>
            <img
              className={styles.icon}
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+23/Vector.svg"
            />
            Explore what members are talking about and join the conversation.
          </div>
        </div>
        <div className="row">
          <div className={`${styles.icon_text} col-12 marg-top-20`}>
            <img
              className={styles.icon}
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/Vector.svg"
            />
            Chat one on one with patients looking for support.
          </div>
        </div>
      </div>
    </div>
  ) : user.current_profile_type === "Patient" ? (
    <div
      className="content_container marg-top-20"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div className={styles.container_edge}></div>
      <div className="container">
        <div
          className={`${styles1.create_new_banner}`}
          style={{ backgroundColor: "transparent", padding: "0" }}
        >
          <h4 className={styles.title}>Welcome to PatientPartner</h4>
          <span
            onClick={removeWelcomeWidgetOnClick}
            className={`${styles1.close}`}
          >
            <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg" />
          </span>
        </div>
        <p className={`${styles.body} marg-top-10`}>
          This is a safe space where you can find support and guidance for your
          healthcare journey - all through relatable people.
        </p>
        <div className="row">
          <div className={`${styles.icon_text}  col-12 marg-top-20`}>
            <img
              className={styles.icon}
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+23/Vector.svg"
            />
            Explore what members are talking about and join the conversation.
          </div>
        </div>
        <div className="row">
          <div className={`${styles.icon_text} col-12 marg-top-20`}>
            {" "}
            <img
              className={styles.icon}
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/Vector.svg"
            />
            Chat one on one with recovered patients (aka 'PatientPartner
            Mentors').
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
