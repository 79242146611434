export const findConversation = (
    conversation_id,
    successCallback,
    onError,
    pretendPatient
) => {
    fetch(`/api/v1/conversations/${conversation_id}?pretend_patient=${pretendPatient}`, {
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(response => {
          if (response.ok) return response.json();
          return Promise.reject(response.json())
        })
        .then(data => successCallback(data))
        .catch(data => onError(data))
}

export const findConversations = (
    successCallback
) => {
    fetch(`/api/v1/conversations`, {
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then(response => response.json())
        .then(data => {
            successCallback(data);
        })
}

export const createConversation = (
  params,
  successCallback
) => {
  fetch(`/api/v1/conversations`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
  .then(response => response.json())
  .then(data => {
    successCallback(data);
  })
}


export const requestACall = (uuid, onSuccess, onError) => {
  fetch(`/api/v1/conversations/${uuid}/request_call`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    }
  })
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      onSuccess(data.user);
    }
    else {
      onError(data.errors);
    }
  });
}

export const updateConversation = (
  id,
  params,
  successCallback
) => {
  fetch(`/api/v1/conversations/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: 'PATCH',
    body: JSON.stringify(params),
  })
  .then(response => response.json())
  .then(data => {
      successCallback(data);
  })
}
