import React, { useState, useContext } from 'react'
import GhostButton from '../../../../ui_kit/utilities/ghost_button'
import RequestButton from '../../../../ui_kit/utilities/request_button'
import InputWithOptions from '../../../../ui_kit/utilities/input_with_options'
import SelectSearch from '../../../../ui_kit/inputs/select_search'
import Context from '../../../../../utils/context';
import uuid from 'react-uuid'
import styles from './mf_previous_symptom_management.module.css';

export default function MfPreviousSymptomManagement({
    value,
    onBack,
    onSubmit,
    para = "Which medication(s), apart from your current one, have you (or the patient) previously been prescribed to manage Myelofibrosis (MF)? Using the dropdown selection, please specify how long you were using each medication"
}) {
    const [addSymptom, setAddSymptom] = useState();
    const [selectedMedication, setSelectedMedication] = useState("");
    const [selectedDuration, setSelectedDuration] = useState("");
    const [allMedications, setAllMedications] = useState(value || []);
    const context = useContext(Context);
    let isMobile = context.viewport.getIsMobile()

    const selectableMedications = [
      'Jakafi® (ruxolitinib)',
      'VONJO® (pacritinib)',
      'INREBIC® (fedratinib)',
      'Peginterferon',
      'Hydroxyurea'
    ];

    const selectableDurations = [
      '0-3mo',
      '3-6mo',
      '6-12mo',
      '1yr+'
    ];

    const addMedication = (afterAction) => {
      setAddSymptom(false);
      const currentAllMedications = [...allMedications];
      currentAllMedications.push({ id: uuid(), medication: selectedMedication, duration: selectedDuration })
      setAllMedications([...currentAllMedications]);
      afterAction();
    }

    const deleteMedication = (id) => {
      setAllMedications(allMedications.filter(m => m.id !== id));
    }

    const submit = (e) => {
      e.preventDefault();
      onSubmit(allMedications);
    }

    return (
        <form id="plans">
            <h5 className="question">
              {para}
            </h5>
            <GhostButton onClick={() => setAddSymptom(!addSymptom)} style={{ marginLeft: 'auto' }}>
              <img alt='add medication' style={{ marginRight: '14px', height: '12px', transform: addSymptom ? 'rotate(45deg)' : 'rotate(0deg)' }} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/plus_.svg" />
              <div style={{ position: 'relative' }}>
                <span className={`${addSymptom ? styles.show : null} ${styles.no_show} ${styles.cancel}`} style={{ fontWeight: '600', color: '#419DA5', position: 'absolute', right: '0px' }}>Cancel</span>
                <span className={`${addSymptom ? null : styles.show} ${styles.no_show} ${styles.add}`} style={{ fontWeight: '600', color: '#419DA5' }}>Add medication</span>
              </div>
            </GhostButton>
            {
              addSymptom &&
              <div className="container">
                <div style={{padding: '20px'}} className={`${styles.add_procedure} ${styles.show} row`}>
                  <div className={`${styles.input} col-12 col-md-6 de-pad`}>
                    <InputWithOptions
                      value={selectedMedication}
                      setValue={setSelectedMedication}
                      options={selectableMedications}
                      label={'Medication'}
                    />
                  </div>
                  <div className={`${styles.input} col-12 col-md-6 de-pad`}>
                    <InputWithOptions
                      value={selectedDuration}
                      setValue={setSelectedDuration}
                      options={selectableDurations}
                      label={'Duration'}
                    />
                  </div>
                  <RequestButton
                    classnames={styles.add_button}
                    cta="Add"
                    request={(afterAction) => addMedication(afterAction)}
                    disabled={!(selectedMedication !== '' && selectableDurations.includes(selectedDuration))}
                  />
                </div>
              </div>
            }
            {
              allMedications.length > 0 &&
              <div className='content_container'>
                {
                  allMedications.map(medication => {
                    return (
                      <div className={medication.id !== 0 ? 'marg-top-15' : null} style={{ display: 'flex' }}>
                        <p className={`${styles.bio}`}>{medication.medication} ({medication.duration})</p>
                        <div style={{ marginLeft: 'auto', cursor: 'pointer', position: 'relative', display:'flex', alignItems: 'center' }}>
                          <img onClick={() => deleteMedication(medication.id)} src={"https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/trash.svg"}/>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }
            <div className="container-fluid de-pad">
                <div className="row button-holder de-pad">
                    {onBack &&
                      <button className="back col-lg-6 col" type="button" onClick={onBack}>Back</button>
                    }
                    <button className={`gtm-preexisting-conditions submit col-lg-6 col`} type="submit" onClick={(e) => submit(e)} >Next</button>
                </div>
            </div>
        </form>
    )
}
