import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import Context from "../../../utils/context";
import "./index.css";

export default function TextLeftImgRight({
  headline = "This is a big headline",
  para = "Have questions, comments, or suggestions? We’d love to know. Fill out the form below and we’ll get back to you within 24 hours.",
  actionContent = "",
  bgColor = "#DDF7F9",
  bg = "https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/photo-1587554801471-37976a256db0.jpeg",
  // you may have to tweak this to get the icon to positon correctly
  heightOfImage = "1000px",
  widthOfImage = "1000px",
  showRightImgOnMobile = true,
  imgOffset = true,
  wrapperPadding,
  headlineFontSize,
  bgImgFit = false,
  topImage = {},
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  return (
    <div
      style={{
        backgroundColor: bgColor,
        paddingTop: `${isMobile ? "" : wrapperPadding}`,
        paddingBottom: wrapperPadding,
      }}
      className={`container-fluid txtimgbg_hero ${
        imgOffset ? "" : "marg-bottom-0"
      }`}
    >
      <div className="container">
        <div className="semibg"></div>
        <div className="col PP-logo">{topImage}</div>

        <div className="row justify-content-center h-100">
          <div className="col-md-6 my-auto">
            <h1 style={{ fontSize: headlineFontSize }}>{headline}</h1>
            <p className="marg-bottom-30">{para}</p>
            {actionContent}
          </div>
          {!isMobile && (
            <div className="col-md-6 my-auto rightside">
              <div
                className={`rs-image ${
                  imgOffset ? "" : "marg-bottom-40 marg-top-40"
                }`}
                style={{
                  backgroundImage: `url('${bg}')`,
                  height: heightOfImage,
                  backgroundSize: `${bgImgFit ? "contain" : "cover"}`,
                }}
              ></div>
            </div>
          )}
          {isMobile && showRightImgOnMobile && (
            <div className="col-md-6 my-auto rightside">
              <div
                style={{
                  backgroundImage: `url('${bg}')`,
                  height: heightOfImage,
                  backgroundSize: `${bgImgFit ? "contain" : "cover"}`,
                }}
                className={`rs-image ${
                  imgOffset ? "" : "marg-bottom-40 marg-top-40"
                }`}
              ></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
