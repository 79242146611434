import React from 'react'
import btn_styles from './index.module.css'

export default function IconButtonSvg({
  icon = <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.1 1.90909H16.15V0H14.25V1.90909H4.75V0H2.85V1.90909H1.9C0.855 1.90909 0 2.76818 0 3.81818V19.0909C0 20.1409 0.855 21 1.9 21H17.1C18.145 21 19 20.1409 19 19.0909V3.81818C19 2.76818 18.145 1.90909 17.1 1.90909ZM17.1 19.0909H1.9V6.68182H17.1V19.0909Z" fill="white"/></svg>,
  text = "Book a meeting",
  onClick,
  onMouseOver,
  onMouseOut,
  classNames,
  other,
  styles,
  textStyles = {}
}) {

  return (
    <button style={styles} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} className={`${btn_styles.button_w_icon} ${classNames}`}>
        {icon}
        <p style={textStyles} className={btn_styles.txt}>{text}</p>
        {other}
    </button>
  )
}
