import React, { useState } from 'react'
import MaskedInput from 'react-maskedinput';

export default function Age({
  title = 'What is your age?',
  para = 'We’ll match you with someone who is in the same age range as you.',
  answers,
  onBack,
  onSubmit,
  cta = 'Next',
  gtm,
}) {
  const [dateOfBirth, setDateOfBirth] = useState((answers.date_of_birth !== null && answers.date_of_birth !== undefined) ? answers.date_of_birth : "");

  return (
    <div className="row">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8">
        <div className="age-choice">
          <div>
            <h5 className="mobile-marg-20 marg-bottom-10">
              {title}
            </h5>
            <p>
              {para}
            </p>
            <div className="row marg-top-20">
              <div className="col my-auto material-input light" style={{ maxWidth: '100%', margin: '0px' }}>
                <MaskedInput value={dateOfBirth} type="tel" onChange={(e) => setDateOfBirth(e.target.value)} style={{textAlign:'left'}} mask="11/11/1111" placeholder="mm/dd/yyyy" />
              </div>
            </div>
          </div>
          <div className="row button-holder">
            {
              onBack && <button className="back col-6" type="button" onClick={onBack} data-gtm-click-label={`${gtm} Age Back`}>Back</button>
            }
            <button disabled={dateOfBirth === ''} className={`gtm-preexisting-conditions submit col-6`} type="submit" onClick={() => onSubmit({ ...answers, date_of_birth: dateOfBirth })} data-gtm-click-label={`${gtm} Age Submit`}>{cta}</button>
          </div>
        </div>
      </div>
      <div className="col-sm-2">
      </div>
    </div>

  );
}
