import React, { useContext } from "react";
import Context from "../../utils/context";
import "./index.css";

export default function EverythingYouNeed({ steps }) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  return (
    <div className="container marg-top-100 marg-bottom-100">
      <div className="container everything-you-need text-center">
        <p className="sm-header marg-bottom-10">How we help you</p>
        <h2>Everything you need. One Place. For free.</h2>
        <p className="para marg-top-20 marg-bottom-50">
          We make it as simple as possible for you to navigate your medical
          procedure. We bring all the resources and information to one place, so
          you can easily navigate through your medical procedure.
        </p>
      </div>
      <div className="row text-center"></div>
      <div
        className={"row justify-content-center text-center"} style={{paddingLeft: "25px", paddingRight: "25px"}}
      >
        {steps.map((step) => (
          <div className="col-md-4">
            <div className="step-image">{step.image}</div>
            <div className="step-headline">
              <h3>{step.headline}</h3>
            </div>
            <div className="step-para marg-bottom-60">
              <p>{step.para}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
