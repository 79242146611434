import React, { useState, useEffect, useRef } from "react";
import styles from "./createpost.module.css";
import { createPostUserReport } from "./../../api/post_user_reports";
import RequestButton from "../ui_kit/utilities/request_button";
import { successToast } from '../ui_kit/utilities/success_toast';

import { useClickAway } from "react-use";

export default function ReportModal({ post, setReportModal }) {
  const ref = useRef(null);
  const ref_1 = useRef(null);
  const [check, setCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [description, setDescription] = useState("");

  const reportReasons = [
    { reason: "Harassment or bullying" },
    { reason: "Self-injury" },
    { reason: "Promotes violence" },
    { reason: "Commercial or spam" },
    { reason: "Irrelevant content" },
    { reason: "Inappropriate content" },
    { reason: "I don’t want to see this content" },
  ];

  const checkedReasons = (reason) => {
    if (checkedItems.map((i) => i.includes(reason)).includes(true)) {
      setCheckedItems(checkedItems.filter((a) => a != reason));
    } else {
      var arr = [...checkedItems];
      arr.push(reason);
      setCheckedItems(arr);
    }
    setCheck(!check);
  };

  const submitPostUserReport = (afterAction) => {
    let reasons = [...checkedItems];
    if (description !== "") {
      reasons.push(description);
    }
    createPostUserReport(post.uuid, reasons, (data) => {
      afterAction();
      setCheckedItems([]);
      setDescription("");
      setReportModal(false);
      successToast('Thank you for reporting! Our team will review the reported post.')
    });
  };

  useClickAway(ref_1, () => {
    setReportModal(true);
  });

  useClickAway(ref, () => {
    setReportModal(false);
  });
  return (
    <div className={`${styles.create_post_otr} container-fluid`}>
      <div className={`container ${styles.cp_cont}`}>
        <div className="row justify-content-center">
          <div ref={ref} className={`col-lg-5 ${styles.cp_col}`}>
            <div className={styles.create_post_wrap}>
              <div className={`${styles.create_new_banner}`}>
                <p className={`${styles.nb_para}`}>Report post</p>
                <span
                  onClick={() => setReportModal(false)}
                  className={`${styles.close}`}
                >
                  <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg" />
                </span>
              </div>
              <div className={`${styles.content}`}>
                <div className="container">
                  <div className="container marg-top-20">
                    {reportReasons.map((r) => (
                      <>
                        <div key={r.reason} className="row">
                          <button
                            className={styles.report_button}
                            onClick={() => checkedReasons(r.reason)}
                            style={{ background: "none" }}
                          >
                            <div
                              className="col de-pad"
                              style={{ display: "flex" }}
                            >
                              {checkedItems.includes(r.reason) ? (
                                <img
                                  className={styles.no_trans}
                                  style={{ marginLeft: "-2px", marginRight: '-2px' }}
                                  src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+19/checkbox+checked.svg"
                                />
                              ) : (
                                <img
                                  className={styles.no_trans}
                                  style={{ marginLeft: "0px" }}
                                  src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+18/Rectangle.svg"
                                />
                              )}
                              <p
                                className={styles.report_reason_txt}
                                key={r.reason}
                              >
                                {r.reason}
                              </p>
                            </div>
                          </button>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="row justify-content-center">
                    <div className="col">
                      <p className={`${styles.dropdown_label}`}>
                        Description (optional)
                      </p>
                      <div className="col comment-col de-pad">
                        <div ref={ref} style={{ position: "relative" }}>
                          <textarea
                            className={`material-input marg-bottom-10 ${styles.dropdown_label}`}
                            ref={ref}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{
                              overflow: "hidden",
                              maxWidth: "100%",
                              zIndex: "0",
                              height: "100px",
                              marginTop: "0px",
                            }}
                            placeholder="Write why you want to report this..."
                            value={description}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.cta_wrap}`}>
                    <button
                      onClick={() => setReportModal(false)}
                      className={`${styles.cancel} ${styles.cancel_report} liner`}
                    >
                      Cancel
                    </button>
                    <RequestButton
                      disabled={(description !== '' || checkedItems.length !== 0) ? "" : "disabled"}
                      cta={"Report Post"}
                      classnames={styles.report_post}
                      request={submitPostUserReport}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
