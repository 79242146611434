import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import DiscussionsQuestionnaire from '../../questionnaire/discussions'
import { autofocusAll } from '../../../helpers'
import styles from './index.module.css'

export default function GetStartedV3Support({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners,
}) {
  useEffect(() => {
    autofocusAll();
  }, []);
  let history = useHistory();

  const onBackClick = () => {
    history.goBack();
  }

  return (
    <div className={styles.questionnaire_ottr} id="questionnaire">
      <div className="row justify-content-center h-100">
        <div className="col h-100">
          <DiscussionsQuestionnaire
            home={home}
            standAlone
          />
        </div>
      </div>
    </div>
  );
}
