import React, { useState, useEffect, useRef } from 'react';
import RequestButton from '../request_button';
import styles from './styles.module.css'

export default function ConfirmationModal({
  title,
  para,
  confirmationText,
  onConfirm,
  onClose,
}) {
  const ref = useRef(null);
  const ref_1 = useRef(null);

  return (
    <div className={`${styles.otr} container-fluid`}>
        <div className="container">
            <div className="row justify-content-center">
                <div ref={ref_1} className="col-lg-4">
                    <div className={`${styles.wrap} marg-top-80`}>
                        <div className={`${styles.banner}`}>
                            <p className={`${styles.para}`}>{title}</p>
                            <span onClick={onClose} className={`${styles.close}`}>
                                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg" />
                            </span>
                        </div>
                        <div className={`${styles.content}`}>
                            <p className="marg-top-20" style={{fontSize:'16px',color:'#828282',fontWeight:'500'}}>{para}</p>
                            <div className={`${styles.cta_wrap}`}>
                                <button onClick={onClose} className={`${styles.cancel} liner`}>
                                    Cancel
                                </button>
                                <RequestButton
                                  cta={confirmationText}
                                  request={onConfirm}
                                  style={{ backgroundColor: "rgb(223, 109, 72)" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
