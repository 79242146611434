import React, { useContext } from "react";
import LikeButton from '../ui_kit/utilities/like_button'
import ShareLinks from '../ui_kit/utilities/share_links'
import MoreLinks from './more_links';
import styles from "./style.module.css";
import Context from './../../utils/context';
import { useHistory } from "react-router-dom"

export default function PostCommentButtonRow({ post, clickReply, refreshPost, onEdit }) {

  let history = useHistory();
  const context = useContext(Context);

  return (
    <div className="container">
      <div className="row">
        <div className="de-pad col marg-bottom-5" style={{ display: "flex",alignItems: 'center' }}>
          <LikeButton post={post} />
          <ShareLinks title={post.title} link={post.share_url} logExtra={{ post_id: post.id }} shareLabelStyle={{fontWeight: '600', fontSize: '14px', color: 'rgb(130, 130, 130)', paddingTop: '3px', display: 'inlineFlex'}}/>
          {
            post.can_reply &&
              <button onClick={() => {!context.auth.isLoggedIn() ? history.push(`/member/login?return_to=/${encodeURIComponent(post.share_path)}`) : clickReply()}} style={{ border: "none", backgroundColor: "transparent", padding: "0px", marginRight: '3px', display: 'flex', alignItems: 'center'}}>
                <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+2/Vector.svg" />
                <p
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    fontFamily: "HK Grotesk",
                    padding: "0px 5px",
                    marginTop:'3px',
                    fontWeight: 600,
                  }}
                >
                  Reply
                </p>
              </button>
          }
          <MoreLinks
            post={post}
            onEdit={onEdit}
            removePost={refreshPost}
            parentPendoLink="share ellipses" />
          {/* <button style={{ border: "none", backgroundColor: "transparent", padding: "0px" }}>
            <img src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+9/Vector.svg" />
          </button> */}
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  );
}
