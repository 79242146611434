import React, { useState, useContext } from 'react';
import { createPostUserLike, deletePostUserLike } from "../../../../api/post_user_likes";
import { getPostUserLikes } from "../../../../api/posts";
import { createActivity } from "../../../../api/activities";
import styles from "./style.module.css";
import ReactTooltip from "react-tooltip";
import LoadingScreen from '../../../loading_screen';
import Context from '../../../../utils/context';
import { useHistory } from "react-router-dom"

export default function LikeButton({
  post,
}) {
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();
  const [currentNumLikes, setCurrentNumLikes] = useState(post.num_likes || 0);
  const [userLiked, setUserLiked] = useState(post.user_liked || false);
  const [likedUsers, setLikedUsers] = useState([]);
  let history = useHistory();
  const onMouseEnterLike = () => {
    if (!context.auth.isLoggedIn()) {
      history.push(`/member/login?return_to=/${encodeURIComponent(post.share_path)}`)
    }
    if (userLiked) {
      setCurrentNumLikes(currentNumLikes - 1);
      deletePostUserLike(post.uuid);
    }
    else {
      setCurrentNumLikes(currentNumLikes + 1);
      createActivity("Activity::DiscussionsLike", { post_id: post.uuid });
      createPostUserLike(post.uuid, () => {});
    }
    setUserLiked(!userLiked);
  };
  const getLikedUsers = () => {
    getPostUserLikes(post.uuid, (data) => {
      setLikedUsers(data);
      ReactTooltip.rebuild();
    });

    return null;
  }

  return (<>
    <div data-pendo-tag="like button" style={{paddingLeft: '2px'}} className={`${styles.like_svg} ${styles.icon}`}>
      <div className={`${styles.icon_wrap}`} onClick={onMouseEnterLike}>
        <img
          className={`${styles.like_svg_inactive}`}
          style={userLiked ? { opacity: 0 } : { opacity: 1 }}
          src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+5/Icon.svg"
        />
        <img
          className={`${styles.like_svg_active}`}
          style={userLiked ? { opacity: 1 } : { opacity: 0 }}
          src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/active_like.svg"
        />
        <div>

        </div>
      </div>
      <p data-tip data-for={`like-users-${post.uuid}`} className={`${styles.para}`}>{currentNumLikes}</p>
      <div className={likedUsers.length > 0 ? styles.show : styles.noshow}>
        <ReactTooltip
          id={`like-users-${post.uuid}`}
          place="bottom"
          afterShow={getLikedUsers}
        >
          <div className={`${styles.liked_list}`}>
            {likedUsers.map(user => (
              <p className={`${styles.liked_para}`} key={`post-user-like-${user.id}`}>{user.name}</p>
            ))}
          </div>
        </ReactTooltip>
      </div>
    </div >
  </>);
}
