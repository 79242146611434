import React, { useState } from 'react'
import LocationInput from '../ui_kit/inputs/location';
import RequestButton from '../ui_kit/utilities/request_button';
export default function Location({
  onSubmit,
  head,
  onBack
}) {
  
  const [location, setLocation] = useState('')
  const disabled = location !== '' ? '' : 'disabled';

  const onClick = (event) => {
    onSubmit(location);
  }

  const onBackClick = () => {
    onBack();
  }

  return (
    <div className="row">
      <div className="col-sm-2">
      </div>
      <div className="col-sm-8 intro-question">
        <h5 className="text-left mobile-marg-20 marg-bottom-20">{head}</h5>
        <LocationInput
          placeholder={'Enter zipcode or city name'}
          value={location}
          setValue={setLocation}
          light={true}
          fullWidth={true}
        />
        <div className="row button-holder">
          <button className="back col-6" type="button" onClick={onBackClick}>Back</button>
          <RequestButton
            request={onClick}
            disabled={disabled}
            classnames={'gtm-preexisting-conditions submit col-6'}
            cta={`Next`}
          />
        </div>
      </div>
      <div className="col-sm-2">
      </div>
    </div>
  );
}
