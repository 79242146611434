import React from 'react'
import styles from './index.module.css'

export default function ({
    headline,
    para,
    bgColor = "#DDF7F9",
    textColor = "#173959"
}) {
  return (
    <div style={{ backgroundColor: bgColor }} className={`container-fluid ${styles.container}`}>
      <div className="container">
        <div className="row h-100">
          <div className="col">
            <h2 className="text-center marg-bottom-20">
              {headline}
            </h2>
          </div>
        </div>
        <div className="row h-100">
          <div className="col">
            <p>{para}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
