import React, { useState, useEffect, useContext } from "react";
import {
  useHistory,
} from "react-router-dom";
import "./index.css";
import styles from "./index.module.css";
import { getPosts } from "../../api/posts";
import Post from "../posts/post";
import CreateModule from "../posts/create_module";
import LoadingScreen from "../loading_screen";
import ScrollTrigger from "../ui_kit/utilities/scroll_trigger";
import Context from '../../utils/context';
import Pullable from 'react-pullable';
import WelcomePopup from "./welcome_popup";
import FindPeoplePopup from "./find_people_popup";
import SuggestedMembers from "../suggested_members";

export default function Newsfeed({
  profileId,
  classNames,
  topTwo = null,
  location = null,
  redirectToConversations = true
}) {
  const context = useContext(Context);
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(context.auth.getCurrentUser());
  const [openCreate, setOpenCreate] = useState(false);
  let onProfile = false
  if (profileId) {
    onProfile = true
  }

  const history = useHistory();
  if (redirectToConversations) {
    history.push("/member/conversations")
  }

  useEffect(() => {
    getNewsfeedPosts();
    setSeenHomePage()
  }, []);

  const getNewsfeedPosts = () => {
    setIsLoading(true);
    getPosts({ offset: posts.length, profile_id: profileId, location: location, top_two: topTwo }, (newsfeedPosts) => {
      setPosts([...posts, ...newsfeedPosts]);
      setIsLoading(false);
    });
  }

  const setSeenHomePage = () => {
    if (user.seen_home_page == null) {
      context.auth.userUpdate({ seen_home_page: new Date() }, (result) => {
        setUser(result)
      },
        (errors) => {
          console.log(errors);
        });
    }
  }

  const removeFindPeopleWidgetOnClick = () => {
    context.auth.userUpdate({ do_not_show_find_people_widget: true }, (result) => {
      setUser(result)
    },
      (errors) => {
        console.log(errors);
      });
  }

  const removeWelcomeWidgetOnClick = () => {
    context.auth.userUpdate({ do_not_show_welcome_widget: true }, (result) => {
      setUser(result)
    },
      (errors) => {
        console.log(errors);
      });
  }

  let refresh = () => {
    setIsLoading(true);
    getPosts({ offset: 0, profile_id: profileId }, (posts) => {
      setPosts(posts);
      setIsLoading(false);
    });
  }

  const removePost = (post) => {
    setPosts(posts.filter(p => p.uuid !== post.uuid));
  }

  let isCurrentUser = true
  if (profileId && context.auth.getCurrentUser().profile_id !== profileId) {
    isCurrentUser = false
  }

  let createModulePendoTag = 'discussions - start a post'
  if (window.location.pathname.includes('/member/profile/')){
    createModulePendoTag = 'profile - start a post'
  }

  return (
    <div className={`${styles.news_otr} row justify-content-center`}>
      <div className={`${styles.news_wrap} ${classNames} ${onProfile ? '' : 'col-md-6 col-11'}`}>
        {isCurrentUser && user.under_24_hrs && !user.do_not_show_welcome_widget ?
          <WelcomePopup removeWelcomeWidgetOnClick={removeWelcomeWidgetOnClick} /> : null
        }
        {isCurrentUser && !user.profile_complete && !user.do_not_show_find_people_widget ?
          <FindPeoplePopup removeFindPeopleWidgetOnClick={removeFindPeopleWidgetOnClick} /> : null
        }
        {isCurrentUser &&
          <CreateModule pendoTag={createModulePendoTag} setOpenCreate={setOpenCreate} openCreate={openCreate} refresh={refresh} />
        }
        {isLoading && <LoadingScreen />}
        {!isLoading && posts.length === 0 &&
          <div className='marg-bottom-20' style={{ textAlign: 'center' }}>
            <img
              alt="sad face"
              className="marg-top-20 marg-bottom-20"
              src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/icon+22/Bitmap.svg"
            />
            <p className={styles.no_posts_yet}>No posts yet</p>
            {profileId && context.auth.isLoggedIn() && context.auth.getCurrentUser().profile_id === profileId && <>
              <p style={{ marginTop: '8px' }} className={`${styles.npy_copy}`}>Write what's new, ask a question<br />or share useful information</p>
              <button onClick={() => setOpenCreate(true)} style={{ marginTop: '15px' }}>Start a post</button>
            </>}
          </div>
        }
        <Pullable onRefresh={refresh}>
          {posts.length > 0 ?
            <>
              {posts.map((post, i) => (
                <div key={`post-${i}`}>
                  {i === 1 && (isCurrentUser) ? <SuggestedMembers /> : null}
                  {i === posts.length - 2 &&
                    <ScrollTrigger callback={() => getNewsfeedPosts()} />
                  }
                  <Post key={`newsfeed-post-${post.uuid}`} id={post.uuid} post={post} removePost={removePost} />
                  {(posts.length === 1 && isCurrentUser) ? <SuggestedMembers /> : null }
                </div>
              ))}
            </>
            :
            <>
              {(isCurrentUser)&& <SuggestedMembers />}
            </>
          }
        </Pullable>
      </div>
    </div>
  );
}
