import React, {useState} from 'react'
import Divider from '../../../../ui_kit/utilities/divider';
import styles from '../../index.module.css'
import {
  Link,
  useRouteMatch,
} from "react-router-dom";
import GhostButton from '../../../../ui_kit/utilities/ghost_button';
import ScrollTo from '../../../../scroll_to'
import Context from '../../../../../utils/context';

export default function AboutMe({
  user
}) {
  const context = React.useContext(Context)
  const isMobile = context.viewport.getIsMobile();

  let { path } = useRouteMatch();
  path = path.replace(`:id`, user.slug);

  let profile = user.current_profile;

  const renderSurgeryOrProcedure = (cond) => {
    if (cond.surgery && cond.surgery !== "I'm not sure") {
      return cond.surgery;
    }

    return cond.procedure;
  }

  let pastProcedures = [...(new Set(profile.profile_procedures.filter((p) => p.recovered).map((p) => renderSurgeryOrProcedure(p))))];
  let currentProcedures = [...(new Set(profile.profile_procedures.filter((p) => !p.recovered).map((p) => renderSurgeryOrProcedure(p))))];

  return (<>
    {(profile.bio != null || profile.profile_procedures.length > 0) &&
      <div className={`content_container`}>
        {profile.bio != null && <>
          <p className={`${styles.sm_head}`}>About me</p>
          <p className={`${styles.bio}`}>{profile.bio}</p>
          <Link to={`${path}/about-me`}>
            <GhostButton onClick={isMobile ? () => ScrollTo('#bio', 100) : null} style={{marginTop:'10px', textAlign:'center', display: 'block'}}><span>See full bio</span></GhostButton>
          </Link>
          <Divider />
        </>}
        {currentProcedures.length > 0 && <>
          <p className={`${styles.sm_title}`}>Current Procedures/Treatments</p>
          {currentProcedures.map((cond, i) =>
            <p key={`current-procedures-${i}`} className={`${styles.tab}`}>{cond}</p>
          )}
        </>}
        {pastProcedures.length > 0 && <>
          <p style={{marginTop: '25px'}} className={`${styles.sm_title}`}>Past procedures</p>
          {pastProcedures.map((cond, i) =>
            <p key={`past-procedures-${i}`} className={`${styles.tab}`}>{cond}</p>
          )}
        </>}
      </div>
    }
  </>)
}
